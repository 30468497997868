import BaseEndpoint from './BaseEndpoint';
import Urls from './Urls';
import { downloadFile } from '../../app/util/downloadFile';
import { applyAdvancedCriteria } from './SiteEndpoint';

let CACHE_EXPORT_GROUPS;

const parseJson = exportTypes => {
  // const groups = Array.from(new Set(exportTypes.map(f => f.exportGroup)));
  const groups = exportTypes
    .map(item => item.exportGroup)
    .filter((value, index, self) => self.indexOf(value) === index);

  return groups.map(g => ({
    name: g,
    exportTypes: exportTypes
      .filter(f => f.exportGroup === g)
      .map(f => ({
        exportName: f.exportName,
        exportTypeId: f.exportTypeId,
        displayOrder: f.displayOrder,
        exportDescr: f.exportDescr,
      })),
  }));
};

/**
 * Export endpoint.
 */
export default class ExportEndpoint extends BaseEndpoint {
  getAll() {
    if (CACHE_EXPORT_GROUPS) {
      return Promise.resolve(CACHE_EXPORT_GROUPS);
    }
    const url = `${super.url(Urls.Export.All)}`;

    return super
      .get(url)
      .then(response => response.json())
      .then(json => parseJson(json))
      .then(exportGroups => {
        CACHE_EXPORT_GROUPS = exportGroups;
        return exportGroups;
      });
  }

  async downloadCsv(map, exportTypeId, fileName) {
    // add the remaining criteria
    const newCriteria = applyAdvancedCriteria({ ...map.search.criteria });

    let url = `${super.url(Urls.Export.DownloadCsv)}?${super.toParameterString(newCriteria)}`;

    url += exportTypeId ? `&exportTypeId=${encodeURIComponent(exportTypeId)}` : '';
    url += `&includeFilterCount=false`;
    return await downloadFile(url, fileName, '.zip');
  }
}
