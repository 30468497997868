import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { Box, Hr } from '../../../lib/base';
import { MEDIA_BREAKPOINT } from '../../Constants';
import { labelAsSentence } from '../../util/label';
import HeadingText from '../text/HeadingText';

class SectionPanel extends Component {
  static propTypes = {
    /**
     * Children components to render.
     */

    children: PropTypes.node.isRequired,

    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,

    /**
     * Title
     */
    title: PropTypes.string,

    /**
     * Title
     */
    titleLabelKey: PropTypes.string,

    /**
     * Pad left and right sides of content.
     */
    padded: PropTypes.bool,
  };

  static defaultProps = {
    padded: false,
  };

  render() {
    const { body } = this.props.classes;

    const titleSentence = this.props.titleLabelKey
      ? labelAsSentence(this.props.titleLabelKey)
      : this.props.title;

    return (
      <Box stack="large">
        <Box className={this.props.classes.heading}>
          <HeadingText size="large" title={titleSentence}>
            {titleSentence}
          </HeadingText>
        </Box>
        <Hr mTop="small" mBottom="large" color="gray" colorVariant="light1" />
        <div className={body}>{this.props.children}</div>
      </Box>
    );
  }
}

const styles = theme => ({
  body: {
    paddingLeft: props => (props.padded ? theme.spacing.medium : undefined),
    paddingRight: props => (props.padded ? theme.spacing.medium : undefined),
  },

  [`@media ${MEDIA_BREAKPOINT}`]: {
    heading: {
      paddingLeft: 0,
      paddingRight: 0,
    },

    body: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
});

export default injectSheet(styles)(SectionPanel);
