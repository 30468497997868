import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Map from 'ol/Map';
import { BasicOverlay } from '../../../../../../lib/map';
import LayerMetadataContainer from './metadata/LayerMetadataContainer';
import { toLonLatCenter } from '../../../../../../lib/map/core/transfomer';
import { LAYER_ID } from './SitesLayer';

export default class LayerMetadataOverlay extends Component {
  static propTypes = {
    /**
     * The instance of map this layer is attached to. The map instance
     * will be provided to the component automatically when used as a
     * child component of the <Map/>.
     */
    map: PropTypes.instanceOf(Map),
  };

  static defaultProps = {};

  constructor(props) {
    super(props);

    this.state = {
      position: undefined,
    };

    this.handleOnClick = this.handleOnClick.bind(this);
    this.handleOnCloseOverlay = this.handleOnCloseOverlay.bind(this);
  }

  componentDidMount() {
    this.props.map.on('singleclick', this.handleOnClick);
  }

  componentWillUnmount() {
    this.props.map.un('singleclick', this.handleOnClick);
  }

  handleOnClick(evt) {
    this.setState({
      position: undefined,
    });

    const layerMetadata = [];
    let sitesLayerInView = false;
    const coordinate = evt.coordinate;
    const resolution = evt.map.getView().getResolution();
    let wmsUrl;

    evt.map.forEachFeatureAtPixel(evt.pixel, function(feature) {
      if (feature.get('metadata')) {
        layerMetadata.push(feature.get('metadata'));
      }
    });

    evt.map.forEachLayerAtPixel(evt.pixel, function(layer) {
      if (layer.get('id') === LAYER_ID) {
        sitesLayerInView = true;
        return;
      }

      if (layer.get('metadata')) {
        layerMetadata.push(layer.get('metadata'));
      }

      if (
        layer.get('metadata') &&
        layer.get('metadata').layerType.match(/wms$/i) &&
        layer.getSource().getGetFeatureInfoUrl !== undefined
      ) {
        wmsUrl = layer.getSource().getGetFeatureInfoUrl(coordinate, resolution, 'EPSG:3857', {
          INFO_FORMAT: 'application/json',
          exceptions: 'application/vnd.ogc.se_inimage',
        });
      }
    });

    if (!sitesLayerInView && layerMetadata.length > 0) {
      this.setState({
        position: evt.coordinate,
        layerMetadata,
        wmsUrl: wmsUrl,
      });
    } else {
      this.setState({
        position: undefined,
      });
    }
  }

  handleOnCloseOverlay() {
    this.setState({
      position: undefined,
    });
  }

  renderOverlayContents() {
    const lonlat = toLonLatCenter(this.state.position);
    const wmsUrl = this.state.wmsUrl;

    return (
      <div style={{ maxHeight: '240px', overflowY: 'auto', paddingRight: '8px' }}>
        {this.state.layerMetadata.map(m => (
          <LayerMetadataContainer
            key={m.name}
            metadata={m}
            longitude={lonlat[0]}
            latitude={lonlat[1]}
            wmsUrl={wmsUrl}
          />
        ))}
      </div>
    );
  }

  render() {
    return (
      <BasicOverlay
        key={this.props.map.id}
        map={this.props.map}
        position={this.state.position}
        onClose={this.handleOnCloseOverlay}
        title="Layer metadata"
        render={() => this.renderOverlayContents()}
      />
    );
  }
}
