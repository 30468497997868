import {
  COLOR_BASE_BLACK,
  COLOR_BASE_BLUE,
  COLOR_BASE_FUSCIA,
  COLOR_BASE_GRAY,
  COLOR_BASE_GREEN,
  COLOR_BASE_ORANGE,
  COLOR_BASE_RED,
  COLOR_BASE_VIOLET,
  COLOR_BASE_WHITE,
  COLOR_BASE_YELLOW,
  COLOR_BLUE_DARK_1,
  COLOR_BLUE_DARK_2,
  COLOR_BLUE_DARK_3,
  COLOR_BLUE_DARK_4,
  COLOR_BLUE_DARK_5,
  COLOR_BLUE_DARK_6,
  COLOR_BLUE_LIGHT_1,
  COLOR_BLUE_LIGHT_2,
  COLOR_BLUE_LIGHT_3,
  COLOR_BLUE_LIGHT_4,
  COLOR_BLUE_LIGHT_5,
  COLOR_BLUE_LIGHT_6,
  COLOR_FUSCIA_DARK_1,
  COLOR_FUSCIA_DARK_2,
  COLOR_FUSCIA_DARK_3,
  COLOR_FUSCIA_DARK_4,
  COLOR_FUSCIA_DARK_5,
  COLOR_FUSCIA_DARK_6,
  COLOR_FUSCIA_LIGHT_1,
  COLOR_FUSCIA_LIGHT_2,
  COLOR_FUSCIA_LIGHT_3,
  COLOR_FUSCIA_LIGHT_4,
  COLOR_FUSCIA_LIGHT_5,
  COLOR_FUSCIA_LIGHT_6,
  COLOR_GRAY_DARK_1,
  COLOR_GRAY_DARK_2,
  COLOR_GRAY_DARK_3,
  COLOR_GRAY_DARK_4,
  COLOR_GRAY_DARK_5,
  COLOR_GRAY_DARK_6,
  COLOR_GRAY_LIGHT_1,
  COLOR_GRAY_LIGHT_2,
  COLOR_GRAY_LIGHT_3,
  COLOR_GRAY_LIGHT_4,
  COLOR_GRAY_LIGHT_5,
  COLOR_GRAY_LIGHT_6,
  COLOR_GREEN_DARK_1,
  COLOR_GREEN_DARK_2,
  COLOR_GREEN_DARK_3,
  COLOR_GREEN_DARK_4,
  COLOR_GREEN_DARK_5,
  COLOR_GREEN_DARK_6,
  COLOR_GREEN_LIGHT_1,
  COLOR_GREEN_LIGHT_2,
  COLOR_GREEN_LIGHT_3,
  COLOR_GREEN_LIGHT_4,
  COLOR_GREEN_LIGHT_5,
  COLOR_GREEN_LIGHT_6,
  COLOR_ORANGE_DARK_1,
  COLOR_ORANGE_DARK_2,
  COLOR_ORANGE_DARK_3,
  COLOR_ORANGE_DARK_4,
  COLOR_ORANGE_DARK_5,
  COLOR_ORANGE_DARK_6,
  COLOR_ORANGE_LIGHT_1,
  COLOR_ORANGE_LIGHT_2,
  COLOR_ORANGE_LIGHT_3,
  COLOR_ORANGE_LIGHT_4,
  COLOR_ORANGE_LIGHT_5,
  COLOR_ORANGE_LIGHT_6,
  COLOR_RED_DARK_1,
  COLOR_RED_DARK_2,
  COLOR_RED_DARK_3,
  COLOR_RED_DARK_4,
  COLOR_RED_DARK_5,
  COLOR_RED_DARK_6,
  COLOR_RED_LIGHT_1,
  COLOR_RED_LIGHT_2,
  COLOR_RED_LIGHT_3,
  COLOR_RED_LIGHT_4,
  COLOR_RED_LIGHT_5,
  COLOR_RED_LIGHT_6,
  COLOR_VIOLET_DARK_1,
  COLOR_VIOLET_DARK_2,
  COLOR_VIOLET_DARK_3,
  COLOR_VIOLET_DARK_4,
  COLOR_VIOLET_DARK_5,
  COLOR_VIOLET_DARK_6,
  COLOR_VIOLET_LIGHT_1,
  COLOR_VIOLET_LIGHT_2,
  COLOR_VIOLET_LIGHT_3,
  COLOR_VIOLET_LIGHT_4,
  COLOR_VIOLET_LIGHT_5,
  COLOR_VIOLET_LIGHT_6,
  COLOR_YELLOW_DARK_1,
  COLOR_YELLOW_DARK_2,
  COLOR_YELLOW_DARK_3,
  COLOR_YELLOW_DARK_4,
  COLOR_YELLOW_DARK_5,
  COLOR_YELLOW_DARK_6,
  COLOR_YELLOW_LIGHT_1,
  COLOR_YELLOW_LIGHT_2,
  COLOR_YELLOW_LIGHT_3,
  COLOR_YELLOW_LIGHT_4,
  COLOR_YELLOW_LIGHT_5,
  COLOR_YELLOW_LIGHT_6,
} from './variables';

const base = {
  white: COLOR_BASE_WHITE,
  black: COLOR_BASE_BLACK,
  blue: COLOR_BASE_BLUE,
  orange: COLOR_BASE_ORANGE,
  red: COLOR_BASE_RED,
  green: COLOR_BASE_GREEN,
  yellow: COLOR_BASE_YELLOW,
  violet: COLOR_BASE_VIOLET,
  fuscia: COLOR_BASE_FUSCIA,
};

const gray = {
  light1: COLOR_GRAY_LIGHT_1,
  light2: COLOR_GRAY_LIGHT_2,
  light3: COLOR_GRAY_LIGHT_3,
  light4: COLOR_GRAY_LIGHT_4,
  light5: COLOR_GRAY_LIGHT_5,
  light6: COLOR_GRAY_LIGHT_6,
  base: COLOR_BASE_GRAY,
  dark1: COLOR_GRAY_DARK_1,
  dark2: COLOR_GRAY_DARK_2,
  dark3: COLOR_GRAY_DARK_3,
  dark4: COLOR_GRAY_DARK_4,
  dark5: COLOR_GRAY_DARK_5,
  dark6: COLOR_GRAY_DARK_6,
};

const blue = {
  light1: COLOR_BLUE_LIGHT_1,
  light2: COLOR_BLUE_LIGHT_2,
  light3: COLOR_BLUE_LIGHT_3,
  light4: COLOR_BLUE_LIGHT_4,
  light5: COLOR_BLUE_LIGHT_5,
  light6: COLOR_BLUE_LIGHT_6,
  base: COLOR_BASE_BLUE,
  dark1: COLOR_BLUE_DARK_1,
  dark2: COLOR_BLUE_DARK_2,
  dark3: COLOR_BLUE_DARK_3,
  dark4: COLOR_BLUE_DARK_4,
  dark5: COLOR_BLUE_DARK_5,
  dark6: COLOR_BLUE_DARK_6,
};

const orange = {
  light1: COLOR_ORANGE_LIGHT_1,
  light2: COLOR_ORANGE_LIGHT_2,
  light3: COLOR_ORANGE_LIGHT_3,
  light4: COLOR_ORANGE_LIGHT_4,
  light5: COLOR_ORANGE_LIGHT_5,
  light6: COLOR_ORANGE_LIGHT_6,
  base: COLOR_BASE_ORANGE,
  dark1: COLOR_ORANGE_DARK_1,
  dark2: COLOR_ORANGE_DARK_2,
  dark3: COLOR_ORANGE_DARK_3,
  dark4: COLOR_ORANGE_DARK_4,
  dark5: COLOR_ORANGE_DARK_5,
  dark6: COLOR_ORANGE_DARK_6,
};

const red = {
  light1: COLOR_RED_LIGHT_1,
  light2: COLOR_RED_LIGHT_2,
  light3: COLOR_RED_LIGHT_3,
  light4: COLOR_RED_LIGHT_4,
  light5: COLOR_RED_LIGHT_5,
  light6: COLOR_RED_LIGHT_6,
  base: COLOR_BASE_RED,
  dark1: COLOR_RED_DARK_1,
  dark2: COLOR_RED_DARK_2,
  dark3: COLOR_RED_DARK_3,
  dark4: COLOR_RED_DARK_4,
  dark5: COLOR_RED_DARK_5,
  dark6: COLOR_RED_DARK_6,
};

const green = {
  light1: COLOR_GREEN_LIGHT_1,
  light2: COLOR_GREEN_LIGHT_2,
  light3: COLOR_GREEN_LIGHT_3,
  light4: COLOR_GREEN_LIGHT_4,
  light5: COLOR_GREEN_LIGHT_5,
  light6: COLOR_GREEN_LIGHT_6,
  base: COLOR_BASE_GREEN,
  dark1: COLOR_GREEN_DARK_1,
  dark2: COLOR_GREEN_DARK_2,
  dark3: COLOR_GREEN_DARK_3,
  dark4: COLOR_GREEN_DARK_4,
  dark5: COLOR_GREEN_DARK_5,
  dark6: COLOR_GREEN_DARK_6,
};

const yellow = {
  light1: COLOR_YELLOW_LIGHT_1,
  light2: COLOR_YELLOW_LIGHT_2,
  light3: COLOR_YELLOW_LIGHT_3,
  light4: COLOR_YELLOW_LIGHT_4,
  light5: COLOR_YELLOW_LIGHT_5,
  light6: COLOR_YELLOW_LIGHT_6,
  base: COLOR_BASE_YELLOW,
  dark1: COLOR_YELLOW_DARK_1,
  dark2: COLOR_YELLOW_DARK_2,
  dark3: COLOR_YELLOW_DARK_3,
  dark4: COLOR_YELLOW_DARK_4,
  dark5: COLOR_YELLOW_DARK_5,
  dark6: COLOR_YELLOW_DARK_6,
};

const violet = {
  light1: COLOR_VIOLET_LIGHT_1,
  light2: COLOR_VIOLET_LIGHT_2,
  light3: COLOR_VIOLET_LIGHT_3,
  light4: COLOR_VIOLET_LIGHT_4,
  light5: COLOR_VIOLET_LIGHT_5,
  light6: COLOR_VIOLET_LIGHT_6,
  base: COLOR_BASE_VIOLET,
  dark1: COLOR_VIOLET_DARK_1,
  dark2: COLOR_VIOLET_DARK_2,
  dark3: COLOR_VIOLET_DARK_3,
  dark4: COLOR_VIOLET_DARK_4,
  dark5: COLOR_VIOLET_DARK_5,
  dark6: COLOR_VIOLET_DARK_6,
};

const fuscia = {
  light1: COLOR_FUSCIA_LIGHT_1,
  light2: COLOR_FUSCIA_LIGHT_2,
  light3: COLOR_FUSCIA_LIGHT_3,
  light4: COLOR_FUSCIA_LIGHT_4,
  light5: COLOR_FUSCIA_LIGHT_5,
  light6: COLOR_FUSCIA_LIGHT_6,
  base: COLOR_BASE_FUSCIA,
  dark1: COLOR_FUSCIA_DARK_1,
  dark2: COLOR_FUSCIA_DARK_2,
  dark3: COLOR_FUSCIA_DARK_3,
  dark4: COLOR_FUSCIA_DARK_4,
  dark5: COLOR_FUSCIA_DARK_5,
  dark6: COLOR_FUSCIA_DARK_6,
};

export default {
  base,
  gray,
  blue,
  orange,
  red,
  green,
  yellow,
  violet,
  fuscia,
  primary: blue,
  secondary: gray,
  danger: red,
  success: green,

  /**
   * TODO Are these needed?
   */
  //warning: yellow,
  //info: fuscia,
  //light: fuscia,
  //dark: fuscia,
};
