import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { Text } from '../../../../../../../../lib/base';
import { IconClear } from '../../../../../../../components/icons';
import { COLOR_BASE_PRIMARY } from '../../../../../../../Constants';
import TouchTarget from '../../../../../../../components/touchable/TouchTarget';
import { Columns, Column } from '../../../../../../../../lib/base';

class CriteriaChip extends Component {
  static propTypes = {
    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,

    label: PropTypes.string.isRequired,

    onRemove: PropTypes.func.isRequired,

    onClick: PropTypes.func.isRequired,
  };

  render() {
    const { container, touchTarget } = this.props.classes;

    return (
      <Columns inline vAlign="center" className={container} onClick={this.props.onClick}>
        <Column>
          <Text size="smaller" color={COLOR_BASE_PRIMARY} colorVariant="dark4">
            {this.props.label}
          </Text>
        </Column>
        <Column>
          <TouchTarget onClick={this.props.onRemove} className={touchTarget}>
            <IconClear color={COLOR_BASE_PRIMARY} colorVariant="dark4" />
          </TouchTarget>
        </Column>
      </Columns>
    );
  }
}

const styles = theme => ({
  container: {
    borderRadius: `16px`,
    backgroundColor: theme.color[COLOR_BASE_PRIMARY]['light2'],
    border: `1px solid ${theme.color[COLOR_BASE_PRIMARY]['dark2']}`,
    paddingLeft: theme.spacing.small,

    '&:hover': {
      backgroundColor: theme.color[COLOR_BASE_PRIMARY]['light3'],
    },
  },

  touchTarget: {
    minWidth: '32px',
    minHeight: '32px',
  },
});

export default injectSheet(styles)(CriteriaChip);
