/**
 * Load profile detail data.
 */
export const LOAD_PROFILE_DETAIL_BEGIN = 'LOAD_PROFILE_DETAIL_BEGIN';
export const LOAD_PROFILE_DETAIL_SUCCESS = 'LOAD_PROFILE_DETAIL_SUCCESS';
export const LOAD_PROFILE_DETAIL_ERROR = 'LOAD_PROFILE_DETAIL_ERROR';

/**
 * Fetch generic data.
 */
export const FETCH_GENERIC_BEGIN = 'FETCH_GENERIC_BEGIN';
export const FETCH_GENERIC_SUCCESS = 'FETCH_GENERIC_SUCCESS';
export const FETCH_GENERIC_ERROR = 'FETCH_GENERIC_ERROR';
