const PHONE = 599;
const TABLET_P = 899;
const TABLET_L = 1199;
const DESKTOP = 1799;

const min = size => `min-width: ${size}px`;
const max = size => `max-width: ${size}px`;

/**
 * Defines the break point media selectors.
 */
const breakpoints = {
  phone: {
    up: min(PHONE),
    only: max(PHONE),
  },
  tablet: {
    portrait: {
      up: min(TABLET_P),
      down: max(TABLET_P),
    },
    landscape: {
      up: min(TABLET_L),
      down: max(TABLET_L),
    },
  },
  desktop: {
    up: min(DESKTOP),
    down: max(DESKTOP),
  },
};

export default breakpoints;
