import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import exportReducer from './export/export.reducer';
import mapReducer from './map/map.reducer';
import profileReducer from './profile/profile.reducer';
import searchReducer from './search/search.reducer';
import systemReducer from './system/system.reducer';

const isDevelopment = process.env.NODE_ENV === 'development';

const composeEnhancers =
  isDevelopment && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

export default function configureStore(initialState) {
  const reducers = {
    profile: profileReducer,
    system: systemReducer,
    map: mapReducer,
    search: searchReducer,
    exportData: exportReducer,
  };

  const middleware = [thunk];

  const rootReducer = combineReducers({
    ...reducers,
  });

  return createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(...middleware)));
}
