import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import injectSheet from 'react-jss';
import { animated, config, Transition } from 'react-spring/renderprops';
import { COLOR_BASE_PRIMARY } from '../../Constants';
import { join } from '../../util/style';
import { IconChevronDown, IconChevronUp, IconClear } from '../icons';

class Select extends PureComponent {
  static propTypes = {
    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,
    /**
     * The selected value.
     */
    value: PropTypes.any,
    /**
     * Placeholder text.
     */
    placeholder: PropTypes.string,
    /**
     * Handle when selected choice has changed.
     */
    onChange: PropTypes.func,
    /**
     * Is choice panel open?
     */
    isOpen: PropTypes.bool,
    /**
     * Arrayy of choices.
     */
    choices: PropTypes.array.isRequired,
    /**
     * Key extractor for each choice.
     */
    keyExtractor: PropTypes.func.isRequired,
    /**
     * Label extractor for each choice.
     */
    labelExtractor: PropTypes.func.isRequired,
    /**
     * Renders each choice.
     */
    renderChoice: PropTypes.func.isRequired,
    /**
     * Width of the select.
     */
    width: PropTypes.string,
    /**
     * Can clear the selection.
     */
    canClear: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    children: undefined,
    onChange: undefined,
    isOpen: false,
    width: undefined,
    canClear: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpen: props.isOpen,
      choices: props.choices,
    };

    this.container = React.createRef();

    this.handleOnClick = this.handleOnClick.bind(this);
    this.handleClearValue = this.handleClearValue.bind(this);
  }

  componentDidMount() {
    this.setState({ containerLayout: this.container.current.getBoundingClientRect() });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.choices !== prevProps.choices) {
      this.setState({ choices: this.props.choices });
    }
  }

  handleOnClick(event) {
    this.setState({ isOpen: !this.state.isOpen });
    event.stopPropagation();
  }

  handleOnClickChoice(event, item, index) {
    this.props.onChange(event, item, index);
  }

  handleClearValue(event) {
    this.props.onChange(event, undefined, undefined);
    event.stopPropagation();
  }

  isSelected(item, index) {
    return (
      this.props.value &&
      this.props.keyExtractor(item, index) === this.props.keyExtractor(this.props.value, index)
    );
  }

  renderPlaceholder() {
    if (this.props.value) {
      return this.props.labelExtractor(this.props.value);
    }
    return this.props.placeholder;
  }

  renderCaret() {
    if (this.props.value && this.props.canClear) {
      return (
        <span onClick={this.handleClearValue}>
          <IconClear />
        </span>
      );
    }

    return this.state.isOpen ? (
      <IconChevronDown color={COLOR_BASE_PRIMARY} colorVariant="dark4" />
    ) : (
      <IconChevronUp color={COLOR_BASE_PRIMARY} colorVariant="dark4" />
    );
  }

  render() {
    const {
      container,
      choices,
      placeholder,
      caret,
      placeholderText,
      choice,
      selected,
    } = this.props.classes;

    const containerBoxStyle =
      this.props.width !== undefined
        ? {
            minWidth: this.props.width,
          }
        : {};

    const choiceBoxStyle =
      this.state.containerLayout !== undefined
        ? {
            top: this.state.containerLayout.height,
            left: 0,
          }
        : {};

    return (
      <div
        ref={this.container}
        className={container}
        style={containerBoxStyle}
        onClick={this.handleOnClick}
      >
        <div className={placeholder}>
          <div className={placeholderText}>{this.renderPlaceholder()}</div>
          <div className={caret}>{this.renderCaret()}</div>
        </div>
        <Transition
          native
          items={this.state.isOpen}
          from={{ left: 0, top: choiceBoxStyle.top - 2, height: 0 }}
          enter={{ left: 0, top: choiceBoxStyle.top - 2, height: 'auto' }}
          leave={{ left: 0, top: choiceBoxStyle.top - 2, height: 0 }}
          config={{ ...config.stiff, clamp: true }}
        >
          {on =>
            on &&
            (springStyles => (
              <animated.div className={choices} style={springStyles}>
                {this.state.choices.map((item, index) => {
                  return (
                    <div
                      key={this.props.keyExtractor(item, index)}
                      className={join(choice, this.isSelected(item, index) ? selected : null)}
                      onClick={e => this.handleOnClickChoice(e, item, index)}
                    >
                      {this.props.renderChoice(item, index)}
                    </div>
                  );
                })}
              </animated.div>
            ))
          }
        </Transition>
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    display: 'inline-block',
    position: 'relative',
    minHeight: '48px',
    width: '100%',
    padding: '16px',
    paddingLeft: '16px',
    backgroundColor: 'white',
    border: `1px solid ${theme.color[COLOR_BASE_PRIMARY].light4}`,

    '&:hover': {
      cursor: 'pointer',
    },
  },

  pressed: {
    backgroundColor: theme.color[COLOR_BASE_PRIMARY].light4,
    border: `1px solid ${theme.color[COLOR_BASE_PRIMARY].light4}`,
  },

  placeholder: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  placeholderText: {
    flex: 1,
  },

  caret: {
    flex: 0,
    marginLeft: '16px',
  },

  choices: {
    position: 'absolute',
    zIndex: 999,
    backgroundColor: 'white',
    overflow: 'hidden',
    overflowY: 'auto',
    border: `1px solid ${theme.color[COLOR_BASE_PRIMARY].light4}`,
    marginBottom: '24px',
    marginLeft: '-1px',
    width: 'calc(100% + 2px)',
    ...theme.elevation.shadow3,
  },

  choice: {
    minHeight: '48px',
    width: '100%',
    padding: '8px 16px',
    display: 'flex',
    alignItems: 'center',

    '&:hover': {
      backgroundColor: theme.color[COLOR_BASE_PRIMARY].light2,
    },

    '& > *': {
      display: 'block',
      width: '100%',
    },
  },

  selected: {
    backgroundColor: theme.color[COLOR_BASE_PRIMARY].light4,

    '&:hover': {
      backgroundColor: theme.color[COLOR_BASE_PRIMARY].light5,
    },
  },
});

export default injectSheet(styles)(Select);
