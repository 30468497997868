import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Box, Button, Column, Columns, Paragraph } from '../../../../../../../lib/base';
import {
  TOOL_TYPE_CIRCLE,
  TOOL_TYPE_LINE,
  TOOL_TYPE_POLYGON,
} from '../../../../../../../lib/map/core/measure/MeasureTool';
import {
  areaUnitsAsArray,
  lengthUnitsAsArray,
} from '../../../../../../../lib/map/core/measure/uom';
import { selectMeasureTool } from '../../../../../../../store/map/map.action';
import HeadingText from '../../../../../../components/text/HeadingText';
import {
  COLOR_BASE_PRIMARY,
  COLOR_VARIANT_PRIMARY,
  DEFAULT_TEXT_SIZE_DESCRIPTIONS,
} from '../../../../../../Constants';
import Select from '../../../../../../components/form/Select';
import { IconErase } from '../../../../../../components/icons/fontawesome/icons';

class MeasurePanel extends Component {
  static propTypes = {
    /**
     * The active drawing tool.
     */
    activeMeasureTool: PropTypes.string,

    /**
     * Called when measurements are cleared.
     */
    onClickClearMeasurements: PropTypes.func.isRequired,

    /**
     * Called when unit selection changes.
     */
    onUnitChange: PropTypes.func.isRequired,
  };

  componentWillUnmount() {
    const dispatchSelectTool = () => this.props.dispatchSelectMeasureTool();
    dispatchSelectTool(undefined);
  }

  static defaultProps = {
    children: undefined,
  };

  constructor(props) {
    super(props);

    this.state = {
      unit: undefined,
      unitChoices: areaUnitsAsArray(),
    };

    this.handleOnClickLine = this.handleOnClickLine.bind(this);
    this.handleOnClickCircle = this.handleOnClickCircle.bind(this);
    this.handleOnClickPolygon = this.handleOnClickPolygon.bind(this);
    this.handleOnClickClearMeasurements = this.handleOnClickClearMeasurements.bind(this);
    this.handleOnChangeUnit = this.handleOnChangeUnit.bind(this);
  }

  handleOnClickLine(event) {
    this.handleOnClickTool(event, TOOL_TYPE_LINE);
  }

  handleOnClickCircle(event, turnOn) {
    this.handleOnClickTool(event, TOOL_TYPE_CIRCLE);
  }

  handleOnClickPolygon(event, turnOn) {
    this.handleOnClickTool(event, TOOL_TYPE_POLYGON);
  }

  handleOnClickTool(event, tool) {
    const dispatchSelectTool = tool => this.props.dispatchSelectMeasureTool(tool);

    const turnOff = tool === this.props.activeMeasureTool;

    if (turnOff) {
      dispatchSelectTool(undefined);
      this.updateUnitsSelect(event, undefined);
    } else {
      dispatchSelectTool(tool);
      this.updateUnitsSelect(event, tool);
    }
  }

  handleOnClickClearMeasurements(event) {
    this.props.onClickClearMeasurements(event);
    // turn off measuring tool
    const dispatchSelectTool = () => this.props.dispatchSelectMeasureTool();
    dispatchSelectTool(undefined);
    this.updateUnitsSelect(event, undefined);
  }

  isPressed(tool) {
    return this.props.activeMeasureTool === tool;
  }

  handleOnChangeUnit(event, choice, index) {
    this.setState({ unit: choice });
    if (this.props.onUnitChange) {
      this.props.onUnitChange(event, choice);
    }
  }

  updateUnitsSelect(event, tool) {
    let isUnitsSelectable;
    let unitChoices;
    let unit = this.state.unit;

    switch (tool) {
      case TOOL_TYPE_LINE:
        isUnitsSelectable = true;
        unitChoices = lengthUnitsAsArray();
        break;

      case TOOL_TYPE_POLYGON:
      case TOOL_TYPE_CIRCLE:
        isUnitsSelectable = true;
        unitChoices = areaUnitsAsArray();
        break;

      default:
        isUnitsSelectable = false;
        break;
    }

    if (!this.state.unit) {
      unit = unitChoices[0];
    } else if (
      unitChoices &&
      unitChoices.find(c => c.label === this.state.unit.label) === undefined
    ) {
      unit = unitChoices[0];
    }

    if (unit !== this.state.unit) {
      this.props.onUnitChange(event, unit);
    }

    this.setState({
      isUnitsSelectable,
      unitChoices,
      unit,
    });
  }

  render() {
    return (
      <Box pad="medium">
        <Box stack="smaller">
          <HeadingText>Measure Tool</HeadingText>
        </Box>

        <Paragraph size={DEFAULT_TEXT_SIZE_DESCRIPTIONS}>
          Select the line, circle, or polygon tool below and then click the map to measure your
          first point - double-click to complete the measurement.
        </Paragraph>

        <Columns gutter="smallest">
          <Column flex={1}>
            <Button
              block
              onClick={e => this.handleOnClickLine(e)}
              color={COLOR_BASE_PRIMARY}
              colorVariant={COLOR_VARIANT_PRIMARY}
              pressed={this.isPressed(TOOL_TYPE_LINE)}
            >
              Line
            </Button>
          </Column>
          <Column flex={1}>
            <Button
              block
              onClick={e => this.handleOnClickCircle(e)}
              color={COLOR_BASE_PRIMARY}
              colorVariant={COLOR_VARIANT_PRIMARY}
              pressed={this.isPressed(TOOL_TYPE_CIRCLE)}
            >
              Circle
            </Button>
          </Column>
          <Column flex={1}>
            <Button
              block
              onClick={e => this.handleOnClickPolygon(e)}
              color={COLOR_BASE_PRIMARY}
              colorVariant={COLOR_VARIANT_PRIMARY}
              pressed={this.isPressed(TOOL_TYPE_POLYGON)}
            >
              Polygon
            </Button>
          </Column>
        </Columns>

        {this.state.isUnitsSelectable && (
          <Box stack="small" mTop="small">
            <Select
              value={this.state.unit}
              onChange={this.handleOnChangeUnit}
              placeholder="Choose..."
              choices={this.state.unitChoices}
              keyExtractor={choice => choice.id}
              labelExtractor={choice => choice.label}
              renderChoice={choice => <div>{choice.label}</div>}
            />
          </Box>
        )}
        <Box textAlign="right" mTop="medium">
          <Button
            color={COLOR_BASE_PRIMARY}
            onClick={this.handleOnClickClearMeasurements}
            title="Delete all measurements."
            type="flat"
            size="small"
          >
            <IconErase color={COLOR_BASE_PRIMARY} />
            Clear Measurements
          </Button>
        </Box>
      </Box>
    );
  }
}

function mapStateToProps(state) {
  return {
    activeMeasureTool: state.map.activeMeasureTool,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchSelectMeasureTool: tool => {
      dispatch(selectMeasureTool(tool));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MeasurePanel);
