import Color from 'color';

/*********************************************************************
 *
 * CORE
 *
 * The whole design system is designed to fit onto an 8pt grid. Everything
 * from margin & padding to font-size and line-height is based off of
 * this value. Because of this all elements on the screen will line up
 * beautifully and provide for consistent look and feel.
 *
 *********************************************************************/

/**
 * The god unit - all things layout and sizing are based off this value, do not change.
 */
export const GRID_UNIT = 8;

export const GRID_UNIT_1X = GRID_UNIT;
export const GRID_UNIT_2X = GRID_UNIT * 2;
export const GRID_UNIT_3X = GRID_UNIT * 3;
export const GRID_UNIT_4X = GRID_UNIT * 4;
export const GRID_UNIT_5X = GRID_UNIT * 5;
export const GRID_UNIT_6X = GRID_UNIT * 6;

/*********************************************************************
 *
 * COLOR
 *
 *********************************************************************/

/**
 * Ratios to lighten and darken base colors.
 */
export const COLOR_LIGHTEN_1_RATIO = 0.82;
export const COLOR_LIGHTEN_2_RATIO = 0.595;
export const COLOR_LIGHTEN_3_RATIO = 0.371;
export const COLOR_LIGHTEN_4_RATIO = 0.223;
export const COLOR_LIGHTEN_5_RATIO = 0.148;
export const COLOR_LIGHTEN_6_RATIO = 0.075;

export const COLOR_DARKEN_6_RATIO = 0.52;
export const COLOR_DARKEN_5_RATIO = 0.37;
export const COLOR_DARKEN_4_RATIO = 0.296;
export const COLOR_DARKEN_3_RATIO = 0.226;
export const COLOR_DARKEN_2_RATIO = 0.148;
export const COLOR_DARKEN_1_RATIO = 0.075;

/**
 * Base - Each color is related by triadic color scheme, a color is a triad of another.
 *
 */
export const COLOR_BASE_WHITE = '#FFFFFF';
export const COLOR_BASE_BLACK = '#000000';
export const COLOR_BASE_GRAY = '#828282';
export const COLOR_BASE_BLUE = '#228AE6';
export const COLOR_BASE_ORANGE = '#E67E22';
export const COLOR_BASE_RED = '#E62228';
export const COLOR_BASE_GREEN = '#8AE622';
export const COLOR_BASE_YELLOW = '#E6E022';
export const COLOR_BASE_VIOLET = '#7E22E6';
export const COLOR_BASE_FUSCIA = '#E6228A';

/**
 * Grays: shades & tints
 */
let color = Color(COLOR_BASE_GRAY);

export const COLOR_GRAY_LIGHT_1 = color.lighten(COLOR_LIGHTEN_1_RATIO).hex();
export const COLOR_GRAY_LIGHT_2 = color.lighten(COLOR_LIGHTEN_2_RATIO).hex();
export const COLOR_GRAY_LIGHT_3 = color.lighten(COLOR_LIGHTEN_3_RATIO).hex();
export const COLOR_GRAY_LIGHT_4 = color.lighten(COLOR_LIGHTEN_4_RATIO).hex();
export const COLOR_GRAY_LIGHT_5 = color.lighten(COLOR_LIGHTEN_5_RATIO).hex();
export const COLOR_GRAY_LIGHT_6 = color.lighten(COLOR_LIGHTEN_6_RATIO).hex();

export const COLOR_GRAY_DARK_1 = color.darken(COLOR_DARKEN_1_RATIO).hex();
export const COLOR_GRAY_DARK_2 = color.darken(COLOR_DARKEN_2_RATIO).hex();
export const COLOR_GRAY_DARK_3 = color.darken(COLOR_DARKEN_3_RATIO).hex();
export const COLOR_GRAY_DARK_4 = color.darken(COLOR_DARKEN_4_RATIO).hex();
export const COLOR_GRAY_DARK_5 = color.darken(COLOR_DARKEN_5_RATIO).hex();
export const COLOR_GRAY_DARK_6 = color.darken(COLOR_DARKEN_6_RATIO).hex();

/**
 * Blues: shades & tints
 */
color = Color(COLOR_BASE_BLUE);

export const COLOR_BLUE_LIGHT_1 = color.lighten(COLOR_LIGHTEN_1_RATIO).hex();
export const COLOR_BLUE_LIGHT_2 = color.lighten(COLOR_LIGHTEN_2_RATIO).hex();
export const COLOR_BLUE_LIGHT_3 = color.lighten(COLOR_LIGHTEN_3_RATIO).hex();
export const COLOR_BLUE_LIGHT_4 = color.lighten(COLOR_LIGHTEN_4_RATIO).hex();
export const COLOR_BLUE_LIGHT_5 = color.lighten(COLOR_LIGHTEN_5_RATIO).hex();
export const COLOR_BLUE_LIGHT_6 = color.lighten(COLOR_LIGHTEN_6_RATIO).hex();

export const COLOR_BLUE_DARK_1 = color.darken(COLOR_DARKEN_1_RATIO).hex();
export const COLOR_BLUE_DARK_2 = color.darken(COLOR_DARKEN_2_RATIO).hex();
export const COLOR_BLUE_DARK_3 = color.darken(COLOR_DARKEN_3_RATIO).hex();
export const COLOR_BLUE_DARK_4 = color.darken(COLOR_DARKEN_4_RATIO).hex();
export const COLOR_BLUE_DARK_5 = color.darken(COLOR_DARKEN_5_RATIO).hex();
export const COLOR_BLUE_DARK_6 = color.darken(COLOR_DARKEN_6_RATIO).hex();

/**
 * Oranges: shades & tints
 */
color = Color(COLOR_BASE_ORANGE);

export const COLOR_ORANGE_LIGHT_1 = color.lighten(COLOR_LIGHTEN_1_RATIO).hex();
export const COLOR_ORANGE_LIGHT_2 = color.lighten(COLOR_LIGHTEN_2_RATIO).hex();
export const COLOR_ORANGE_LIGHT_3 = color.lighten(COLOR_LIGHTEN_3_RATIO).hex();
export const COLOR_ORANGE_LIGHT_4 = color.lighten(COLOR_LIGHTEN_4_RATIO).hex();
export const COLOR_ORANGE_LIGHT_5 = color.lighten(COLOR_LIGHTEN_5_RATIO).hex();
export const COLOR_ORANGE_LIGHT_6 = color.lighten(COLOR_LIGHTEN_6_RATIO).hex();

export const COLOR_ORANGE_DARK_1 = color.darken(COLOR_DARKEN_1_RATIO).hex();
export const COLOR_ORANGE_DARK_2 = color.darken(COLOR_DARKEN_2_RATIO).hex();
export const COLOR_ORANGE_DARK_3 = color.darken(COLOR_DARKEN_3_RATIO).hex();
export const COLOR_ORANGE_DARK_4 = color.darken(COLOR_DARKEN_4_RATIO).hex();
export const COLOR_ORANGE_DARK_5 = color.darken(COLOR_DARKEN_5_RATIO).hex();
export const COLOR_ORANGE_DARK_6 = color.darken(COLOR_DARKEN_6_RATIO).hex();

/**
 * Reds: shades & tints
 */
color = Color(COLOR_BASE_RED);

export const COLOR_RED_LIGHT_1 = color.lighten(COLOR_LIGHTEN_1_RATIO).hex();
export const COLOR_RED_LIGHT_2 = color.lighten(COLOR_LIGHTEN_2_RATIO).hex();
export const COLOR_RED_LIGHT_3 = color.lighten(COLOR_LIGHTEN_3_RATIO).hex();
export const COLOR_RED_LIGHT_4 = color.lighten(COLOR_LIGHTEN_4_RATIO).hex();
export const COLOR_RED_LIGHT_5 = color.lighten(COLOR_LIGHTEN_5_RATIO).hex();
export const COLOR_RED_LIGHT_6 = color.lighten(COLOR_LIGHTEN_6_RATIO).hex();

export const COLOR_RED_DARK_1 = color.darken(COLOR_DARKEN_1_RATIO).hex();
export const COLOR_RED_DARK_2 = color.darken(COLOR_DARKEN_2_RATIO).hex();
export const COLOR_RED_DARK_3 = color.darken(COLOR_DARKEN_3_RATIO).hex();
export const COLOR_RED_DARK_4 = color.darken(COLOR_DARKEN_4_RATIO).hex();
export const COLOR_RED_DARK_5 = color.darken(COLOR_DARKEN_5_RATIO).hex();
export const COLOR_RED_DARK_6 = color.darken(COLOR_DARKEN_6_RATIO).hex();

/**
 * Greens: shades & tints
 */
color = Color(COLOR_BASE_GREEN);

export const COLOR_GREEN_LIGHT_1 = color.lighten(COLOR_LIGHTEN_1_RATIO).hex();
export const COLOR_GREEN_LIGHT_2 = color.lighten(COLOR_LIGHTEN_2_RATIO).hex();
export const COLOR_GREEN_LIGHT_3 = color.lighten(COLOR_LIGHTEN_3_RATIO).hex();
export const COLOR_GREEN_LIGHT_4 = color.lighten(COLOR_LIGHTEN_4_RATIO).hex();
export const COLOR_GREEN_LIGHT_5 = color.lighten(COLOR_LIGHTEN_5_RATIO).hex();
export const COLOR_GREEN_LIGHT_6 = color.lighten(COLOR_LIGHTEN_6_RATIO).hex();

export const COLOR_GREEN_DARK_1 = color.darken(COLOR_DARKEN_1_RATIO).hex();
export const COLOR_GREEN_DARK_2 = color.darken(COLOR_DARKEN_2_RATIO).hex();
export const COLOR_GREEN_DARK_3 = color.darken(COLOR_DARKEN_3_RATIO).hex();
export const COLOR_GREEN_DARK_4 = color.darken(COLOR_DARKEN_4_RATIO).hex();
export const COLOR_GREEN_DARK_5 = color.darken(COLOR_DARKEN_5_RATIO).hex();
export const COLOR_GREEN_DARK_6 = color.darken(COLOR_DARKEN_6_RATIO).hex();

/**
 * Yellows: shades & tints
 */
color = Color(COLOR_BASE_YELLOW);

export const COLOR_YELLOW_LIGHT_1 = color.lighten(COLOR_LIGHTEN_1_RATIO).hex();
export const COLOR_YELLOW_LIGHT_2 = color.lighten(COLOR_LIGHTEN_2_RATIO).hex();
export const COLOR_YELLOW_LIGHT_3 = color.lighten(COLOR_LIGHTEN_3_RATIO).hex();
export const COLOR_YELLOW_LIGHT_4 = color.lighten(COLOR_LIGHTEN_4_RATIO).hex();
export const COLOR_YELLOW_LIGHT_5 = color.lighten(COLOR_LIGHTEN_5_RATIO).hex();
export const COLOR_YELLOW_LIGHT_6 = color.lighten(COLOR_LIGHTEN_6_RATIO).hex();

export const COLOR_YELLOW_DARK_1 = color.darken(COLOR_DARKEN_1_RATIO).hex();
export const COLOR_YELLOW_DARK_2 = color.darken(COLOR_DARKEN_2_RATIO).hex();
export const COLOR_YELLOW_DARK_3 = color.darken(COLOR_DARKEN_3_RATIO).hex();
export const COLOR_YELLOW_DARK_4 = color.darken(COLOR_DARKEN_4_RATIO).hex();
export const COLOR_YELLOW_DARK_5 = color.darken(COLOR_DARKEN_5_RATIO).hex();
export const COLOR_YELLOW_DARK_6 = color.darken(COLOR_DARKEN_6_RATIO).hex();

/**
 * Purple: shades & tints
 */
color = Color(COLOR_BASE_VIOLET);

export const COLOR_VIOLET_LIGHT_1 = color.lighten(COLOR_LIGHTEN_1_RATIO).hex();
export const COLOR_VIOLET_LIGHT_2 = color.lighten(COLOR_LIGHTEN_2_RATIO).hex();
export const COLOR_VIOLET_LIGHT_3 = color.lighten(COLOR_LIGHTEN_3_RATIO).hex();
export const COLOR_VIOLET_LIGHT_4 = color.lighten(COLOR_LIGHTEN_4_RATIO).hex();
export const COLOR_VIOLET_LIGHT_5 = color.lighten(COLOR_LIGHTEN_5_RATIO).hex();
export const COLOR_VIOLET_LIGHT_6 = color.lighten(COLOR_LIGHTEN_6_RATIO).hex();

export const COLOR_VIOLET_DARK_1 = color.darken(COLOR_DARKEN_1_RATIO).hex();
export const COLOR_VIOLET_DARK_2 = color.darken(COLOR_DARKEN_2_RATIO).hex();
export const COLOR_VIOLET_DARK_3 = color.darken(COLOR_DARKEN_3_RATIO).hex();
export const COLOR_VIOLET_DARK_4 = color.darken(COLOR_DARKEN_4_RATIO).hex();
export const COLOR_VIOLET_DARK_5 = color.darken(COLOR_DARKEN_5_RATIO).hex();
export const COLOR_VIOLET_DARK_6 = color.darken(COLOR_DARKEN_6_RATIO).hex();

/**
 * Fuscia: shades & tints
 */
color = Color(COLOR_BASE_FUSCIA);

export const COLOR_FUSCIA_LIGHT_1 = color.lighten(COLOR_LIGHTEN_1_RATIO).hex();
export const COLOR_FUSCIA_LIGHT_2 = color.lighten(COLOR_LIGHTEN_2_RATIO).hex();
export const COLOR_FUSCIA_LIGHT_3 = color.lighten(COLOR_LIGHTEN_3_RATIO).hex();
export const COLOR_FUSCIA_LIGHT_4 = color.lighten(COLOR_LIGHTEN_4_RATIO).hex();
export const COLOR_FUSCIA_LIGHT_5 = color.lighten(COLOR_LIGHTEN_5_RATIO).hex();
export const COLOR_FUSCIA_LIGHT_6 = color.lighten(COLOR_LIGHTEN_6_RATIO).hex();

export const COLOR_FUSCIA_DARK_1 = color.darken(COLOR_DARKEN_1_RATIO).hex();
export const COLOR_FUSCIA_DARK_2 = color.darken(COLOR_DARKEN_2_RATIO).hex();
export const COLOR_FUSCIA_DARK_3 = color.darken(COLOR_DARKEN_3_RATIO).hex();
export const COLOR_FUSCIA_DARK_4 = color.darken(COLOR_DARKEN_4_RATIO).hex();
export const COLOR_FUSCIA_DARK_5 = color.darken(COLOR_DARKEN_5_RATIO).hex();
export const COLOR_FUSCIA_DARK_6 = color.darken(COLOR_DARKEN_6_RATIO).hex();

/*********************************************************************
 *
 * TYPOGRAPHY
 *
 *********************************************************************/

/**
 * Typeface
 *
 * Note: fonts are installed through npm (ie.  typeface-open-sans) and
 * must be imported in your <MyAppRoot/> component.
 *
 * <code>
 *    import 'typeface-open-sans';
 *    import 'typeface-open-sans-condensed';
 * </code>
 *
 */
export const TYPE_FONT_FAMILY_SAN_SERIF = '"Open Sans", sans-serif';
export const TYPE_FONT_FAMILY_SERIF = ''; // TODO - Find and install a serif font.
export const TYPE_FONT_FAMILY_MONOSPACED = ''; // TODO - Find and install a monospaced font.

/**
 * Font sizes smallest through largest.
 */
export const TYPE_FONT_SIZE_LARGEST = 32;
export const TYPE_FONT_SIZE_LARGER = 24;
export const TYPE_FONT_SIZE_LARGE = 18;
export const TYPE_FONT_SIZE_MEDIUM = 16;
export const TYPE_FONT_SIZE_SMALL = 14;
export const TYPE_FONT_SIZE_SMALLER = 13;
export const TYPE_FONT_SIZE_SMALLEST = 10;
export const TYPE_FONT_SIZE_DEFAULT = TYPE_FONT_SIZE_SMALL;

/**
 * Font weight from thin/light to heavy/dark.
 */
export const TYPE_FONT_WEIGHT_LIGHTEST = 100;
export const TYPE_FONT_WEIGHT_LIGHTER = 300;
export const TYPE_FONT_WEIGHT_LIGHT = 300;
export const TYPE_FONT_WEIGHT_NORMAL = 400;
export const TYPE_FONT_WEIGHT_HEAVY = 600;
export const TYPE_FONT_WEIGHT_HEAVIER = 700;
export const TYPE_FONT_WEIGHT_HEAVIEST = 800;
export const TYPE_FONT_WEIGHT_DEFAULT = 400;

/**
 * Default body text colors.
 */
export const TYPE_COLOR_DEFAULT = COLOR_GRAY_DARK_6;
export const TYPE_COLOR_MUTED = COLOR_GRAY_DARK_5;

/**
 *  Line height (aka "leading")
 */
export const TYPE_LINE_HEIGHT_RATIO = 1.5;
export const TYPE_LINE_HEIGHT_RATIO_HEADING = 1.25;

/*********************************************************************
 *
 * ELEVATION
 *
 * For giving containers a sense of elevation with box shadows.
 *
 *********************************************************************/

const ELEVATION_SHADOW_ALPHA = '0.3';

/**
 * Shadows - from low to high.
 */
export const ELEVATION_SHADOW_1 = `0 1px 2px 0 rgba(0,0,0,${ELEVATION_SHADOW_ALPHA})`;
export const ELEVATION_SHADOW_2 = `0 2px 4px 0 rgba(0,0,0,${ELEVATION_SHADOW_ALPHA})`;
export const ELEVATION_SHADOW_3 = `0 4px 8px 0 rgba(0,0,0,${ELEVATION_SHADOW_ALPHA})`;
export const ELEVATION_SHADOW_4 = `0 8px 16px 0 rgba(0,0,0,${ELEVATION_SHADOW_ALPHA})`;
export const ELEVATION_SHADOW_5 = `0 16px 32px 0 rgba(0,0,0,${ELEVATION_SHADOW_ALPHA})`;
export const ELEVATION_SHADOW_6 = `0 32px 64px 0 rgba(0,0,0,${ELEVATION_SHADOW_ALPHA})`;

/*********************************************************************
 *
 * SPACING
 *
 * For giving containers margin, and padding.
 *
 *********************************************************************/

const SPACING_BASE = 2;

export const SPACING_SMALLEST = Math.pow(SPACING_BASE, 1); // 2
export const SPACING_SMALLER = Math.pow(SPACING_BASE, 2); // 4
export const SPACING_SMALL = Math.pow(SPACING_BASE, 3); // 8
export const SPACING_MEDIUM = Math.pow(SPACING_BASE, 4); // 16
export const SPACING_LARGE = Math.pow(SPACING_BASE, 5); // 32
export const SPACING_LARGER = Math.pow(SPACING_BASE, 6); // 64
export const SPACING_LARGEST = Math.pow(SPACING_BASE, 7); // 128

/*********************************************************************
 *
 * BUTTONS
 *
 *********************************************************************/

export const BUTTON_DEFAULT_COLOR = COLOR_GRAY_DARK_2;
export const BUTTON_DEFAULT_BORDER_WIDTH = 2;
export const BUTTON_DEFAULT_BORDER_COLOR = BUTTON_DEFAULT_COLOR;
export const BUTTON_DEFAULT_BORDER_STYLE = 'solid';

/**
 * Button size
 */
export const BUTTON_SIZE_SMALLEST_HEIGHT = GRID_UNIT * 1.5;
export const BUTTON_SIZE_SMALLEST_LINE_HEIGHT = GRID_UNIT;
export const BUTTON_SIZE_SMALLEST_FONT_SIZE = GRID_UNIT;
export const BUTTON_SIZE_SMALLEST_BORDER_WIDTH = 1;
export const BUTTON_SIZE_SMALLEST_PADDING_X = GRID_UNIT / 2;
export const BUTTON_SIZE_SMALLEST_PADDING_Y = 0;
export const BUTTON_SIZE_SMALLEST_RADIUS = 2;

export const BUTTON_SIZE_SMALLER_HEIGHT = GRID_UNIT * 2;
export const BUTTON_SIZE_SMALLER_LINE_HEIGHT = GRID_UNIT * 1.25;
export const BUTTON_SIZE_SMALLER_FONT_SIZE = BUTTON_SIZE_SMALLER_LINE_HEIGHT;
export const BUTTON_SIZE_SMALLER_BORDER_WIDTH = 1;
export const BUTTON_SIZE_SMALLER_PADDING_X = GRID_UNIT;
export const BUTTON_SIZE_SMALLER_PADDING_Y = 0;
export const BUTTON_SIZE_SMALLER_RADIUS = 2;

export const BUTTON_SIZE_SMALL_HEIGHT = GRID_UNIT * 2.5;
export const BUTTON_SIZE_SMALL_LINE_HEIGHT = GRID_UNIT * 1.5;
export const BUTTON_SIZE_SMALL_FONT_SIZE = BUTTON_SIZE_SMALL_LINE_HEIGHT;
export const BUTTON_SIZE_SMALL_BORDER_WIDTH = 1;
export const BUTTON_SIZE_SMALL_PADDING_X = GRID_UNIT * 1.5;
export const BUTTON_SIZE_SMALL_PADDING_Y = 0;
export const BUTTON_SIZE_SMALL_RADIUS = 2;

export const BUTTON_SIZE_MEDIUM_HEIGHT = GRID_UNIT * 3.5;
export const BUTTON_SIZE_MEDIUM_LINE_HEIGHT = GRID_UNIT * 1.75;
export const BUTTON_SIZE_MEDIUM_FONT_SIZE = BUTTON_SIZE_MEDIUM_LINE_HEIGHT;
export const BUTTON_SIZE_MEDIUM_BORDER_WIDTH = 1;
export const BUTTON_SIZE_MEDIUM_PADDING_X = GRID_UNIT * 3;
export const BUTTON_SIZE_MEDIUM_PADDING_Y = 0;
export const BUTTON_SIZE_MEDIUM_RADIUS = 2;

export const BUTTON_SIZE_LARGE_HEIGHT = GRID_UNIT * 4;
export const BUTTON_SIZE_LARGE_LINE_HEIGHT = GRID_UNIT * 1.75;
export const BUTTON_SIZE_LARGE_FONT_SIZE = BUTTON_SIZE_LARGE_LINE_HEIGHT;
export const BUTTON_SIZE_LARGE_BORDER_WIDTH = 1;
export const BUTTON_SIZE_LARGE_PADDING_X = GRID_UNIT * 4;
export const BUTTON_SIZE_LARGE_PADDING_Y = 0;
export const BUTTON_SIZE_LARGE_RADIUS = 2;

export const BUTTON_SIZE_LARGER_HEIGHT = GRID_UNIT * 5;
export const BUTTON_SIZE_LARGER_LINE_HEIGHT = GRID_UNIT * 1.75;
export const BUTTON_SIZE_LARGER_FONT_SIZE = BUTTON_SIZE_LARGER_LINE_HEIGHT;
export const BUTTON_SIZE_LARGER_BORDER_WIDTH = 2;
export const BUTTON_SIZE_LARGER_PADDING_X = GRID_UNIT * 5;
export const BUTTON_SIZE_LARGER_PADDING_Y = 0;
export const BUTTON_SIZE_LARGER_RADIUS = 2;

export const BUTTON_SIZE_LARGEST_HEIGHT = GRID_UNIT * 7;
export const BUTTON_SIZE_LARGEST_LINE_HEIGHT = GRID_UNIT * 1.75;
export const BUTTON_SIZE_LARGEST_FONT_SIZE = BUTTON_SIZE_LARGEST_LINE_HEIGHT;
export const BUTTON_SIZE_LARGEST_BORDER_WIDTH = 3;
export const BUTTON_SIZE_LARGEST_PADDING_X = GRID_UNIT * 6;
export const BUTTON_SIZE_LARGEST_PADDING_Y = 0;
export const BUTTON_SIZE_LARGEST_RADIUS = 2;

/**
 * BOX
 */
export const BOX_DEFAULT_BACKGROUND_COLOR = 'transparent';
export const BOX_DEFAULT_BORDER_WIDTH = 1;
export const BOX_DEFAULT_BORDER_COLOR = COLOR_BASE_GRAY;

/**
 * ICON
 */
export const ICON_DEFAULT_COLOR = COLOR_GRAY_DARK_4;
