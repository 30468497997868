import PropTypes from 'prop-types';
import isNumber from 'lodash/isNumber';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import Columns from '../../layout/flex/Columns';
import Column from '../../layout/flex/Column';
import Text from '../../text/Text';
import { hasNextPage, hasPreviousPage, pageCount } from './helper';
import { propTypeColor } from '../../util/propTypes';
import { IconChevronLeft, IconChevronRight } from '../../../../app/components/icons';
import { jssColorLookup } from '../../util/jss';
import Box from '../../layout/Box';
import { isSmallScreen } from '../../../../app/util/browser';
import Rows from '../../layout/flex/Rows';

class Paginator extends Component {
  static propTypes = {
    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,

    /**
     * The column definitions.
     */
    columns: PropTypes.array.isRequired,

    /**
     * The base color of the paginator.
     */
    color: propTypeColor,

    /**
     * The page number.
     */
    page: PropTypes.number.isRequired,

    /**
     * The page size.
     */
    pageSize: PropTypes.number.isRequired,

    /**
     * Total number of records.
     */
    total: PropTypes.number.isRequired,

    /**
     * The available page sizes to choose from.
     */
    sizes: PropTypes.array.isRequired,

    /**
     * Page changed callback
     */
    onPageChange: PropTypes.func.isRequired,

    /**
     * Width of the container.
     */
    width: PropTypes.number,
  };

  static defaultProps = {
    sizes: [5, 10, 20, 25, 50, 100],
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.stateUpdate) {
      return {
        stateUpdate: false,
      };
    }

    const nextState = prevState;

    if (prevState.page !== nextProps.page) {
      nextState.page = nextProps.page;
    }

    return nextState;
  }

  constructor(props) {
    super(props);

    this.state = {
      page: props.page,
      pageSize: props.pageSize,
      total: props.total,
    };

    this.handleOnPreviousPage = this.handleOnPreviousPage.bind(this);
    this.handleOnNextPage = this.handleOnNextPage.bind(this);
    this.handleOnBlurPage = this.handleOnBlurPage.bind(this);
    this.handleOnKeyPress = this.handleOnKeyPress.bind(this);
    this.handleOnChangePage = this.handleOnChangePage.bind(this);
    this.handleOnChangePageSize = this.handleOnChangePageSize.bind(this);
  }

  nextPageRequest(nextPage) {
    return {
      page: nextPage || this.props.page,
      pageSize: this.props.pageSize,
    };
  }

  changePage(pageRequest) {
    if (this.props.onPageChange) {
      this.props.onPageChange(pageRequest);
    }
  }

  isPageValueInStateValid() {
    const page = Number.parseInt(this.state.page, 10);

    if (!isNumber(page)) {
      return false;
    }

    return page >= 1 && page <= pageCount(this.props.pageSize, this.props.total);
  }

  hasPageValueChanged() {
    return this.props.page !== this.state.page;
  }

  handleOnPreviousPage() {
    this.changePage(this.nextPageRequest(this.state.page - 1));
  }

  handleOnNextPage() {
    this.changePage(this.nextPageRequest(this.state.page + 1));
  }

  handleOnBlurPage() {
    if (this.isPageValueInStateValid() && this.hasPageValueChanged()) {
      this.changePage(this.nextPageRequest(Number.parseInt(this.state.page, 10)));
    } else {
      this.setState({ page: this.props.page });
    }
  }

  handleOnChangePage(event) {
    this.setState({
      stateUpdate: true,
      page: event.target.value,
    });
  }

  handleOnChangePageSize(event) {
    const nextPageSize = Number.parseInt(event.target.value, 10);

    const pageRequest = {
      page: 1,
      pageSize: nextPageSize,
    };

    this.changePage(pageRequest);
  }

  handleOnKeyPress(event) {
    if (event.key === 'Enter') {
      this.handleOnBlurPage();
    }
  }

  renderSelect() {
    return (
      <select
        onChange={this.handleOnChangePageSize}
        value={this.props.pageSize}
        className={this.props.classes.select}
      >
        {this.props.sizes.map(size => (
          <option key={size} value={size}>
            {size} rows
          </option>
        ))}
      </select>
    );
  }

  renderPaginator() {
    const textSize = 'smaller';

    return (
      <Columns gutter="small" vAlign="center" hAlign="center">
        <Column>
          <button
            className={this.props.classes.button}
            disabled={!hasPreviousPage(this.props.page, this.props.pageSize, this.props.total)}
            onClick={this.handleOnPreviousPage}
          >
            <IconChevronLeft color="white" />
          </button>
        </Column>
        <Column>
          <Text size={textSize}>Page:</Text>
        </Column>
        <Column>
          <input
            value={this.state.page}
            onBlur={this.handleOnBlurPage}
            onChange={this.handleOnChangePage}
            onKeyPress={this.handleOnKeyPress}
            className={this.props.classes.input}
            type="number"
            min={1}
            max={pageCount(this.props.pageSize, this.props.total)}
          />
        </Column>
        <Column>
          <Text size={textSize}>of</Text>
        </Column>
        <Column>
          <Text size={textSize}>
            {pageCount(this.props.pageSize, this.props.total).toLocaleString()}
          </Text>
        </Column>
        <Column>
          <button
            className={this.props.classes.button}
            disabled={!hasNextPage(this.props.page, this.props.pageSize, this.props.total)}
            onClick={this.handleOnNextPage}
          >
            <IconChevronRight color="white" />
          </button>
        </Column>
      </Columns>
    );
  }

  renderCurrentPosition(hAlign) {
    const textSize = 'smaller';

    const rangeEnd =
      this.props.total < this.props.page * this.props.pageSize
        ? this.props.total
        : this.props.page * this.props.pageSize;

    return (
      <Columns gutter="smaller" vAlign="center" hAlign={hAlign}>
        <Column>
          <Text size={textSize}>{(this.props.page - 1) * this.props.pageSize + 1}</Text>
        </Column>
        <Column>
          <Text size={textSize}>-</Text>
        </Column>
        <Column>
          <Text size={textSize}>{rangeEnd}</Text>
        </Column>
        <Column>
          <Text size={textSize}>of</Text>
        </Column>
        <Column>
          <Text size={textSize}>{this.props.total.toLocaleString()}</Text>
        </Column>
      </Columns>
    );
  }

  renderDesktop() {
    return (
      <div className={this.props.classes.container}>
        <Columns vAlign="center">
          <Column flex={0}>{this.renderSelect()}</Column>
          <Column flex={1}>{this.renderPaginator()}</Column>
          <Column flex={0}>{this.renderCurrentPosition()}</Column>
        </Columns>
      </div>
    );
  }

  renderMobile() {
    return (
      <div className={this.props.classes.container}>
        <Rows hAlign="center">
          <Box stack="small">{this.renderPaginator()}</Box>
        </Rows>
        <Box stack="small" textAlign="center">
          {this.renderSelect()}
        </Box>
        <Box>{this.renderCurrentPosition('center')}</Box>
      </div>
    );
  }

  render() {
    return isSmallScreen() ? this.renderMobile() : this.renderDesktop();
  }
}

const styles = theme => ({
  container: {
    width: props => props.width,
    padding: theme.spacing.smaller,
  },

  input: {
    ...theme.input.small,
    ...theme.typography.small,
    border: props => `1px solid ${theme.color[props.color]['light2']}`,
    textAlign: 'center',
    minWidth: '50px',

    '&:hover': {
      cursor: 'pointer',
    },
  },

  select: {
    ...theme.input.small,
    ...theme.typography.small,
    border: props => `1px solid ${theme.color[props.color]['light2']}`,
    backgroundColor: 'white',
    height: '24px',
    maxWidth: '100px',

    '&:hover': {
      cursor: 'pointer',
    },
  },

  button: {
    ...theme.button.reset,
    height: '24px',
    lineHeight: '24px',
    color: 'white',
    fontSize: '14px',
    padding: `0 ${theme.spacing.medium}`,
    backgroundColor: props => jssColorLookup(theme, props.color),

    '&:hover': {
      cursor: 'pointer',
    },
  },
});

export default injectSheet(styles)(Paginator);
