import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Box, Column, Columns, Text } from '../../../../../../../lib/base';
import { DEFAULT_TEXT_SIZE_DESCRIPTIONS } from '../../../../../../Constants';

export default class ArcGisLegend extends Component {
  static propTypes = {
    /**
     * Legend data.
     */
    legend: PropTypes.object.isRequired,
  };

  render() {
    const { legend } = this.props;

    const src = `data:${legend.contentType};base64,${legend.imageData}`;

    return (
      <Columns vCenter>
        <Column flex={4 / 5}>
          <Text size={DEFAULT_TEXT_SIZE_DESCRIPTIONS}>{legend.label}</Text>
        </Column>
        <Column flex={1 / 5}>
          <Box textAlign="right">
            <img
              width="24"
              height="24"
              alt={legend.label}
              src={src}
              style={{ backgroundColor: 'white' }}
            />
          </Box>
        </Column>
      </Columns>
    );
  }
}
