import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Box } from '../../../../lib/base';
import Columns from '../../../../lib/base/layout/flex/Columns';
import Column from '../../../../lib/base/layout/flex/Column';
import Value from '../../lv/Value';
import Label from '../../lv/Label';
import None from '../../panel/None';
import ComponentPager from './ComponentPager';

export default class AlternateIDs extends Component {
  static propTypes = {
    /**
     * Alias IDs
     */
    aliases: PropTypes.arrayOf(
      PropTypes.shape({
        aliasId: PropTypes.string,
        aliasIdTypeDesc: PropTypes.string,
      })
    ),
  };

  static defaultProps = {
    aliases: [],
  };

  render() {
    return (
      <div>
        {this.props.aliases.length === 0 && (
          <Box stack="medium">
            <None />
          </Box>
        )}
        <ComponentPager
          pageSize={10}
          items={this.props.aliases}
          scrollToTop={false}
          render={items => (
            <div>
              {items.map((alias, index) => (
                <Box key={index} mBottom="smaller">
                  <Columns>
                    <Column flex={2}>
                      <Label label={alias.aliasIdTypeDesc} />
                    </Column>
                    <Column flex={1} style={{ textAlign: 'right' }}>
                      <Value value={alias.aliasId} />
                    </Column>
                  </Columns>
                </Box>
              ))}
            </div>
          )}
        />
      </div>
    );
  }
}
