import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { IconClear } from '../icons';
import { Column, Columns } from '../../../lib/base';

class TextInput extends Component {
  static propTypes = {
    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,

    /**
     * The value of the input.
     */
    value: PropTypes.string,

    /**
     * Handle input value changes.
     */
    onChange: PropTypes.func.isRequired,

    /**
     * Handle when value is cleared.
     */
    onClear: PropTypes.func,

    /**
     * Handle when input is blurred.
     */
    onBlur: PropTypes.func,

    /**
     * Handle on input keypress.
     */
    onKeypress: PropTypes.func,
  };

  static defaultProps = {
    value: undefined,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.value !== nextProps.value) {
      prevState.value = nextProps.value || '';
    }

    return prevState;
  }

  constructor(props) {
    super(props);

    this.state = {
      value: props.value || '',
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
    this.handleOnKeypress = this.handleOnKeypress.bind(this);
    this.handleOnClear = this.handleOnClear.bind(this);
  }

  handleOnChange(event) {
    const value = event.target.value;
    this.setState({ value: value });
    this.props.onChange(event, value);
  }

  handleOnBlur(event) {
    if (this.props.onBlur) {
      const value = event.target.value;
      this.props.onBlur(event, value);
    }
  }

  handleOnKeypress(event) {
    if (this.props.onKeypress) {
      const value = event.target.value;
      this.props.onKeypress(event, value);
    }
  }

  handleOnClear(event) {
    const v = '';
    this.setState({ value: v });
    this.props.onChange(event, v);

    if (this.props.onClear) {
      this.props.onClear(event);
    }
  }

  hasValue() {
    return this.state.value && this.state.value.length > 0;
  }

  render() {
    const { container, column, clickable, input } = this.props.classes;

    return (
      <Columns className={container} vAlign="center">
        <Column flex={1} className={column} vAlign="center">
          <input
            type="text"
            onChange={this.handleOnChange}
            onBlur={this.handleOnBlur}
            onKeyPress={this.handleOnKeypress}
            value={this.state.value}
            className={input}
          />
        </Column>
        {this.hasValue() && (
          <Column
            flex={0}
            className={[clickable, column].join(' ')}
            onClick={this.handleOnClear}
            vAlign="center"
          >
            <IconClear />
          </Column>
        )}
      </Columns>
    );
  }
}

const styles = theme => ({
  container: {
    padding: `${theme.spacing.small} ${theme.spacing.small}`,
    height: '48px',
  },

  clickable: {
    '&:hover': {
      cursor: 'pointer',
    },
  },

  input: {
    ...theme.input.medium,
  },
});

export default injectSheet(styles)(TextInput);
