import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import SiteProfileDetailHeader from './header/SiteProfileDetailHeader';
import SiteProfileSummaryHeader from './header/SiteProfileSummaryHeader';

export default class SlideOutSiteProfileHeader extends PureComponent {
  static propTypes = {
    /**
     * Site profile data.
     */
    siteProfile: PropTypes.object.isRequired,

    /**
     * Show summary header
     */
    showSummary: PropTypes.bool,

    /**
     * Show detail header
     */
    showDetail: PropTypes.bool,
  };

  static defaultProps = {
    showSummary: false,
    showDetail: false,
  };

  render() {
    const { siteProfile, showSummary, showDetail } = this.props;

    if (showDetail) {
      return <SiteProfileDetailHeader siteProfile={siteProfile} />;
    }

    if (showSummary) {
      return <SiteProfileSummaryHeader siteProfile={siteProfile} />;
    }

    return null;
  }
}
