import get from 'lodash/get';

import ConfigKeys from './keys';

const ignoredKeys = ['header', 'labels'];

const reportMissingServerSettings = (configKeys, settings) => {
  configKeys.forEach(c => {
    const setting = get(settings, c);
    if (process.env.NODE_ENV === 'development' && (setting === undefined || setting == null)) {
      console.warn(`The application setting "${c}" is required.`);
    }
  });
};

const reportExtraneousServerSettings = (configKeys, settings) => {
  Object.keys(settings).forEach(key => {
    if (
      !configKeys.includes(key) &&
      !ignoredKeys.includes(key) &&
      process.env.NODE_ENV === "development"
    ) {
      console.warn(`The setting "${key}" is no longer required, remove it.`);
    }
  });
};

/**
 * Validate settings coming from the server and log warnings to the console.
 */
export const validate = settings => {
  const configKeys = Object.values(ConfigKeys);
  reportMissingServerSettings(configKeys, settings);
  reportExtraneousServerSettings(configKeys, settings);
};
