import BaseEndpoint from './BaseEndpoint';
import { applyAdvancedCriteria } from './SiteEndpoint';
import Urls from './Urls';

let CACHE_FILTER_GROUPS;

/**
 * Transforms the filters json response to a format which this application expects. Basically it
 * group the filters based on the displayName property.
 *
 * @param filters json filters response
 * @returns {{name: any, filters: *}[]}
 */
const parseJson = filters => {
  //new Set isn't in our polyfill library so I had to take it out
  //const groups = Array.from(new Set(filters.map(f => f.displayName)));
  const groups = filters
    .map(item => item.displayName)
    .filter((value, index, self) => self.indexOf(value) === index);

  return groups.map(g => ({
    name: g,
    filters: filters
      .filter(f => f.displayName === g)
      .map(f => ({
        label: f.value,
        value: `${f.displayName}:${f.value}`,
      })),
  }));
};

/**
 * Filter endpoint.
 */
export default class FilterEndpoint extends BaseEndpoint {
  /**
   * Returns a promise resolving filter data.
   *
   * @returns {Promise<any>} filter data
   */
  getAll() {
    if (CACHE_FILTER_GROUPS) {
      return Promise.resolve(CACHE_FILTER_GROUPS);
    }

    const url = `${super.url(Urls.Filter.All)}`;

    return super
      .get(url)
      .then(response => response.json())
      .then(json => parseJson(json))
      .then(filterGroups => {
        CACHE_FILTER_GROUPS = filterGroups;
        return filterGroups;
      });
  }

  /**
   * Returns the row count for each filter for the given search criteria.
   *
   * @param criteria the site serarch criteria.
   */
  getSiteFilterCounts(criteria, options) {
    const newCriteria = applyAdvancedCriteria({ ...criteria });

    const url = `${super.url(Urls.Filter.SiteFilterCounts)}?${super.toParameterString(
      newCriteria
    )}`;

    return super
      .get(url, options)
      .then(response => response.json())
      .then(json => json);
  }
}
