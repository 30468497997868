import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';

class ProfilePage extends Component {
  render() {
    const { params } = this.props.match;

    return <Redirect to={`/site/${params.id}`} />;
  }
}

export default withRouter(ProfilePage);
