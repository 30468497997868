import { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import Config from "../../config";
import isAllowDebug from "../../config/isAllowDebug";
import ReactGA from "react-ga4";

class GoogleAnalytics extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isInitialized: false,
      trackerNames: [],
    };
  }

  componentDidMount() {
    this.gaInitialize();
  }

  gaInitialize() {
    if (Config.getGoogleAnalyticsKey()) {
      const keys = Config.getGoogleAnalyticsKey().split(",");
      const trackerNames = [];
      const trackers = keys.map((key) => {
        const trackingId = key.trim();
        const trackerName = key.trim().replace(/[^0-9a-z]/gi, "");
        trackerNames.push(trackerName);

        return {
          trackingId: trackingId,
          debug: isAllowDebug(),
          gaOptions: {
            name: trackerName,
          },
        };
      });

      ReactGA.initialize(trackers);

      this.setState({
        trackerNames: trackerNames,
        isInitialized: true,
      });
    }
  }

  canRecord() {
    return this.state.isInitialized && this.state.trackerNames.length > 0;
  }

  currentPath(props) {
    const { location } = props;
    return location.pathname + location.search;
  }

  render() {
    return null;
  }
}

export default withRouter(GoogleAnalytics);
