import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { allTabs } from '../../../util/tabs';
import Tabs from '../../tabs/Tabs';

/**
 * Identifiers for each individual tab.
 */
export const PROFILE_TAB_DETAIL = 'profile';
export const PROFILE_TAB_MAP = 'map';
export const PROFILE_TAB_COMPLIANCE = 'compliance';
export const PROFILE_TAB_DOCUMENTS = 'documents';
export const PROFILE_TAB_CHEMICALS = 'chemicals';
export const PROFILE_TAB_GENERIC = 'generic';
export const PROFILE_TAB_EI = 'ei';

/**
 * Tab specific for the profile tabs.
 */
class ProfileTabs extends Component {
  constructor(props) {
    super(props);
    this.handleOnTabClick = this.handleOnTabClick.bind(this);
  }

  goTo(tab) {
    const { path, params } = this.props.match;

    if (path.includes(':tab')) {
      return path
        .replace(':panel', params.panel)
        .replace(':subPanel', params.subPanel)
        .replace(':id', params.id)
        .replace(':tab', tab.altId);
    } else {
      return (
        path
          .replace(':panel', params.panel)
          .replace(':subPanel', params.subPanel)
          .replace(':id', params.id) +
        '/' +
        tab.altId
      );
    }
  }

  handleOnTabClick(tab) {
    const { history, location } = this.props;

    history.push({
      pathname: this.goTo(tab),
      search: location.search,
    });
  }

  render() {
    const { tabCounts } = this.props;
    const { params } = this.props.match;
    const currentTab = params.tab;
    const tabs = allTabs(tabCounts);
    const activeTab = tabs.find(tab => tab.altId === currentTab);
    const defaultTab = tabs.find(tab => tab.altId === 'profile');

    return <Tabs tabs={tabs} onClick={this.handleOnTabClick} active={activeTab || defaultTab} />;
  }
}

function mapStateToProps(state) {
  return {
    tabCounts: state.profile.detail.data.tabCounts,
  };
}

export default withRouter(connect(mapStateToProps)(ProfileTabs));
