import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { jssColor } from '../util/jss';
import { propTypeColor, propTypeColorVariant } from '../util/propTypes';

class H1 extends PureComponent {
  static propTypes = {
    /**
     * Children.
     */
    children: PropTypes.node,

    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,

    /**
     * Text color - blue, red, green, gray, etc.
     */
    color: propTypeColor,

    /**
     * Variant of text color - light3, light6, dark2, etc.
     */
    colorVariant: propTypeColorVariant,
  };

  static defaultProps = {
    children: undefined,
    color: undefined,
    colorVariant: undefined,
  };

  render() {
    return (
      <h1
        className={[this.props.classes.h1, this.props.color && this.props.classes.color].join(' ')}
      >
        {this.props.children}
      </h1>
    );
  }
}

const styles = theme => ({
  h1: {
    ...theme.typography.h1,
  },

  color: {
    color: props => props.color && jssColor(theme, props),
  },
});

export default injectSheet(styles)(H1);
