import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import injectSheet from 'react-jss';

class TouchTarget extends PureComponent {
  static propTypes = {
    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,

    children: PropTypes.node.isRequired,

    onClick: PropTypes.func.isRequired,

    stretch: PropTypes.bool,

    className: PropTypes.string,

    /**
     * Make text unselectable.
     */
    noselect: PropTypes.bool,

    /**
     * The minimum width width and height in px.
     */
    size: PropTypes.string,

    styles: PropTypes.object,
  };

  static defaultProps = {
    stretch: false,
    className: undefined,
    size: '48px',
  };

  render() {
    const { container } = this.props.classes;

    return (
      <div
        className={[container, this.props.className].join(' ')}
        onClick={this.props.onClick}
        style={this.props.style}
      >
        {this.props.children}
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    display: props => (!props.stretch ? 'inline-flex' : 'flex'),
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: props => props.size,
    minWidth: props => props.size,
    'user-select': props => (props.noselect ? 'none' : undefined),

    '&:hover': {
      cursor: 'pointer',
    },
  },
});

export default injectSheet(styles)(TouchTarget);
