import { View } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
import format from 'date-fns/format';
import isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Label from './Label';
import Value from './Value';

const RootView = styled(View)`
  display: flex;
  flex-direction: row;
  margin-bottom: 2;
`;

const LeftView = styled(View)`
  width: ${props => props.labelWidth};
`;

LeftView.propTypes = {
  labelWidth: PropTypes.string,
};

const RightView = styled(View)`
  margin-left: 4;
  width: ${props => props.valueWidth};
`;

RightView.propTypes = {
  valueWidth: PropTypes.string,
};

export default class LabelValuePair extends Component {
  static propTypes = {
    /**
     * Label.
     */
    label: PropTypes.string,

    /**
     * Label width as %.
     */
    labelWidth: PropTypes.string,

    /**
     * Value.
     */
    value: PropTypes.any,

    /**
     * Value width as %.
     */
    valueWidth: PropTypes.string,

    /**
     * Hide label value pair when the value is empty.
     */
    hideEmpty: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    hideEmpty: false,
    label: '',
    labelWidth: '65%',
    value: undefined,
    valueWidth: '35%',
  };

  static asValue(value) {
    if (value instanceof Date) {
      return format(value, 'MM/DD/YYYY');
    }
    return value;
  }

  static isEmpty(value) {
    if (!value) return true;

    if (typeof value === 'string') {
      return value.trim().length === 0;
    } else if (Array.isArray(value)) {
      return value.length === 0;
    }

    return false;
  }

  static renderValue(val) {
    if (isFunction(val)) {
      return val();
    } else if (Array.isArray(val)) {
      return val.map((v, index) => <Value key={index} value={v} />);
    } else {
      if (this.isEmpty(val)) {
        return <Value value="—" empty />;
      }

      return <Value value={val} />;
    }
  }

  render() {
    const val = LabelValuePair.asValue(this.props.value);
    const lab = this.props.label;

    if (this.props.hideEmpty && LabelValuePair.isEmpty(val)) {
      return null;
    }

    return (
      <RootView>
        <LeftView labelWidth={this.props.labelWidth}>
          <Label label={lab} />
        </LeftView>
        <RightView valueWidth={this.props.valueWidth}>{LabelValuePair.renderValue(val)}</RightView>
      </RootView>
    );
  }
}
