import BaseEndpoint from './BaseEndpoint';
import Urls from './Urls';

/**
 * Profile endpoint.
 */
export default class ProfileEndpoint extends BaseEndpoint {
  /**
   * Returns a promise resolving profile detail data.
   *
   * @param sourceIdentifier the identifier for the lookup
   * @returns {Promise<any>} profile detail data
   */
  getProfileDetails(sourceIdentifier) {
    const url = `${super.url(Urls.Profile.GetProfileDetails)}?SourceIdentifier=${sourceIdentifier}`;

    return super
      .get(url)
      .then(response => response.json())
      .then(json => json);
  }

  getTier2Reports(sourceIdentifier) {
    const url = `${super.url(Urls.Profile.GetTier2Reports)}?SourceIdentifier=${sourceIdentifier}`;

    return super
      .get(url)
      .then(response => response.json())
      .then(json => json);
  }

  getChemicals(tier2ReportId, options = {}) {
    const params = {
      tier2ReportId: tier2ReportId,
      page: options.page || 1,
      pageSize: options.pageSize || 10,
      sortBy: options.sortBy || 'CHEMICAL_NAME',
      isAsc: options.isAsc === undefined ? true : options.isAsc,
      criteria: options.criteria === undefined ? {} : options.criteria,
    };

    const url = `${super.url(Urls.Profile.GetTier2Chemicals)}?${super.toParameterString(params)}`;

    return super
      .get(url)
      .then(response => response.json())
      .then(json => json);
  }

  getEnvInterests(sourceIdentifier, options) {
    const safeOptions = options || {};

    const params = {
      sourceIdentifier: sourceIdentifier,
      page: safeOptions.page || 1,
      pageSize: safeOptions.pageSize || 10,
      sortBy: safeOptions.sortBy || 'TYPE_DESC',
      isAsc: safeOptions.isAsc === undefined ? true : safeOptions.isAsc,
      criteria: safeOptions.criteria === undefined ? {} : safeOptions.criteria,
    };

    const url = `${super.url(Urls.Profile.GetEnvInterests)}?${super.toParameterString(params)}`;

    return super
      .get(url)
      .then(response => response.json())
      .then(json => json);
  }

  getComplianceSummary(sourceIdentifier) {
    const url = `${super.url(
      Urls.Profile.GetComplianceSummary
    )}?sourceIdentifier=${sourceIdentifier}`;

    return super
      .get(url)
      .then(response => response.json())
      .then(json => json);
  }

  getComplianceEvaluations(sourceIdentifier, options) {
    const safeOptions = options || {};

    const params = {
      sourceIdentifier: sourceIdentifier,
      isViolationsFound: options.isViolationsFound,
      page: safeOptions.page || 1,
      pageSize: safeOptions.pageSize || 10,
      sortBy: safeOptions.sortBy || 'EVALUATION_DATE',
      isAsc: safeOptions.isAsc === undefined ? true : safeOptions.isAsc,
      criteria: safeOptions.criteria === undefined ? {} : safeOptions.criteria,
    };

    const url = `${super.url(Urls.Profile.GetComplianceEvaluations)}?${super.toParameterString(
      params
    )}`;

    return super
      .get(url)
      .then(response => response.json())
      .then(json => json);
  }

  getComplianceViolations(sourceIdentifier, options) {
    const safeOptions = options || {};

    const params = {
      sourceIdentifier: sourceIdentifier,
      isOpen: options.isOpen,
      page: safeOptions.page || 1,
      pageSize: safeOptions.pageSize || 10,
      sortBy: safeOptions.sortBy || 'DETERMINATION_DATE',
      isAsc: safeOptions.isAsc === undefined ? true : safeOptions.isAsc,
      criteria: safeOptions.criteria === undefined ? {} : safeOptions.criteria,
    };

    const url = `${super.url(Urls.Profile.GetComplianceViolations)}?${super.toParameterString(
      params
    )}`;

    return super
      .get(url)
      .then(response => response.json())
      .then(json => json);
  }

  getComplianceActions(sourceIdentifier, options) {
    const safeOptions = options || {};

    const params = {
      sourceIdentifier: sourceIdentifier,
      isFormal: options.isFormal,
      page: safeOptions.page || 1,
      pageSize: safeOptions.pageSize || 10,
      sortBy: safeOptions.sortBy || 'ENF_ACTION_DATE',
      isAsc: safeOptions.isAsc === undefined ? true : safeOptions.isAsc,
      criteria: safeOptions.criteria === undefined ? {} : safeOptions.criteria,
    };

    const url = `${super.url(Urls.Profile.GetComplianceActions)}?${super.toParameterString(
      params
    )}`;

    return super
      .get(url)
      .then(response => response.json())
      .then(json => json);
  }

  getDocuments(sourceIdentifier, options = {}) {
    const params = {
      sourceIdentifier: sourceIdentifier,
      page: options.page || 1,
      pageSize: options.pageSize || 10,
      sortBy: options.sortBy || 'DOC_DATE',
      isAsc: options.isAsc === undefined ? true : options.isAsc,
      criteria: options.criteria === undefined ? {} : options.criteria,
    };

    const url = `${super.url(Urls.Profile.GetDocuments)}?${super.toParameterString(params)}`;

    return super
      .get(url)
      .then(response => response.json())
      .then(json => json);
  }

  getDocumentsCount(sourceIdentifier) {
    const params = {
      sourceIdentifier: sourceIdentifier,
    };

    const url = `${super.url(Urls.Profile.GetDocumentsCount)}?${super.toParameterString(params)}`;

    return super
      .get(url)
      .then(response => response.json())
      .then(json => json);
  }

  getDocumentsAll(sourceIdentifier) {
    const params = {
      sourceIdentifier: sourceIdentifier,
    };

    const url = `${super.url(Urls.Profile.GetDocumentsAll)}?${super.toParameterString(params)}`;

    return super
      .get(url)
      .then(response => response.json())
      .then(json => json);
  }

  getGeneric(sourceIdentifier) {
    const url = `${super.url(Urls.Profile.GetGeneric)}?SourceIdentifier=${sourceIdentifier}`;

    return super
      .get(url)
      .then(response => response.json())
      .then(json => json);
  }
}
