import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { Box } from '../../../../../../../../lib/base';
import { COLOR_BASE_PRIMARY, MEDIA_BREAKPOINT } from '../../../../../../../Constants';

class ToolbarButton extends Component {
  static propTypes = {
    /**
     * Circular button?
     */
    circular: PropTypes.bool,

    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,

    /**
     * Click handler.
     */
    onClick: PropTypes.func.isRequired,

    /**
     * Icon.
     */
    icon: PropTypes.node.isRequired,

    /**
     * Inverse the colors of the button.
     */
    inverse: PropTypes.bool,

    /**
     * Title when hovering.
     */
    title: PropTypes.string.isRequired,

    /**
     * Is the tool this button represents active?
     */
    active: PropTypes.bool,
  };

  static defaultProps = {
    inverse: false,
  };

  render() {
    const { inverse, classes } = this.props;

    const { active, container } = classes;

    const Icon = React.cloneElement(this.props.icon, {
      color: !inverse ? 'white' : COLOR_BASE_PRIMARY,
    });

    return (
      <Box
        className={[container, this.props.active && active].join(' ')}
        title={this.props.title}
        pad="small"
        onClick={this.props.onClick}
      >
        {Icon}
      </Box>
    );
  }
}

const styles = theme => ({
  container: {
    minWidth: '36px',
    borderRadius: props => (props.circular ? '24px' : undefined),
    textAlign: 'center',

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: props => !props.inverse && theme.color[COLOR_BASE_PRIMARY]['dark2'],
    },
  },

  active: {
    backgroundColor: props => !props.inverse && theme.color[COLOR_BASE_PRIMARY]['dark4'],

    '&:hover': {
      backgroundColor: props => !props.inverse && theme.color[COLOR_BASE_PRIMARY]['dark2'],
    },
  },

  [`@media ${MEDIA_BREAKPOINT}`]: {
    container: {
      padding: theme.spacing.medium,
    },
  },
});

export default injectSheet(styles)(ToolbarButton);
