import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SiteSearchCriteria from '../../../../../../../../api/impl/SiteSearchCriteria';
import Link from '../../../../../../../../lib/base/text/Link';
import {
  deselectSite,
  resetMap,
  searchFilterCountIfNeeded,
  searchSitesIfNeeded,
} from '../../../../../../../../store/map/map.action';
import { clearSearchGeocode } from '../../../../../../../../store/search/search.action';
import { IconHome } from '../../../../../../../components/icons';
import { navigateToMapHome, navigateToResultsDirect } from '../../../../../../../routing';
import { isDesktopScreen, isSmallScreen } from '../../../../../../../util/browser';
import ToolbarButton from './ToolbarButton';

class HomeButton extends Component {
  static propTypes = {
    /**
     * Circular button?
     */
    circular: PropTypes.bool,

    /**
     * Inverse the colors of the button.
     */
    inverse: PropTypes.bool,

    /**
     * Button shows only text
     */
    textOnly: PropTypes.bool,

    /**
     * Resets the map.
     */
    dispatchResetMap: PropTypes.func,
  };

  static defaultProps = {
    inverse: false,
    textOnly: false,
  };

  constructor(props) {
    super(props);
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  handleOnClick() {
    const criteria = new SiteSearchCriteria({
      bbox: this.props.criteria.boundingBox,
    });
    this.props.dispatchResetMap(criteria);

    if (isDesktopScreen()) {
      navigateToResultsDirect(this.props);
    } else if (isSmallScreen()) {
      navigateToMapHome(this.props);
    }
  }

  render() {
    if (this.props.textOnly) {
      return (
        <Link onClick={this.handleOnClick} size="small">
          Home
        </Link>
      );
    }

    return (
      <ToolbarButton
        onClick={this.handleOnClick}
        title="Resets the map"
        inverse={this.props.inverse}
        circular={this.props.circular}
        icon={<IconHome />}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    criteria: state.map.search.criteria,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchResetMap: criteria => {
      dispatch(clearSearchGeocode());
      dispatch(deselectSite());
      dispatch(resetMap());
      dispatch(searchSitesIfNeeded(criteria));
      dispatch(searchFilterCountIfNeeded(criteria));
    },
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(HomeButton)
);
