import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Box, Text } from '../../../../../../../../lib/base';
import SpinnerPanel from '../../../../../../../components/spinner/SpinnerPanel';
import FilterGroup from './FilterGroup';

/**
 * Comparator to sort filters based on display name.
 * @param left filter A
 * @param right filter B
 */
const diplayNameComparator = (left, right) => {
  const nameLeft = left.label && left.label.toUpperCase(); // ignore upper and lowercase
  const nameRight = right.label && right.label.toUpperCase(); // ignore upper and lowercase
  if (nameLeft < nameRight) {
    return -1;
  }
  if (nameLeft > nameRight) {
    return 1;
  }
  // names must be equal
  return 0;
};

class FiltersSearchForm extends Component {
  static propTypes = {
    /**
     * Selected filters.
     */
    selected: PropTypes.array.isRequired,

    /**
     * Filters count
     */
    filtersCount: PropTypes.array,

    /**
     * Called when a filter is selected.
     */
    onSelect: PropTypes.func.isRequired,

    /**
     * filters
     */
    filters: PropTypes.array,

    /**
     * Called when to select all filters.
     */
    onSelectAll: PropTypes.func.isRequired,

    /**
     * Called when filter crieria is removed.
     */
    onRemove: PropTypes.func.isRequired,

    /**
     * Is the app currently fetching the latest filter count?
     */
    isFilterCountFetching: PropTypes.bool.isRequired,

    /**
     * Is the filter panel currently visible
     */
    panelVisible: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.handleOnRemove = this.handleOnRemove.bind(this);
  }

  handleOnRemove(event, filterValue) {
    event.stopPropagation();
    this.props.onRemove(filterValue);
  }

  getSelectedByGroup(group) {
    const groupFilters = group.filters.map(f => f.value);
    const selected = this.props.selected;
    return selected
      .filter(selection => groupFilters.includes(selection))
      .sort(diplayNameComparator);
  }

  getCount(filter) {
    const fc = this.props.filtersCount.find(fc => filter.label === fc.filter);
    if (!fc) return undefined;
    return fc.count;
  }

  getFiltersByGroup(group) {
    const filterCounts = this.props.filtersCount && this.props.filtersCount.map(fc => fc.filter);

    return group.filters
      .filter(f => filterCounts && filterCounts.includes(f.label))
      .sort(diplayNameComparator)
      .map(f => ({
        value: f.value,
        label: f.label,
        count: this.getCount(f),
      }));
  }

  renderFilterGroups() {
    if (!this.props.filters) {
      return null;
    }

    /**
     * Get an array of filter groups and filter the empty ones.
     */
    const filterGroups = this.props.filters
      .map(group => {
        const filters = this.getFiltersByGroup(group);

        return filters.length > 0
          ? {
              filters: filters,
              name: group.name,
            }
          : undefined;
      })
      .filter(group => group !== undefined);

    if (filterGroups.length === 0 && this.props.isFilterCountFetching) {
      return <SpinnerPanel />;
    }

    /**
     * Map the filterGroups to <FilterGroup/>
     */
    if (filterGroups.length > 0 || this.props.isFilterCountFetching) {
      return filterGroups.map(group => (
        <FilterGroup
          key={group.name}
          name={group.name}
          filters={group.filters}
          selected={this.getSelectedByGroup(group)}
          onSelect={this.props.onSelect}
          onSelectAll={this.props.onSelectAll}
          isFilterCountFetching={this.props.isFilterCountFetching}
        />
      ));
    }

    /**
     * Return empty state when there are no filter groups.
     */
    return <Text>No Filters.</Text>;
  }

  render() {
    return <Box mTop="smallest">{this.renderFilterGroups()}</Box>;
  }
}

export default withRouter(FiltersSearchForm);
