/**
 * Format a number as a pixel for css propterties. For example, you will get '3px' when given 3.
 *
 * @param num the number to format.
 * @returns {string}
 */
export const px = num => `${num}px`;

/**
 * Format a number as a pixel for css propterties. For example, you will get '3px 0px' when given 3.
 *
 * @param x the number to format.
 * @param y the number to format.
 * @returns {string}
 */
export const pxXY = (x, y) => `${y}px ${x}px`;

/**
 * Returns the jss style from <code>classes</code> property on the given props object.
 *
 * The <code>value</code> is used as the key to lookup the style on the <code>classes</code> object.
 *
 * @param props the component props.
 */
export const jssSize = props => {
  return props.size ? props.classes[props.size] : undefined;
};

/**
 * Returns the color from the theme base on 'color' and 'colorVariant' props.
 *
 * @param theme
 * @param props
 * @returns {string}
 */
export const jssColor = (theme, props) => {
  return jssColorLookup(theme, props.color, props.colorVariant);
};

/**
 * Returns the color from the theme base on base and 'variant values.
 *
 * @param theme
 * @param base base color value - white, green, etc.
 * @param variant color variant - light1, light2, etc.
 * @returns {string}
 */
export const jssColorLookup = (theme, base, variant = 'base') => {
  if (base === 'white' || base === 'black') {
    return theme.color.base[base];
  } else if (base) {
    return theme.color[base][variant];
  }
  return undefined;
};
