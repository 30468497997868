import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Columns } from '../../index';
import Column from '../../layout/flex/Column';
import Text from '../../text/Text';
import { propTypeColor, propTypeColorVariant } from '../../util/propTypes';
import Cell from './Cell';
import SortHeaderIcon from './SortHeaderIcon';

export default class SortHeaderCell extends Component {
  static propTypes = {
    /**
     * Text alignment.
     */
    align: PropTypes.oneOf(['left', 'center', 'right']),

    /**
     * Column definition.
     */
    column: PropTypes.object.isRequired,

    /**
     * Click handler.
     */
    onClickCell: PropTypes.func,

    /**
     * Background color.
     */
    bgColor: propTypeColor,

    /**
     * Background color variant.
     */
    bgColorVariant: propTypeColorVariant,

    /**
     * Property to sort by.
     */
    sortBy: PropTypes.string,

    /**
     * Is sort ascending order.
     */
    isAsc: PropTypes.bool,
  };

  static defaultProps = {
    align: 'left',
    onClickCell: undefined,
    bgColor: 'primary',
    bgColorVariant: 'light1',
    sortBy: undefined,
    isAsc: undefined,
  };

  constructor(props) {
    super(props);
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  handleOnClick(event) {
    event.stopPropagation();
    if (this.props.onClickCell) {
      this.props.onClickCell(this.props.column);
    }
  }

  renderLabel() {
    return (
      <Text size="smaller" color="primary" colorVariant="dark5" bold>
        {this.props.column.headerLabel}
      </Text>
    );
  }

  render() {
    const { sortBy, isAsc, column, ...rest } = this.props;

    const canSort = column.colName && this.props.onClickCell;

    return (
      <Cell onClick={canSort && this.handleOnClick} width={column.width} {...rest}>
        <Columns vAlign="center" gutter="small">
          <Column flex={1}>{this.renderLabel()}</Column>
          {canSort && (
            <Column flex={0}>
              <SortHeaderIcon sortBy={sortBy} column={column} isAsc={isAsc} />
            </Column>
          )}
        </Columns>
      </Cell>
    );
  }
}
