import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Text } from '../../../lib/base';
import Label from '../lv/Label';

export default class DocumentLink extends Component {
  static propTypes = {
    /**
     * Document object
     */
    documents: PropTypes.array.isRequired,

    /**
     * Header for linked files
     */
    label: PropTypes.string.isRequired,
  };

  render() {
    return (
      <div>
        {this.props.documents.length > 0 && <Label label={this.props.label} />}
        {this.props.documents.map((document, index) => (
          <div key={index}>
            <a href={document.url} target="_blank" rel="noopener noreferrer">
              <Text size="smaller">{document.docName}</Text>
            </a>
          </div>
        ))}
      </div>
    );
  }
}
