import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Link from '../../../../lib/base/text/Link';
import Config from '../../../config';
import { MEDIA_BREAKPOINT } from '../../../Constants';
import { navigateToDetailEnvIntTab } from '../../../routing';
import { getSiteId } from '../../../util/entities/siteProfile';
import { formatDate } from '../../../util/format';
import { isNotBlank } from '../../../util/string';
import Card from '../../card/Card';
import LabelValueTable from '../../lv/LabelValueTable';
import Value from '../../lv/Value';
import None from '../../panel/None';
import ComponentPager from './ComponentPager';

class EnvironmentalInterests extends Component {
  static propTypes = {
    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,

    /**
     * Environmental Interests
     */
    environmentalInterests: PropTypes.arrayOf(
      PropTypes.shape({
        sourceIdentifier: PropTypes.string,
        envIntId: PropTypes.number,
        programId: PropTypes.string,
        startDateDesc: PropTypes.string,
        startDate: PropTypes.string,
        endDate: PropTypes.string,
        typeDesc: PropTypes.string,
        typeDescLong: PropTypes.string,
        contactName: PropTypes.string,
        contactPhone: PropTypes.string,
        facilityPageUrl: PropTypes.string,
        lastInspectionDate: PropTypes.string,
        envIntNotes: PropTypes.string,
        osDesc: PropTypes.string,
        endDateDesc: PropTypes.string,
      })
    ),

    /**
     * Site profile data.
     */
    siteProfile: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.handleMoreInfoClick = this.handleMoreInfoClick.bind(this);
  }

  static defaultProps = {
    environmentalInterests: [],
  };

  handleMoreInfoClick(event, description) {
    navigateToDetailEnvIntTab(this.props, getSiteId(this.props.siteProfile), description);
    event.stopPropagation();
  }

  static toLabelValueData(ei) {
    const data = [];
    let eiStartDate = ei.startDate ? new Date(ei.startDate) : null;
    let eiEndDate = ei.endDate ? new Date(ei.endDate) : null;
    let lastInspectionDate = ei.lastInspectionDate ? new Date(ei.lastInspectionDate) : null;

    if (eiStartDate !== null) {
      data.push({ label: ei.startDateDesc || 'Start Date', value: eiStartDate });
    }

    if (eiEndDate !== null) {
      data.push({ label: ei.endDateDesc || 'End Date', value: eiEndDate });
    }

    if (lastInspectionDate !== null) {
      data.push({ label: 'Last Inspected', value: lastInspectionDate });
    }

    data.push({ label: 'Source System', value: ei.osDesc });
    data.push({
      label: 'Source System ID',
      value: () => {
        if (isNotBlank(ei.facilityPageUrl)) {
          return (
            <Link href={ei.facilityPageUrl} size="smaller" target="_blank">
              {ei.programId}
            </Link>
          );
        }

        return <Value value={ei.programId} />;
      },
    });
    return data;
  }

  static toLabelValueDataSummarized(ei) {
    if (ei.count === 1) {
      return this.toLabelValueData(ei);
    }

    const data = [];

    let eiStartDate = ei.minStartDate || null;
    let eiEndDate = ei.maxEndDate || null;

    if (eiStartDate !== null) {
      data.push({
        label: 'Min ' + ei.startDateDesc || 'Min Start Date',
        value: formatDate(eiStartDate),
      });
    }
    if (eiEndDate !== null) {
      data.push({ label: 'Max ' + ei.endDateDesc || 'Max End Date', value: formatDate(eiEndDate) });
    }

    data.push({ label: 'Count', value: ei.count });

    return data;
  }

  summarize() {
    const { environmentalInterests } = this.props;

    return environmentalInterests.reduce((arr, item) => {
      let found = false;

      for (let i = 0; i < arr.length; i++) {
        if (arr[i].typeDesc === item.typeDesc) {
          found = true;
          arr[i].count++;

          if (arr[i].startDate <= item.startDate) {
            arr[i].minStartDate = item.startDate;
          }

          if (arr[i].endDate >= item.endDate) {
            arr[i].maxEndDate = item.endDate;
          }
        }
      }

      if (!found) {
        item.count = 1;
        arr.push(item);
      }

      return arr;
    }, []);
  }

  render() {
    const { classes, environmentalInterests } = this.props;

    const { container, card } = classes;

    if (environmentalInterests.length === 0) {
      return <None />;
    }

    if (Config.isSummarizeEIs()) {
      return (
        <ComponentPager
          pageSize={10}
          items={this.summarize()}
          render={items => (
            <div className={container}>
              {items.map((ei, index) => (
                <Card
                  key={index}
                  className={card}
                  title={ei.typeDesc}
                  infoText={ei.typeDescLong}
                  renderBody={() => (
                    <div>
                      <LabelValueTable
                        data={EnvironmentalInterests.toLabelValueDataSummarized(ei)}
                      />
                      {ei.count > 1 ? (
                        <Link
                          onClick={e => this.handleMoreInfoClick(e, ei.typeDesc)}
                          size="smaller"
                        >
                          Show More Information
                        </Link>
                      ) : null}
                    </div>
                  )}
                />
              ))}
            </div>
          )}
        />
      );
    }

    return (
      <ComponentPager
        pageSize={10}
        items={environmentalInterests}
        render={items => (
          <div className={container}>
            {items.map((ei, index) => (
              <Card
                key={index}
                className={card}
                title={ei.typeDesc}
                infoText={ei.typeDescLong}
                renderBody={() => (
                  <LabelValueTable data={EnvironmentalInterests.toLabelValueData(ei)} />
                )}
              />
            ))}
          </div>
        )}
      />
    );
  }
}

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },

  card: {
    width: '100%',
    minHeight: '130px',
    marginRight: theme.spacing.large,
    marginBottom: theme.spacing.medium,
  },

  /**
   Conditional class for IE 11 flexbox bug workaround
   */
  [`@media all and (-ms-high-contrast:none)`]: {
    card: {
      minHeight: '400px',
    },
  },

  [`@media (min-width: 1600px)`]: {
    card: {
      width: `calc(50% - ${theme.spacing.large})`,
    },
  },

  [`@media ${MEDIA_BREAKPOINT}`]: {
    card: {
      width: '100%',
      marginRight: 0,
    },
  },
});

function mapStateToProps(state) {
  return {
    siteProfile: state.profile.detail.data,
  };
}

export default withRouter(connect(mapStateToProps)(injectSheet(styles)(EnvironmentalInterests)));
