import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Box from '../../../lib/base/layout/Box';
import Column from '../../../lib/base/layout/flex/Column';
import Columns from '../../../lib/base/layout/flex/Columns';
import Row from '../../../lib/base/layout/flex/Row';
import Rows from '../../../lib/base/layout/flex/Rows';
import SectionPanel from '../../components/panel/SectionPanel';
import ConfigKeys from '../../config/keys';
import { isSmallScreen } from '../../util/browser';
import HeadingBlockText from '../text/HeadingBlockText';
import Affiliates from './detail/Affiliates';
import AlternateIDs from './detail/AlternateIDs';
import AlternateNames from './detail/AlternateNames';
import EnvironmentalInterests from './detail/EnvironmentalInterests';
import GeoPolitical from './detail/GeoPolitical';
import IndustryCodes from './detail/IndustryCodes';
import MiniMap from './detail/map/MiniMap';

export default class ProfileContainer extends Component {
  static propTypes = {
    /**
     * Profile deail.
     */
    siteProfileDetail: PropTypes.object,
  };

  static defaultProps = {};

  renderMobile() {
    const { siteProfileDetail } = this.props;

    const { longitude, latitude } = siteProfileDetail.profileFacilityDetail;

    return (
      <Box>
        <Box stack="medium">
          <MiniMap longitude={longitude} latitude={latitude} interactive={false} />
        </Box>
        <Box stack="medium">
          <SectionPanel titleLabelKey={ConfigKeys.LABEL_ENVIRONMENTAL_INTERESTS}>
            <EnvironmentalInterests environmentalInterests={siteProfileDetail.profileEnvInt} />
          </SectionPanel>
        </Box>
        <Box stack="medium">
          <SectionPanel titleLabelKey={ConfigKeys.LABEL_AFFILIATES}>
            <Affiliates affiliates={siteProfileDetail.profileAffiliate} />
          </SectionPanel>
        </Box>
        <Box stack="medium">
          <SectionPanel title="Geopolitical">
            <GeoPolitical facilityGeopolitical={siteProfileDetail.facilityGeopolitical} />
          </SectionPanel>
        </Box>
        <Box stack="medium">
          <SectionPanel title="Alternate IDs">
            <AlternateIDs aliases={siteProfileDetail.profileAliasId} />
          </SectionPanel>
        </Box>
        <Box stack="medium">
          <SectionPanel title="Alternate Names">
            <AlternateNames alternateNames={siteProfileDetail.profileAlias} />
          </SectionPanel>
        </Box>
        <Box stack="medium">
          <SectionPanel title="Industry Codes">
            <IndustryCodes
              sicCodes={siteProfileDetail.profileSic}
              naicsCodes={siteProfileDetail.profileNaics}
            />
          </SectionPanel>
        </Box>
      </Box>
    );
  }

  renderDesktop() {
    const { siteProfileDetail } = this.props;

    const { longitude, latitude } = siteProfileDetail.profileFacilityDetail;

    return (
      <Columns gutter="larger">
        <Column flex={1 / 3}>
          <Rows>
            <Row>
              <MiniMap longitude={longitude} latitude={latitude} />
            </Row>
            <Row>
              <HeadingBlockText title="Geopolitical" />
              <GeoPolitical facilityGeopolitical={siteProfileDetail.facilityGeopolitical} />
            </Row>
            <Row>
              <HeadingBlockText title="Site Codes" />
              <IndustryCodes
                sicCodes={siteProfileDetail.profileSic}
                naicsCodes={siteProfileDetail.profileNaics}
              />
            </Row>
            <Row>
              <HeadingBlockText title="Alternate IDs" />
              <AlternateIDs aliases={siteProfileDetail.profileAliasId} />
            </Row>
            <Row>
              <HeadingBlockText title="Alternate Names" />
              <AlternateNames alternateNames={siteProfileDetail.profileAlias} />
            </Row>
          </Rows>
        </Column>
        <Column flex={2 / 3}>
          <SectionPanel titleLabelKey={ConfigKeys.LABEL_ENVIRONMENTAL_INTERESTS}>
            <EnvironmentalInterests environmentalInterests={siteProfileDetail.profileEnvInt} />
          </SectionPanel>
          <SectionPanel titleLabelKey={ConfigKeys.LABEL_AFFILIATES}>
            <Affiliates affiliates={siteProfileDetail.profileAffiliate} />
          </SectionPanel>
        </Column>
      </Columns>
    );
  }

  render() {
    return isSmallScreen() ? this.renderMobile() : this.renderDesktop();
  }
}
