import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { DataGrid } from '../../../lib/base';
import Link from '../../../lib/base/text/Link';
import { formatDate } from '../../util/format';
import { isNotBlank } from '../../util/string';

export default class EnvironmentalInterestsDataGrid extends Component {
  static DEFAULT_SORT_BY = 'TYPE_DESC';

  static propTypes = {
    /**
     * The data set.
     */
    data: PropTypes.array.isRequired,

    /**
     * Is data loading.
     */
    isLoading: PropTypes.bool.isRequired,

    /**
     * Callback to fetch data.
     */
    onRequestData: PropTypes.func.isRequired,

    /**
     * The page number of the data set.
     */
    page: PropTypes.number.isRequired,

    /**
     * The page size of the data set.
     */
    pageSize: PropTypes.number.isRequired,

    /**
     * Total number of records in data set.
     */
    total: PropTypes.number.isRequired,

    /**
     * The property name to sort the results by.
     */
    sortBy: PropTypes.string,

    /**
     * Is the sort ascending?
     */
    isAsc: PropTypes.bool,

    /**
     * The criteria object.
     */
    criteria: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.gridColumns = [
      {
        headerLabel: 'Description',
        accessor: 'typeDesc',
        colName: 'TYPE_DESC',
        width: 200,
        criteriaPropName: 'description',
      },
      {
        headerLabel: 'Source System',
        accessor: 'osDesc',
        colName: 'OS_DESC',
        width: 200,
        criteriaPropName: 'sourceSystem',
      },
      {
        headerLabel: 'Program Id',
        accessor: ei => {
          if (isNotBlank(ei.facilityPageUrl)) {
            return (
              <Link href={ei.facilityPageUrl} size="smaller" target="_blank">
                {ei.programId}
              </Link>
            );
          }

          return ei.programId;
        },
        colName: 'PROGRAM_ID',
        width: 130,
        criteriaPropName: 'programId',
      },
      {
        headerLabel: 'Start Date',
        accessor: item => formatDate(item.startDate),
        colName: 'START_DATE',
        width: 130,
        criteriaPropName: 'startDate',
      },
      {
        headerLabel: 'End Date',
        accessor: item => formatDate(item.endDate),
        colName: 'END_DATE',
        width: 130,
        criteriaPropName: 'endDate',
      },
      {
        headerLabel: 'Long Description',
        accessor: 'typeDescLong',
        colName: 'TYPE_DESC_LONG',
        width: 600,
        criteriaPropName: 'longDescription',
      },
    ];
  }

  columns() {
    return this.gridColumns;
  }

  render() {
    return (
      <DataGrid
        columns={this.columns()}
        data={this.props.data}
        keyExtractor={row => `${row.typeDesc}-${row.programId}-${row.sourceIdentifier}`}
        onRequestData={this.props.onRequestData}
        page={this.props.page}
        pageSize={this.props.pageSize}
        total={this.props.total}
        sortBy={this.props.sortBy}
        isAsc={this.props.isAsc}
        criteria={this.props.criteria}
        loading={this.props.isLoading}
        striped
        showPaginationBottom={this.props.total > 5}
      />
    );
  }
}
