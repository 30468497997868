import React, { Component } from 'react';
import { Box, Column, Columns } from '../../../../../../../lib/base';
import { COLOR_BASE_PRIMARY, COLOR_VARIANT_PRIMARY } from '../../../../../../Constants';
import ExportButton from './buttons/ExportButton';
import LocationlessSitesButton from './buttons/LocationlessSitesButton';
import HomeButton from './buttons/HomeButton';
import MapLayersButton from './buttons/MapLayersButton';
import MeasureButton from './buttons/MeasureButton';
import MyLocationButton from './buttons/MyLocationButton';
import { isSmallScreen } from '../../../../../../util/browser';
import ApplicationMenu from '../../../../../../components/header/ApplicationMenu';
import UserDeniedGeoLocationModal from './UserDeniedGeoLocationModal';
import HelpButton from './buttons/HelpButton';

export default class Toolbar extends Component {
  constructor(props) {
    super(props);

    this.state = { isModalVisible: false };

    this.handleOnUserDeniedError = this.handleOnUserDeniedError.bind(this);
    this.handleOnCloseModal = this.handleOnCloseModal.bind(this);
  }

  handleOnUserDeniedError() {
    this.setState({ isModalVisible: true });
  }

  handleOnCloseModal() {
    this.setState({ isModalVisible: false });
  }

  render() {
    const isMobile = isSmallScreen();

    return (
      <Box pad="small" bgColor={COLOR_BASE_PRIMARY} bgColorVariant={COLOR_VARIANT_PRIMARY}>
        <UserDeniedGeoLocationModal
          visible={this.state.isModalVisible}
          onRequestClose={this.handleOnCloseModal}
        />
        <Columns>
          <Column flex={1} vAlign="center">
            <Columns gutter="smaller">
              {!isMobile && (
                <Column>
                  <HomeButton />
                </Column>
              )}

              <Column>
                <MyLocationButton onUserDeniedError={this.handleOnUserDeniedError} />
              </Column>

              {!isMobile && (
                <Column>
                  <MeasureButton />
                </Column>
              )}

              {!isMobile && (
                <Column>
                  <ExportButton />
                </Column>
              )}

              <Column>
                <MapLayersButton />
              </Column>

              {!isMobile && (
                <Column>
                  <HelpButton />
                </Column>
              )}
            </Columns>
          </Column>

          <Column flex={0} vAlign="center">
            <Column>
              <LocationlessSitesButton />
            </Column>

            {isMobile && (
              <Column flex={1}>
                <ApplicationMenu />
              </Column>
            )}
          </Column>
        </Columns>
      </Box>
    );
  }
}
