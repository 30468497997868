import React, { Component } from 'react';
import PropTypes from 'prop-types';
import OMap from 'ol/Map';
import Overlay from 'ol/Overlay';
import './overlay.css';
import { Text } from '../../../base';
import { COLOR_BASE_PRIMARY } from '../../../../app/Constants';

/**
 * Basic map overlay component for displaying info above a map
 * in a popup window form. The popup will pan with the map.
 */
export default class BasicOverlay extends Component {
  static propTypes = {
    map: PropTypes.instanceOf(OMap),
    children: PropTypes.node,
    position: PropTypes.array,
    title: PropTypes.string,
    onClose: PropTypes.func,
    render: PropTypes.func.isRequired,
    key: PropTypes.number,
  };

  static defaultProps = {
    map: undefined,
    position: undefined,
    title: '',
    onClose: undefined,
    render: undefined,
    key: Math.random(),
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextState = prevState;

    if (prevState.position !== nextProps.position) {
      nextState.position = nextProps.position;
    }

    return nextState;
  }

  constructor(props) {
    super(props);

    this.state = {
      position: props.position,
    };

    this.containerRef = React.createRef();

    this.overlay = new Overlay({
      position: props.position,
      autoPan: true,
      autoPanAnimation: {
        duration: 250,
      },
      offset: [0, -10],
    });

    this.handleOnClose = this.handleOnClose.bind(this);
  }

  handleOnClose() {
    this.overlay.setPosition(undefined);

    if (this.props.onClose) {
      this.props.onClose();
    }

    return false;
  }

  componentDidMount() {
    this.overlay.setElement(this.containerRef.current);
    this.overlay.setMap(this.props.map);
    this.props.map.getOverlays().push(this.overlay);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.position !== this.props.position) {
      this.overlay.setPosition(this.state.position);
    }
  }

  renderBody() {
    if (this.state.position) {
      return this.props.render();
    }
    return null;
  }

  render() {
    return (
      <div ref={this.containerRef} className="wnd_react_map_overlay_container">
        <div className="wnd_react_map_overlay_title">
          <span className="wnd_react_map_overlay_title_content">
            <Text
              size="medium"
              bold
              caps
              color={COLOR_BASE_PRIMARY}
              colorVariant="dark5"
              title={this.props.title}
            >
              {this.props.title}
            </Text>
          </span>
          <div onMouseUp={this.handleOnClose} className="wnd_react_map_overlay_title_closer">
            ✖
          </div>
        </div>
        <div className="wnd_react_map_overlay_content">{this.renderBody()}</div>
      </div>
    );
  }
}
