import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import injectSheet from 'react-jss';

/**
 * A low-level component for a span of text.
 */
class Link extends PureComponent {
  static propTypes = {
    /**
     * JSS classes.
     */
    classes: PropTypes.object.isRequired,
    /**
     * Links to...
     */
    href: PropTypes.string,
    /**
     * Children components.
     */
    children: PropTypes.node,
    /**
     * Additional className to style the text.
     */
    className: PropTypes.string,
    /**
     * Title
     */
    title: PropTypes.string,
    /**
     * Target
     */
    target: PropTypes.string,
    /**
     * Do not allow text to wrap.
     */
    nowrap: PropTypes.bool,
    /**
     * Elipsis when text overflows
     */
    elipsis: PropTypes.bool,
    /**
     * Should be a block element.
     */
    block: PropTypes.bool,
    /**
     * Click handler, overrides href.
     */
    onClick: PropTypes.func,
  };

  static defaultProps = {
    children: undefined,
    className: undefined,
    title: undefined,
    nowrap: false,
    elipsis: false,
    block: false,
    target: '_self',
    onClick: undefined,
  };

  render() {
    const { href, onClick, target, title, classes, className, children } = this.props;

    return (
      <a
        href={href}
        onClick={onClick}
        target={target}
        title={title}
        aria-label={title}
        className={[classes.anchor, className].join(' ')}
      >
        {children}
      </a>
    );
  }
}

const styles = theme => ({
  anchor: {
    ...theme.typography.text,
    display: props => (props.block ? 'block' : 'inline-block'),
    color: theme.color.base.white,
    textDecoration: 'none',
    whiteSpace: props => (props.nowrap ? 'nowrap' : undefined),
    textOverflow: props => (props.elipsis ? 'ellipsis' : undefined),
    overflow: props => (props.elipsis ? 'hidden' : undefined),
    padding: props => props.padding,

    '&:hover': {
      color: theme.color.base.white,
      cursor: props => (props.onClick ? 'pointer' : undefined),
    },

    '&:focus': {
      color: theme.color.base.white,
    },

    '&:active': {
      color: theme.color.base.white,
    },

    '&:visited': {
      color: theme.color.base.white,
    },
  },
});

export default injectSheet(styles)(Link);
