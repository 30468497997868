import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class DrawerBlind extends Component {
  static propTypes = {
    /**
     * Children components seen in the drawer blind.
     */
    children: PropTypes.node,

    /**
     * Is the blind open?
     */
    isOpen: PropTypes.bool.isRequired,

    /**
     * The zIndex of the container.
     */
    zIndex: PropTypes.number.isRequired,

    /**
     * The height when opened.
     */
    heightOpen: PropTypes.number.isRequired,

    /**
     * The bottom style prop.
     */
    bottom: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.containerRef.current.style.height = `${this.nextPosition()}px`;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.updateContainerLayout();
  }

  heightClosed() {
    return 0;
  }

  heightOpen() {
    return this.props.heightOpen;
  }

  nextPosition() {
    return this.props.isOpen ? this.heightOpen() : this.heightClosed();
  }

  currentPosition() {
    const container = this.containerRef.current;
    return Number.parseInt(container.style.height.replace(/px/i, ''), 10);
  }

  shouldUpdateContainerLayout() {
    return this.currentPosition() !== this.nextPosition();
  }

  updateContainerLayout() {
    if (!this.shouldUpdateContainerLayout()) {
      return;
    }

    this.animateMobilePanelVisibilityRAF();
  }

  animateMobilePanelVisibilityRAF() {
    const container = this.containerRef;

    const from = this.currentPosition();
    const to = this.nextPosition();
    const reversed = from > to;

    const step = 100;

    let pos = from;

    const frame = () => {
      pos = reversed ? pos - step : pos + step;

      if (reversed) {
        if (pos <= to) {
          pos = to;
        } else {
          window.requestAnimationFrame(frame);
        }
      } else {
        if (pos >= to) {
          pos = to;
        } else {
          window.requestAnimationFrame(frame);
        }
      }

      container.current.style.height = `${pos}px`;
    };

    window.requestAnimationFrame(frame);
  }

  render() {
    const style = {
      bottom: `${this.props.bottom || 0}px`,
      zIndex: this.props.zIndex,
      position: 'fixed',
      width: '100%',
      backgroundColor: 'white',
      overflowY: 'auto',
    };

    return (
      <div ref={this.containerRef} style={style}>
        {this.props.children}
      </div>
    );
  }
}
