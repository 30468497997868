import { Text, View } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
import PropTypes from 'prop-types';
import React from 'react';
import { colors } from '../../shared/colors';
import { headingSizes } from '../../shared/typography';

export const sectionMargins = {
  small: {
    body: '12pt',
    underHeading: '4pt',
  },
  default: {
    body: '14pt',
    underHeading: '6pt',
  },
  large: {
    body: '20pt',
    underHeading: '10pt',
  },
};

export const RootView = styled(View)`
  margin-top: ${props => (props.marginTop ? sectionMargins[props.size].body : 0)};
`;

export const Heading = styled(Text)`
  font-weight: bold;
  font-size: ${props => headingSizes[props.size]};
  margin-bottom: ${props => sectionMargins[props.size].underHeading};
`;

export const Hr = styled(View)`
  width: 100%;
  border-bottom: ${props => props.visible && `1pt solid ${colors.HR}`};
  margin-bottom: ${props => sectionMargins[props.size].body};
`;

const Section = ({ title, hr, marginTop, fixed, children, size }) => (
  <RootView marginTop={marginTop} size={size}>
    {title && (
      <Heading size={size} fixed={fixed}>
        {title}
      </Heading>
    )}
    <Hr visible={hr} size={size} fixed={!!title && fixed} />
    <View>{children}</View>
  </RootView>
);

Section.propTypes = {
  title: PropTypes.string,
  hr: PropTypes.bool,
  fixed: PropTypes.bool,
  size: PropTypes.string,
  marginTop: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

Section.defaultProps = {
  hr: true,
  fixed: false,
  size: 'default',
  marginTop: true,
};

export default Section;
