import { Image, View } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
import isString from 'lodash/isString';
import PropTypes from 'prop-types';
import React from 'react';
import { colors } from '../../shared/colors';
import { Text } from '../../shared/typography';
import { sectionMargins } from '../section';

const RootView = styled(View)`
  display: flex;
  flex-direction: row;
  padding-bottom: ${sectionMargins.large.underHeading};
  border-bottom: 1pt solid ${colors.HR};
`;

const LeftView = styled(View)`
  flex-grow: 1;
  width: 70%;
  display: flex;
  flex-direction: column;
`;

const RightView = styled(View)`
  width: 30%;
  max-height: 72pt;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
`;

const Logo = styled(Image)`
  width: 100%;
  max-height: 100%;
  object-fit: scale-down;
`;

export const Title = ({ children }) => (
  <Text bold size="huge">
    {children}
  </Text>
);

Title.propTypes = {
  children: PropTypes.string,
};

export const SubTitle = ({ children }) => <Text size="huge">{children}</Text>;

SubTitle.propTypes = {
  children: PropTypes.string,
};

export const Header = ({ title, subtitle, logo }) => (
  <RootView>
    <LeftView>
      {isString(title) ? <Title>{title}</Title> : title}
      {isString(subtitle) ? <SubTitle>{subtitle}</SubTitle> : subtitle}
    </LeftView>
    {logo && (
      <RightView>
        {logo.dataUri && <Logo src={logo.dataUri} width={logo.width} height={logo.height} />}
      </RightView>
    )}
  </RootView>
);

Header.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  logo: PropTypes.shape({
    dataUri: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }),
};

Header.defaultProps = {
  title: undefined,
  subtitle: undefined,
  logo: undefined,
};
