import isArray from 'lodash/isArray';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ProfileTable } from '../../../../lib/pdf';
import { isNotBlank } from '../../../util/string';

export const formatAddress = ({ address, city, state, zipCode }) => {
  const cityPretty = isNotBlank(city) ? `${city},` : '';
  const addressPretty = `${address || ''}
${cityPretty} ${state || ''} ${zipCode || ''}`;

  return isNotBlank(addressPretty) ? addressPretty : null;
};

const COLUMNS = [
  {
    id: 'entityName',
    displayName: 'Name',
    width: '30%',
  },
  {
    id: 'entityTitle',
    displayName: 'Title',
    width: '24%',
  },
  {
    id: 'phone',
    displayName: 'Phone',
    width: '16%',
  },
  {
    id: 'fullAddress',
    displayName: 'Address',
    width: '30%',
    accessor: row => formatAddress(row),
  },
];

export class SiteAffiliates extends PureComponent {
  static propTypes = {
    affiliates: PropTypes.arrayOf(
      PropTypes.shape({
        sourceIdentifier: PropTypes.string,
        envIntId: PropTypes.number,
        entityName: PropTypes.string,
        entityTitle: PropTypes.string,
        address: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        country: PropTypes.string,
        zipCode: PropTypes.string,
        county: PropTypes.string,
        phone: PropTypes.string,
        affilTypeDesc: PropTypes.string,
        fullAddress: PropTypes.string,
      })
    ),
  };

  static defaultProps = {
    affiliates: [],
  };

  hasData() {
    const { affiliates } = this.props;

    if (!affiliates || !isArray(affiliates)) {
      return false;
    }
    return affiliates.length > 0;
  }

  render() {
    if (!this.hasData()) {
      return null;
    }

    return <ProfileTable columns={COLUMNS} data={this.props.affiliates} />;
  }
}
