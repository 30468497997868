import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import Card from '../../card/Card';
import LabelValueTable from '../../lv/LabelValueTable';
import None from '../../panel/None';
import { MEDIA_BREAKPOINT } from '../../../Constants';
import ComponentPager from './ComponentPager';

class Affiliates extends Component {
  static propTypes = {
    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,

    /**
     * Affiliates.
     */
    affiliates: PropTypes.arrayOf(
      PropTypes.shape({
        sourceIdentifier: PropTypes.string,
        envIntId: PropTypes.number,
        entityName: PropTypes.string,
        entityTitle: PropTypes.string,
        address: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        country: PropTypes.string,
        zipCode: PropTypes.string,
        county: PropTypes.string,
        phone: PropTypes.string,
        affilTypeDesc: PropTypes.string,
        fullAddress: PropTypes.string,
      })
    ),
  };

  static defaultProps = {
    affiliates: [],
  };

  static address(a) {
    const parts = [];
    parts.push(a.city || '');
    parts.push(`${a.state || ''} ${a.zipCode || ''}`);
    return [a.address, parts.join(', ')];
  }

  static toLabelValueData(a) {
    const data = [];

    if (a.entityName && a.entityName.trim().length > 0) {
      data.push({ label: 'Name', value: a.entityName });
    }

    if (a.entityTitle && a.entityTitle.trim().length > 0) {
      data.push({ label: 'Title', value: a.entityTitle });
    }

    if (a.phone && a.phone.trim().length > 0) {
      data.push({ label: 'Phone', value: a.phone });
    }

    if (a.fullAddress && a.fullAddress.trim().length > 0) {
      data.push({ label: 'Address', value: Affiliates.address(a) });
    }

    return data;
  }

  render() {
    if (this.props.affiliates.length === 0) {
      return <None />;
    }

    const { container, card } = this.props.classes;

    return (
      <ComponentPager
        pageSize={10}
        items={this.props.affiliates}
        render={items => (
          <div className={container}>
            {items.map((a, index) => (
              <Card
                key={index}
                className={card}
                title={a.affilTypeDesc}
                renderBody={() => (
                  <div>
                    <LabelValueTable data={Affiliates.toLabelValueData(a)} />
                  </div>
                )}
              ></Card>
            ))}
          </div>
        )}
      />
    );
  }
}

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  card: {
    width: '100%',
    minHeight: '130px',
    marginRight: theme.spacing.large,
    marginBottom: theme.spacing.medium,
  },
  [`@media all and (-ms-high-contrast:none)`]: {
    card: {
      minHeight: '400px',
    },
  },

  entityName: {
    margin: theme.spacing.stack.large,
  },

  [`@media (min-width: 1600px)`]: {
    card: {
      width: `calc(50% - ${theme.spacing.large})`,
    },
  },

  [`@media ${MEDIA_BREAKPOINT}`]: {
    card: {
      width: '100%',
      marginRight: 0,
    },
  },
});

export default injectSheet(styles)(Affiliates);
