import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Box, Column, Columns, Link, Text } from '../../../../../../lib/base';
import { loadProfileDetailIfNeeded } from '../../../../../../store/profile/profile.action';
import WindowDocumentTitle from '../../../../../components/dom/WindowDocumentTitle';
import { IconChevronDown } from '../../../../../components/icons';
import ProfileTabs from '../../../../../components/profile/header/ProfileTabs';
import SiteNameAndAddress from '../../../../../components/profile/header/SiteNameAndAddress';
import SpinnerPanel from '../../../../../components/spinner/SpinnerPanel';
import { COLOR_BASE_PRIMARY } from '../../../../../Constants';
import { navigateToMobileCardView } from '../../../../../routing';
import {
  getSiteId,
  getSiteName,
  toSiteNameAndAddress,
} from '../../../../../util/entities/siteProfile';
import SiteProfileDetail from '../../shared/panel/profile/SiteProfileDetail';
import { isViewingProfile } from '../drawer/isViewing';

class SiteProfileMobile extends Component {
  static propTypes = {
    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.handleOnClose = this.handleOnClose.bind(this);
  }

  componentDidMount() {
    const { siteProfile } = this.props;
    const { params } = this.props.match;
    if (params.id && params.id !== getSiteId(siteProfile)) {
      this.props.dispatchFetchProfile(params.id);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { params: prevParams } = prevProps.match;
    const { params } = this.props.match;

    if (prevParams.id !== params.id && params.id !== undefined) {
      this.props.dispatchFetchProfile(params.id);
    }
  }

  handleOnClose() {
    const { params } = this.props.match;
    navigateToMobileCardView(this.props, params.id);
  }

  render() {
    const { isLoading, siteProfile, error, classes } = this.props;

    if (isLoading) {
      return <SpinnerPanel />;
    }

    if (error) {
      return (
        <Box pad="medium">
          <Text>Unable to locate site.</Text>
        </Box>
      );
    }

    if (!this.props.siteProfile) {
      return null;
    }

    const isProfileView = isViewingProfile(this.props);

    return (
      <Fragment>
        <WindowDocumentTitle title={getSiteName(siteProfile)} removeOnUnmount />
        <Box
          pTop="medium"
          pLeft="medium"
          pRight="medium"
          pBottom="small"
          bgColor={COLOR_BASE_PRIMARY}
          bgColorVariant="light1"
          onClick={isProfileView ? this.handleOnClose : undefined}
        >
          <Columns vAlign="top" gutter="medium">
            <Column flex={1}>
              <SiteNameAndAddress site={toSiteNameAndAddress(siteProfile)} />
            </Column>
            {isProfileView && (
              <Column flex={0}>
                <Link caps bold size="smaller" onClick={this.handleOnClose}>
                  Close&nbsp;&nbsp;
                  <IconChevronDown color={COLOR_BASE_PRIMARY} colorVariant="dark4" />
                </Link>
              </Column>
            )}
          </Columns>
        </Box>
        <Box bgColor={COLOR_BASE_PRIMARY} bgColorVariant="light1">
          <div className={classes.tabContainer}>
            <ProfileTabs />
          </div>
        </Box>
        <Box pad="medium">
          <SiteProfileDetail />
        </Box>
      </Fragment>
    );
  }
}

const styles = () => ({
  tabContainer: {
    overflowX: 'auto',
    display: 'flex',
    flexWrap: 'nowrap',
  },
});

function mapStateToProps(state) {
  return {
    isLoading: state.profile.detail.isLoading,
    error: state.profile.detail.error,
    siteProfile: state.profile.detail.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchFetchProfile: id => {
      dispatch(loadProfileDetailIfNeeded(id));
    },
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(injectSheet(styles)(SiteProfileMobile))
);
