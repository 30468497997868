/**
 * Returns tab objects with name, visible, id
 *
 * @returns {*|String}}
 */
export const getSiteIds = () => {
  let siteIds = getParameterByName('siteIds');
  return siteIds ? `site_id: "${siteIds}"` : '';
};

export const getAppMode = () => {
  let mode = getParameterByName('mode');
  return mode || null;
};

export const getParameterByName = (name, url) => {
  if (!url) {
    url = window.location.href;
  }

  // eslint-disable-next-line
  name = name.replace(/[\[\]]/g, "\\$&");

  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);

  if (!results) {
    return null;
  }

  if (!results[2]) {
    return '';
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};
