import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import WindowResizeListener from '../../../listener/WindowResizeListener';

/**
 * A container component that will monitor window resizes. Use this component when you need to know
 * the exact dimensions of the container. When the window resizes the renderView render prop is
 * called with a width and height property. Children can use these set exact dimensions.
 */
class WindowSizeAwareMapContainer extends Component {
  static propTypes = {
    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,

    /**
     * Func to render the view, width and height props are passed to it.
     */
    renderView: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);

    this.state = {
      width: undefined,
      height: 185,
    };

    this.containerRef = React.createRef();

    this.updateMapSize = this.updateMapSize.bind(this);
    this.callUpdateSize = this.callUpdateSize.bind(this);
  }

  componentDidMount() {
    this.callUpdateSize();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.callUpdateSize();
  }

  updateMapSize() {
    if (this.containerRef.current) {
      const containerRect = this.containerRef.current.getBoundingClientRect();

      if (this.state.width !== containerRect.width) {
        this.setState({ width: containerRect.width, height: containerRect.height });
      }

      /**
       * !! Hack !!
       *
       * For some reason the <canvas/> element of the map has style="display:none" - this removes
       * it.
       */
      const mapCanvas =
        document.getElementsByTagName('canvas') !== undefined &&
        document.getElementsByTagName('canvas')[0];

      if (mapCanvas) {
        mapCanvas.style.display = 'inherit';
      }
    }
  }

  /**
   * Because of the slideout panel animation we need to allow enough time for the dom to resize
   * before update the width/height of the map.
   *
   * TODO Improve this...
   */
  callUpdateSize() {
    setTimeout(this.updateMapSize, 350);
  }

  isCanShowMap() {
    return this.state.width && this.state.height;
  }

  render() {
    const { container } = this.props.classes;

    return (
      <div ref={this.containerRef} className={container}>
        <WindowResizeListener onResize={this.callUpdateSize} />
        {this.isCanShowMap() &&
          this.props.renderView({ width: this.state.width, height: this.state.height })}
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    width: '100%',
    minHeight: '200px',
    height: '100%',
  },
});

export default injectSheet(styles)(WindowSizeAwareMapContainer);
