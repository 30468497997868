import pluralize from 'pluralize';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Text } from '../../../../../../../lib/base';
import Box from '../../../../../../../lib/base/layout/Box';
import { IconList } from '../../../../../../components/icons/fontawesome/icons';
import { COLOR_BASE_PRIMARY, DEFAULT_TEXT_SIZE_DESCRIPTIONS } from '../../../../../../Constants';
import { navigateToResults } from '../../../../../../routing';

class ViewResultsButton extends Component {
  constructor(props) {
    super(props);
    this.handleOnClickCount = this.handleOnClickCount.bind(this);
  }

  handleOnClickCount() {
    navigateToResults(this.props);
  }

  siteCountPluralized() {
    const { count } = this.props;

    if (count) {
      return `${count.toLocaleString({ useGrouping: true })} ${pluralize('site', count)}`;
    } else {
      return '0 sites';
    }
  }

  render() {
    const count = this.props.count && this.props.count.toLocaleString();
    const params = this.props.match.params;

    if (this.props.count && params.panel !== 'results') {
      return (
        <Button
          size="medium"
          type="default"
          block
          color={COLOR_BASE_PRIMARY}
          onClick={this.handleOnClickCount}
          pressed={params.panel === 'results'}
        >
          <IconList color="white" /> {`Search results (${count})`}
        </Button>
      );
    }

    return (
      <Box textAlign="center" pTop="smaller">
        <Text size={DEFAULT_TEXT_SIZE_DESCRIPTIONS} color="gray" colorVariant="light4">
          {this.siteCountPluralized()} found
        </Text>
      </Box>
    );
  }
}

function mapStateToProps(state) {
  return {
    count: state.map.search.count,
  };
}

export default withRouter(connect(mapStateToProps)(ViewResultsButton));
