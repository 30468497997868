import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Box from '../../../../../../lib/base/layout/Box';
import Column from '../../../../../../lib/base/layout/flex/Column';
import Columns from '../../../../../../lib/base/layout/flex/Columns';
import Row from '../../../../../../lib/base/layout/flex/Row';
import Rows from '../../../../../../lib/base/layout/flex/Rows';
import { IconClear } from '../../../../../components/icons';
import HeadingText from '../../../../../components/text/HeadingText';
import TouchTarget from '../../../../../components/touchable/TouchTarget';
import SearchOptionsPanel from '../../shared/panel/criteria/SearchOptionsPanel';
import { navigateCloseSubPanel } from '../../../../../routing';
import SlideOutContainer from './SlideOutContainer';

class SlideOutContainerSearchCriteria extends Component {
  static propTypes = {
    /**
     * The css 'top' position.
     */
    top: PropTypes.number.isRequired,

    /**
     * The css 'width' position.
     */
    width: PropTypes.number.isRequired,

    /**
     * The height of the container div.
     */
    height: PropTypes.number.isRequired,

    /**
     * Z-Index of slide out panel.
     */
    zIndex: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.handleOnClose = this.handleOnClose.bind(this);
  }

  isOpen() {
    const { params } = this.props.match;
    return params.subPanel === 'criteria' || params.subPanel === 'filters';
  }

  handleOnClose() {
    navigateCloseSubPanel(this.props);
  }

  render() {
    return (
      <SlideOutContainer
        zIndex={this.props.zIndex}
        left={this.props.width}
        isOpen={this.isOpen()}
        isOpenFull={false}
        width={this.props.width}
        height={this.props.height}
        top={this.props.top}
      >
        <Box pad="medium" pLeft="large">
          <Rows stretch>
            <Row>
              <Box mBottom="large">
                <Columns vAlign="center">
                  <Column flex={1}>
                    <HeadingText>Advanced Search Criteria</HeadingText>
                  </Column>
                  <Column flex={0}>
                    <TouchTarget onClick={this.handleOnClose}>
                      <IconClear />
                    </TouchTarget>
                  </Column>
                </Columns>
              </Box>
            </Row>
            <Row>
              <SearchOptionsPanel />
            </Row>
          </Rows>
        </Box>
      </SlideOutContainer>
    );
  }
}

export default withRouter(SlideOutContainerSearchCriteria);
