import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Box, Column, Columns, Link } from '../../../../../../lib/base';
import { loadProfileDetailIfNeeded } from '../../../../../../store/profile/profile.action';
import WindowDocumentTitle from '../../../../../components/dom/WindowDocumentTitle';
import { IconChevronUp, IconSpinner } from '../../../../../components/icons';
import SiteNameAndAddress from '../../../../../components/profile/header/SiteNameAndAddress';
import { COLOR_BASE_PRIMARY } from '../../../../../Constants';
import { navigateToMobileProfileView } from '../../../../../routing';
import {
  getSiteId,
  getSiteName,
  toSiteNameAndAddress,
} from '../../../../../util/entities/siteProfile';

class SiteProfileQuickView extends PureComponent {
  constructor(props) {
    super(props);
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  componentDidMount() {
    const { params } = this.props.match;
    if (params.id) {
      this.props.dispatchFetchProfile(params.id);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { params: prevParams } = prevProps.match;
    const { params } = this.props.match;

    if (prevParams.id !== params.id && params.id !== undefined) {
      this.props.dispatchFetchProfile(params.id);
    }
  }

  handleOnClick() {
    const { siteProfile } = this.props;
    navigateToMobileProfileView(this.props, getSiteId(siteProfile));
  }

  render() {
    const { siteProfile, isLoading } = this.props;

    if (isLoading) {
      return (
        <Box pad="medium">
          <IconSpinner color={COLOR_BASE_PRIMARY} colorVariant="dark5" />
        </Box>
      );
    }

    if (!siteProfile) {
      return null;
    }

    return (
      <Fragment>
        <WindowDocumentTitle title={getSiteName(siteProfile)} removeOnUnmount />
        <Box
          pTop="medium"
          pLeft="medium"
          pRight="medium"
          pBottom="small"
          bgColor="white"
          bgColorVariant="light1"
          onClick={this.handleOnClick}
        >
          <Columns vAlign="top" gutter="medium">
            <Column flex={1}>
              <SiteNameAndAddress site={toSiteNameAndAddress(siteProfile)} />
            </Column>
            <Column flex={0}>
              <Link caps bold size="smaller" onClick={this.props.onClickExpand}>
                Open&nbsp;&nbsp;
                <IconChevronUp color={COLOR_BASE_PRIMARY} colorVariant="dark4" />
              </Link>
            </Column>
          </Columns>
        </Box>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    siteProfile: state.profile.detail.data,
    isLoading: state.profile.detail.isLoading,
    error: state.profile.detail.error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchFetchProfile: id => {
      dispatch(loadProfileDetailIfNeeded(id));
    },
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SiteProfileQuickView)
);
