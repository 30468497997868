import Config from '../../app/config';
import { centerPositionWhenPanelIsOpen } from '../../app/util/map';
import {
  ACTIVE_BASE_LAYER,
  ACTIVE_REFERENCE_LAYERS,
  DESELECT_SITE,
  FETCH_SEARCH_FILTER_COUNT_BEGIN,
  FETCH_SEARCH_FILTER_COUNT_ERROR,
  FETCH_SEARCH_FILTER_COUNT_SUCCESS,
  MULTIPLE_SITE_SELECT,
  RESET_MAP,
  RESET_SEARCH_INPUT,
  SEARCH_INPUT_FOCUS_CHANGE,
  SEARCH_SITES_BEGIN,
  SEARCH_SITES_ERROR,
  SEARCH_SITES_SUCCESS,
  SELECT_MEASURE_TOOL,
  SELECT_SITE,
  SET_CENTER_ON_POSITION,
  SET_INFO_PANEL_CONTENT,
  SET_MY_LOCATION_COORDS,
  SKIP_MAP_MOVE_SEARCH_ONCE,
  ZOOM_TO_EXTENT,
  ZOOM_TO_LOCATION,
} from './map.actionType';

/**
 * Get a reference to the AbortController class.
 */
const AbortController = window.AbortController;

/**
 * Needs to be function in order to delay access - when the page intially loads window.nSITE.Api is
 * undefined.
 */
const api = () => window.nSITE.Api;

/**
 * //////////////////////////////////////////////////////
 * ////
 * //// Map related events.
 * ////
 * //////////////////////////////////////////////////////
 */

export function skipMapMoveSearchOnce(isSkip) {
  return {
    type: SKIP_MAP_MOVE_SEARCH_ONCE,
    payload: {
      isSkip: isSkip,
    },
  };
}

export function zoomToCenter(coordinates, zoom) {
  return {
    type: ZOOM_TO_LOCATION,
    payload: {
      coordinates,
      zoom,
    },
  };
}

export function zoomToExtent(extent) {
  return {
    type: ZOOM_TO_EXTENT,
    payload: {
      extent,
    },
  };
}

export function resetSearchInput() {
  return {
    type: RESET_SEARCH_INPUT,
  };
}

export function setInfoPanelContent(content) {
  return {
    type: SET_INFO_PANEL_CONTENT,
    payload: {
      content,
    },
  };
}

export function selectBaseLayer(layer) {
  return {
    type: ACTIVE_BASE_LAYER,
    payload: {
      layer,
    },
  };
}

export function selectReferenceLayers(layers) {
  return {
    type: ACTIVE_REFERENCE_LAYERS,
    payload: {
      layers: [...layers],
    },
  };
}

export function resetMap() {
  return dispatch => {
    /**
     * Get the original mapExtent and nudge one of the extent values so the <Map/> component can
     * recognize the change in property value. This is only necessary when the extent in the store
     * hasn't changed. This would occur if the user had only panned the map because in this case
     * the extent value in the store is only changed when an interaction changes that value such as
     * clicking on a site feature to which we zoom to.
     */
    const extent = Config.getExtent().slice(0);
    extent[3] = extent[3] + 0.0000000000000001;

    dispatch({
      type: RESET_MAP,
      payload: {
        extent: extent,
      },
    });
  };
}

export function selectMeasureTool(type) {
  return {
    type: SELECT_MEASURE_TOOL,
    payload: {
      activeMeasureTool: type,
    },
  };
}

export function selectSite(site, options) {
  return {
    type: SELECT_SITE,
    payload: {
      site,
      options,
    },
  };
}

export function searchInputFocusChange(isFocused) {
  return {
    type: SEARCH_INPUT_FOCUS_CHANGE,
    payload: {
      isFocused,
    },
  };
}

export function deselectSite() {
  return {
    type: DESELECT_SITE,
  };
}

export function multipleSiteSelect(sites) {
  return {
    type: MULTIPLE_SITE_SELECT,
    payload: {
      selectedClusterSiteList: sites,
    },
  };
}

export function setMyLocationCoords(lonLat) {
  return {
    type: SET_MY_LOCATION_COORDS,
    payload: {
      coordinates: lonLat,
    },
  };
}

export function setCenterOnPosition() {
  return {
    type: SET_CENTER_ON_POSITION,
    payload: {
      position: centerPositionWhenPanelIsOpen(),
    },
  };
}

/**
 * //////////////////////////////////////////////////////
 * ////
 * //// Search sites
 * ////
 * //////////////////////////////////////////////////////
 */

/**
 * At each keyup a new api fetch is made to load the site search. If there's a fetch in flight we
 * need to abort it so that we ensure the last request is the only one in flight so we abort the
 * previous fetch.
 */
let siteSearchAbortController = new AbortController();

export function searchSitesIfNeeded(criteria) {
  return dispatch => {
    /**
     * If we're currently fetching site features, abort the fetch and start over.
     */
    siteSearchAbortController.abort();

    /**
     * Setup an AbortController and get a handle to the abort signal. We pass the signal into the
     * fetch so that we cancel this request in the future (see above) if we need to.
     */
    siteSearchAbortController = new AbortController();

    return dispatch(searchSites(criteria, { signal: siteSearchAbortController.signal }));
  };
}

export function searchSites(criteria, options) {
  return dispatch => {
    dispatch(searchSitesBegin(criteria));
    api()
      .site.search(criteria, options)
      .then(results => dispatch(searchSitesSuccess(results)))
      .catch(err => {
        if (err.name !== 'AbortError') {
          dispatch(searchSitesError(err.message));
        }
      });
  };
}

export function searchSitesBegin(criteria) {
  return {
    type: SEARCH_SITES_BEGIN,
    payload: {
      criteria,
    },
  };
}

export function searchSitesSuccess(results) {
  return {
    type: SEARCH_SITES_SUCCESS,
    payload: {
      features: results.features,
      sites: results.sites,
      criteria: results.criteria,
      count: results.count,
    },
  };
}

export function searchSitesError(message) {
  return {
    type: SEARCH_SITES_ERROR,
    payload: {
      error: message,
    },
  };
}

/**
 * //////////////////////////////////////////////////////
 * ////
 * //// Get site search filters count.
 * ////
 * //////////////////////////////////////////////////////
 */
let searchFilterCountAbortController = new AbortController();

export function searchFilterCountIfNeeded(criteria) {
  return dispatch => {
    /**
     * If we're currently fetching site filter counts, abort the fetch and start over.
     */
    searchFilterCountAbortController.abort();

    /**
     * Setup an AbortController and get a handle to the abort signal. We pass the signal into the
     * fetch so that we cancel this request in the future (see above) if we need to.
     */
    searchFilterCountAbortController = new AbortController();

    return dispatch(
      searchFilterCount(criteria, { signal: searchFilterCountAbortController.signal })
    );
  };
}

function searchFilterCount(criteria, options) {
  return dispatch => {
    dispatch(searchFilterCountBegin());
    api()
      .filter.getSiteFilterCounts(criteria, options)
      .then(results => dispatch(searchFilterCountSuccess(results)))
      .catch(err => {
        if (err.name !== 'AbortError') {
          dispatch(searchFilterCountError(err.message));
        }
      });
  };
}

export function searchFilterCountBegin() {
  return {
    type: FETCH_SEARCH_FILTER_COUNT_BEGIN,
  };
}

export function searchFilterCountSuccess(results) {
  return {
    type: FETCH_SEARCH_FILTER_COUNT_SUCCESS,
    payload: {
      filterCounts: results,
    },
  };
}

export function searchFilterCountError(message) {
  return {
    type: FETCH_SEARCH_FILTER_COUNT_ERROR,
    payload: {
      error: message,
    },
  };
}
