import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import injectSheet from 'react-jss';
import { propTypeSize } from '../../util/propTypes';

export const CHILD_COLUMN_SELECTOR = 'WndJssColumn'; // name it something to avoid naming clashes.

/**
 * A parent flex box container component for use in column-based layouts.
 */
class Columns extends PureComponent {
  static propTypes = {
    /**
     * Children components.
     */
    children: PropTypes.node.isRequired,

    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,

    /**
     * Css classname.
     */
    className: PropTypes.string,

    /**
     * React ref callback to the root container.
     */
    containerRef: PropTypes.object,

    /**
     * The size of the gutter between columns.
     */
    gutter: propTypeSize,

    /**
     * Horizontal alignment.
     *
     * [left|center|right|space-between|space-around|space-even]
     */
    hAlign: PropTypes.oneOf([
      'left',
      'center',
      'right',
      'space-between',
      'space-around',
      'space-even',
    ]),

    /**
     * Inline flex box
     */
    inline: PropTypes.bool,

    /**
     * OnClick
     */
    onClick: PropTypes.func,

    /**
     * Reverse the rows bottom to top.
     */
    reverse: PropTypes.bool,

    /**
     * Stretch to full height.
     */
    stretch: PropTypes.bool,

    /**
     * Container style.
     */
    style: PropTypes.object,

    /**
     * Vertical alignment. For this property to have any affect you'll need to set a height.
     *
     * [top|center|bottom|stretch]
     */
    vAlign: PropTypes.oneOf(['top', 'center', 'bottom', 'stretch']),

    /**
     * Wrap.
     */
    wrap: PropTypes.bool,
  };

  propsAsClassnames() {
    const classNames = [];

    switch (this.props.vAlign) {
      case 'top':
        classNames.push(this.props.classes.vAlignTop);
        break;
      case 'center':
        classNames.push(this.props.classes.vAlignCenter);
        break;
      case 'bottom':
        classNames.push(this.props.classes.vAlignBottom);
        break;
      case 'stretch':
        classNames.push(this.props.classes.vAlignStretch);
        break;
      default:
        break;
    }

    switch (this.props.hAlign) {
      case 'left':
        classNames.push(this.props.classes.hAlignLeft);
        break;
      case 'center':
        classNames.push(this.props.classes.hAlignCenter);
        break;
      case 'right':
        classNames.push(this.props.classes.hAlignRight);
        break;
      case 'space-between':
        classNames.push(this.props.classes.hAlignSpaceBetween);
        break;
      case 'space-around':
        classNames.push(this.props.classes.hAlignSpaceAround);
        break;
      case 'space-even':
        classNames.push(this.props.classes.hAlignSpaceEven);
        break;
      default:
        break;
    }

    if (this.props.reverse) {
      classNames.push(this.props.classes.directionReverse);
    }

    if (this.props.wrap) {
      classNames.push(this.props.classes.wrap);
    }

    if (this.props.stretch) {
      classNames.push(this.props.classes.stretch);
    }

    return classNames;
  }

  render() {
    const classNames = [this.props.classes.columns].concat(this.propsAsClassnames());

    classNames.push(this.props.className);

    return (
      <div
        ref={this.props.containerRef}
        onClick={this.props.onClick}
        className={classNames.join(' ')}
        style={this.props.style}
      >
        {this.props.children}
      </div>
    );
  }
}

const styles = theme => ({
  columns: {
    display: props => (props.inline ? 'inline-flex' : 'flex'),
    flexDirection: 'row',

    '&:hover': {
      cursor: props => props.onClick && 'pointer',
    },

    [`& > div[class*="${CHILD_COLUMN_SELECTOR}"]`]: {
      marginRight: props => (props.gutter ? theme.spacing[props.gutter] : undefined),
    },

    [`& > div[class*="${CHILD_COLUMN_SELECTOR}"]:last-of-type`]: {
      marginRight: props => (props.gutter ? 0 : undefined),
    },
  },

  vAlignTop: {
    alignItems: 'flex-start',
  },

  vAlignCenter: {
    alignItems: 'center',
  },

  vAlignBottom: {
    alignItems: 'flex-end',
  },

  vAlignStretch: {
    alignItems: 'stretch',
  },

  hAlignLeft: {
    justifyContent: 'flex-start',
  },

  hAlignCenter: {
    justifyContent: 'center',
  },

  hAlignRight: {
    justifyContent: 'flex-end',
  },

  hAlignSpaceBetween: {
    justifyContent: 'space-between',
  },

  hAlignSpaceAround: {
    justifyContent: 'space-around',
  },

  hAlignSpaceEven: {
    justifyContent: 'space-evenly',
  },

  directionReverse: {
    flexDirection: 'row-reverse',
  },

  wrap: {
    flexWrap: 'wrap',
  },

  stretch: {
    height: '100%',
  },
});

export default injectSheet(styles)(Columns);
