import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import SpinnerPage from '../spinner/SpinnerPage';

export default class PageLoading extends PureComponent {
  static propTypes = {
    /**
     * Loading message
     */
    message: PropTypes.string,
  };

  static defaultProps = {
    message: 'Loading',
  };

  render() {
    return <SpinnerPage message={this.props.message} />;
  }
}
