import { ICON_DEFAULT_COLOR } from './variables';

/**
 * Icon defaults
 */
const icon = {
  default: {
    color: ICON_DEFAULT_COLOR,
  },
};

export default icon;
