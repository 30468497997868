import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import injectSheet from 'react-jss';
import WindowResizeListener from '../../../../app/components/listener/WindowResizeListener';

class HorizontalScrollView extends Component {
  static propTypes = {
    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,

    /**
     * The column definitions.
     */
    columns: PropTypes.array.isRequired,

    /**
     * The children nodes to render.
     */
    children: PropTypes.node.isRequired,

    /**
     * Is pagination visible?
     */
    paginationVisible: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      horizontalScrollbarHidden: undefined,
    };

    this.containerRef = React.createRef();
    this.leftMoreIndicatorRef = React.createRef();
    this.rightMoreIndicatorRef = React.createRef();

    this.updateScrollbarState = this.updateScrollbarState.bind(this);
    this.updateScrollIndicators = this.updateScrollIndicators.bind(this);
    this.debounceHandleOnScroll = debounce(this.updateScrollIndicators, 100);
  }

  componentDidMount() {
    if (this.horizontalScrollbarHidden === undefined) {
      this.updateScrollbarState();
    }
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    return {
      scrollWidth: this.containerRef.current.scrollWidth,
      clientWidth: this.containerRef.current.clientWidth,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevState.scrollWidth !== snapshot.scrollWidth ||
      prevState.clientWidth !== snapshot.clientWidth
    ) {
      this.updateScrollbarState();
    }
  }

  updateScrollbarState() {
    /**
     * Check scrollbar visibility.
     */
    if (this.containerRef && this.containerRef.current) {
      const scrollWidth = this.containerRef.current.scrollWidth;
      const clientWidth = this.containerRef.current.clientWidth;
      this.setState({
        scrollWidth,
        clientWidth,
        horizontalScrollbarHidden: clientWidth === scrollWidth,
      });
    }

    setTimeout(this.updateScrollIndicators, 100);
  }

  updateScrollIndicators() {
    const that = this;

    window.requestAnimationFrame(function() {
      if (!that.containerRef || !that.containerRef.current) {
        return;
      }

      const container = ReactDOM.findDOMNode(that.containerRef.current);
      const left = ReactDOM.findDOMNode(that.leftMoreIndicatorRef.current);
      const right = ReactDOM.findDOMNode(that.rightMoreIndicatorRef.current);

      const scrollbar = {
        scrollLeft: container.scrollLeft,
        offsetLeft: container.offsetLeft,
        offsetWidth: container.offsetWidth,
        scrollWidth: container.scrollWidth,
        clientWidth: container.clientWidth,
        isVisible: container.offsetWidth <= container.scrollWidth,
        isLeftIndicatorVisible: container.scrollLeft !== 0,
        isRightIndicatorVisible:
          container.scrollWidth - (container.scrollLeft + container.offsetWidth) > 0,
      };

      left.style.display = scrollbar.isLeftIndicatorVisible ? 'block' : 'none';
      right.style.display = scrollbar.isRightIndicatorVisible ? 'block' : 'none';
    });
  }

  render() {
    this.updateScrollIndicators();

    const style = {
      borderLeft: this.state.horizontalScrollbarHidden ? 'none' : undefined,
      borderRight: this.state.horizontalScrollbarHidden ? 'none' : undefined,
    };

    const { moreIndicator, leftMoreIndicator, rightMoreIndicator } = this.props.classes;

    return (
      <React.Fragment>
        <WindowResizeListener onResize={this.updateScrollbarState} />
        <div style={{ position: 'relative' }}>
          <div
            className={[moreIndicator, leftMoreIndicator].join(' ')}
            ref={this.leftMoreIndicatorRef}
          >
            &nbsp;
          </div>
          <div
            ref={this.containerRef}
            onScroll={this.debounceHandleOnScroll}
            className={this.props.classes.container}
            style={style}
          >
            {this.props.children}
          </div>
          <div
            className={[moreIndicator, rightMoreIndicator].join(' ')}
            ref={this.rightMoreIndicatorRef}
          >
            &nbsp;
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  container: {
    overflowY: 'hidden',
    overflowX: 'auto',
    '-webkit-transform': 'translateZ(0)',
  },

  moreIndicator: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0)',
    padding: theme.spacing.smaller,
  },

  leftMoreIndicator: {
    left: 0,
    boxShadow: 'inset 7px 0 12px -7px rgba(0,0,0,0.4)',
  },

  rightMoreIndicator: {
    right: 0,
    boxShadow: 'inset -7px 0 12px -7px rgba(0,0,0,0.4)',
  },
});

export default injectSheet(styles)(HorizontalScrollView);
