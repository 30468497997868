import PropTypes from 'prop-types';
import { Component } from 'react';
import Config from '../../config';

const SEPARATOR = '\u2022';

export default class WindowDocumentTitle extends Component {
  static propTypes = {
    title: PropTypes.string,
    removeOnUnmount: PropTypes.bool,
  };

  static defaultProps = {
    title: undefined,
    removeOnUnmount: false,
  };

  static makeTitle(title) {
    if (title) {
      return `${title} ${SEPARATOR} ${Config.getApplicationName()}`;
    }
    return Config.getApplicationName();
  }

  componentDidMount() {
    document.title = WindowDocumentTitle.makeTitle(this.props.title);
  }

  componentDidUpdate() {
    document.title = WindowDocumentTitle.makeTitle(this.props.title);
  }

  componentWillUnmount() {
    if (this.props.removeOnUnmount) {
      document.title = WindowDocumentTitle.makeTitle();
    }
  }

  render() {
    return null;
  }
}
