import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';

class ScrollView extends Component {
  static propTypes = {
    /**
     * Children components.
     */
    children: PropTypes.object.isRequired,
    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,
    /**
     * Css classname.
     */
    className: PropTypes.string,

    onScrollEnd: PropTypes.func,

    onScroll: PropTypes.func,
  };

  static defaultProps = {
    onScrollEnd: undefined,
    onScroll: undefined,
  };

  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
  }

  handleScroll(e) {
    // onScroll
    if (this.props.onScroll) {
      this.props.onScroll();
    }

    // onScrollEnd
    const isScrollEnd = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 1;

    if (isScrollEnd && this.props.onScrollEnd) {
      this.props.onScrollEnd();
    }
  }

  render() {
    const { container } = this.props.classes;

    return (
      <div className={container} onScroll={this.handleScroll}>
        {this.props.children}
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    overflowY: 'auto',
    height: '100%',
  },
});

export default injectSheet(styles)(ScrollView);
