import PropTypes from 'prop-types';

/**
 * Defines the list of availble sizes.
 */
export const propTypeSize = PropTypes.oneOf([
  'smallest',
  'smaller',
  'small',
  'medium',
  'large',
  'larger',
  'largest',
]);

/**
 * Defines the list of values for horizontal alignment.
 */
export const propTypeAlignHorizontal = PropTypes.oneOf(['left', 'right', 'center']);

/**
 * Defines the list of values for vertical alignment.
 */
export const propTypeAlignVertical = PropTypes.oneOf(['top', 'bottom', 'center']);

/**
 * Defines the list of availble base colors and grays.
 */
export const propTypeColor = PropTypes.oneOf([
  'white',
  'black',
  'gray',
  'blue',
  'orange',
  'red',
  'green',
  'yellow',
  'violet',
  'fuscia',
  'primary',
  'secondary',
  'danger',
  'success',
]);

/**
 * Defines the list of availble base color variants, light1, light2, etc.
 */
export const propTypeColorVariant = PropTypes.oneOf([
  'base',
  'light1',
  'light2',
  'light3',
  'light4',
  'light5',
  'light6',
  'dark1',
  'dark2',
  'dark3',
  'dark4',
  'dark5',
  'dark6',
  'gray1',
  'gray2',
  'gray3',
  'gray4',
  'gray5',
  'gray6',
  'gray7',
  'gray8',
  'gray9',
  'gray10',
]);

/**
 * Defines the list of available border sides.
 */
export const propTypeBorderSides = PropTypes.oneOfType([PropTypes.string, PropTypes.array]);
