import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Box } from '../../../../lib/base';
import Columns from '../../../../lib/base/layout/flex/Columns';
import Column from '../../../../lib/base/layout/flex/Column';
import Value from '../../lv/Value';
import Label from '../../lv/Label';
import None from '../../panel/None';
import ComponentPager from './ComponentPager';

export default class AlternateNames extends Component {
  static propTypes = {
    /**
     * Alias IDs
     */
    alternateNames: PropTypes.arrayOf(
      PropTypes.shape({
        aliasType: PropTypes.string,
        aliasName: PropTypes.string,
      })
    ),
  };

  static defaultProps = {
    alternateNames: [],
  };

  render() {
    if (this.props.alternateNames.length === 0) {
      return (
        <Box stack="medium">
          <None />
        </Box>
      );
    } else {
      let alternateNameTypesLength = this.props.alternateNames.filter(altName => {
        return altName.aliasType;
      }).length;
      return (
        <div>
          <ComponentPager
            pageSize={10}
            items={this.props.alternateNames}
            scrollToTop={false}
            render={items => (
              <div>
                {alternateNameTypesLength > 0 && (
                  <Box stack="smaller">
                    <Label label="Alternate Name Type" />
                  </Box>
                )}
                {items.map((alias, index) => (
                  <Box key={index} mBottom="smaller">
                    {alternateNameTypesLength > 0 && (
                      <Columns>
                        <Column flex={2}>
                          <Value value={alias.aliasType} />
                        </Column>
                        <Column flex={1} style={{ textAlign: 'right' }}>
                          <Value value={alias.aliasName} />
                        </Column>
                      </Columns>
                    )}
                    {alternateNameTypesLength === 0 && (
                      <Columns>
                        <Column flex={2}>
                          <Value value={alias.aliasName} />
                        </Column>
                      </Columns>
                    )}
                  </Box>
                ))}
              </div>
            )}
          />
        </div>
      );
    }
  }
}
