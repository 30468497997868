import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { Box } from '../../../../../../../lib/base';
import HeadingText from '../../../../../../components/text/HeadingText';
import Columns from '../../../../../../../lib/base/layout/flex/Columns';
import Column from '../../../../../../../lib/base/layout/flex/Column';
import Value from '../../../../../../components/lv/Value';
import Label from '../../../../../../components/lv/Label';

class LayerMetadata extends Component {
  static propTypes = {
    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,

    /**
     * Title to display
     */
    title: PropTypes.string,

    /**
     * Array of {label,value} pairs.
     */
    metadata: PropTypes.array,
  };

  render() {
    return (
      <Box className={this.props.classes.container} mBottom="medium">
        <HeadingText>{this.props.title}</HeadingText>
        <Box pTop="small">
          {this.props.metadata &&
            this.props.metadata.map((kv, index) => (
              <Columns gutter="small" key={index}>
                <Column flex={1}>
                  <Label label={kv.label} />
                </Column>
                <Column flex={1} className={this.props.classes.value}>
                  <Value value={kv.value} />
                </Column>
              </Columns>
            ))}
        </Box>
      </Box>
    );
  }
}

const styles = theme => ({
  container: {
    minWidth: '300px',
  },

  value: {
    textAlign: 'right',
  },
});

export default injectSheet(styles)(LayerMetadata);
