import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import injectSheet from 'react-jss';
import { jssColor, px } from '../util/jss';
import { propTypeColor, propTypeColorVariant, propTypeSize } from '../util/propTypes';

const PROPS_WITH_CLASSNAMES = ['stack', 'margin', 'mTop', 'mRight', 'mBottom', 'mLeft'];

/**
 * Basic horizontal rule with options for color and spacing.
 */
class Hr extends PureComponent {
  static propTypes = {
    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,

    /**
     * Css classname.
     */
    className: PropTypes.string,

    /**
     * Background color.
     */
    color: propTypeColor,

    /**
     * Background color variant.
     */
    colorVariant: propTypeColorVariant,

    /**
     * The amount of margin to apply..
     */
    margin: propTypeSize,

    /**
     * The amount of margin to apply to the bottom.
     */
    mBottom: propTypeSize,

    /**
     * The amount of margin to apply to the left side.
     */
    mLeft: propTypeSize,

    /**
     * The amount of margin to apply to the right side.
     */
    mRight: propTypeSize,

    /**
     * The amount of margin to apply to the top.
     */
    mTop: propTypeSize,

    /**
     * Is this box stacked - one on top of another.
     */
    stack: propTypeSize,

    /**
     * Thickness in px.
     */
    width: PropTypes.number,
  };

  static defaultProps = {
    className: undefined,
    color: 'gray',
    colorVariant: 'light2',
    margin: undefined,
    mBottom: undefined,
    mLeft: undefined,
    mRight: undefined,
    mTop: undefined,
    stack: undefined,
    width: 1,
  };

  propsAsClassnames() {
    return PROPS_WITH_CLASSNAMES.filter(propName => this.props[propName] !== undefined).map(
      prop => this.props.classes[prop]
    );
  }

  renderClassnames() {
    const classNames = [this.props.classes.hr].concat(this.propsAsClassnames());

    classNames.push(this.props.className);

    return classNames.join(' ');
  }

  render() {
    return <hr className={this.renderClassnames()} />;
  }
}

const styles = theme => ({
  hr: {
    display: 'block',
    height: props => px(props.width),
    border: 0,
    borderTop: props => `${px(props.width)} solid ${jssColor(theme, props)}`,
    padding: 0,
    margin: 0,
  },

  margin: {
    margin: props => theme.spacing[props.margin],
  },

  mTop: {
    marginTop: props => theme.spacing[props.mTop],
  },

  mRight: {
    marginRight: props => theme.spacing[props.mRight],
  },

  mBottom: {
    marginBottom: props => theme.spacing[props.mBottom],
  },

  mLeft: {
    marginLeft: props => theme.spacing[props.mLeft],
  },
});

export default injectSheet(styles)(Hr);
