import get from 'lodash/get';
import config from 'react-global-configuration';
import ConfigKeys from './keys';
import calcExtent from './util/calcExtent';
import { validate } from './validate';

const convertLabelsToObject = labels => {
  const obj = {};
  labels.forEach(label => (obj[label.key] = label.value));
  return obj;
};

const initialize = settings => {
  /**
   * (1) Labels need to be converted to object from an array for easy access.
   * (2) Calculate extent based on initialGeometry setting.
   */
  const fixedSettings = {
    ...settings,
    labels: convertLabelsToObject(settings.labels),
    extent: calcExtent(get(settings, ConfigKeys.INITIAL_GEOMETRY)),
  };

  validate(fixedSettings);
  config.set(fixedSettings);
};

const all = () => JSON.parse(config.serialize());

/**
 * Private function to get config value.
 */
const cfgGet = (key, defaultWhenNotExists) => {
  return config.get(key, defaultWhenNotExists);
};

const isSummarizeEIs = (whenEmpty = false) => {
  return cfgGet(ConfigKeys.SUMMARIZE_EIS, whenEmpty);
};

const getNCoreApplicationName = (whenEmpty = 'nCORE by Windsor Solutions') => {
  return cfgGet(ConfigKeys.NCORE_APPLICATION_NAME, whenEmpty);
};

const getNCoreBaseUrl = (whenEmpty = '') => {
  let baseUrl = cfgGet(ConfigKeys.NCORE_BASE_URL, whenEmpty);
  return baseUrl.charAt(baseUrl.length - 1) == '/'
    ? baseUrl.substring(0, baseUrl.length - 1)
    : baseUrl;
};

const getApplicationName = (whenEmpty = 'nSITE Explorer by Windsor Solutions') => {
  return cfgGet(ConfigKeys.APPLICATION_NAME, whenEmpty);
};

const getApplicationIcon = (whenEmpty = 'logo.png') => {
  return cfgGet(ConfigKeys.APPLICATION_ICON, whenEmpty);
};

const getApplicationInitials = (whenEmpty = '') => {
  return cfgGet(ConfigKeys.APPLICATION_INITIALS, whenEmpty);
};

const getMetaAuthor = (whenEmpty = 'Windsor Solutions, Inc.') => {
  return cfgGet(ConfigKeys.META_AUTHOR, whenEmpty);
};

const getMetaDescription = (
  whenEmpty = 'nSITE provides an integrated, cross-programmatic view of information for sites that are of environmental concern to the agency. Site data from each connected source system is presented as a single record, combining information from multiple program areas to create a holistic view of site activities that have an environmental impact.'
) => {
  return cfgGet(ConfigKeys.META_DESCRIPTION, whenEmpty);
};

const getMetaKeywords = (whenEmpty = '') => {
  return cfgGet(ConfigKeys.META_KEYWORDS, whenEmpty);
};

const getMetaRobotContent = (whenEmpty = '') => {
  return cfgGet(ConfigKeys.META_ROBOT_CONTENT, whenEmpty);
};

const getHeaderColorBackground = (whenEmpty = 'blue') => {
  return cfgGet(ConfigKeys.HEADER_COLOR_BACKGROUND, whenEmpty);
};

const getHeaderColorText = (whenEmpty = 'white') => {
  return cfgGet(ConfigKeys.HEADER_COLOR_TEXT, whenEmpty);
};

const getHeaderNavigation = (whenEmpty = []) => {
  return cfgGet(ConfigKeys.HEADER_NAVIGATION, whenEmpty);
};

const getIeWarning = (whenEmpty = '') => {
  return cfgGet(ConfigKeys.IE_WARNING, whenEmpty);
};

//
// Default to all of USA?
//
const getInitialGeometry = (whenEmpty = undefined) => {
  return cfgGet(ConfigKeys.INITIAL_GEOMETRY, whenEmpty);
};

const isZoomToLocationDesktop = (whenEmpty = false) => {
  return cfgGet(ConfigKeys.ZOOM_TO_LOCATION_DESKTOP, whenEmpty);
};

const isZoomToLocationMobile = (whenEmpty = false) => {
  return cfgGet(ConfigKeys.ZOOM_TO_LOCATION_MOBILE, whenEmpty);
};

const getExtent = (whenEmpty = undefined) => {
  return cfgGet(ConfigKeys.EXTENT, whenEmpty);
};

const getMinZoom = (whenEmpty = undefined) => {
  return cfgGet(ConfigKeys.MIN_ZOOM, whenEmpty);
};

const getMaxZoom = (whenEmpty = undefined) => {
  return cfgGet(ConfigKeys.MAX_ZOOM, whenEmpty);
};

const getSiteClusterFillColor = (whenEmpty = 'rgba(255, 0, 252, 0.7)') => {
  return cfgGet(ConfigKeys.SITE_CLUSTER_FILL_COLOR, whenEmpty);
};

const getSiteClusterStrokeColor = (whenEmpty = 'rgba(56, 0, 55, 0.9)') => {
  return cfgGet(ConfigKeys.SITE_CLUSTER_STROKE_COLOR, whenEmpty);
};

const getBingApiKey = (whenEmpty = '') => {
  return cfgGet(ConfigKeys.BING_API_KEY, whenEmpty);
};

const getStartPanel = (whenEmpty = 'details') => {
  return cfgGet(ConfigKeys.START_PANEL, whenEmpty);
};

const getGoogleAnalyticsKey = (whenEmpty = undefined) => {
  return cfgGet(ConfigKeys.GOOGLE_ANALYTICS_KEY, whenEmpty);
};

const isConditionallyHideOrigSysId = (whenEmpty = false) => {
  return cfgGet(ConfigKeys.CONDITIONALLY_HIDE_ORIG_SYS_ID, whenEmpty);
};

const isShowDocumentCount = (whenEmpty = false) => {
  return cfgGet(ConfigKeys.SHOW_DOCUMENT_COUNT, whenEmpty);
};

const getBuildBranch = (whenEmpty = undefined) => {
  return cfgGet(ConfigKeys.BUILD_BRANCH, whenEmpty);
};

const getBuildRevision = (whenEmpty = undefined) => {
  return cfgGet(ConfigKeys.BUILD_REVISION, whenEmpty);
};

const getBuildTimestamp = (whenEmpty = undefined) => {
  return cfgGet(ConfigKeys.BUILD_TIMESTAMP, whenEmpty);
};

const getLabel = (key, whenEmpty = `{{${key}}}`) => {
  return cfgGet(key, whenEmpty);
};

const getApplicationBaseUrl = () => {
  return cfgGet(ConfigKeys.APPLICATION_BASE_URL);
};

const getReportHeaderImageUrl = () => {
  return `${getApplicationBaseUrl()}/logo-report-header.png`;
};

const getRecaptchaSiteKey = (whenEmpty = undefined) => {
  return cfgGet(ConfigKeys.RECAPTCHA_SITE_KEY, whenEmpty);
};

export default {
  initialize,
  all,
  getApplicationIcon,
  getApplicationInitials,
  getApplicationName,
  getApplicationBaseUrl,
  getBingApiKey,
  getBuildBranch,
  getBuildRevision,
  getBuildTimestamp,
  getExtent,
  getGoogleAnalyticsKey,
  getRecaptchaSiteKey,
  getHeaderColorBackground,
  getHeaderColorText,
  getHeaderNavigation,
  getIeWarning,
  getInitialGeometry,
  getLabel,
  getMetaAuthor,
  getMetaDescription,
  getMetaKeywords,
  getMetaRobotContent,
  getMaxZoom,
  getMinZoom,
  getNCoreApplicationName,
  getNCoreBaseUrl,
  getReportHeaderImageUrl,
  getSiteClusterFillColor,
  getSiteClusterStrokeColor,
  getStartPanel,
  isConditionallyHideOrigSysId,
  isShowDocumentCount,
  isSummarizeEIs,
  isZoomToLocationDesktop,
  isZoomToLocationMobile,
};
