import { Image, View } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ProfileDocument, ProfileSection, Text } from '../../../../lib/pdf';
import Config from '../../../config';
import ConfigKeys from '../../../config/keys';
import { getSiteName } from '../../../util/entities/siteProfile';
import { labelAsSentence } from '../../../util/label';
import { AlternateIDsSection } from './AlternateIDsSection';
import { AlternateNamesSection } from './AlternateNamesSection';
import { EnvironmentalInterests } from './EnvironmentalInterests';
import { GeopoliticalSection } from './GeopoliticalSection';
import { formatAddress, SiteAffiliates } from './SiteAffiliates';
import { SiteCodesSection } from './SiteCodesSection';

const PrimaryInfoColumns = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const PrimaryInfoMainColumn = styled(View)`
  flex: 1;
`;

const PrimaryInfoAside = styled(View)`
  flex: 0 0 auto;
  padding-left: 50pt;
`;

export default class SiteProfileDocument extends PureComponent {
  static propTypes = {
    /**
     * Site profile data object.
     */
    siteProfile: PropTypes.object.isRequired,

    /**
     * data uri of the site map.
     */
    mapDataUri: PropTypes.string,

    /**
     * Width of the map.
     */
    mapWidth: PropTypes.number,

    /**
     * Height of the map.
     */
    mapHeight: PropTypes.number,

    /**
     * Url to the header logo.
     */
    logoUrl: PropTypes.string.isRequired,

    /**
     * Width of the logo.
     */
    logoWidth: PropTypes.number,

    /**
     * Height of the logo.
     */
    logoHeight: PropTypes.number,
  };

  static defaultProps = {
    mapDataUrl: undefined,
  };

  render() {
    const {
      siteProfile,
      logoUrl,
      logoWidth,
      logoHeight,
      mapDataUrl,
      mapWidth,
      mapHeight,
    } = this.props;

    const { profileFacilityDetail: facility } = siteProfile;

    return (
      <ProfileDocument
        applicationName={Config.getApplicationName()}
        title="Site Report"
        subtitle={getSiteName(siteProfile)}
        logo={{
          dataUri: logoUrl,
          width: logoWidth,
          height: logoHeight,
        }}
        author={Config.getApplicationName()}
        creator={'nSITE by Windsor Solutions, Inc.'}
      >
        <ProfileSection hr={false} marginTop={false}>
          <PrimaryInfoColumns>
            <PrimaryInfoMainColumn>
              {facility && (
                <Text bold size="large">
                  {formatAddress(facility)}
                </Text>
              )}
              <GeopoliticalSection siteProfile={siteProfile} />
            </PrimaryInfoMainColumn>

            <PrimaryInfoAside>
              {mapDataUrl && (
                <Image src={mapDataUrl} style={{ height: mapHeight, width: mapWidth }} />
              )}
            </PrimaryInfoAside>
          </PrimaryInfoColumns>
        </ProfileSection>
        <SiteCodesSection sicCodes={siteProfile.profileSic} naicsCodes={siteProfile.profileNaics} />

        <AlternateNamesSection alternateNames={siteProfile.profileAlias} />
        <AlternateIDsSection aliases={siteProfile.profileAliasId} />

        <ProfileSection title={labelAsSentence(ConfigKeys.LABEL_ENVIRONMENTAL_INTERESTS)}>
          <EnvironmentalInterests environmentalInterests={siteProfile.profileEnvInt} />
        </ProfileSection>

        <ProfileSection title={labelAsSentence(ConfigKeys.LABEL_AFFILIATES)}>
          <SiteAffiliates affiliates={siteProfile.profileAffiliate} />
        </ProfileSection>
      </ProfileDocument>
    );
  }
}
