import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Columns from '../../../../lib/base/layout/flex/Columns';
import Column from '../../../../lib/base/layout/flex/Column';
import Button from '../../../../lib/base/button/Button';
import { COLOR_BASE_PRIMARY, COLOR_VARIANT_PRIMARY } from '../../../Constants';
import { IconChevronLeft, IconChevronRight } from '../../icons/fontawesome/icons';
import { Box, Text } from '../../../../lib/base';
import { getScrollParent } from '../../../util/dom';

export default class ComponentPager extends Component {
  static propTypes = {
    /**
     * Number of components to show per page.
     */
    pageSize: PropTypes.number.isRequired,

    /**
     * Items to page.
     */
    items: PropTypes.array.isRequired,

    /**
     * Renderer
     */
    render: PropTypes.func.isRequired,

    /**
     * Should this component scroll to top after paging.
     */
    scrollToTop: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    scrollToTop: true,
  };

  constructor(props) {
    super(props);

    const page = 0;

    const start = page * props.pageSize;
    const end = start + props.pageSize;

    this.state = {
      page: page,
      list: props.items ? props.items.slice(start, end) : [],
      total: props.items ? props.items.length : 0,
      hasPrevious: false,
      hasNext: true,
    };

    this.componentRef = React.createRef();

    this.handleOnPrevious = this.handleOnPrevious.bind(this);
    this.handleOnNext = this.handleOnNext.bind(this);
  }

  goToPage(page) {
    const start = page * this.props.pageSize;
    const end = start + this.props.pageSize;
    const total = this.state.total;

    this.setState({
      page: page,
      list: (this.props.items && this.props.items.slice(start, end)) || [],
      hasPrevious: page > 0,
      hasNext: (page + 1) * this.props.pageSize <= total,
    });

    if (this.props.scrollToTop) {
      const scrollable = getScrollParent(this.componentRef.current);

      if (scrollable) {
        scrollable.scrollTop = 0;
      }
    }
  }

  handleOnPrevious() {
    this.goToPage(this.state.page - 1);
  }

  handleOnNext() {
    this.goToPage(this.state.page + 1);
  }

  showingText() {
    const start = this.state.page * this.props.pageSize + 1;
    let end = (this.state.page + 1) * this.props.pageSize;
    end = end >= this.state.total ? this.state.total : end;
    const total = this.state.total;

    return `Showing ${start}-${end} of ${total}`;
  }

  renderPagerButtons() {
    return (
      <div>
        <Columns gutter="smaller">
          <Column flex={1}>
            <Button
              disabled={!this.state.hasPrevious}
              block
              onClick={this.handleOnPrevious}
              color={COLOR_BASE_PRIMARY}
              colorVariant={COLOR_VARIANT_PRIMARY}
            >
              <IconChevronLeft color="white" /> Prev {this.props.pageSize}
            </Button>
          </Column>
          <Column flex={1}>
            <Button
              disabled={!this.state.hasNext}
              block
              onClick={this.handleOnNext}
              color={COLOR_BASE_PRIMARY}
              colorVariant={COLOR_VARIANT_PRIMARY}
            >
              Next {this.props.pageSize} <IconChevronRight color="white" />
            </Button>
          </Column>
        </Columns>
        <Box textAlign="right" mTop="small">
          <Text size="smaller">{this.showingText()}</Text>
        </Box>
      </div>
    );
  }

  render() {
    if (!this.props.items) {
      return null;
    }

    if (this.props.items && this.props.items.length <= this.props.pageSize) {
      return this.props.render(this.props.items);
    }

    return (
      <div ref={this.componentRef}>
        {this.props.render(this.state.list)}
        <Box mTop="small">{this.renderPagerButtons()}</Box>
      </div>
    );
  }
}
