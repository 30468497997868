import { BREAKPOINT_HEIGHT_PX, BREAKPOINT_WIDTH_PX } from '../Constants';

/**
 * Helper function to determine if screen is small.
 */
export const isSmallScreen = () => {
  const screenWidth = document.documentElement.clientWidth;
  const screenHeight = document.documentElement.clientHeight;
  return screenWidth < BREAKPOINT_WIDTH_PX || screenHeight < BREAKPOINT_HEIGHT_PX;
};

/**
 * Helper function to determine if screen is desktop.
 */
export const isDesktopScreen = () => {
  return !isSmallScreen();
};

/**
 * Returns whether or not this device is a touch device.
 */
export const isTouchDevice = () => {
  return 'ontouchstart' in document.documentElement;
};

/**
 * Returns version of IE or false when browser is not Internet Explorer.
 */
export const detectIE = () => {
  const ua = window.navigator.userAgent;

  const msie = ua.indexOf('MSIE ');

  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  const trident = ua.indexOf('Trident/');

  if (trident > 0) {
    // IE 11 => return version number
    const rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  const edge = ua.indexOf('Edge/');

  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }

  // other browser
  return false;
};

/**
 * Returns true when browser is Internet Explorer, false otherwise.
 *
 * @returns {boolean} true when browser is Internet Explorer, false otherwise.
 */
export const isInternetExplorer = () => {
  return detectIE() !== false;
};
