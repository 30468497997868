import icon from './impl/icon';
import box from './impl/box';
import breakpoint from './impl/breakpoint';
import button from './impl/button';
import color from './impl/color';
import elevation from './impl/elevation';
import inputImpl from './impl/input';
import spacing from './impl/spacing';
import typographyImpl from './impl/typography';
import {
  GRID_UNIT_1X,
  GRID_UNIT_2X,
  GRID_UNIT_3X,
  GRID_UNIT_4X,
  GRID_UNIT_5X,
  GRID_UNIT_6X,
} from './impl/variables';

/**
 * Core
 */
const core = {
  grid: {
    unit: {
      x1: GRID_UNIT_1X,
      x2: GRID_UNIT_2X,
      x3: GRID_UNIT_3X,
      x4: GRID_UNIT_4X,
      x5: GRID_UNIT_5X,
      x6: GRID_UNIT_6X,
    },
  },
};

/**
 * Typography setup.
 */
const typography = typographyImpl({ core });

/**
 * Input setup.
 */
const input = inputImpl({
  color,
  typography,
});

/**
 * Windsor react base library default theme - defined as an object literal. Each of the areas
 * (typography, spacing, etc.) of the theme are definied in separate objects and then included
 * here.
 *
 * Custom themes are created by overriding the values at a particular path on this object before
 * supplying it to the Jss ThemeProvider. An example of how to that would be:
 *
 * const myTheme = Object.assign({}, defaultTheme, myOverrides);
 *
 */
const theme = {
  core,
  color,
  typography,
  elevation,
  spacing,
  breakpoint,
  button,
  input,
  box,
  icon,
};
export default theme;
