/**
 * NO-OP Function
 */
export const NO_OP_FUNC = () => ({});
/**
 * Default padding around around the map - prevent markers from sitting on the edge of the screen.
 */
export const DEFAULT_MAP_PADDING = [50, 50, 50, 50];
/**
 * Screen width to break at for small screens.
 */
export const BREAKPOINT_WIDTH_PX = 800;
/**
 * Screen height to break at for small screens.
 */
export const BREAKPOINT_HEIGHT_PX = 600;
/**
 * Search delay in ms.
 */
export const SEARCH_DELAY_TIME_MS = 500;
/**
 * The @media breakpoint the application will start to render for small screens.
 */
export const MEDIA_BREAKPOINT = `screen and (max-width: ${BREAKPOINT_WIDTH_PX}px), screen and (max-height: ${BREAKPOINT_HEIGHT_PX}px)`;
/**
 * Width of the left search results panel. Especially needed for applying a padding to the search
 * extent.
 */
export const SEARCH_RESULT_PANEL_WIDTH_PX = 380;
/**
 * Padding around the search result panel.
 */
export const SEARCH_RESULT_PANEL_MARGIN_PX = 16;
/**
 * The number of pixels to offset the left extent of the map.
 */
export const LEFT_EXTENT_BUFFER_PX =
  SEARCH_RESULT_PANEL_WIDTH_PX + SEARCH_RESULT_PANEL_MARGIN_PX * 2;
/**
 * The color of a selected marker.
 */
export const MARKER_SELECTED_COLOR = 'rgba(227,69,0, 0.9)';
/**
 * The size of a selected marker.
 */
export const MARKER_SELECTED_SIZE = 32;
/**
 * The size of a selected marker.
 */
export const MARKER_UN_SELECTED_SIZE = 30;
/**
 * Size of cluster feature.
 */
export const CLUSTER_RADIUS_PX = 24;
/**
 * Cluster color when hovered.
 */
export const CLUSTER_COLOR_HOVER = 'white';
/**
 * Z-index - Header menu
 */
export const Z_INDEX_MAP_BUTTONS = 50;
/**
 * Z-index - Mobile drawer primary
 */
export const Z_INDEX_MOBILE_DRAWER_BLIND_PRIMARY = 99;
/**
 * Z-index - Mobile drawer secondary
 */
export const Z_INDEX_MOBILE_DRAWER_BLIND_SECONDARY = 101;
/**
 * Z-index - Search bar
 */
export const Z_INDEX_SEARCH_BAR = 100;
/**
 * Z-index - Header menu
 */
export const Z_INDEX_HEADER_MENU = 200;
/**
 * Icon color on light background
 */
export const COLOR_ICON_ON_LIGHT = 'gray';
/**
 * Icon color on light background
 */
export const COLOR_VARIANT_ICON_ON_LIGHT = 'dark3';
/**
 * Color - Primary base color.
 */
export const COLOR_BASE_PRIMARY = 'blue';
/**
 * Color - Selected list color.
 */
export const COLOR_BASE_ORANGE = 'orange';
/**
 * Color - Primary variant color.
 */
export const COLOR_VARIANT_PRIMARY = 'base';
/**
 * Color - Danger Color
 */
export const COLOR_BASE_RED = 'red';
/**
 * The size of the text seen on the panel
 */
export const DEFAULT_TEXT_SIZE_DESCRIPTIONS = 'smaller';
/**
 * Regex for matching user denied geolocation.
 */
export const REGEX_USER_DENIED_GEOLOCATION_ERR_MESSAGE = /user denied geolocation$/i;
/**
 * Claim Site parameter
 */
export const CLAIM_SITE = 'claimsite';
/**
 * Routes
 */
export const ROUTE_HOME = '/';
export const ROUTE_NOT_FOUND = '/not-found';
export const ROUTE_ABOUT = '/about';
export const ROUTE_DEBUG = '/debug';
export const ROUTE_PROFILE = '/profile/:id';
export const ROUTE_SITE_PRINT = ['/site/:id/print'];
export const ROUTE_SITE_PROFILE = ['/site/:id/:tab', '/site/:id'];
export const ROUTE_MAP = [
  '/map/:panel/:subPanel/:id/:tab/:subTab',
  '/map/:panel/:subPanel/:id/:tab',
  '/map/:panel/:subPanel/:id',
  '/map/:panel/:subPanel',
  '/map/:panel',
  '/map',
];
export const ROUTE_STANDALONE_MAP = '/standalone-map/:id';
