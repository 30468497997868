import isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import Text from '../../text/Text';
import Cell from './Cell';
import CheckboxCell from './CheckboxCell';
import { cellBorderStyle, sumColumnWidths } from './helper';

class Row extends Component {
  static propTypes = {
    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,

    /**
     * Columns definition.
     */
    columns: PropTypes.array.isRequired,

    /**
     * Function to extract the row id.
     */
    keyExtractor: PropTypes.func.isRequired,

    /**
     * Renders subsection when extender is open.
     */
    renderSubsection: PropTypes.func,

    /**
     * Data for this row only.
     */
    rowData: PropTypes.object.isRequired,

    /**
     * Add zebra-striping to row.
     */
    striped: PropTypes.bool.isRequired,

    /**
     * conditionally hide a field in the row or subsection
     */
    conditionallyHide: PropTypes.bool,

    /**
     * Callback when a row is selected.
     */
    onSelect: PropTypes.func,

    /**
     * The selected entities.
     */
    selections: PropTypes.array,
  };

  static defaultProps = {
    renderSubsection: undefined,
  };

  constructor(props) {
    super(props);

    this.state = {
      isExtenderOpen: false,
    };

    this.handleOnClickExtender = this.handleOnClickExtender.bind(this);
  }

  handleOnClickExtender() {
    this.setState({ isExtenderOpen: !this.state.isExtenderOpen });
  }

  cell(row, col, colIndex) {
    if (React.isValidElement(col)) {
      return col;
    }

    if (isFunction(col.Extender)) {
      return (
        <Cell key={colIndex} width={col.width} onClick={this.handleOnClickExtender}>
          {col.Extender(row, this.state.isExtenderOpen)}
        </Cell>
      );
    }

    return (
      <Cell key={colIndex} width={col.width} align={col.align}>
        <Text size="smaller">{this.cellValue(row, col)}</Text>
      </Cell>
    );
  }

  cellValue(rowData, column) {
    if (column.accessor) {
      if (isFunction(column.accessor)) {
        return column.accessor(rowData);
      }
      return rowData[column.accessor];
    }
    return null;
  }

  rowClassName() {
    return [this.props.classes.row, this.props.striped ? this.props.classes.rowStriped : ''].join(
      ' '
    );
  }

  isSelected() {
    const { rowData, selections, keyExtractor } = this.props;
    const id = keyExtractor(rowData);
    const found = selections.find(s => id === keyExtractor(s));
    return found !== undefined;
  }

  render() {
    const columns = [...this.props.columns];

    if (this.props.onSelect) {
      columns.unshift(
        <CheckboxCell
          key="select"
          rowData={this.props.rowData}
          onChange={this.props.onSelect}
          checked={this.isSelected()}
        />
      );
    }

    return (
      <React.Fragment>
        <div className={this.rowClassName()}>
          {columns.map((col, colIndex) => this.cell(this.props.rowData, col, colIndex))}
        </div>
        {this.state.isExtenderOpen && (
          <div className={this.props.classes.subSection}>
            {this.props.renderSubsection(this.props.rowData, this.props.conditionallyHide)}
          </div>
        )}
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  row: {
    flex: 0,
    borderLeft: cellBorderStyle(theme),
    width: props => `${sumColumnWidths(props.columns)}px`,
    display: 'flex',
    flexDirection: 'row',
    borderCollapse: 'collapse',
  },

  rowStriped: {
    backgroundColor: '#efefef',
  },

  subSection: {
    flex: 0,
    width: props => `${sumColumnWidths(props.columns)}px`,
    borderBottom: cellBorderStyle(theme),
    borderLeft: cellBorderStyle(theme),
    borderRight: cellBorderStyle(theme),
  },
});

export default injectSheet(styles)(Row);
