import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import injectSheet from 'react-jss';
import { Row, Rows } from '../../../../../../../lib/base';
import SlideOutSiteProfileBody from './SlideOutSiteProfileBody';
import SlideOutSiteProfileHeader from './SlideOutSiteProfileHeader';

class SlideOutSiteProfile extends PureComponent {
  static propTypes = {
    /**
     * Site profile data.
     */
    siteProfile: PropTypes.object.isRequired,

    /**
     * Show site profile summary.
     */
    showSummary: PropTypes.bool,

    /**
     * Show site profile detail.
     */
    showDetail: PropTypes.bool,
  };

  static defaultProps = {
    showSummary: false,
    showDetail: false,
  };

  render() {
    const { siteProfile, showSummary, showDetail, classes } = this.props;

    return (
      <Rows stretch>
        <Row flex={0}>
          <SlideOutSiteProfileHeader
            siteProfile={siteProfile}
            showSummary={showSummary}
            showDetail={showDetail}
          />
        </Row>
        <Row flex={1} className={classes.body}>
          <SlideOutSiteProfileBody showSummary={showSummary} showDetail={showDetail} />
        </Row>
      </Rows>
    );
  }
}

const styles = theme => ({
  body: {
    height: '100%',
    overflow: 'hidden',
    overflowY: 'auto',
    padding: theme.spacing.medium,
    paddingLeft: theme.spacing.large,
  },
});

export default injectSheet(styles)(SlideOutSiteProfile);
