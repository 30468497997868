import CircleGeom from 'ol/geom/Circle';
import LineString from 'ol/geom/LineString';
import Polygon from 'ol/geom/Polygon';
import Draw from 'ol/interaction/Draw';
import VectorLayer from 'ol/layer/Vector';
import Map from 'ol/Map';
import { unByKey } from 'ol/Observable';
import Overlay from 'ol/Overlay';
import VectorSource from 'ol/source/Vector';
import { getArea, getLength } from 'ol/sphere';
import Circle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import PropTypes from 'prop-types';
import { Component } from 'react';

import './measure.css';

/**
 * Drawing types this measurement tool supports.
 */
export const TOOL_TYPE_LINE = 'LineString';
export const TOOL_TYPE_CIRCLE = 'Circle';
export const TOOL_TYPE_POLYGON = 'Polygon';

/**
 * Overlay type name - create new overlays with a 'type' so that we can easily delete them later
 * when the measurements are cleared.
 */
const OVERLAY_TYPE_NAME = 'wnd-measure-tool-overlay';

export default class MeasureTool extends Component {
  /**
   * List of properties this component accepts. The usage of PropTypes is for type checking of the
   * properties.
   *
   */
  static propTypes = {
    /**
     * Map instance, set automatically when this component is a child of <Map/>
     */
    map: PropTypes.instanceOf(Map),
    /**
     * Unit of measure.
     */
    unit: PropTypes.shape({
      label: PropTypes.string.isRequired,
      convert: PropTypes.func.isRequired,
    }),
    /**
     * Called when drawing tool is activated.
     */
    onDrawActivated: PropTypes.func,
    /**
     * Called when drawing tool is de-activated.
     */
    onDrawDeactivated: PropTypes.func,
    /**
     * The index where to insert the vector layer of drawings.
     */
    zIndex: PropTypes.number.isRequired,
    /**
     * The active measure tool (line, circle, or polygon)
     */
    drawingType: PropTypes.oneOf([TOOL_TYPE_LINE, TOOL_TYPE_CIRCLE, TOOL_TYPE_POLYGON]),
  };

  static defaultProps = {
    map: undefined,
    unit: undefined,
    onDrawActivated: undefined,
    onDrawDeactivated: undefined,
    drawingType: undefined,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextState = prevState;

    if (prevState.drawingType !== nextProps.drawingType) {
      nextState.drawingType = nextProps.drawingType;
      nextState.isActive = nextProps.drawingType !== undefined;
    }

    return nextState;
  }

  /**
   * Returns the coordinates based on the type of geometry passed in.
   */
  static geometryCoordinates(geometry) {
    if (geometry instanceof Polygon) {
      return geometry.getInteriorPoint().getCoordinates();
    } else if (geometry instanceof LineString) {
      return geometry.getLastCoordinate();
    } else if (geometry instanceof CircleGeom) {
      return geometry.getCenter();
    }
  }

  constructor(props) {
    super(props);

    /**
     * Internal state of this component.
     */
    this.state = {
      isActive: props.drawingType !== undefined,
      drawingType: props.drawingType,
    };

    /**
     * Vector source - where the drawing data will stored.
     */
    this.vectorSource = new VectorSource();

    /**
     * Vector layer - Hosts the vector source and is attached to the map as a layer. These styles
     * are for the measurement drawings that are added to the layer once the measurement
     * interaction is complete.
     */
    this.vectorLayer = new VectorLayer({
      source: this.vectorSource,
      zIndex: props.zIndex,
      style: new Style({
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.2)',
        }),
        stroke: new Stroke({
          color: '#ffcc33',
          width: 2,
        }),
        image: new Circle({
          radius: 7,
          fill: new Fill({
            color: '#ffcc33',
          }),
        }),
      }),
    });

    /**
     * Bind hanlders to this.
     */
    this.handleKeyup = this.handleKeyup.bind(this);
    this.handleOnDrawStart = this.handleOnDrawStart.bind(this);
    this.handleOnDrawEnd = this.handleOnDrawEnd.bind(this);
    this.handleOnDrawGeometryChange = this.handleOnDrawGeometryChange.bind(this);
    this.handleClearMeasurement = this.handleClearMeasurement.bind(this);
  }

  /**
   * Adds the vector layer to the map after this component has been mounted.
   */
  componentDidMount() {
    this.props.map.getLayers().push(this.vectorLayer);
    this.configureDrawInteraction(this.state.drawingType);
  }

  /**
   * Props or state has been updated.
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.drawingType !== this.props.drawingType) {
      this.props.map.removeOverlay(this.measurementOverlay);
      this.resetDrawInteraction();
    }
  }

  /**
   * Cleanup.
   */
  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleKeyup);
    this.props.map.removeOverlay(this.measurementOverlay);
    this.props.map.removeInteraction(this.drawInteraction);
    this.props.map.removeLayer(this.vectorLayer);
    this.drawInteraction.un('drawstart', this.handleOnDrawStart);
    this.drawInteraction.un('drawend', this.handleOnDrawEnd);
    this.drawInteraction = undefined;
    this.vectorLayer = undefined;
  }

  /**
   * Creates a new measurement overlay, this overlay is the tooltip with the value of the
   * measurement and close button.
   */
  newMeasurementOverlay() {
    /**
     * Create overlay instance. We also set a 'type' property so we can easily get a handle of all
     * the measurement overlays that may have been created, we'll need to do this when clearing ALL
     * measurements from the map.
     */
    const measurementOverlay = new Overlay({
      offset: [0, -15],
      positioning: 'bottom-center',
      stopEvent: true,
    });
    measurementOverlay.set('type', OVERLAY_TYPE_NAME, true);

    /**
     * DOM elements used to display the measurement value/label tooltip bubble.
     */
    const container = document.createElement('div');
    const body = document.createElement('div');
    const closer = document.createElement('span');
    container.className = 'wnd_react_map_measure_tooltip wnd_react_map_measure_tooltip_measurement';
    body.className = 'wnd_react_map_measure_tooltip_body';
    container.appendChild(body);
    container.appendChild(closer);
    measurementOverlay.setElement(container);

    /**
     * Adds a 'setBody' function so the draw event handler can easily update the contents with a
     * measurement value.
     */
    measurementOverlay.setBody = measurement => {
      body.innerHTML = measurement;
    };

    /**
     * Provides a link user to delete measurement.
     */
    measurementOverlay.enableCloser = () => {
      closer.innerHTML = '×';
      closer.className = 'wnd_react_map_measure_tooltip_closer';
      closer.onclick = e => {
        this.handleClearMeasurement(e, measurementOverlay);
      };
    };

    return measurementOverlay;
  }

  /**
   * Measure the drawing geometry - length or area.
   */
  measureGeometry(geometry) {
    if (geometry instanceof Polygon) {
      return this.measurePolygon(geometry);
    } else if (geometry instanceof LineString) {
      return this.measureLineString(geometry);
    } else if (geometry instanceof CircleGeom) {
      return this.measureCircle(geometry);
    }
  }

  /**
   * Measure the length of a LineString.
   */
  measureLineString(lineString) {
    const length = getLength(lineString);
    const val = this.props.unit.convert(length);
    const sup = this.props.unit.label;
    return `${val} ${sup}`;
  }

  /**
   * Measure the area of the polygon.
   */
  measurePolygon(polygon) {
    const area = getArea(polygon);
    const val = this.props.unit.convert(area);
    const sup = this.props.unit.label;
    return `${val} ${sup}`;
  }

  /**
   * Measure the area of the circle.
   */
  measureCircle(circle) {
    const area = Math.PI * circle.getRadius() * circle.getRadius();
    const val = this.props.unit.convert(area);
    const sup = this.props.unit.label;
    return `${val} ${sup}`;
  }

  /**
   * Configures the drawing interaction by setting various styles of the drawing. These are the
   * styles when actively drawing the measurement.
   */
  configureDrawInteraction(drawingType) {
    this.drawInteraction = new Draw({
      source: this.vectorSource,
      type: drawingType,
      style: new Style({
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.2)',
        }),
        stroke: new Stroke({
          color: 'rgba(0, 0, 0, 0.8)',
          lineDash: [10, 10],
          width: 4,
        }),
        image: new Circle({
          radius: 5,
          stroke: new Stroke({
            color: 'rgba(0, 0, 0, 0.7)',
          }),
          fill: new Fill({
            color: 'rgba(255, 255, 255, 0.2)',
          }),
        }),
      }),
    });
  }

  /**
   * Adds the drawing interaction to the map.
   */
  addDrawInteraction() {
    if (this.props.onDrawActivated) {
      this.props.onDrawActivated();
    }

    this.props.map.addInteraction(this.drawInteraction);
    this.drawInteraction.on('drawstart', this.handleOnDrawStart);
    this.drawInteraction.on('drawend', this.handleOnDrawEnd);
  }

  /**
   * Remove the drawing interaction from the map and notify listeners.
   */
  removeDrawInteraction() {
    this.props.map.removeInteraction(this.drawInteraction);

    if (this.props.onDrawDeactivated) {
      this.props.onDrawDeactivated();
    }
  }

  /**
   * Resets the drawing interaction.
   */
  resetDrawInteraction() {
    this.removeDrawInteraction();
    this.configureDrawInteraction(this.state.drawingType);
    if (this.state.isActive) {
      this.addDrawInteraction();
    }
  }

  /**
   * Handler to setup the measurement overlay when the user starts to draw.
   */
  handleOnDrawStart(evt) {
    document.addEventListener('keyup', this.handleKeyup);
    this.measurementOverlay = this.newMeasurementOverlay();
    this.measurementOverlay.set('feature', evt.feature);
    this.props.map.addOverlay(this.measurementOverlay);
    this.sketch = evt.feature;
    this.listener = this.sketch.getGeometry().on('change', this.handleOnDrawGeometryChange);
  }

  /**
   * Handler to update the current measurement overlay while the user is drawing.
   */
  handleOnDrawGeometryChange(evt) {
    const geom = evt.target;
    this.measurementOverlay.setBody(this.measureGeometry(geom));
    this.measurementOverlay.setPosition(MeasureTool.geometryCoordinates(geom));
  }

  /**
   * Handler when drawing is done - cleanup.
   */
  handleOnDrawEnd() {
    this.measurementOverlay.enableCloser();
    this.measurementOverlay.getElement().className =
      'wnd_react_map_measure_tooltip wnd_react_map_measure_tooltip_static';
    this.measurementOverlay.setOffset([0, -7]);
    this.sketch = null;
    this.measurementOverlay = null;
    document.removeEventListener('keyup', this.handleKeyup);
    unByKey(this.listener);
  }

  /**
   * When the "Escape" key is pressed reset the active measurement drawing - let the user start
   * over.
   */
  handleKeyup(event) {
    if (event.key === 'Escape') {
      if (this.measurementOverlay) {
        this.props.map.removeOverlay(this.measurementOverlay);
        this.resetDrawInteraction();
      }
    }
  }

  /**
   * Handles click to clear measurement drawings.
   */
  clearMeasurements() {
    /**
     * Collect and delete the measurement overlays.
     */
    const overlays = [];
    this.props.map.getOverlays().forEach(o => overlays.push(o));

    overlays
      .filter(o => o.get('type') === OVERLAY_TYPE_NAME)
      .forEach(o => this.props.map.removeOverlay(o));

    this.vectorSource.clear(true);
  }

  /**
   * Handles click to remove a single measurement drawing.
   */
  handleClearMeasurement(evt, measurementOverlay) {
    this.vectorSource.removeFeature(measurementOverlay.get('feature'));
    this.props.map.removeOverlay(measurementOverlay);
  }

  /**
   * Render
   */
  render() {
    return null;
  }
}
