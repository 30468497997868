import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { DataGrid } from '../../../../lib/base';
import { formatDate } from '../../../util/format';
import DocumentLink from './DocumentLink';

const keyExtractor = doc => doc.docId;

const columns = [
  {
    headerLabel: 'Date',
    accessor: document => formatDate(document.docDate),
    width: 140,
    colName: 'DOC_DATE',
    criteriaPropName: 'date',
  },
  {
    headerLabel: 'File',
    accessor: doc => <DocumentLink document={doc} />,
    width: 300,
    colName: 'DOC_NAME',
    criteriaPropName: 'file',
  },
  {
    headerLabel: 'Type',
    accessor: 'docType',
    width: 250,
    colName: 'DOC_TYPE',
    criteriaPropName: 'type',
  },
  {
    headerLabel: 'Description',
    accessor: 'docDesc',
    width: 500,
    colName: 'DOC_DESC',
    criteriaPropName: 'description',
  },
];

export default class DocumentsDataGrid extends Component {
  static propTypes = {
    /**
     * The chemicals data set.
     */
    data: PropTypes.array.isRequired,

    /**
     * Is tier2 data loading.
     */
    isLoading: PropTypes.bool.isRequired,

    /**
     * Callback to fetch data.
     */
    onRequestData: PropTypes.func.isRequired,

    /**
     * The page number of the data set.
     */
    page: PropTypes.number.isRequired,

    /**
     * The page size of the data set.
     */
    pageSize: PropTypes.number.isRequired,

    /**
     * Total number of records in data set.
     */
    total: PropTypes.number.isRequired,

    /**
     * The property name to sort the results by.
     */
    sortBy: PropTypes.string,

    /**
     * Is the sort ascending?
     */
    isAsc: PropTypes.bool,

    /**
     * The criteria object.
     */
    criteria: PropTypes.object,

    /**
     * Callback when a row is selected.
     */
    onSelect: PropTypes.func,

    /**
     * Pre-selections
     */
    selections: PropTypes.array,
  };

  render() {
    return (
      <DataGrid
        columns={columns}
        data={this.props.data}
        keyExtractor={keyExtractor}
        onRequestData={this.props.onRequestData}
        page={this.props.page}
        pageSize={this.props.pageSize}
        total={this.props.total}
        sortBy={this.props.sortBy}
        isAsc={this.props.isAsc}
        criteria={this.props.criteria}
        loading={this.props.isLoading}
        striped
        showPaginationBottom
        renderSubsection={this.renderSubsection}
        onSelect={this.props.onSelect}
        selections={this.props.selections}
      />
    );
  }
}
