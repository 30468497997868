import debounce from "lodash/debounce";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Box } from "../../../../../../../lib/base";
import Button from "../../../../../../../lib/base/button/Button";
import {
  searchFilterCountIfNeeded,
  searchSitesIfNeeded,
  skipMapMoveSearchOnce,
  zoomToCenter
} from "../../../../../../../store/map/map.action";
import { IconSpinner } from "../../../../../../components/icons/fontawesome/icons";
import { COLOR_BASE_PRIMARY, SEARCH_DELAY_TIME_MS } from "../../../../../../Constants";
import {
  navigateCloseSubPanel,
  navigateToDetail,
  navigateToDetailTab,
  navigateToSummary
} from "../../../../../../routing";
import { isSmallScreen } from "../../../../../../util/browser";
import EmptySiteListPanel from "./EmptySiteListPanel";
import GeocodeAddressResult from "./GeocodeAddressResult";
import SiteListItem from "./SiteListItem";

class SitesListPanel extends Component {
  static propTypes = {
    /**
     * Sites.
     */
    sites: PropTypes.array,

    /**
     * Selected site.
     */
    selected: PropTypes.object,

    /**
     * Count for all sites
     */
    count: PropTypes.number,

    /**
     * Zoom level
     */
    zoom: PropTypes.number,

    /**
     * get selected cluster site list to highlight left hand results
     */
    selectedClusterSiteList: PropTypes.array,

    /**
     * Flag to go directly to details, instead of summary panel.
     */
    isDirectToDetail: PropTypes.bool.isRequired,
    dispatchZoomToCenter: PropTypes.func,
    dispatchSkipMapMoveSearchOnce: PropTypes.func
  };

  static defaultProps = {
    sites: undefined,
    count: undefined,
    selected: undefined,
    isDirectToDetail: false
  };

  constructor(props) {
    super(props);

    this.handleOnLoadMoreSites = this.handleOnLoadMoreSites.bind(this);
    this.handleOnClickSite = this.handleOnClickSite.bind(this);

    this.dispatchSearchSitesDebounced = debounce(
      this.props.dispatchSearchSites,
      SEARCH_DELAY_TIME_MS
    );
  }

  handleOnLoadMoreSites() {
    this.dispatchSearchSitesDebounced(this.props.criteria, true);
    navigateCloseSubPanel(this.props);
  }

  handleOnClickSite(site) {
    if (this.isSiteInView(site)) {
      navigateCloseSubPanel(this.props);
    } else {
      const { params } = this.props.match;
      if (this.props.isDirectToDetail && params.subPanel === undefined) {
        navigateToDetail(this.props, site.sourceIdentifier);
      } else if (this.isNavigateToSummary(params)) {
        navigateToSummary(this.props, site.sourceIdentifier);
      } else if (params.tab !== undefined) {
        navigateToDetailTab(this.props, site.sourceIdentifier, params.tab);
      } else {
        navigateToDetail(this.props, site.sourceIdentifier);
      }

      if (site.latitude != null && site.longitude != null) {
        if (!isSmallScreen()) {
          this.props.dispatchSkipMapMoveSearchOnce();
          this.props.dispatchZoomToCenter(site, this.props.zoom);
        }
      }
    }
  }

  isNavigateToSummary(params) {
    return (
      params.subPanel === "summary" ||
      params.subPanel === "criteria" ||
      params.subPanel === "filters" ||
      params.subPanel === undefined
    );
  }

  isSiteInView(site) {
    const { params } = this.props.match;
    return site.sourceIdentifier === params.id;
  }

  isMoreSitesToFetch() {
    return this.props.siteCount < this.props.count;
  }

  render() {
    if (!this.props.sites) {
      return null;
    }

    if (this.props.sites.length === 0) {
      return (
        <Fragment>
          <GeocodeAddressResult />
          <EmptySiteListPanel />
        </Fragment>
      );
    }

    return (
      <React.Fragment>
        <GeocodeAddressResult />

        {/*TODO Move into redux action function*/}

        {this.props.selectedClusterSiteList &&
          this.props.sites.forEach(site => {
            let selectedSite = this.props.selectedClusterSiteList.find(selectedSite => {
              return selectedSite.sourceIdentifier === site.sourceIdentifier;
            });

            if (selectedSite) {
              selectedSite.isInSelectedCluster = true;
            }
          })}

        {this.props.sites.map(site => (
          <SiteListItem
            key={site.sourceIdentifier}
            site={site}
            onClickSite={this.handleOnClickSite}
            isSelected={this.isSiteInView(site)}
          />
        ))}

        {this.isMoreSitesToFetch() && (
          <Box pad="medium" pTop="large" pBottom="large">
            <Button color={COLOR_BASE_PRIMARY} onClick={this.handleOnLoadMoreSites} block>
              Load next 100 sites
              {this.props.isFetching && (
                <Box inline pLeft="small">
                  <IconSpinner color="white" />
                </Box>
              )}
            </Button>
          </Box>
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    zoom: state.map.zoom,
    criteria: state.map.search.criteria,
    isFetching: state.map.search.isFetching,
    sites: state.map.search.sites,
    count: state.map.search.count,
    siteCount: state.map.search.sites && state.map.search.sites.length,
    selectedClusterSiteList: state.map.search.selectedClusterSiteList
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchSkipMapMoveSearchOnce: () => {
      dispatch(skipMapMoveSearchOnce(true));
    },

    dispatchZoomToCenter: (site, zoom) => {
      if (
        site.feature &&
        Math.abs(site.feature.coordinates[0]) > 1 &&
        Math.abs(site.feature.coordinates[1]) > 1
      ) {
        dispatch(zoomToCenter(site.feature.coordinates, zoom));
      }
    },
    dispatchSearchSites: (criteria, shouldTakeWithoutClustering) => {
      /**
       * Incrementally grab additional sites every time the user scrolls to the bottom of the site
       * list (reset on map move)
       */
      if (shouldTakeWithoutClustering && criteria.takeWithoutClustering < 800) {
        criteria.takeWithoutClustering += 100;
      }
      dispatch(searchSitesIfNeeded(criteria));
      dispatch(searchFilterCountIfNeeded(criteria));
    }
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SitesListPanel)
);
