/**
 * Load export data
 */

export const FETCH_EXPORTGROUPS_BEGIN = 'FETCH_EXPORTGROUPS_BEGIN';
export const FETCH_EXPORTGROUPS_ERROR = 'FETCH_EXPORTGROUPS_ERROR';
export const FETCH_EXPORTGROUPS_SUCCESS = 'FETCH_EXPORTGROUPS_SUCCESS';

export const FETCH_EXPORTCSV_BEGIN = 'FETCH_EXPORTCSV_BEGIN';
export const FETCH_EXPORTCSV_ERROR = 'FETCH_EXPORTCSV_ERROR';
export const FETCH_EXPORTCSV_SUCCESS = 'FETCH_EXPORTCSV_SUCCESS';
