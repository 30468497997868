import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toWebMercatorCenter } from '../../../../../../../../lib/map/core/transfomer';
import { setMyLocationCoords, zoomToCenter } from '../../../../../../../../store/map/map.action';
import { reportError } from '../../../../../../../../store/system/system.action';
import { IconMyLocation } from '../../../../../../../components/icons';
import ToolbarButton from './ToolbarButton';
import Link from '../../../../../../../../lib/base/text/Link';
import { REGEX_USER_DENIED_GEOLOCATION_ERR_MESSAGE } from '../../../../../../../Constants';

class MyLocationButton extends Component {
  static propTypes = {
    /**
     * Circular button?
     */
    circular: PropTypes.bool,

    /**
     * Inverse the colors of the button.
     */
    inverse: PropTypes.bool,

    /**
     * Button shows only text
     */
    textOnly: PropTypes.bool,

    /**
     * Handle geo-location request errors.
     */
    onUserDeniedError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    inverse: false,
    textOnly: false,
  };

  constructor(props) {
    super(props);

    this.handleOnClickMyLocation = this.handleOnClickMyLocation.bind(this);
    this.handleOnPositionSuccess = this.handleOnPositionSuccess.bind(this);
    this.handleOnPositionError = this.handleOnPositionError.bind(this);
  }

  handleOnClickMyLocation() {
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        this.handleOnPositionSuccess,
        this.handleOnPositionError,
        {}
      );
    }
  }

  handleOnPositionSuccess(position) {
    this.props.dispatchSetMyLocation([position.coords.longitude, position.coords.latitude]);
    this.props.dispatchZoomToMyLocation(
      toWebMercatorCenter([position.coords.longitude, position.coords.latitude])
    );
  }

  handleOnPositionError(err) {
    if (err.message.match(REGEX_USER_DENIED_GEOLOCATION_ERR_MESSAGE)) {
      this.props.onUserDeniedError(err);
    } else {
      this.props.dispatchReportError(err);
    }
  }

  render() {
    if (this.props.textOnly) {
      return (
        <Link onClick={this.handleOnClickMyLocation} size="small">
          Show your location
        </Link>
      );
    }

    return (
      <ToolbarButton
        onClick={this.handleOnClickMyLocation}
        title="Zoom to your location on the map"
        inverse={this.props.inverse}
        circular={this.props.circular}
        icon={<IconMyLocation />}
      />
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchSetMyLocation: lonlat => {
      dispatch(setMyLocationCoords(lonlat));
    },
    dispatchZoomToMyLocation: coordinates => {
      dispatch(zoomToCenter(coordinates, 12 + Math.random() / 100)); //this is in place to ensure that the zoom is unique everytime and the map updates each time the user clicks the zoom to location button
    },
    dispatchReportError: err => {
      dispatch(reportError(err));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyLocationButton);
