import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Box, Text } from '../../../../../../../lib/base';
import Column from '../../../../../../../lib/base/layout/flex/Column';
import Columns from '../../../../../../../lib/base/layout/flex/Columns';
import {
  IconChemical,
  IconCME,
  IconDocument,
  IconNoLocation,
} from '../../../../../../components/icons/fontawesome/icons';
import ListItemBox from '../../../../../../components/list/ListItemBox';
import SiteNameAndAddress from '../../../../../../components/profile/header/SiteNameAndAddress';

export default class SiteListItem extends PureComponent {
  static propTypes = {
    /**
     * The site list item.
     */
    site: PropTypes.object.isRequired,

    /**
     * Called when site item is clicked.
     */
    onClickSite: PropTypes.func.isRequired,

    /**
     * Is site list item selected?
     */
    isSelected: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.handleOnClickSite = this.handleOnClickSite.bind(this);
  }

  handleOnClickSite() {
    this.props.onClickSite(this.props.site);
  }

  render() {
    const { site, isSelected } = this.props;

    const isLocationLess =
      (site.latitude === null && site.longitude === null) ||
      (site.latitude === 0 && site.longitude === 0);

    return (
      <ListItemBox
        onClick={this.handleOnClickSite}
        active={isSelected}
        clusterHighlight={site.isInSelectedCluster} /* TODO This does nothing */
      >
        <Box pTop="medium" pBottom="medium">
          <SiteNameAndAddress site={site} />
          <Box>
            <Columns vAlign="center" gutter="small" hAlign="left">
              {site.hasCme && (
                <Column title="Compliance">
                  <IconCME color="gray" colorVariant="light6" size="xs" />
                </Column>
              )}
              {site.hasDocument && (
                <Column title="Documents">
                  <IconDocument color="gray" colorVariant="light6" size="xs" />
                </Column>
              )}
              {site.hasChemical && (
                <Column title="Chemicals">
                  <IconChemical color="gray" colorVariant="light6" size="xs" />
                </Column>
              )}
            </Columns>
          </Box>
          {isLocationLess && (
            <Box mTop="smaller">
              <Columns gutter="small">
                <Column>
                  <IconNoLocation color="gray" colorVariant="light3" />
                </Column>
                <Column flex={1}>
                  <Text size="smaller" color="gray" colorVariant="light5">
                    No location, not seen on the map.
                  </Text>
                </Column>
              </Columns>
            </Box>
          )}
        </Box>
      </ListItemBox>
    );
  }
}
