import PropTypes from 'prop-types';
import React, { Component } from 'react';
import None from '../panel/None';
import LabelValuePair from './LabelValuePair';
import Box from '../../../lib/base/layout/Box';

/**
 * Component that renders a list of data in label/value format. Each row consist of a single label
 * value pair.
 */
export default class LabelValueTable extends Component {
  static propTypes = {
    /**
     * The data for the table.
     */
    data: PropTypes.array,

    /**
     * Func that takes a single element from the array and returns the label for that row.
     */
    labelFunc: PropTypes.func,

    /**
     * Func that takes a single element from the array and returns the value for that row.
     */
    valueFunc: PropTypes.func,

    /**
     * Func to generate the key.
     */
    keyFunc: PropTypes.func,
  };

  static defaultProps = {
    data: [],
    labelFunc: row => row.label,
    valueFunc: row => row.value,
    keyFunc: undefined,
  };

  render() {
    if (this.props.data.length === 0) {
      return (
        <Box stack="medium">
          <None />
        </Box>
      );
    }

    const { keyFunc, labelFunc, valueFunc } = this.props;

    return this.props.data.map((row, index) => (
      <LabelValuePair
        key={keyFunc ? keyFunc(row) : index}
        label={labelFunc(row)}
        value={valueFunc(row)}
      />
    ));
  }
}
