import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import injectSheet from 'react-jss';

class Row extends PureComponent {
  static propTypes = {
    /**
     * Children components.
     */
    children: PropTypes.node,

    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,

    /**
     * React ref callback to the root container.
     */
    containerRef: PropTypes.object,

    /**
     * Shorthand form for flex-grow, flex-shrink & flex-basis.
     */
    flex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    /**
     * Horizontal alignment.
     *
     * [left|center|right|stretch]
     */
    hAlign: PropTypes.oneOf(['left', 'center', 'right', 'stretch']),

    /**
     * OnClick function.
     */
    onClick: PropTypes.func,

    /**
     * Container style.
     */
    style: PropTypes.object,
  };

  propsAsClassnames() {
    const classNames = [];

    switch (this.props.hAlign) {
      case 'left':
        classNames.push(this.props.classes.hAlignLeft);
        break;
      case 'center':
        classNames.push(this.props.classes.hAlignCenter);
        break;
      case 'right':
        classNames.push(this.props.classes.hAlignRight);
        break;
      case 'stretch':
        classNames.push(this.props.classes.hAlignStretch);
        break;
      default:
        break;
    }

    return classNames;
  }

  render() {
    const classNames = [this.props.classes.row].concat(this.propsAsClassnames());
    classNames.push(this.props.className);

    return (
      <div
        ref={this.props.containerRef}
        onClick={this.props.onClick}
        className={classNames.join(' ')}
        style={this.props.style}
      >
        {this.props.children}
      </div>
    );
  }
}

const styles = theme => ({
  row: {
    flex: props => props.flex || '0 1 auto',

    '&:hover': {
      cursor: props => props.onClick && 'pointer',
    },
  },

  hAlignLeft: {
    alignSelf: 'flex-start',
  },

  hAlignRight: {
    alignSelf: 'flex-end',
  },

  hAlignCenter: {
    alignSelf: 'center',
  },

  hAlignStretch: {
    alignSelf: 'stretch',
  },
});

export default injectSheet(styles)(Row);
