import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import injectSheet from 'react-jss';

/**
 * A parent flex box container component for use in row-based layouts.
 */
class Rows extends PureComponent {
  static propTypes = {
    /**
     * Children components.
     */
    children: PropTypes.node.isRequired,

    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,

    /**
     * Css classname.
     */
    className: PropTypes.string,

    /**
     * React ref callback to the root container.
     */
    containerRef: PropTypes.object,

    /**
     * Horizontal alignment.
     *
     * [left|center|right|stretch]
     */
    hAlign: PropTypes.oneOf(['left', 'center', 'right', 'stretch']),

    /**
     * Inline flex box
     */
    inline: PropTypes.bool,

    /**
     * OnClick
     */
    onClick: PropTypes.func,

    /**
     * Reverse the rows bottom to top.
     */
    reverse: PropTypes.bool,

    /**
     * Stretch to 100% height.
     */
    stretch: PropTypes.bool,

    /**
     * Container style.
     */
    style: PropTypes.object,

    /**
     * Vertical alignment. For this property to have any affect you'll need to set a height.
     *
     * [top|center|bottom|space-between|space-around|space-even]
     */
    vAlign: PropTypes.oneOf([
      'top',
      'center',
      'bottom',
      'space-between',
      'space-around',
      'space-even',
    ]),
  };

  propsAsClassnames() {
    const classNames = [];

    switch (this.props.hAlign) {
      case 'left':
        classNames.push(this.props.classes.hAlignLeft);
        break;
      case 'center':
        classNames.push(this.props.classes.hAlignCenter);
        break;
      case 'right':
        classNames.push(this.props.classes.hAlignRight);
        break;
      case 'stretch':
        classNames.push(this.props.classes.hAlignStretch);
        break;
      default:
        break;
    }

    switch (this.props.vAlign) {
      case 'top':
        classNames.push(this.props.classes.vAlignTop);
        break;
      case 'center':
        classNames.push(this.props.classes.vAlignCenter);
        break;
      case 'bottom':
        classNames.push(this.props.classes.vAlignBottom);
        break;
      case 'space-between':
        classNames.push(this.props.classes.vAlignSpaceBetween);
        break;
      case 'space-around':
        classNames.push(this.props.classes.vAlignSpaceAround);
        break;
      case 'space-even':
        classNames.push(this.props.classes.vAlignSpaceEven);
        break;
      default:
        break;
    }

    if (this.props.reverse) {
      classNames.push(this.props.classes.directionReverse);
    }

    if (this.props.stretch) {
      classNames.push(this.props.classes.stretch);
    }

    return classNames;
  }

  render() {
    const classNames = [this.props.classes.rows].concat(this.propsAsClassnames());

    classNames.push(this.props.className);

    return (
      <div
        ref={this.props.containerRef}
        onClick={this.props.onClick}
        className={classNames.join(' ')}
        style={this.props.style}
      >
        {this.props.children}
      </div>
    );
  }
}

const styles = theme => ({
  rows: {
    display: props => (props.inline ? 'inline-flex' : 'flex'),
    flexDirection: 'column',

    '&:hover': {
      cursor: props => props.onClick && 'pointer',
    },
  },

  hAlignLeft: {
    alignItems: 'flex-start',
  },

  hAlignRight: {
    alignItems: 'flex-end',
  },

  hAlignCenter: {
    alignItems: 'center',
  },

  hAlignStretch: {
    alignItems: 'stretch',
  },

  vAlignTop: {
    justifyContent: 'flex-start',
  },

  vAlignCenter: {
    justifyContent: 'center',
  },

  vAlignBottom: {
    justifyContent: 'flex-end',
  },

  vAlignSpaceBetween: {
    justifyContent: 'space-between',
  },

  vAlignSpaceAround: {
    justifyContent: 'space-around',
  },

  vAlignSpaceEven: {
    justifyContent: 'space-evenly',
  },

  directionReverse: {
    flexDirection: 'column-reverse',
  },

  stretch: {
    height: '100%',
  },
});

export default injectSheet(styles)(Rows);
