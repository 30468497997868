/**
 * Returns a site name and address data object.
 *
 * @param siteProfile the site profile data from the server.
 */
export const toSiteNameAndAddress = siteProfile => {
  const { profileFacilityDetail } = siteProfile;

  return {
    siteName: profileFacilityDetail.facilityName,
    address: profileFacilityDetail.address,
    city: profileFacilityDetail.city,
    zip: profileFacilityDetail.zipCode,
    state: profileFacilityDetail.state,
  };
};

export const getSiteId = siteProfile => {
  if (siteProfile && siteProfile.profileFacilityDetail) {
    return siteProfile.profileFacilityDetail.sourceIdentifier;
  }
  return undefined;
};

export const getSiteName = siteProfile => {
  if (siteProfile && siteProfile.profileFacilityDetail) {
    return siteProfile.profileFacilityDetail.facilityName;
  }
  return undefined;
};
