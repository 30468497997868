/**
 * Downloads a file using the fetch api.
 * @param url the url of the file to fetch.
 * @param fileName the name of the file.
 * @param extension the file extension.
 * @param onFetchBegin callback before fetch starts.
 * @param onFetchEnd callback after fetch has completed.
 * @param onFetchError callback when an error occurs.
 * @returns {Promise<boolean>}
 */
import { toast } from 'react-toastify';

export async function downloadFileImproved({
  url,
  fileName,
  extension,
  onFetchBegin,
  onFetchEnd,
  onFetchError,
}) {
  try {
    if (onFetchBegin) {
      onFetchBegin();
    }

    const docFetch = await fetch(url);
    const blob = await docFetch.blob();

    if (onFetchEnd) {
      onFetchEnd();
    }

    if (window.navigator.msSaveOrOpenBlob) {
      let msFileName = extension ? fileName + extension : fileName;
      window.navigator.msSaveBlob(blob, msFileName);
      return true;
    }

    let link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = window.URL.createObjectURL(blob);
    link.download = typeof extension === 'string' ? fileName + extension : fileName;
    link.dispatchEvent(new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window }));
    return true;
  } catch (e) {
    if (onFetchError) {
      onFetchError(e);
    }
  }
}

export async function downloadFile(url, fileName, extension) {
  const blob = await fetch(url)
    .then(r => {
      if (r.status !== 200) {
        throw r.json();
      }
      return r.blob();
    })
    .catch(error => {
      error.then(result => {
        console.warn(result.Message);
        toast.error(result.Message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
    });
  const data = window.URL.createObjectURL(blob);

  if (window.navigator.msSaveOrOpenBlob) {
    let msFileName = extension ? fileName + extension : fileName;
    window.navigator.msSaveBlob(blob, msFileName);
    return true;
  }

  let link = document.createElement('a');
  link.setAttribute('type', 'hidden');
  link.href = data;
  link.download = typeof extension === 'string' ? fileName + extension : fileName;
  link.dispatchEvent(new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window }));
  return true;
}
