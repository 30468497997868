import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { startMapSystemIfNeeded } from '../../../store/system/system.action';
import Header from '../../components/header/Header';
import WindowResizeListener from '../../components/listener/WindowResizeListener';
import PageLoading from '../../components/loading/PageLoading';
import { isSmallScreen } from '../../util/browser';
import MapDesktopContainer from './components/MapDesktopContainer';
import MapMobileContainer from './components/MapMobileContainer';

class MapPage extends PureComponent {
  static propTypes = {
    /**
     * JSS classes.
     */
    classes: PropTypes.object.isRequired,

    /**
     * Loads map settings in redux store.
     */
    dispatchLoadMapSettings: PropTypes.func.isRequired,

    /**
     * Is map settings in loading state.
     */
    isStarting: PropTypes.bool,

    /**
     * Map loading message.
     */
    message: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      isSmallScreen: isSmallScreen(),
    };
    this.handleOnWindowResize = this.handleOnWindowResize.bind(this);
  }

  componentDidMount() {
    this.props.dispatchLoadMapSettings();
  }

  handleOnWindowResize() {
    this.setState({ isSmallScreen: isSmallScreen() });
  }

  renderContent() {
    const { isStarting, message } = this.props;

    if (isStarting) {
      return <PageLoading message={message} />;
    }

    return this.state.isSmallScreen ? this.renderMobile() : this.renderDesktop();
  }

  renderMobile() {
    return (
      <div className={this.props.classes.mobileBody}>
        <MapMobileContainer />
      </div>
    );
  }

  renderDesktop() {
    return (
      <div className={this.props.classes.desktopBody}>
        <MapDesktopContainer />
      </div>
    );
  }

  render() {
    const { isStarting, classes } = this.props;

    if (isStarting === undefined) {
      return null;
    }

    const { application } = classes;

    return (
      <div className={application}>
        <WindowResizeListener onResize={this.handleOnWindowResize} />
        <Header />
        {this.renderContent()}
      </div>
    );
  }
}

const styles = () => ({
  application: {
    height: '100%',
    width: '100%',
  },

  desktopBody: {
    height: 'calc(100% - 88px)',
  },

  mobileBody: {
    height: 'calc(100% - 44px)',
  },
});

function mapStateToProps(state) {
  return {
    isStarting: state.map.startup.isStarting,
    message: state.map.startup.message,
    error: state.map.startup.error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchLoadMapSettings: () => {
      dispatch(startMapSystemIfNeeded());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectSheet(styles)(MapPage));
