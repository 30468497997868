import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import injectSheet from 'react-jss';
import { withRouter } from 'react-router-dom';
import { Column, Columns, Row, Rows, Text } from '../../../lib/base';
import Config from '../../config';
import { MEDIA_BREAKPOINT } from '../../Constants';
import { toHome } from '../../routing';
import { isSmallScreen } from '../../util/browser';
import ApplicationMenu from './ApplicationMenu';
import { createNavLink } from './createNavLink';
import Link from './Link';

class Header extends Component {
  static propTypes = {
    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { isShowHamburgerMenu: false };
    this.handleOnClickHamburger = this.handleOnClickHamburger.bind(this);
  }

  /**
   * Updates component from props that have changed.
   *
   * (1) Meta theme color - Changes chrome address bar color.
   *
   * TODO Extract to a separate component.
   */
  componentDidMount() {
    const meta = document.getElementById('meta-theme-color');
    meta.content = Config.getHeaderColorBackground();
  }

  /**
   * Wraps a component with a link to this application's url.
   */
  withApplicationLink(component) {
    return (
      <Link
        onClick={toHome(this.props.history)}
        title={Config.getApplicationName()}
        nowrap
        elipsis
        block={isSmallScreen()}
      >
        {component}
      </Link>
    );
  }

  /**
   * Toggle the hamburger menu.
   */
  handleOnClickHamburger() {
    this.setState({ isShowHamburgerMenu: !this.state.isShowHamburgerMenu });
  }

  render() {
    const {
      container,
      header,
      warning,
      applicationIcon,
      applicationInitials,
      bullet,
      applicationName,
      links,
      link,
      mobileOnly,
    } = this.props.classes;

    return (
      <Rows className={container}>
        <Row flex={0}>
          <Columns className={links} vAlign="center">
            {!!window.MSInputMethodContext && !!document.documentMode && (
              <Column flex={0} className={warning}>
                <Text size="smaller">{Config.getIeWarning()}</Text>
              </Column>
            )}

            {Config.getHeaderNavigation().map((nav, index) => (
              <Column flex={0} key={index}>
                {createNavLink(this.props.history, nav, link)}
              </Column>
            ))}
          </Columns>
        </Row>
        <Row>
          <Columns vAlign="center" className={header}>
            {Config.getApplicationIcon() && (
              <Column flex={0}>
                {this.withApplicationLink(
                  <img
                    src={Config.getApplicationIcon()}
                    alt={Config.getApplicationName()}
                    className={applicationIcon}
                  />
                )}
              </Column>
            )}

            {Config.getApplicationInitials() && (
              <Fragment>
                <Column flex={0} className={applicationInitials}>
                  {this.withApplicationLink(
                    <Text size="small">{Config.getApplicationInitials()}</Text>
                  )}
                </Column>
                <Column flex={0} className={bullet}>
                  <Text size="small">•</Text>
                </Column>
              </Fragment>
            )}

            <Column flex={1} className={applicationName}>
              {this.withApplicationLink(<Text size="small">{Config.getApplicationName()}</Text>)}
            </Column>

            <Column flex={0} className={mobileOnly}>
              <ApplicationMenu />
            </Column>
          </Columns>
        </Row>
      </Rows>
    );
  }
}

const styles = theme => ({
  container: {
    ...theme.elevation.shadow1,
    height: '88px',

    [`@media ${MEDIA_BREAKPOINT}`]: {
      height: '44px',
    },
  },

  /**
   * The header background and text colors can be defined in the settings object.
   */
  header: {
    height: '44px',
    backgroundColor: Config.getHeaderColorBackground() || theme.color.base.blue,
    color: Config.getHeaderColorText() || theme.color.base.white,
  },

  mobileOnly: {
    display: 'none',
  },

  applicationIcon: {
    height: '24px',
    margin: theme.spacing.inset.stretch.medium,
  },

  applicationInitials: {
    fontWeight: theme.typography.weight.heavier,
    letterSpacing: 1.5,
    paddingLeft: Config.getApplicationIcon() ? undefined : '1em',
  },

  bullet: {
    padding: theme.spacing.inset.stretch.small,
    fontWeight: theme.typography.weight.heaviest,
    color: Config.getHeaderColorText() || theme.color.base.white,
  },

  applicationName: {
    minWidth: 0,
  },

  warning: {
    textAlign: 'left',
    paddingRight: '2em',
  },

  links: {
    height: '44px',
    backgroundColor: theme.color.gray.dark5,
    color: theme.color.base.white,
    whiteSpace: 'nowrap',
    overflowY: 'hidden',
    overflowX: 'auto',
    justifyContent: 'flex-end',
    '-webkit-overflow-scrolling': 'touch',
  },

  link: {
    padding: theme.spacing.inset.stretch.medium,
    margin: theme.spacing.smaller,
    fontSize: '14px',

    '&:active, &:visited': {
      textDecoration: 'none',
      color: theme.color.base.white,
    },

    '&:hover': {
      color: theme.color.base.blue,
    },

    '&:focus': {
      outlineColor: theme.color.base.blue,
    },
  },

  [`@media ${MEDIA_BREAKPOINT}`]: {
    mobileOnly: {
      display: 'block',
    },

    links: {
      display: 'none',
    },

    applicationInitials: {
      display: 'none',
    },

    bullet: {
      display: 'none',
    },

    hamburger: {
      display: 'block',
      padding: '0 8px',
    },
  },
});

export default withRouter(injectSheet(styles)(Header));
