import {
  ELEVATION_SHADOW_1,
  ELEVATION_SHADOW_2,
  ELEVATION_SHADOW_3,
  ELEVATION_SHADOW_4,
  ELEVATION_SHADOW_5,
  ELEVATION_SHADOW_6,
} from './variables';

/**
 * Elevation, for giving containers a sense of elevation through shadows.
 */

const elevation = {
  shadow1: {
    boxShadow: ELEVATION_SHADOW_1,
  },
  shadow2: {
    boxShadow: ELEVATION_SHADOW_2,
  },
  shadow3: {
    boxShadow: ELEVATION_SHADOW_3,
  },
  shadow4: {
    boxShadow: ELEVATION_SHADOW_4,
  },
  shadow5: {
    boxShadow: ELEVATION_SHADOW_5,
  },
  shadow6: {
    boxShadow: ELEVATION_SHADOW_6,
  },
};

export default elevation;
