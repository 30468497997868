import startCase from 'lodash/startCase';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Box, Columns } from '../../../../../../../../lib/base';
import {
  deselectSite,
  searchFilterCountIfNeeded,
  searchSitesIfNeeded,
} from '../../../../../../../../store/map/map.action';
import { navigateToCriteria, navigateToFilters } from '../../../../../../../routing';
import CriteriaChip from './CriteriaChip';

class CurrentCriteriaChips extends Component {
  constructor(props) {
    super(props);
    this.handleOnAdvanceFormCriteriaRemove = this.handleOnAdvanceFormCriteriaRemove.bind(this);
    this.handleOnFiltersFormRemove = this.handleOnFiltersFormRemove.bind(this);
    this.handleOnClickAdvancedSearch = this.handleOnClickAdvancedSearch.bind(this);
    this.handleOnClearCriteria = this.handleOnClearCriteria.bind(this);
  }

  executeSearch(criteria) {
    this.props.dispatchSearch(criteria);
    this.props.dispatchDeselectSite();
  }

  /**
   * Submits the search when site attribute is removed.
   */
  handleOnAdvanceFormCriteriaRemove(event, key) {
    event.stopPropagation();
    const criteria = { ...this.props.criteria };
    delete criteria.advanced[key];
    this.executeSearch(criteria);
  }

  /**
   * Removes the filter and executes a new search.
   */
  handleOnFiltersFormRemove(event, filterValue) {
    event.stopPropagation();
    const criteria = this.props.criteria;
    criteria.filters = criteria.filters.filter(f => f !== filterValue);
    this.executeSearch(criteria);
  }

  handleOnClearCriteria() {
    const criteria = { ...this.props.criteria };
    criteria.advanced = {};
    criteria.filters = [];
    this.executeSearch(criteria);
  }

  hasFilterCriteria() {
    const { filters } = this.props.criteria;
    return filters && filters.length > 0;
  }

  hasSiteAttributeCriteria() {
    const { advanced } = this.props.criteria;
    return (
      Object.keys(advanced).filter(key => advanced[key] && advanced[key].length > 0).length > 0
    );
  }

  hasAdvancedCriteria() {
    return this.hasFilterCriteria() || this.hasSiteAttributeCriteria();
  }

  handleOnClickAdvancedSearch(type) {
    if (type === 'filter') {
      navigateToFilters(this.props);
    } else {
      navigateToCriteria(this.props);
    }
  }

  label(key) {
    return startCase(key).toUpperCase() + ': ' + this.props.criteria.advanced[key];
  }

  renderSelectedAsChips() {
    return this.props.criteria.filters.map(f => (
      <Box key={f} mTop="smaller" mLeft="smaller">
        <CriteriaChip
          label={f.split(':')[1]}
          onClick={() => this.handleOnClickAdvancedSearch('filter')}
          onRemove={event => this.handleOnFiltersFormRemove(event, f)}
        />
      </Box>
    ));
  }

  renderSiteAttributeCriteriaAsChips() {
    const { advanced } = this.props.criteria;

    return Object.keys(advanced)
      .filter(key => advanced[key] && advanced[key].length > 0)
      .map(key => (
        <Box key={key} mTop="smaller" mLeft="smaller">
          <CriteriaChip
            label={this.label(key)}
            onClick={this.handleOnClickAdvancedSearch}
            onRemove={event => this.handleOnAdvanceFormCriteriaRemove(event, key)}
          />
        </Box>
      ));
  }

  renderAll() {
    const chips = [];
    chips.push(this.renderSiteAttributeCriteriaAsChips());
    chips.push(this.renderSelectedAsChips());
    return chips;
  }

  render() {
    if (!this.hasAdvancedCriteria()) return null;

    return (
      <Box pad="smaller">
        <Columns mTop="medium" wrap>
          {this.renderAll()}
        </Columns>
      </Box>
    );
  }
}

function mapStateToProps(state) {
  return {
    criteria: { ...state.map.search.criteria },
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchSearch: criteria => {
      dispatch(searchSitesIfNeeded(criteria));
      dispatch(searchFilterCountIfNeeded(criteria));
    },
    dispatchDeselectSite: () => {
      dispatch(deselectSite());
    },
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CurrentCriteriaChips)
);
