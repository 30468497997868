const SMALL = 24;
const MEDIUM = 32;
const LARGE = 40;

const reset = ({ color, typography }) => ({
  ...typography.base,
  border: 'none',
  outline: 'none',
  margin: 0,
  padding: 0,
  width: '100%',
});

/**
 * Values defining whitespace in a systematic way.
 */
const input = ({ color, typography }) => ({
  /**
   * Size related styles properties.
   */
  small: {
    ...reset({ color, typography }),
    height: `${SMALL}px`,
    lineHeight: `${SMALL}px`,
    fontSize: '14px',
  },

  medium: {
    ...reset({ color, typography }),
    height: `${MEDIUM}px`,
    lineHeight: `${MEDIUM}px`,
    fontSize: '16px',
  },

  large: {
    ...reset({ color, typography }),
    height: `${LARGE}px`,
    lineHeight: `${LARGE}px`,
    fontSize: '18px',
  },
});

export default input;
