import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Box, Column, Columns, Link, Text } from '../../../lib/base';
import SpinnerPanel from '../spinner/SpinnerPanel';
import DocumentsDataGrid from './documents/DocumentsDataGrid';
import DownloadSelectionsButton from './documents/DownloadSelectionsButton';

const api = () => window.nSITE.Api;

export default class DocumentsContainer extends Component {
  static DEFAULT_SORT_BY = 'DOC_DATE';

  static propTypes = {
    /**
     * Source identifier to look up.
     */
    sourceIdentifier: PropTypes.string,
    /**
     * Application settings.
     */
    settings: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      documents: undefined,
      isInitialized: false,
      isLoading: true,
      error: undefined,
      page: 1,
      pageSize: 5,
      total: undefined,
      sortBy: DocumentsDataGrid.DEFAULT_SORT_BY,
      isAsc: false,
      criteria: {},
      selections: [],
      count: 0,
    };

    this.handleOnRequestData = this.handleOnRequestData.bind(this);
    this.handleOnSelect = this.handleOnSelect.bind(this);
    this.handleOnSelectAll = this.handleOnSelectAll.bind(this);
    this.handleOnClear = this.handleOnClear.bind(this);
  }

  componentDidMount() {
    this.fetchData();
    this.fetchCount();
  }

  fetchData() {
    this.setState({ isLoading: true });

    api()
      .profile.getDocuments(this.props.sourceIdentifier, {
        page: this.state.page,
        pageSize: this.state.pageSize,
        total: this.state.total,
        sortBy: this.state.sortBy,
        isAsc: this.state.isAsc,
        criteria: this.state.criteria,
      })
      .then(pageResponse => {
        this.setState({
          documents: pageResponse.data,
          page: pageResponse.page,
          pageSize: pageResponse.pageSize,
          total: pageResponse.total,
          sortBy: pageResponse.sortBy,
          isAsc: pageResponse.isAsc,
          criteria: pageResponse.criteria,
          isLoading: false,
          isInitialized: true,
        });
      })
      .catch(err => {
        this.setState({
          error: err,
          isLoading: false,
        });
      });
  }

  handleOnRequestData({ page, pageSize, sortBy, isAsc, criteria }) {
    this.setState(
      {
        page: page || this.state.page,
        pageSize: pageSize || this.state.pageSize,
        sortBy: sortBy || this.state.sortBy,
        isAsc: isAsc === undefined ? true : isAsc,
        criteria: criteria || this.state.criteria,
      },
      this.fetchData
    );
  }

  fetchCount() {
    this.setState({
      isLoading: true,
    });

    api()
      .profile.getDocumentsCount(this.props.sourceIdentifier)
      .then(count => {
        this.setState({
          count: count,
          isLoading: false,
        });
      })
      .catch(err => {
        this.setState({
          error: err,
          isLoading: false,
        });
      });
  }

  handleOnSelect(selections) {
    this.setState({
      selections: selections,
    });
  }

  handleOnSelectAll() {
    this.setState({
      isLoading: true,
    });

    api()
      .profile.getDocumentsAll(this.props.sourceIdentifier)
      .then(documents => {
        this.setState({
          selections: documents,
          isLoading: false,
        });
      })
      .catch(err => {
        this.setState({
          error: err,
          isLoading: false,
        });
      });
  }

  handleOnClear() {
    this.setState({
      selections: [],
    });
  }

  renderSelectAll() {
    if (this.state.count === this.state.selections.length) {
      return (
        <Text size="smaller">
          Select All <Text bold>{this.state.count}</Text> Documents
        </Text>
      );
    }

    return (
      <Link
        onClick={this.handleOnSelectAll}
        size="smaller"
        title={`Select all ${this.state.count} documents`}
      >
        Select All <Text bold>{this.state.count}</Text> Documents
      </Link>
    );
  }

  renderClearSelections() {
    if (this.state.selections.length > 0) {
      return (
        <Link onClick={this.handleOnClear} size="smaller">
          <Text bold>Clear Selections</Text>
        </Link>
      );
    }

    return <Text size="smaller">Clear Selections</Text>;
  }

  render() {
    if (this.state.isInitialized) {
      return (
        <Box>
          <Box stack="small">
            <Columns gutter="medium" vAlign="center">
              <Column>
                <DownloadSelectionsButton selections={this.state.selections} />
              </Column>
              <Column>{this.renderSelectAll()}</Column>
              <Column>{this.renderClearSelections()}</Column>
            </Columns>
          </Box>
          <DocumentsDataGrid
            selections={this.state.selections}
            data={this.state.documents}
            isLoading={this.state.isLoading}
            onRequestData={this.handleOnRequestData}
            page={this.state.page}
            pageSize={this.state.pageSize}
            total={this.state.total}
            sortBy={this.state.sortBy}
            isAsc={this.state.isAsc}
            criteria={this.state.criteria}
            onSelect={this.handleOnSelect}
          />
        </Box>
      );
    }

    return <SpinnerPanel />;
  }
}
