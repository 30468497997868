import format from 'date-fns/format';

const safeFormat = (date, pattern) => {
  if (!date) {
    return '';
  }
  return format(date, pattern);
};

export const formatDate = date => {
  return safeFormat(date, 'MM/DD/YYYY');
};

export const formatDateTime = date => {
  return safeFormat(date, 'MM/DD/YYYY hh:mm ZZ');
};
