import format from 'date-fns/format';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Box, Column, Columns, Text } from '../../../lib/base';
import Select from '../../components/form/Select';
import LabelValuePair from '../../components/lv/LabelValuePair';
import HeadingText from '../../components/text/HeadingText';
import { formatDate } from '../../util/format';
import SpinnerPanel from '../spinner/SpinnerPanel';
import ChemicalsDataGridContainer from './chemical/ChemicalsDataGridContainer';

const api = () => window.nSITE.Api;

export default class ChemicalsContainer extends Component {
  static propTypes = {
    /**
     * The source id of the site.
     */
    sourceIdentifier: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isInitialized: false,
      isLoading: true,
      error: undefined,
      tier2reports: undefined,
      tier2reportSelected: undefined,
    };

    this.handleOnChangeReport = this.handleOnChangeReport.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    this.setState({ isLoading: true });

    api()
      .profile.getTier2Reports(this.props.sourceIdentifier)
      .then(tier2reports => {
        this.setState({
          tier2reports: tier2reports,
          tier2reportSelected: tier2reports[0],
          error: undefined,
          isLoading: false,
          isInitialized: true,
        });
      })
      .catch(err => {
        this.setState({
          error: err,
          isLoading: false,
        });
      });
  }

  handleOnChangeReport(event, choice) {
    this.setState({ tier2reportSelected: choice });
  }

  renderIntro() {
    if (this.state.tier2reports.length > 1) {
      return (
        <Box mBottom="medium">
          <Box mBottom="medium">
            <HeadingText>Select Reporting Period</HeadingText>
          </Box>
        </Box>
      );
    } else {
      const report = this.state.tier2reports[0];

      return (
        <Box>
          <Box mBottom="medium">
            <HeadingText>Chemical Storage</HeadingText>
          </Box>
          <Box>
            <Columns gutter="larger">
              <Column>
                <LabelValuePair label="Reporting Period" value={report.reportPeriod} />
              </Column>
              <Column>
                <LabelValuePair label="Submitted On" value={formatDate(report.submissionDate)} />
              </Column>
            </Columns>
          </Box>
        </Box>
      );
    }
  }

  render() {
    if (!this.state.isInitialized) {
      return null;
    }

    if (this.state.error) {
      return <Text>{this.state.error}</Text>;
    }

    if (this.state.isLoading) {
      return <SpinnerPanel />;
    }

    if (this.state.tier2reports.length === 0) {
      return (
        <Box mTop="large" textAlign="center">
          <Text size="small">No chemicals were found.</Text>
        </Box>
      );
    }

    return (
      <Box mTop="medium">
        {this.renderIntro()}

        {this.state.tier2reports.length > 1 && (
          <Select
            value={this.state.tier2reportSelected}
            onChange={this.handleOnChangeReport}
            placeholder="Choose..."
            choices={this.state.tier2reports}
            keyExtractor={choice => choice.tier2ReportSeq}
            labelExtractor={choice =>
              `${choice.reportPeriod} - Sumbitted On: ${format(
                choice.submissionDate,
                'MM/DD/YYYY'
              )} (${choice.chemicalCount})`
            }
            renderChoice={choice =>
              `${choice.reportPeriod} - Sumbitted On: ${format(
                choice.submissionDate,
                'MM/DD/YYYY'
              )} (${choice.chemicalCount})`
            }
          />
        )}

        {this.state.tier2reportSelected && (
          <Box mTop="medium">
            <ChemicalsDataGridContainer
              tier2ReportId={this.state.tier2reportSelected.tier2ReportSeq}
            />
          </Box>
        )}
      </Box>
    );
  }
}
