import { Document } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import { formatDate } from '../../../../app/util/format';
import { Footer } from './footer';
import { Header } from './header';
import { Body, Page } from './page';

const EntityDetailDocument = ({ children, applicationName, title, subtitle, logo, ...rest }) => {
  const footerMeta = [
    {
      text: applicationName,
      bold: false,
      italic: false,
    },
    {
      text: title,
      bold: true,
      italic: false,
    },
    {
      text: subtitle,
      bold: true,
      italic: true,
    },
    {
      text: formatDate(new Date()),
      bold: false,
      italic: false,
    },
  ];

  const documentTitle = `${title} - ${subtitle}`;

  return (
    <Document title={documentTitle} creator={applicationName} producer={applicationName} {...rest}>
      <Page size="letter" orientation="portrait" wrap={true} debug={false}>
        <Header title={title} subtitle={subtitle} logo={logo} />
        <Body>{children}</Body>
        <Footer meta={footerMeta} />
      </Page>
    </Document>
  );
};

EntityDetailDocument.propTypes = {
  applicationName: PropTypes.string,
  logo: PropTypes.shape({
    dataUri: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  children: PropTypes.node.isRequired,
};

export default EntityDetailDocument;
