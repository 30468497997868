import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Tabs from '../../../components/tabs/Tabs';
import { allTabs } from '../../../util/tabs';

class SiteProfileTabs extends Component {
  static propTypes = {
    /**
     * Get tab labels from settings
     */
    tab: PropTypes.string.isRequired,

    /**
     * Router history.
     */
    history: PropTypes.object.isRequired,

    /**
     * Router location.
     */
    location: PropTypes.object.isRequired,

    /**
     * Router location.
     */
    match: PropTypes.object.isRequired,

    /**
     * information about tabs
     */
    tabCounts: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.handleOnTabClick = this.handleOnTabClick.bind(this);
  }

  goTo(tab) {
    const { params } = this.props.match;
    return `/site/${params.id}/${tab.altId}`;
  }

  handleOnTabClick(tab) {
    const { history, location } = this.props;

    history.push({
      pathname: this.goTo(tab),
      search: location.search,
    });
  }

  render() {
    const { tab: currentTab } = this.props;
    const tabs = allTabs(this.props.tabCounts);
    const activeTab = tabs.find(tab => tab.altId === currentTab);
    const defaultTab = tabs.find(tab => tab.altId === 'profile');

    return <Tabs tabs={tabs} onClick={this.handleOnTabClick} active={activeTab || defaultTab} />;
  }
}

function mapStateToProps(state) {
  return {
    tabCounts: state.profile.detail.data.tabCounts,
  };
}

export default withRouter(connect(mapStateToProps)(SiteProfileTabs));
