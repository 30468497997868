import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import Cell from './Cell';
import FilterHeaderCell from './FilterHeaderCell';
import { cellBorderStyle, sumColumnWidths } from './helper';

class FilterHeader extends Component {
  static propTypes = {
    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,

    /**
     * Columns definition.
     */
    columns: PropTypes.array.isRequired,

    /**
     * Property to sort by.
     */
    criteria: PropTypes.object,

    /**
     * Called when the sort has changed.
     */
    onCriteriaChange: PropTypes.func,

    /**
     * Add a spacer to account for checkbox cell found in other rows.
     */
    hasSortSpacer: PropTypes.bool,
  };

  static defaultProps = {
    sortBy: undefined,
    isAsc: undefined,
    onSortChange: undefined,
    criteria: {},
    onCriteriaChange: () => {},
  };

  constructor(props) {
    super(props);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnClear = this.handleOnClear.bind(this);
  }

  handleOnChange(column, value) {
    const criteria = { ...this.props.criteria };
    criteria[column.criteriaPropName] = value;
    this.criteriaChanged(criteria);
  }

  handleOnClear(column) {
    const criteria = { ...this.props.criteria };
    delete criteria[column.criteriaPropName];
    this.criteriaChanged(criteria);
  }

  criteriaChanged(criteria) {
    if (this.props.onCriteriaChange) {
      this.props.onCriteriaChange(criteria);
    }
  }

  inputValue(column) {
    if (this.props.criteria) {
      return this.props.criteria[column.criteriaPropName];
    }
  }

  render() {
    const columns = [...this.props.columns];

    if (this.props.hasSortSpacer) {
      columns.unshift(<Cell key="select" width={50} bgColor="primary" bgColorVariant={'light1'} />);
    }

    return (
      <div className={this.props.classes.container}>
        {columns.map((column, index) => {
          if (React.isValidElement(column)) {
            return column;
          }

          if (column.Extender) {
            return (
              <Cell key={index} width={column.width} bgColor="primary" bgColorVariant="light1" />
            );
          }

          return (
            <FilterHeaderCell
              key={index}
              column={column}
              initialValue={this.inputValue(column)}
              onChange={this.handleOnChange}
              onClear={this.handleOnClear}
              bgColor="primary"
              bgColorVariant="light1"
            />
          );
        })}
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    borderTop: cellBorderStyle(theme),
    borderLeft: cellBorderStyle(theme),
    width: props => sumColumnWidths(props.columns),
    borderCollapse: 'collapse',
    display: 'flex',
    flexDirection: 'row',
  },
});

export default injectSheet(styles)(FilterHeader);
