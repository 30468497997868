import { StyleSheet, View } from '@react-pdf/renderer';
import isArray from 'lodash/isArray';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import LabelValueTable from '../../../pdf/lv/LabelValueTable';
import { ProfileSection } from '../../../../lib/pdf';
export class AlternateIDsSection extends PureComponent {
  static propTypes = {
    aliases: PropTypes.arrayOf(
      PropTypes.shape({
        aliasId: PropTypes.string,
        aliasIdTypeDesc: PropTypes.string,
      })
    ),
  };

  static defaultProps = {
    aliases: [],
  };

  hasData() {
    const { aliases } = this.props;

    if (!aliases || !isArray(aliases)) {
      return false;
    }
    return aliases.length > 0;
  }

  render() {
    const { aliases } = this.props;

    if (!this.hasData()) {
      return null;
    }

    const halfLength = Math.ceil(aliases.length / 2);
    const dataLeftColumn = aliases.slice(0, halfLength);
    const dataRightColumn = aliases.slice(halfLength, aliases.length);

    return (
      <ProfileSection title="Alternate IDs">
        <View style={styles.root}>
          <View style={[styles.column, styles.firstColumn]}>
            <LabelValueTable
              data={dataLeftColumn}
              labelFunc={row => row.aliasIdTypeDesc}
              valueFunc={row => row.aliasId}
            />
          </View>
          <View style={styles.column}>
            <LabelValueTable
              data={dataRightColumn}
              labelFunc={row => row.aliasIdTypeDesc}
              valueFunc={row => row.aliasId}
            />
          </View>
        </View>
      </ProfileSection>
    );
  }
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    flex: 1,
  },
  firstColumn: {
    marginRight: 4,
  },
});
