import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  searchFilterCountIfNeeded,
  searchSitesIfNeeded,
} from '../../../../../../../../store/map/map.action';
import {
  IconNoLocation,
  IconWithLocation,
} from '../../../../../../../components/icons/fontawesome/icons';
import { navigateToResults } from '../../../../../../../routing';
import { isSmallScreen } from '../../../../../../../util/browser';
import ToolbarButton from './ToolbarButton';

class LocationLessSitesButton extends Component {
  static propTypes = {
    /**
     * Search criteria.
     */
    criteria: PropTypes.object.isRequired,
    /**
     * Circular button?
     */
    circular: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  handleOnClick() {
    /**
     * We're making a copy of the current criteria and toggling the discardLocationlessFeatures
     * property - then search with the new criteria.
     */
    const criteria = Object.assign({}, this.props.criteria, {
      discardLocationlessFeatures: !this.props.criteria.discardLocationlessFeatures,
    });

    this.props.dispatchSearchSites(criteria);

    if (!isSmallScreen()) {
      navigateToResults(this.props);
    }
  }

  render() {
    if (this.props.criteria.discardLocationlessFeatures) {
      return (
        <ToolbarButton
          title="Include sites that cannot be shown on the map in the list."
          onClick={this.handleOnClick}
          circular={this.props.circular}
          icon={<IconWithLocation />}
          active={false}
        />
      );
    } else {
      return (
        <ToolbarButton
          title="Do not include sites with no location."
          onClick={this.handleOnClick}
          icon={<IconNoLocation />}
          circular={this.props.circular}
          active={true}
        />
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    criteria: state.map.search.criteria,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchSearchSites: criteria => {
      dispatch(searchSitesIfNeeded(criteria));
      dispatch(searchFilterCountIfNeeded(criteria));
    },
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LocationLessSitesButton)
);
