import { easeInOutQuad } from 'ez.js';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import Tweezer from 'tweezer.js';
import WindowResizeListener from '../../../../../components/listener/WindowResizeListener';
import { SEARCH_RESULT_PANEL_MARGIN_PX } from '../../../../../Constants';

/**
 * An absolutely positioned div that slides in/out from the left. The div can slide out to
 * an intermediate position and then can extend out even further to full screen width.
 */
class SlideOutContainer extends Component {
  static propTypes = {
    /**
     * Children components.
     */
    children: PropTypes.node.isRequired,

    /**
     * The css 'top' position.
     */
    top: PropTypes.number.isRequired,

    /**
     * The height of the container div.
     */
    height: PropTypes.number.isRequired,

    /**
     * The left position of the container div.
     */
    left: PropTypes.number.isRequired,

    /**
     * The width of the container div.
     */
    width: PropTypes.number.isRequired,

    /**
     * Is the slide out panel open.
     */
    isOpen: PropTypes.bool.isRequired,

    /**
     * Is the slide out panel fully open.
     */
    isOpenFull: PropTypes.bool.isRequired,

    /**
     * Z-Index css property.
     */
    zIndex: PropTypes.number.isRequired,

    /**
     * Function to call on window resize events.
     */
    onWindowResize: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.handleOnWindowResize = this.handleOnWindowResize.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let to = {};

    if (this.props.isOpenFull) {
      const screenWidth = document.documentElement.clientWidth;
      to.width = screenWidth - this.props.width - SEARCH_RESULT_PANEL_MARGIN_PX;
      to.left = this.props.left;
    } else if (this.props.isOpen) {
      to.width = this.props.width;
      to.left = this.props.left;
    } else {
      to.width = 0;
      to.left = 0;
    }

    this.animate(to);
  }

  animate(to) {
    const durationMs = 250;

    const style = this.containerRef.current.style;
    const startLeft = Number.parseInt(style.left.replace(/px/i, ''), 10);
    const startWidth = Number.parseInt(style.width.replace(/px/i, ''), 10);

    /**
     * Tween left style property.
     */
    new Tweezer({
      start: startLeft,
      end: to.left,
      duration: durationMs,
      easing: easeInOutQuad,
    })
      .on('tick', value => {
        if (this.containerRef && this.containerRef.current) {
          this.containerRef.current.style.left = `${value}px`;
        }
      })
      .begin();

    /**
     * Tween width style property.
     */
    new Tweezer({
      start: startWidth,
      end: to.width,
      duration: durationMs,
      easing: easeInOutQuad,
    })
      .on('tick', value => {
        if (this.containerRef && this.containerRef.current) {
          this.containerRef.current.style.width = `${value}px`;
        }
      })
      .begin();
  }

  handleOnWindowResize() {
    if (this.props.onWindowResize) {
      this.props.onWindowResize();
    }
    this.forceUpdate();
  }

  render() {
    const styles = {
      zIndex: this.props.zIndex,
      position: 'absolute',
      backgroundColor: 'white',
      top: this.props.top,
      height: this.props.height,
      left: `${SEARCH_RESULT_PANEL_MARGIN_PX}px`,
      boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.4)',
    };

    return (
      <div ref={this.containerRef} style={styles}>
        <WindowResizeListener onResize={this.handleOnWindowResize} />
        <div className={this.props.classes.body}>{this.props.children}</div>
      </div>
    );
  }
}

const styles = () => ({
  body: {
    height: '100%',
    overflow: 'hidden',
    overflowY: props => (props.isOpen || props.isOpenFull ? 'auto' : 'hidden'),
    boxShadow: 'inset 7px 0 9px -7px rgba(0,0,0,0.4)',
  },
});

export default injectSheet(styles)(SlideOutContainer);
