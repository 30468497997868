import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import SpinnerPanel from '../../spinner/SpinnerPanel';
import ViolationsDataGrid from './ViolationsDataGrid';

const api = () => window.nSITE.Api;

export default class ViolationsContainer extends PureComponent {
  static propTypes = {
    /**
     * Source identifier to look up.
     */
    sourceIdentifier: PropTypes.string.isRequired,

    /**
     * Filter evaluations on whether violations were found or not found.
     */
    isOpen: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      violations: undefined,
      isInitialized: false,
      isLoading: true,
      error: undefined,
      page: 1,
      pageSize: 5,
      total: undefined,
      sortBy: ViolationsDataGrid.DEFAULT_SORT_BY,
      isAsc: false,
      criteria: {},
    };

    this.handleOnRequestData = this.handleOnRequestData.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    this.setState({ isLoading: true });

    api()
      .profile.getComplianceViolations(this.props.sourceIdentifier, {
        isOpen: this.props.isOpen,
        page: this.state.page,
        pageSize: this.state.pageSize,
        total: this.state.total,
        sortBy: this.state.sortBy,
        isAsc: this.state.isAsc,
        criteria: this.state.criteria,
      })
      .then(pageResponse => {
        this.setState({
          violations: pageResponse.data,
          page: pageResponse.page,
          pageSize: pageResponse.pageSize,
          total: pageResponse.total,
          sortBy: pageResponse.sortBy,
          isAsc: pageResponse.isAsc,
          criteria: pageResponse.criteria,
          isLoading: false,
          isInitialized: true,
        });
      })
      .catch(err => {
        this.setState({
          error: err,
          isLoading: false,
        });
      });
  }

  handleOnRequestData({ page, pageSize, sortBy, isAsc, criteria }) {
    this.setState(
      {
        page: page || this.state.page,
        pageSize: pageSize || this.state.pageSize,
        sortBy: sortBy || this.state.sortBy,
        isAsc: isAsc === undefined ? true : isAsc,
        criteria: criteria || this.state.criteria,
      },
      this.fetchData
    );
  }

  render() {
    if (this.state.isInitialized) {
      return (
        <ViolationsDataGrid
          data={this.state.violations}
          isLoading={this.state.isLoading}
          onRequestData={this.handleOnRequestData}
          page={this.state.page}
          pageSize={this.state.pageSize}
          total={this.state.total}
          sortBy={this.state.sortBy}
          isAsc={this.state.isAsc}
          criteria={this.state.criteria}
        />
      );
    }

    return <SpinnerPanel />;
  }
}
