/**
 * Relative path to all the nSITE endpoints in one location.
 */
const endpoints = {
  AppSettings: {
    GetAppSettings: `/settings`,
    GetDashboardList: '/settings/dashboardlist',
    GetDashboardChart: '/settings/dashboardchart',
  },
  Export: {
    All: '/export/exportTypes',
    DownloadCsv: '/export/exportcsv',
    DownloadZip: '/export/exportzip',
  },
  Geocode: {
    Address: `/geocode/address`,
    GeocodeLocal: `/geocode/geocodeLocal`,
  },
  Profile: {
    GetProfileDetails: `/profile/details`,
    GetTier2Reports: `/profile/tier2reports`,
    GetTier2Chemicals: `/profile/tier2chemicals`,
    GetComplianceSummary: `/profile/compliance/summary`,
    GetEnvInterests: `/profile/env-interests`,
    GetComplianceEvaluations: `/profile/compliance/evaluations`,
    GetComplianceViolations: `/profile/compliance/violations`,
    GetComplianceActions: `/profile/compliance/actions`,
    GetGeneric: '/profile/generic',
    GetDocuments: '/profile/documents',
    GetDocumentsAll: '/profile/documents/all',
    GetDocumentsCount: '/profile/documents/count',
  },
  Layer: {
    All: `/layer/findall`,
    GetDatabaseLayers: `/layer/getDatabaseLayers`,
    GetSpatialLayerMetadata: `/layer/getSpatialLayerMetadata`,
    GetFeatureLayerMetadata: '/layer/getSqlSpatialFeatureMetadataById',
  },
  Site: {
    GetSite: '/site',
    Search: '/site/sitecluster',
    ClaimSite: '/site/claimsite',
  },
  Search: {
    Suggest: '/search/suggest',
  },
  SearchKey: {
    All: '/searchkeys',
  },
  Filter: {
    All: '/filter/sitefilters',
    SiteFilterCounts: '/filter/sitefiltercounts',
  },
};

export default endpoints;
