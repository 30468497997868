import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { Box } from '../../../../../lib/base';
import Config from '../../../../config';
import { COLOR_BASE_PRIMARY, COLOR_VARIANT_PRIMARY } from '../../../../Constants';
import CurrentCriteriaChips from '../shared/panel/criteria/chip/CurrentCriteriaChips';
import SearchInput from '../shared/panel/search/SearchInput';
import ModalHelp from './modal/ModalHelp';
import ModalInfo from './modal/ModalInfo';
import ModalMapLayers from './modal/ModalMapLayers';
import ModalSearchCriteria from './modal/ModalSearchCriteria';

class SearchBarMobile extends Component {
  static propTypes = {
    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,

    /**
     * React reference to root of this component.
     */
    containerRef: PropTypes.object,
  };

  render() {
    return (
      <Box
        pad="small"
        bgColor={COLOR_BASE_PRIMARY}
        bgColorVariant={COLOR_VARIANT_PRIMARY}
        className={this.props.classes.container}
        containerRef={this.props.containerRef}
      >
        <ModalSearchCriteria />
        <ModalMapLayers />
        <ModalHelp />
        <ModalInfo />
        <Box pBottom="smallest">
          <SearchInput />
        </Box>
        <CurrentCriteriaChips />
      </Box>
    );
  }
}

const styles = theme => ({
  container: {
    backgroundColor: Config.getHeaderColorBackground() || theme.color.base.blue,
  },
});

export default injectSheet(styles)(SearchBarMobile);
