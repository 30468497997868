import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { Box, Text } from '../../../lib/base';
import { COLOR_BASE_PRIMARY } from '../../Constants';
import { propTypeColor } from '../../../lib/base/util/propTypes';
import { jssColorLookup } from '../../../lib/base/util/jss';

class Labeled extends Component {
  static propTypes = {
    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,
    /**
     * border color
     */
    borderColor: propTypeColor,
    /**
     *
     */
    renderView: PropTypes.func.isRequired,
    /**
     * Label for the input field.
     */
    label: PropTypes.string.isRequired,
  };

  static defaultProps = {
    borderColor: COLOR_BASE_PRIMARY,
  };

  render() {
    const { container, label, inputContainer } = this.props.classes;

    return (
      <Box
        className={container}
        pTop="small"
        pBottom="small"
        stack="medium"
        title={this.props.title || this.props.label}
      >
        <Box pad="smaller" borderColor={this.props.borderColor} borderColorVariant="light3">
          <Text className={label} caps block color={COLOR_BASE_PRIMARY}>
            {this.props.label}
          </Text>
          <Box className={inputContainer}>{this.props.renderView()}</Box>
        </Box>
      </Box>
    );
  }
}

const styles = theme => ({
  container: {
    position: 'relative',
  },

  label: {
    position: 'absolute',
    backgroundColor: theme.color.base.white,
    top: 4,
    left: 8,
    lineHeight: '12px',
    fontSize: '12px',
    padding: '0 4px',
  },

  inputContainer: {},
});

export default injectSheet(styles)(Labeled);
