import React, { PureComponent } from 'react';
import SiteProfileContainer from './components/SiteProfileContainer';

export default class SiteProfilePage extends PureComponent {
  render() {
    const { params } = this.props.match;

    return <SiteProfileContainer id={params.id} tab={params.tab} />;
  }
}
