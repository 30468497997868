import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import injectSheet from 'react-jss';
import { withRouter } from 'react-router-dom';
import { Box, Button, Column, Columns, Link } from '../../../../../../../../lib/base';
import { IconChevronLeft, IconClear, IconNcore } from '../../../../../../../components/icons';
import ProfilePageLink from '../../../../../../../components/profile/header/ProfilePageLink';
import ProfileTabs from '../../../../../../../components/profile/header/ProfileTabs';
import SiteNameAndAddress from '../../../../../../../components/profile/header/SiteNameAndAddress';
import Config from '../../../../../../../config';
import { COLOR_BASE_PRIMARY } from '../../../../../../../Constants';
import { navigateCloseSubPanel, navigateToSummary } from '../../../../../../../routing';
import { getSiteId, toSiteNameAndAddress } from '../../../../../../../util/entities/siteProfile';
import BaseHeader from './BaseHeader';

class SiteProfileDetailHeader extends PureComponent {
  static propTypes = {
    /**
     * Site profile data.
     */
    siteProfile: PropTypes.object.isRequired,

    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.handleOnClose = this.handleOnClose.bind(this);
    this.handleOnShowLessInformation = this.handleOnShowLessInformation.bind(this);
  }

  handleOnShowLessInformation() {
    navigateToSummary(this.props, getSiteId(this.props.siteProfile));
  }

  handleOnClose() {
    navigateCloseSubPanel(this.props);
  }

  render() {
    const { siteProfile, classes } = this.props;
    const sourceId = getSiteId(siteProfile);
    const nCoreSiteUrl = siteProfile.profileFacilityDetail.ncoresiteurl;

    return (
      <BaseHeader>
        <Columns vAlign="top" gutter="medium">
          <Column flex={1}>
            <SiteNameAndAddress site={toSiteNameAndAddress(siteProfile)} />
          </Column>
          <Column flex={0}>
            <Box pad="medium" pRight="small">
              <ProfilePageLink sourceIdentifier={sourceId} print />
            </Box>
          </Column>
          <Column flex={0}>
            <Box pad="medium" pRight="small">
              <ProfilePageLink sourceIdentifier={sourceId} />
            </Box>
          </Column>
          {nCoreSiteUrl && (
            <Column flex={0}>
              <Box pad="medium" pRight="small">
                <Link
                  href={nCoreSiteUrl}
                  target="_blank"
                  title={`Open Site in ${Config.getNCoreApplicationName()}`}
                >
                  <IconNcore />
                </Link>
              </Box>
            </Column>
          )}
          <Column flex={0}>
            <Box
              onClick={this.handleOnClose}
              pad="medium"
              pLeft="small"
              title="Close Site Profile View"
            >
              <IconClear />
            </Box>
          </Column>
        </Columns>
        <Columns column vAlign="bottom" className={classes.lowerBarContainer}>
          <Column flex={1} hCenter>
            <Box textAlign="center">
              <ProfileTabs />
            </Box>
          </Column>
          <Column flex={0} className={classes.buttonContainer} vAlign="bottom">
            <Button
              onClick={this.handleOnShowLessInformation}
              type="flat"
              color={COLOR_BASE_PRIMARY}
              size="small"
            >
              <IconChevronLeft color={COLOR_BASE_PRIMARY} /> Show Less Information
            </Button>
          </Column>
        </Columns>
      </BaseHeader>
    );
  }
}

const styles = theme => ({
  buttonContainer: {
    padding: `${theme.spacing.small} 0`,
  },

  [`@media screen and (max-width: 1250px)`]: {
    lowerBarContainer: {
      flexDirection: 'column-reverse',
      alignItems: 'flex-start',
    },
    buttonContainer: {
      alignSelf: 'flex-end',
    },
  },
});

export default withRouter(injectSheet(styles)(SiteProfileDetailHeader));
