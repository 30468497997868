import Color from 'color';
import { click } from 'ol/events/condition';
import Select from 'ol/interaction/Select';
import Map from 'ol/Map';
import Circle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BasicOverlay, FeaturesLayer } from '../../../../../../lib/map';
import { profileToFeatures } from '../../../../../util/feature';
import Columns from '../../../../../../lib/base/layout/flex/Columns';
import Column from '../../../../../../lib/base/layout/flex/Column';
import Box from '../../../../../../lib/base/layout/Box';
import Label from '../../../../../components/lv/Label';
import Value from '../../../../../components/lv/Value';

const SELECTED_STROKE = 'FF8C00';

/**
 * Site Feature layer
 */
class SiteFeaturesLayer extends Component {
  /**
   * Property types.
   */
  static propTypes = {
    /**
     * The instance of map this layer is attached to. The map instance
     * will be provided to the component automatically when used as a
     * child component of the <Map/>.
     */
    map: PropTypes.instanceOf(Map),

    /**
     * Whether or not site feature layer is visible on the map.
     */
    visible: PropTypes.bool,

    /**
     * Where to insert the layer.
     */
    zIndex: PropTypes.number,

    /**
     * Called after vector layer populates with features.
     */
    onAfterPopulateFeatures: PropTypes.func,

    /**
     * Whether or not the layer is interactive.
     */
    interactive: PropTypes.bool,
  };

  /**
   * Default properties for those props that are not required.
   */
  static defaultProps = {
    map: undefined,
    visible: true,
    zIndex: undefined,
    interactive: true,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextState = prevState;

    if (prevState.profile !== nextProps.profile) {
      nextState.profile = nextProps.profile;
      nextState.features = profileToFeatures(nextProps.profile);
    }

    if (prevState.visible !== nextProps.visible) {
      nextState.visible = nextProps.visible;
    }

    return nextState;
  }

  static tryColor(sourceColor) {
    return `#${sourceColor + 'FFFFFF'}`.substring(0, 7);
  }

  static lighten(color, isSelected) {
    try {
      return Color(color)
        .alpha(isSelected ? 0.9 : 0.7)
        .rgb()
        .string();
    } catch (err) {
      console.log('unable to parse siteFeatureLayer color ' + color);
    }
  }

  static fill(hexFill, isSelected) {
    return SiteFeaturesLayer.lighten(SiteFeaturesLayer.tryColor(hexFill), isSelected);
  }

  static stroke(hexStroke, isSelected) {
    return SiteFeaturesLayer.tryColor(isSelected ? SELECTED_STROKE : hexStroke);
  }

  static featureStyle(feature, isSelected) {
    const profileFeature = feature.get('profileFeature');

    const fill = SiteFeaturesLayer.fill(profileFeature.featureFillColor, isSelected);
    const stroke = SiteFeaturesLayer.stroke(profileFeature.featureOutlineColor, isSelected);

    return new Style({
      fill: new Fill({
        color: fill,
      }),
      stroke: new Stroke({
        color: stroke,
        width: isSelected ? 3 : 2,
      }),
      image: new Circle({
        radius: isSelected ? 9 : 7,
        fill: new Fill({
          color: fill,
        }),
        stroke: new Stroke({
          color: stroke,
          width: isSelected ? 3 : 2,
        }),
      }),
    });
  }

  constructor(props) {
    super(props);

    this.state = {
      profile: props.profile,
      features: profileToFeatures(props.profile),
      position: undefined,
      visible: props.visible,
      featuresLayer: undefined,
    };

    this.selectFilter = this.selectFilter.bind(this);

    this.interactionSelect = new Select({
      condition: click,
      filter: this.selectFilter,
    });

    this.featuresLayerRef = React.createRef();

    this.handleOnAddLayer = this.handleOnAddLayer.bind(this);
    this.handleOnAddFeature = this.handleOnAddFeature.bind(this);
    this.handleOnSelect = this.handleOnSelect.bind(this);
    this.handleOnCloseOverlay = this.handleOnCloseOverlay.bind(this);
  }

  componentDidMount() {
    this.interactionSelect.on('select', this.handleOnSelect);

    if (this.props.interactive) {
      this.props.map.addInteraction(this.interactionSelect);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.visible !== this.props.visible) {
      this.tile.setVisible(this.props.visible);

      if (this.props.visible && this.props.interactive) {
        this.props.map.addInteraction(this.interactionSelect);
      } else {
        this.props.map.removeInteraction(this.interactionSelect);
      }
    }
  }

  selectFilter(feature, layer) {
    if (this.state.features && this.state.features.length > 0) {
      return this.state.features.includes(feature);
    }

    return false;
  }

  handleOnSelect(e) {
    const that = this;
    const p = e.mapBrowserEvent.coordinate;

    e.deselected.forEach(function(each) {
      that.setState({
        position: undefined,
        feature: undefined,
      });
      each.setStyle(SiteFeaturesLayer.featureStyle(each, false));
    });

    e.selected.forEach(function(each) {
      that.setState({
        position: p,
        feature: each,
      });
      each.setStyle(SiteFeaturesLayer.featureStyle(each, true));
    });
  }

  handleOnAddLayer(layer) {
    this.setState({ featuresLayer: layer });
  }

  handleOnCloseOverlay() {
    this.setState({
      position: undefined,
      feature: undefined,
    });

    this.interactionSelect.getFeatures().forEach(each => {
      each.setStyle(SiteFeaturesLayer.featureStyle(each, false));
    });
    this.interactionSelect.getFeatures().clear();
  }

  handleOnAddFeature(feature) {
    feature.setStyle(SiteFeaturesLayer.featureStyle(feature, false));
  }

  overlayTitle() {
    const profileFeature = this.state.feature && this.state.feature.get('profileFeature');

    if (!profileFeature) {
      return 'No feature selected.';
    }

    return `Site feature: ${profileFeature.featureName || ''}`;
  }

  render() {
    return (
      <FeaturesLayer
        id="SiteFeaturesLayer"
        ref={this.featuresLayerRef}
        map={this.props.map}
        zIndex={this.props.zIndex}
        features={this.state.features}
        onAddLayer={this.handleOnAddLayer}
        onAddFeature={this.handleOnAddFeature}
        onAfterPopulateFeatures={this.props.onAfterPopulateFeatures}
        visible={true}
      >
        {this.props.interactive && (
          <BasicOverlay
            map={this.props.map}
            position={this.state.position}
            onClose={this.handleOnCloseOverlay}
            title={this.overlayTitle()}
            render={() => {
              const profileFeature = this.state.feature && this.state.feature.get('profileFeature');

              if (!profileFeature) {
                return <span>No feature selected.</span>;
              }

              return (
                <Box style={{ minWidth: '240px' }}>
                  <Columns>
                    <Column flex={1}>
                      <Label label="Source Identifier" />
                    </Column>
                    <Column flex={1}>
                      <Box textAlign="right">
                        <Value value={profileFeature.sourceIdentifier} />
                      </Box>
                    </Column>
                  </Columns>
                  <Columns>
                    <Column flex={1}>
                      <Label label="Name" />
                    </Column>
                    <Column flex={1}>
                      <Box textAlign="right">
                        <Value value={profileFeature.featureName} />
                      </Box>
                    </Column>
                  </Columns>
                  <Columns>
                    <Column flex={1}>
                      <Label label="Type" />
                    </Column>
                    <Column flex={1}>
                      <Box textAlign="right">
                        <Value value={profileFeature.featureType} />
                      </Box>
                    </Column>
                  </Columns>
                </Box>
              );
            }}
          />
        )}
      </FeaturesLayer>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile.detail.data,
    isLoading: state.profile.detail.isLoading,
    error: state.profile.detail.error,
  };
}

export default connect(mapStateToProps)(SiteFeaturesLayer);
