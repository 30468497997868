import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Box, Text } from '../../../../../lib/base';

export default class ComplianceDataSectionEmpty extends PureComponent {
  static propTypes = {
    /**
     * Children.
     */
    children: PropTypes.string.isRequired,
  };

  render() {
    return (
      <Box margin="large" textAlign="center">
        <Text size="small">{this.props.children}</Text>
      </Box>
    );
  }
}
