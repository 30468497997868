import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ChemicalsContainer from '../../../../../../components/profile/ChemicalsContainer';
import ComplianceContainer from '../../../../../../components/profile/ComplianceContainer';
import DocumentsContainer from '../../../../../../components/profile/DocumentsContainer';
import EnvironmentalInterestsContainer from '../../../../../../components/profile/EnvironmentalInterestsContainer';
import GenericContainer from '../../../../../../components/profile/GenericContainer';
import {
  PROFILE_TAB_CHEMICALS,
  PROFILE_TAB_COMPLIANCE,
  PROFILE_TAB_DETAIL,
  PROFILE_TAB_DOCUMENTS,
  PROFILE_TAB_EI,
  PROFILE_TAB_GENERIC,
  PROFILE_TAB_MAP,
} from '../../../../../../components/profile/header/ProfileTabs';
import ProfileContainer from '../../../../../../components/profile/ProfileContainer';
import SiteMapContainer from '../../../../../../components/profile/SiteMapContainer';
import SpinnerPanel from '../../../../../../components/spinner/SpinnerPanel';
import { getSiteId } from '../../../../../../util/entities/siteProfile';

class SiteProfileDetail extends Component {
  static propTypes = {
    /**
     * Site profile data.
     */
    siteProfile: PropTypes.object,
  };

  render() {
    const { isLoading, siteProfile } = this.props;
    const { params } = this.props.match;

    if (isLoading) {
      return <SpinnerPanel />;
    }

    if (!siteProfile) {
      return null;
    }

    const siteId = getSiteId(siteProfile);
    const tab = params.tab || 'profile';

    switch (tab) {
      case PROFILE_TAB_DETAIL:
        return <ProfileContainer siteProfileDetail={siteProfile} />;
      case PROFILE_TAB_MAP:
        return <SiteMapContainer />;
      case PROFILE_TAB_COMPLIANCE:
        return <ComplianceContainer sourceIdentifier={siteId} />;
      case PROFILE_TAB_DOCUMENTS:
        return <DocumentsContainer sourceIdentifier={siteId} />;
      case PROFILE_TAB_CHEMICALS:
        return <ChemicalsContainer sourceIdentifier={siteId} />;
      case PROFILE_TAB_GENERIC:
        return <GenericContainer sourceIdentifier={siteId} />;
      case PROFILE_TAB_EI:
        return <EnvironmentalInterestsContainer sourceIdentifier={siteId} />;
      default:
        return <ProfileContainer siteProfileDetail={siteProfile} />;
    }
  }
}

function mapStateToProps(state) {
  return {
    siteProfile: state.profile.detail.data,
    isLoading: state.profile.detail.isLoading,
  };
}

export default withRouter(connect(mapStateToProps)(SiteProfileDetail));
