/**
 * Labels by key object.
 */
import Config from '../config';

/**
 * Labels by key object in sentence form.
 */
let labelsByKeySentenceForm = {};

export const label = (key, whenEmpty) => {
  return Config.getLabel(key, whenEmpty);
};

export const labelCapitalized = (key, whenEmpty) => {
  return capitalizeFirstLetter(Config.getLabel(key, whenEmpty));
};

export const labelAsSentence = (key, whenEmpty) => {
  const label = Config.getLabel(key, whenEmpty);

  if (labelsByKeySentenceForm[key]) {
    return labelsByKeySentenceForm[key];
  }

  const parts = label.split(' ');
  parts[0] = parts[0].charAt(0).toUpperCase() + parts[0].substr(1);
  labelsByKeySentenceForm[key] = parts.join(' ');
  return labelsByKeySentenceForm[key];
};

const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
