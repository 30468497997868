import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'abortcontroller-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'react-jss';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'typeface-open-sans';
import 'typeface-open-sans-condensed';
import nSiteApi from './api';
import App from './app/App';
import Config from './app/config';
import './app/css';
import { theme } from './lib/base';
import configureStore from './store/configureStore';

/**
 * Base url of where the app is hosted.
 */
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const applicationBaseUrl = window.location.origin + baseUrl.replace(/\/$/, '');

/**
 * Window global for nSITE statics such Api and Config.
 */
window.nSITE = {};

/**
 * nSITE API.
 */
window.nSITE.Api = new nSiteApi({ baseUrl: applicationBaseUrl + '/api' });

/**
 * Load settings and then mount the app.
 */
window.nSITE.Api.system.getAppSettings().then(appSettings => {
  /**
   * Setup global configuration from settings.
   */
  Config.initialize({ ...appSettings, applicationBaseUrl });
  window.nSITE.Config = Config.all();

  /**
   * Create the redux store.
   */
  const store = configureStore({});

  /**
   * Bootstrap the React app.
   */
  ReactDOM.render(
    <StoreProvider store={store}>
      <BrowserRouter basename={baseUrl}>
        <ThemeProvider theme={Object.assign({}, theme)}>
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </StoreProvider>,
    document.getElementById('root')
  );
});
