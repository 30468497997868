import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import { setInfoPanelContent } from '../../../../../../../store/map/map.action';
import { IconInfo } from '../../../../../../components/icons/fontawesome/icons';
import { COLOR_BASE_PRIMARY, NO_OP_FUNC } from '../../../../../../Constants';
import { navigateToInfo } from '../../../../../../routing';

class InfoButton extends Component {
  static propTypes = {
    /**
     * Info text to display
     */
    infoText: PropTypes.string,

    /**
     * Redux connected function.
     */
    dispatchSetInfoPanelContent: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  handleOnClick(e) {
    e.stopPropagation();
    navigateToInfo(this.props);
    this.props.dispatchSetInfoPanelContent(this.props.infoText);
  }

  render() {
    return (
      <span onClick={this.handleOnClick} className={this.props.classes.icon}>
        <IconInfo color={COLOR_BASE_PRIMARY} />
      </span>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchSetInfoPanelContent: content => {
      dispatch(setInfoPanelContent(content));
    },
  };
}

const styles = theme => ({
  icon: {
    padding: `${theme.spacing.small} ${theme.spacing.small}`,
    textAlign: 'right',

    '&:hover': {
      cursor: 'pointer',
      color: theme.color[COLOR_BASE_PRIMARY]['dark2'],
    },
  },
});

export default withRouter(
  connect(
    NO_OP_FUNC,
    mapDispatchToProps
  )(injectSheet(styles)(InfoButton))
);
