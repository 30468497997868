import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { Box, Column, Columns, Row, Rows } from '../../../lib/base';
import WarehouseStatsContainer from '../../components/about/WarehouseStatsContainer';
import WindowDocumentTitle from '../../components/dom/WindowDocumentTitle';
import HeadingText from '../../components/text/HeadingText';
import Config from '../../config';
import { isSmallScreen } from '../../util/browser';
import BaseStandardPage from '../base/BaseStandardPage';

const api = () => window.nSITE.Api;

class AboutPage extends Component {
  constructor(props) {
    super(props);
    this.aboutRefLeft = React.createRef();
    this.aboutRefCenter = React.createRef();
    this.disclaimerRef = React.createRef();

    this.state = {
      dashboards: undefined,
      isLoading: true,
      error: undefined,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    fetch(Config.getApplicationBaseUrl() + '/content/aboutLeft')
      .then(response => response.text())
      .then(text => {
        if (this.aboutRefLeft && this.aboutRefLeft.current) {
          this.aboutRefLeft.current.innerHTML = text;
        }
      });

    fetch(Config.getApplicationBaseUrl() + '/content/aboutCenter')
      .then(response => response.text())
      .then(text => {
        if (this.aboutRefCenter && this.aboutRefCenter.current) {
          this.aboutRefCenter.current.innerHTML = text;
        }
      });

    fetch(Config.getApplicationBaseUrl() + '/content/aboutDisclaimer')
      .then(response => response.text())
      .then(text => {
        if (this.disclaimerRef && this.disclaimerRef.current) {
          this.disclaimerRef.current.innerHTML = text;
        }
      });

    this.setState({ isLoading: true });

    api()
      .system.getDashboardList()
      .then(dashboards => {
        this.setState({
          dashboards,
          isLoading: false,
        });
      })
      .catch(err => {
        this.setState({
          error: err,
          isLoading: false,
        });
      });
  }

  renderContent() {
    if (isSmallScreen()) {
      return (
        <Box pad="medium">
          <Box stack="large" containerRef={this.aboutRefLeft}>
            &nbsp;
          </Box>
          <Box stack="large" containerRef={this.aboutRefCenter}>
            &nbsp;
          </Box>
          <Box stack="large">
            {this.state.dashboards && (
              <WarehouseStatsContainer dashboards={this.state.dashboards} />
            )}
          </Box>
          <HeadingText>Disclaimer</HeadingText>
          <Box pTop="medium" mBottom="largest" containerRef={this.disclaimerRef}>
            &nbsp;
          </Box>
        </Box>
      );
    }

    return (
      <Rows>
        <Row hAlign="center">
          <Columns gutter="larger" hAlign="center" className={this.props.classes.maxWidth}>
            <Column flex={1}>
              <div ref={this.aboutRefLeft}>&nbsp;</div>
            </Column>
            <Column flex={1}>
              <div ref={this.aboutRefCenter}>&nbsp;</div>
            </Column>
            <Column flex={1}>
              {this.state.dashboards && (
                <WarehouseStatsContainer dashboards={this.state.dashboards} />
              )}
            </Column>
          </Columns>
        </Row>
        <Row hAlign="center">
          <Box mTop="larger" className={this.props.classes.maxWidth}>
            <HeadingText>Disclaimer</HeadingText>
            <Box pTop="medium" mBottom="largest" containerRef={this.disclaimerRef}>
              &nbsp;
            </Box>
          </Box>
        </Row>
      </Rows>
    );
  }

  render() {
    return (
      <BaseStandardPage>
        <WindowDocumentTitle title="About" />
        {this.renderContent()}
      </BaseStandardPage>
    );
  }
}

const styles = () => ({
  maxWidth: {
    maxWidth: '1200px',
  },
});

export default injectSheet(styles)(AboutPage);
