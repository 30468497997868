import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Box } from '../../../lib/base';
import {
  COLOR_BASE_PRIMARY,
  COLOR_VARIANT_PRIMARY,
  COLOR_BASE_ORANGE,
  MEDIA_BREAKPOINT,
} from '../../Constants';
import injectSheet from 'react-jss';

class ListItemBox extends Component {
  static propTypes = {
    ...Box.propTypes,

    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,

    /**
     * Child component.
     */
    children: PropTypes.node.isRequired,

    /**
     * List item is active.
     */
    active: PropTypes.bool,

    /**
     * List item is in cluster select
     */
    clusterHighlight: PropTypes.bool,
  };

  render() {
    const { active, clusterHighlight, className, ...boxProps } = this.props;

    return (
      <Box
        className={[
          this.props.classes.container,
          this.props.active && this.props.classes.active,
          this.props.clusterHighlight && this.props.classes.clusterHighlight,
          className,
        ].join(' ')}
        pLeft="medium"
        pRight="medium"
        bgColor={clusterHighlight ? COLOR_BASE_ORANGE : active ? COLOR_BASE_PRIMARY : 'white'}
        bgColorVariant="light1"
        bordered={!!active}
        borderSides={['left']}
        borderColor={COLOR_BASE_PRIMARY}
        borderWidth={4}
        borderColorVariant={COLOR_VARIANT_PRIMARY}
        {...boxProps}
      >
        <div className={this.props.classes.containerInner}>{this.props.children}</div>
      </Box>
    );
  }
}

const styles = theme => ({
  container: {
    paddingLeft: `calc(${theme.spacing.medium} - 4px)`,
    borderLeft: `4px solid white`,

    '@media screen and (min-width: 600px)': {
      ' &:hover': {
        paddingLeft: props => (props.onClick ? `calc(${theme.spacing.medium} - 4px)` : undefined),
        backgroundColor: props =>
          props.onClick ? theme.color[COLOR_BASE_PRIMARY]['light1'] : undefined,
        borderLeft: props =>
          props.onClick ? `4px solid ${theme.color[COLOR_BASE_PRIMARY]['dark3']}` : undefined,
        transition: 'background-color 350ms, border-left 350ms',
      },
    },
  },

  active: {
    paddingLeft: `calc(${theme.spacing.medium} - 4px)`,
    borderLeft: `4px solid ${theme.color[COLOR_BASE_PRIMARY][COLOR_VARIANT_PRIMARY]}`,

    '&:hover': {
      backgroundColor: props =>
        props.onClick ? theme.color[COLOR_BASE_PRIMARY]['light1'] : undefined,
      borderLeft: props =>
        props.onClick ? `4px solid ${theme.color[COLOR_BASE_PRIMARY]['dark3']}` : undefined,
      transition: 'background-color 350ms, border-left 350ms',
    },
  },

  [`@media ${MEDIA_BREAKPOINT}`]: {
    containerInner: {
      borderBottom: `1px solid #efefef`,
    },
  },
});

export default injectSheet(styles)(ListItemBox);
