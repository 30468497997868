import {
  FETCH_GENERIC_BEGIN,
  FETCH_GENERIC_ERROR,
  FETCH_GENERIC_SUCCESS,
  LOAD_PROFILE_DETAIL_BEGIN,
  LOAD_PROFILE_DETAIL_ERROR,
  LOAD_PROFILE_DETAIL_SUCCESS,
} from './profile.actionType';

/**
 * Initial state.
 */
const initialState = {
  detail: {
    isLoading: false,
    data: undefined,
    error: undefined,
  },
  generic: {
    isLoading: false,
    data: undefined,
    error: undefined,
  },
};

/**
 * Profile reducer function.
 *
 * @param state
 * @param action
 * @returns {*}
 */
const profile = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_PROFILE_DETAIL_BEGIN:
      return Object.assign({}, state, {
        detail: {
          ...state.detail,
          isLoading: true,
        },
      });
    case LOAD_PROFILE_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        detail: {
          isLoading: false,
          data: payload.detail,
          error: undefined,
        },
      });
    case LOAD_PROFILE_DETAIL_ERROR:
      return Object.assign({}, state, {
        detail: {
          isLoading: false,
          data: undefined,
          error: payload.error,
        },
      });

    case FETCH_GENERIC_BEGIN:
      return Object.assign({}, state, {
        generic: {
          ...state.generic,
          isLoading: true,
        },
      });

    case FETCH_GENERIC_SUCCESS:
      return Object.assign({}, state, {
        generic: {
          isLoading: false,
          data: payload.generic,
          error: undefined,
        },
      });

    case FETCH_GENERIC_ERROR:
      return Object.assign({}, state, {
        generic: {
          isLoading: false,
          data: undefined,
          error: payload.error,
        },
      });

    default:
      return Object.assign({}, state, {});
  }
};
export default profile;
