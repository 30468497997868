import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import injectSheet from 'react-jss';
import { jssColorLookup, px } from '../util/jss';
import {
  propTypeAlignHorizontal,
  propTypeBorderSides,
  propTypeColor,
  propTypeColorVariant,
  propTypeSize,
} from '../util/propTypes';

const PROPS_WITH_CLASSNAMES = [
  'stack',
  'pad',
  'margin',
  'mTop',
  'mRight',
  'mBottom',
  'mLeft',
  'pTop',
  'pBottom',
  'pRight',
  'pLeft',
  'textAlign',
];

export const CHILD_BOX_SELECTOR = 'WndJssBox'; // name it something to avoid naming clashes.

/**
 * A box is a low-level component with many convenience properties for styling a div. You can apply
 * margin, padding, borders, and a background color to a box.
 */
class Box extends PureComponent {
  static propTypes = {
    /**
     * Border color.
     */
    borderColor: propTypeColor,

    /**
     * Border color variant.
     */
    borderColorVariant: propTypeColorVariant,

    /**
     * Bordered - Turns on the borders with the defaults.
     */
    bordered: PropTypes.bool,

    /**
     * Borders
     */
    borderSides: propTypeBorderSides,

    /**
     * Border thickness in px.
     */
    borderWidth: PropTypes.number,

    /**
     * Background color.
     */
    bgColor: propTypeColor,

    /**
     * Background color variant.
     */
    bgColorVariant: propTypeColorVariant,

    /**
     * Children components.
     */
    children: PropTypes.node,

    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,

    /**
     * Css classname.
     */
    className: PropTypes.string,

    /**
     * React ref callback to the root container.
     */
    containerRef: PropTypes.object,

    /**
     * Display the box inline.
     */
    inline: PropTypes.bool,

    /**
     * The amount of margin to apply to this box.
     */
    margin: propTypeSize,

    /**
     * The amount of margin to apply to the bottom of this box.
     */
    mBottom: propTypeSize,

    /**
     * The amount of margin to apply to the left side of this box.
     */
    mLeft: propTypeSize,

    /**
     * The amount of margin to apply to the right side of this box.
     */
    mRight: propTypeSize,

    /**
     * The amount of margin to apply to the top of this box.
     */
    mTop: propTypeSize,

    /**
     * OnClick function.
     */
    onClick: PropTypes.func,

    /**
     * The amount of padding to apply to this box.
     */
    pad: propTypeSize,

    /**
     * The amount of padding to apply to the bottom of this box.
     */
    pBottom: propTypeSize,

    /**
     * The amount of padding to apply to the left side of this box.
     */
    pLeft: propTypeSize,

    /**
     * The amount of padding to apply to the right side of this box.
     */
    pRight: propTypeSize,

    /**
     * The amount of padding to apply to the top of this box.
     */
    pTop: propTypeSize,

    /**
     * Is this box stacked - one on top of another.
     */
    stack: propTypeSize,

    /**
     * Custom css style object.
     */
    style: PropTypes.object,

    /**
     * Align text - left, center, or right.
     */
    textAlign: propTypeAlignHorizontal,

    /**
     * Title to show on hover.
     */
    title: PropTypes.string,

    /**
     * Whether or not Box is visible.
     */
    visible: PropTypes.bool,
  };

  static defaultProps = {
    borderColor: undefined,
    borderColorVariant: undefined,
    bordered: undefined,
    borderSides: undefined,
    borderWidth: undefined,
    bgColor: undefined,
    bgColorVariant: undefined,
    children: undefined,
    classes: undefined,
    className: undefined,
    containerRef: undefined,
    inline: undefined,
    margin: undefined,
    mBottom: undefined,
    mLeft: undefined,
    mRight: undefined,
    mTop: undefined,
    onClick: undefined,
    pad: undefined,
    pBottom: undefined,
    pLeft: undefined,
    pRight: undefined,
    pTop: undefined,
    stack: undefined,
    style: undefined,
    textAlign: undefined,
    title: undefined,
    visible: true,
  };

  propsAsClassnames() {
    return PROPS_WITH_CLASSNAMES.filter(propName => this.props[propName] !== undefined).map(
      prop => this.props.classes[prop]
    );
  }

  renderClassnames() {
    const classNames = [
      CHILD_BOX_SELECTOR,
      this.props.classes.box,
      this.props.classes.border,
      this.props.classes.background,
    ].concat(this.propsAsClassnames());

    if (this.props.inline) {
      classNames.push(this.props.classes.inline);
    }

    classNames.push(this.props.className);

    return classNames.join(' ');
  }

  render() {
    if (!this.props.visible) {
      return null;
    }

    return (
      <div
        className={this.renderClassnames()}
        onClick={this.props.onClick}
        style={this.props.style}
        ref={this.props.containerRef}
        title={this.props.title}
      >
        {this.props.children}
      </div>
    );
  }
}

/**
 * Returns the background color from props.
 */
const backgroundColor = (theme, props) => {
  if (props.bgColor) {
    return {
      backgroundColor: jssColorLookup(theme, props.bgColor, props.bgColorVariant),
    };
  }
  return undefined;
};

/**
 * Returns the border based on the props or the default width from the theme.
 */
const borderWidth = (theme, props) => {
  return props.borderWidth ? props.borderWidth : theme.box.default.borderWidth;
};

/**
 * Returns the border color from props or default border from the theme.
 */
const borderColor = (theme, props) => {
  if (props.borderColor) {
    return jssColorLookup(theme, props.borderColor, props.borderColorVariant);
  }
  return theme.box.default.borderColor;
};

/**
 * Returns which sides to border based on the props.
 */
const borderSides = (theme, props) => {
  if (props.borderSides) {
    const sides = props.borderSides;

    if (typeof sides === 'string') {
      if (sides === 'all') {
        return ['top', 'right', 'bottom', 'left'];
      }

      return [sides];
    }

    return props.borderSides;
  }

  return 'all';
};

/**
 * Returns a style object based on the border related props.
 */
const borderStyle = (theme, props) => {
  /**
   * Return right away when bordered=false
   */
  if (props.bordered !== undefined && props.bordered === false) {
    return {
      borderTop: 'none',
      borderRight: 'none',
      borderBottom: 'none',
      borderLeft: 'none',
    };
  }

  /**
   * If any border property is set.
   */
  if (props.bordered || props.borderColor || props.borderSides || props.borderWidth) {
    const sides = borderSides(theme, props);
    const color = borderColor(theme, props);
    const width = borderWidth(theme, props);

    if (sides === 'all') {
      return {
        border: `${px(width)} solid ${color}`,
      };
    } else {
      const style = {};
      sides.forEach(side => (style[`border-${side}`] = `${px(width)} solid ${color}`));
      return style;
    }
  }

  return {
    borderTop: 'none',
    borderRight: 'none',
    borderBottom: 'none',
    borderLeft: 'none',
  };
};

const styles = theme => ({
  box: {
    '&:hover': {
      cursor: props => (props.onClick !== undefined ? 'pointer' : undefined),
    },
  },

  inline: {
    display: 'inline-block',
  },

  border: props => borderStyle(theme, props),

  background: props => backgroundColor(theme, props),

  stack: {
    margin: props => theme.spacing.stack[props.stack],
  },

  pad: {
    padding: props => theme.spacing[props.pad],
  },

  margin: {
    margin: props => theme.spacing[props.margin],
  },

  mTop: {
    marginTop: props => theme.spacing[props.mTop],
  },

  mRight: {
    marginRight: props => theme.spacing[props.mRight],
  },

  mBottom: {
    marginBottom: props => theme.spacing[props.mBottom],
  },

  mLeft: {
    marginLeft: props => theme.spacing[props.mLeft],
  },

  pTop: {
    paddingTop: props => theme.spacing[props.pTop],
  },

  pBottom: {
    paddingBottom: props => theme.spacing[props.pBottom],
  },

  pRight: {
    paddingRight: props => theme.spacing[props.pRight],
  },

  pLeft: {
    paddingLeft: props => theme.spacing[props.pLeft],
  },

  textAlign: {
    textAlign: props => props.textAlign,
  },
});

export default injectSheet(styles)(Box);
