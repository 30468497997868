import React, { PureComponent } from 'react';
import { Box, Link, Text } from '../../../lib/base';
import { toHome } from '../../routing';
import BaseStandardPage from '../base/BaseStandardPage';

export default class NotFoundPage extends PureComponent {
  render() {
    return (
      <BaseStandardPage>
        <Box pad="medium">
          <Box stack="large">
            <Text size="large" bold>
              Page Not Found
            </Text>
          </Box>
          <Box stack="large">
            <Text size="small">The page you're looking for cannot be found.</Text>
          </Box>
          <Link target="_blank" onClick={toHome(this.props.history)}>
            go home
          </Link>
        </Box>
      </BaseStandardPage>
    );
  }
}
