import Map from 'ol/Map';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FeaturesLayer } from '../../../../../../lib/map';
import { myLocationToFeature } from '../../../../../util/feature';

/**
 * Displays the current location of the user.
 */
class MyLocationLayer extends Component {
  static propTypes = {
    /**
     * The instance of map this layer is attached to. The map instance
     * will be provided to the component automatically when used as a
     * child component of the <Map/>.
     */
    map: PropTypes.instanceOf(Map),

    /**
     * Lon/lat coordinates.
     */
    myLocation: PropTypes.array,
  };

  /**
   * Default properties for those props that are not required.
   */
  static defaultProps = {
    map: undefined,
    myLocation: undefined,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextState = prevState;

    if (prevState.myLocation !== nextProps.myLocation) {
      nextState.features = [myLocationToFeature(nextProps.myLocation)];
    }

    return nextState;
  }

  constructor(props) {
    super(props);

    this.state = {
      myLocation: props.myLocation,
      features: [myLocationToFeature(props.myLocation)],
    };
  }

  render() {
    return (
      <FeaturesLayer
        id="MyLocationFeatureLayer"
        map={this.props.map}
        zIndex={201}
        features={this.state.features}
        visible={true}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    myLocation: state.map.myLocation,
  };
}

export default connect(mapStateToProps)(MyLocationLayer);
