import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import { IconFileDownload } from '../../../../../../../components/icons/fontawesome/icons';
import { navigateToExport, navigateToResults } from '../../../../../../../routing';

import ToolbarButton from './ToolbarButton';

class ExportButton extends PureComponent {
  constructor(props) {
    super(props);
    this.handleOnClickExportButton = this.handleOnClickExportButton.bind(this);
  }

  handleOnClickExportButton() {
    if (this.isPressed()) {
      navigateToResults(this.props);
    } else {
      navigateToExport(this.props);
    }
  }

  isPressed() {
    const { params } = this.props.match;
    return params.panel === 'export';
  }

  render() {
    return (
      <ToolbarButton
        onClick={this.handleOnClickExportButton}
        title="Download data to your computer"
        active={this.isPressed()}
        icon={<IconFileDownload />}
      />
    );
  }
}

export default withRouter(ExportButton);
