import React, { Component } from 'react';
import { Box } from '../../../../../lib/base';
import Toolbar from '../shared/panel/toolbar/Toolbar';
import SearchInput from '../shared/panel/search/SearchInput';
import ViewResultsButton from '../shared/panel/search/ViewResultsButton';

export default class DesktopSearchBar extends Component {
  render() {
    return (
      <Box bgColor="white" pad="medium">
        <Toolbar />
        <Box pBottom="small">
          <SearchInput />
        </Box>
        <Box style={{ height: '32px' }} pTop="smallest">
          <ViewResultsButton />
        </Box>
      </Box>
    );
  }
}
