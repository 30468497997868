import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router";
import GoogleAnalytics from "./components/analytics/GoogleAnalytics";
import MetaTagsUpdater from "./components/dom/MetaTagsUpdater";
import WindowDocumentTitle from "./components/dom/WindowDocumentTitle";
import isAllowDebug from "./config/isAllowDebug";
import {
  ROUTE_ABOUT,
  ROUTE_DEBUG,
  ROUTE_MAP,
  ROUTE_NOT_FOUND,
  ROUTE_PROFILE,
  ROUTE_SITE_PRINT,
  ROUTE_SITE_PROFILE,
  ROUTE_STANDALONE_MAP
} from "./Constants";
import AboutPage from "./page/about/AboutPage";
import DebugPage from "./page/debugger/DebugPage";
import StandaloneMapPage from "./page/map-standalone/StandaloneMapPage";
import MapPage from "./page/map/MapPage";
import NotFoundPage from "./page/not-found/NotFoundPage";
import ProfilePage from "./page/profile/ProfilePage";
import SitePdfPage from "./page/site-print/SiteProfilePDFPage";
import SiteProfilePage from "./page/site/SiteProfilePage";
import "./polyfill";
import { defaultMapRoutePath } from "./routing";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/**
 * Root application component.
 */
class App extends Component {
  static propTypes = {
    /**
     * Router location.
     */
    location: PropTypes.object.isRequired
  };

  render() {
    return (
      <Fragment>
        <WindowDocumentTitle />
        <MetaTagsUpdater />
        <ToastContainer />
        <GoogleAnalytics />
        <Switch location={this.props.location}>
          <Route exact path={ROUTE_SITE_PRINT} component={SitePdfPage} />
          <Route exact path={ROUTE_STANDALONE_MAP} component={StandaloneMapPage} />
          <Route exact path={ROUTE_SITE_PROFILE} component={SiteProfilePage} />
          <Route exact path={ROUTE_PROFILE} component={ProfilePage} />
          <Route exact path={ROUTE_ABOUT} component={AboutPage} />
          <Route exact path={ROUTE_NOT_FOUND} component={NotFoundPage} />
          {isAllowDebug() && <Route exact path={ROUTE_DEBUG} component={DebugPage} />}
          <Route path={ROUTE_MAP} component={MapPage} />
          <Route path="/" render={() => <Redirect to={defaultMapRoutePath()} />} />
        </Switch>
      </Fragment>
    );
  }
}

export default withRouter(App);
