import BaseEndpoint from "./BaseEndpoint";
import Urls from "./Urls";

/**
 * Geocode endpoint.
 */
export default class GeocodeEndpoint extends BaseEndpoint {
  /**
   * Returns a promise resolving geocode data.
   *
   * @returns {Promise<any>} geocode data.
   */
  address(address, myLocation) {
    if (address === undefined || (typeof address === "string" && address.length === 0)) {
      return Promise.resolve({});
    }

    let url = `${super.url(Urls.Geocode.Address)}?address=${address}`;

    if (myLocation) {
      url += `&userLocation=${myLocation[0]}&userLocation=${myLocation[1]}`;
    }

    return super
      .get(url, {})
      .then(response =>
        response.json().catch(ex => {
          console.warn(ex);
          return {};
        })
      )
      .then(json => json);
  }

  geocodeLocal(searchTerm) {
    if (searchTerm === undefined || (typeof searchTerm === "string" && searchTerm.length === 0)) {
      return Promise.resolve({});
    }

    let criteria = {
      locationName: `/%${searchTerm}/%`,
      top: 10,
      includeMetadataInResponse: false
    };

    let url = `${super.url(Urls.Geocode.GeocodeLocal)}?${super.toParameterString(criteria)}`;

    return super
      .get(url, {})
      .then(response => response.json())
      .then(json => {
        json.queryResults.forEach(item => {
          item.label = item.locationName;
        });
        return json.queryResults;
      });
  }
}
