import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Box, Column, Columns, Row, Rows, Text } from '../../../../../../../lib/base';
import {
  deselectSite,
  searchFilterCountIfNeeded,
  searchSitesIfNeeded,
} from '../../../../../../../store/map/map.action';
import {
  fetchFiltersIfNeeded,
  fetchSearchKeysIfNeeded,
} from '../../../../../../../store/search/search.action';
import SpinnerOverlay from '../../../../../../components/spinner/SpinnerOverlay';
import Tab from '../../../../../../components/tabs/Tab';
import { COLOR_BASE_PRIMARY } from '../../../../../../Constants';
import {
  navigateToCriteria,
  navigateToFilters,
  navigateToResults,
} from '../../../../../../routing';
import AttributesSearchForm from './attribute/AttributesSearchForm';
import FiltersSearchForm from './filter/FiltersSearchForm';

class SearchOptionsPanel extends Component {
  static propTypes = {
    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    /**
     * Handlers for controlling the search inputs.
     */
    this.handleShowAttributes = this.handleShowAttributes.bind(this);
    this.handleShowFilters = this.handleShowFilters.bind(this);

    /**
     * Handlers for site attributes form.
     */
    this.handleOnAdvancedFormSubmit = this.handleOnAdvancedFormSubmit.bind(this);

    /**
     * Handlers for filters form.
     */
    this.handleOnFiltersFormSelect = this.handleOnFiltersFormSelect.bind(this);
    this.handleOnFiltersFormSelectAll = this.handleOnFiltersFormSelectAll.bind(this);
    this.handleOnFiltersFormRemove = this.handleOnFiltersFormRemove.bind(this);
  }

  componentDidMount() {
    this.props.dispatchFetchSearchFilters();
    this.props.dispatchFetchSearchKeys();
  }

  executeSearch(criteria) {
    this.props.dispatchSearch(criteria);
    this.props.dispatchDeselectSite();
    navigateToResults(this.props);
  }

  isCriteriaActive() {
    const params = this.props.match.params;
    return params.subPanel === 'criteria';
  }

  isFiltersActive() {
    const params = this.props.match.params;
    return params.subPanel === 'filters';
  }

  /**
   * Updates the filter criteria and executes a new search.
   */
  handleOnFiltersFormSelect(filter, checked) {
    const criteria = { ...this.props.criteria };

    if (checked) {
      criteria.filters.push(filter);
    } else {
      criteria.filters = criteria.filters.filter(f => f !== filter);
    }

    this.executeSearch(criteria);
  }

  /**
   * Updates the filters and executes a new search.
   */
  handleOnFiltersFormSelectAll(filters, checked) {
    const criteria = { ...this.props.criteria };

    if (checked) {
      const add = filters.filter(f => !criteria.filters.includes(f));
      criteria.filters = criteria.filters.concat(add);
    } else {
      criteria.filters = criteria.filters.filter(f => !filters.includes(f));
    }

    this.executeSearch(criteria);
  }

  /**
   * Removes the filter and executes a new search.
   */
  handleOnFiltersFormRemove(filterValue) {
    const criteria = { ...this.props.criteria };
    criteria.filters = criteria.filters.filter(f => f !== filterValue);
    this.executeSearch(criteria);
  }

  /**
   * Submits the search when site attribute form is submitted.
   */
  handleOnAdvancedFormSubmit(advancedForm) {
    const criteria = { ...this.props.criteria };
    criteria.advanced = advancedForm;
    this.executeSearch(criteria);
  }

  /**
   * Show the site attributes search form.
   */
  handleShowAttributes() {
    navigateToCriteria(this.props);
  }

  /**
   * Show the filters search from.
   */
  handleShowFilters() {
    navigateToFilters(this.props);
  }

  renderFormPanel() {
    if (this.isCriteriaActive()) {
      if (this.props.searchKeysIsFetching) {
        return <SpinnerOverlay />;
      }

      if (this.props.searchKeys) {
        return (
          <AttributesSearchForm
            searchKeys={this.props.searchKeys}
            advancedCritera={{ ...this.props.criteria.advanced }}
            onSubmit={this.handleOnAdvancedFormSubmit}
          />
        );
      }
    }

    if (this.isFiltersActive()) {
      return (
        <FiltersSearchForm
          selected={this.props.criteria.filters}
          filters={this.props.filters}
          filtersCount={this.props.filtersCount}
          onSelect={this.handleOnFiltersFormSelect}
          onSelectAll={this.handleOnFiltersFormSelectAll}
          onRemove={this.handleOnFiltersFormRemove}
          isFilterCountFetching={this.props.isFilterCountFetching}
        />
      );
    }

    return null;
  }

  render() {
    const advancedCriteriaCount = Object.keys(this.props.criteria.advanced).length;
    const filterCount = this.props.criteria.filters.length;
    const criteriaTitle = `Criteria (${advancedCriteriaCount})`;
    const filterTitle = `Filters (${filterCount})`;

    return (
      <Rows stretch>
        <Row flex={0}>
          <Box mBottom="large">
            <Columns gutter="smallest">
              <Column flex={1}>
                <Tab
                  id="criteriaTab"
                  name={criteriaTitle}
                  onClick={this.handleShowAttributes}
                  color={COLOR_BASE_PRIMARY}
                  title="Advanced Search Criteria"
                  active={this.isCriteriaActive()}
                  visible={true}
                  bold={true}
                />
              </Column>
              <Column flex={1}>
                <Tab
                  id="filterTab"
                  name={filterTitle}
                  title="Search Filters"
                  onClick={this.handleShowFilters}
                  color={COLOR_BASE_PRIMARY}
                  active={this.isFiltersActive()}
                  visible={true}
                  bold={true}
                >
                  Filters (<Text bold={filterCount > 0}>{filterCount}</Text>)
                </Tab>
              </Column>
            </Columns>
          </Box>
        </Row>
        <Row flex={1} className={this.props.classes.body}>
          {this.renderFormPanel()}
        </Row>
      </Rows>
    );
  }
}

const styles = () => ({
  [`@media all and (-ms-high-contrast:none)`]: {
    body: {
      minHeight: '100vh',
    },
  },
});

function mapStateToProps(state) {
  return {
    searchKeys: state.search.searchKeys.data,
    searchKeysIsFetching: state.search.searchKeys.isFetching,
    filters: state.search.filters.data,
    criteria: state.map.search.criteria,
    filtersCount: state.map.searchFilterCount.data,
    isFilterCountFetching: state.map.searchFilterCount.isFetching,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchFetchSearchKeys: () => {
      dispatch(fetchSearchKeysIfNeeded());
    },
    dispatchFetchSearchFilters: () => {
      dispatch(fetchFiltersIfNeeded());
    },
    dispatchSearch: criteria => {
      dispatch(searchSitesIfNeeded(criteria));
      dispatch(searchFilterCountIfNeeded(criteria));
    },
    dispatchDeselectSite: () => {
      dispatch(deselectSite());
    },
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(injectSheet(styles)(SearchOptionsPanel))
);
