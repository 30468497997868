import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { Text } from '../../../lib/base';
import { COLOR_BASE_PRIMARY } from '../../Constants';

class Badge extends Component {
  static propTypes = {
    /**
     * Badge content.
     */
    children: PropTypes.node,

    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,
  };

  render() {
    if (!this.props.children) {
      return null;
    }

    return (
      <Text size="smaller" bold className={this.props.classes.container}>
        {this.props.children}
      </Text>
    );
  }
}

const styles = theme => ({
  container: {
    display: 'inline-block',
    padding: `${theme.spacing.smaller} ${theme.spacing.small}`,
    borderRadius: '12px',
    backgroundColor: theme.color[COLOR_BASE_PRIMARY]['base'],
    color: 'white',
  },
});

export default injectSheet(styles)(Badge);
