/**
 * Returns true when the string is null, undefined, or empty string.
 *
 * @param str the specimen
 * @returns {boolean} is blank
 */
export const isBlank = str => !str || str.trim().length === 0;

/**
 * Returns true when the string is not null, not undefined, or not an empty string.
 *
 * @param str the specimen
 * @returns {boolean} is blank
 */
export const isNotBlank = str => !isBlank(str);

/**
 * validates a string as an email
 */
export const isEmail = str => {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(str).toLowerCase());
};
