import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import MapLayersButton from '../../shared/panel/toolbar/buttons/MapLayersButton';
import { Z_INDEX_MAP_BUTTONS } from '../../../../../Constants';
import HomeButton from '../../shared/panel/toolbar/buttons/HomeButton';
import MyLocationButton from '../../shared/panel/toolbar/buttons/MyLocationButton';
import UserDeniedGeoLocationModal from '../../shared/panel/toolbar/UserDeniedGeoLocationModal';
import LocationlessSitesButton from '../../shared/panel/toolbar/buttons/LocationlessSitesButton';

class OnMapButtonLayers extends Component {
  static propTypes = {
    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);

    this.state = {
      isModalVisible: false,
    };

    this.handleOnUserDeniedError = this.handleOnUserDeniedError.bind(this);
    this.handleOnCloseModal = this.handleOnCloseModal.bind(this);
  }

  handleOnUserDeniedError() {
    this.setState({ isModalVisible: true });
  }

  handleOnCloseModal() {
    this.setState({ isModalVisible: false });
  }

  render() {
    return (
      <React.Fragment>
        <UserDeniedGeoLocationModal
          visible={this.state.isModalVisible}
          onRequestClose={this.handleOnCloseModal}
        />
        <span className={this.props.classes.container}>
          <span className={this.props.classes.buttonContainer}>
            <HomeButton circular />
          </span>
          <span className={this.props.classes.buttonContainer}>
            <MapLayersButton circular />
          </span>
          <span className={this.props.classes.buttonContainer}>
            <MyLocationButton circular onUserDeniedError={this.handleOnUserDeniedError} />
          </span>
          <span className={this.props.classes.buttonContainer}>
            <LocationlessSitesButton circular />
          </span>
        </span>
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  container: {
    zIndex: Z_INDEX_MAP_BUTTONS,
    position: 'absolute',
    top: 5,
    right: 5,
    display: 'inline-flex',
    flexDirection: 'column',
  },

  buttonContainer: {
    backgroundColor: 'rgba(0,60,136,.3)', // openlayers default
    ...theme.elevation.shadow1,
    borderRadius: '24px',
    marginBottom: '4px',
  },
});

export default injectSheet(styles)(OnMapButtonLayers);
