import format from 'date-fns/format';
import isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Box } from '../../../lib/base';
import Label from './Label';
import Value from './Value';

export default class LabelValuePair extends Component {
  static propTypes = {
    /**
     * Label.
     */
    label: PropTypes.string,

    /**
     * Value.
     */
    value: PropTypes.any,

    /**
     * Hide label value pair when the value is empty.
     */
    hideEmpty: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    label: '',
    value: undefined,
    hideEmpty: false,
  };

  static asValue(value) {
    if (value instanceof Date) {
      return format(value, 'MM/DD/YYYY');
    }
    return value;
  }

  static isEmpty(value) {
    if (!value) return true;

    if (typeof value === 'string') {
      return value.trim().length === 0;
    } else if (Array.isArray(value)) {
      return value.length === 0;
    }

    return false;
  }

  static renderValue(val) {
    if (isFunction(val)) {
      return val();
    } else if (Array.isArray(val)) {
      return val.map((v, index) => <Value key={index} value={v} />);
    } else {
      if (this.isEmpty(val)) {
        return <Value value="—" empty />;
      }

      return <Value value={val} />;
    }
  }

  render() {
    const val = LabelValuePair.asValue(this.props.value);
    const lab = this.props.label;

    if (this.props.hideEmpty && LabelValuePair.isEmpty(val)) {
      return null;
    }

    return (
      <Box stack="large">
        <Box stack="smaller">
          <Label label={lab} />
        </Box>
        <Box>{LabelValuePair.renderValue(val)}</Box>
      </Box>
    );
  }
}
