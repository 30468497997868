import Color from 'color';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { jssColorLookup } from '../util/jss';
import { propTypeColor, propTypeSize } from '../util/propTypes';

class Button extends Component {
  static propTypes = {
    /**
     * Display as block.
     */
    block: PropTypes.bool,

    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,

    /**
     * Additional cass class name to apply.
     */
    className: PropTypes.string,

    /**
     * Jss classes.
     */
    children: PropTypes.node.isRequired,

    /**
     * Color scheme of the button.
     */
    color: propTypeColor,

    /**
     * Disable button.
     */
    disabled: PropTypes.bool,

    /**
     * onClick handler.
     */
    onClick: PropTypes.func,

    /**
     * Size of button.
     */
    size: propTypeSize,

    /**
     * Inline style.
     */
    style: PropTypes.object,

    /**
     * The type of button. One of default, flat, or alt.
     */
    type: PropTypes.oneOf(['default', 'flat', 'alt']),

    /**
     * Button is depressed.
     */
    pressed: PropTypes.bool,

    /**
     * Other props to consider:
     *
     * round
     * circle
     * block
     * raised
     */
  };

  static defaultProps = {
    color: 'gray',
    state: 'default',
    type: 'default',
    size: 'medium',
    disabled: false,
  };

  handleOnClick = e => {
    if (!this.props.disabled) {
      this.props.onClick(e);
    }
  };

  render() {
    return (
      <button
        onClick={this.handleOnClick}
        style={this.props.style}
        className={[
          this.props.classes.button,
          this.props.classes[this.props.size],
          this.props.pressed && this.props.classes.pressed,
          this.props.block && this.props.classes.block,
          this.props.className,
        ].join(' ')}
      >
        {this.props.children}
      </button>
    );
  }
}

const styles = theme => ({
  button: {
    ...theme.button.reset,
    ...theme.button.base,
    //...theme.elevation.shadow1, TODO - Parameterize as 'raised'...
    color: props => fontColor(theme, props),
    fontWeight: props => fontWeight(theme, props),
    borderWidth: props => borderWidth(theme, props),
    borderColor: props => borderColor(theme, props),
    backgroundColor: props => backgoundColor(theme, props),

    transition: 'all 0.2s ease 0s',

    '&:hover': {
      cursor: props => (props.disabled ? 'not-allowed' : 'pointer'),
      backgroundColor: props => backgoundColor(theme, props, 'hover'),
      color: props => fontColor(theme, props, 'hover'),
      borderColor: props => borderColor(theme, props, 'hover'),
    },

    '&:focus': {
      backgroundColor: props => backgoundColor(theme, props, 'focus'),
      color: props => fontColor(theme, props, 'focus'),
      borderColor: props => color(theme, props, 'focus'),
    },

    '&:active': {
      backgroundColor: props => backgoundColor(theme, props, 'active'),
      color: props => fontColor(theme, props, 'active'),
      borderColor: props => color(theme, props, 'active'),
    },

    '&:disabled': {
      backgroundColor: props => backgoundColor(theme, props),
      color: props => fontColor(theme, props),
      borderColor: props => color(theme, props),
    },

    '& svg': {
      marginRight: theme.spacing.smaller,
    },
  },

  pressed: {
    backgroundColor: props => backgoundColor(theme, props, 'hover'),
    color: props => fontColor(theme, props, 'hover'),
    borderColor: props => borderColor(theme, props, 'hover'),
  },

  smallest: {
    ...theme.button.height.smallest,
  },

  smaller: {
    ...theme.button.height.smaller,
  },

  small: {
    ...theme.button.height.small,
  },

  medium: {
    ...theme.button.height.medium,
  },

  large: {
    ...theme.button.height.large,
  },

  larger: {
    ...theme.button.height.larger,
  },

  largest: {
    ...theme.button.height.largest,
  },

  block: {
    width: '100%',
  },
});

/**
 * Ensure contrast, when the base color is dark return 'light1', else 'dark6'.
 */
const fontColor = (theme, props, state) => {
  if (props.disabled && state === 'hover') return undefined;

  const bgColor = Color(backgoundColor(theme, props, state));

  switch (props.type) {
    case 'default':
      return bgColor.darken(0.2).isDark()
        ? color(theme, props, props.disabled ? 'light2' : 'light1')
        : color(theme, props, props.disabled ? 'dark5' : 'dark6');
    case 'flat':
      return color(theme, props, props.disabled ? 'light4' : 'base');
    case 'alt':
      return color(theme, props, props.disabled ? 'light4' : 'base');
    default:
      return undefined;
  }
};

const fontWeight = (theme, props, state) => {
  switch (props.type) {
    case 'default':
      return props.disabled ? 300 : 400;
    case 'flat':
      return props.disabled ? 500 : 700;
    case 'alt':
      return props.disabled ? 300 : 500;
    default:
      return 400;
  }
};

const backgroundColorVariantByState = (theme, props, state) => {
  switch (state) {
    case 'hover':
      return 'dark2';
    case 'focus':
      return 'dark2';
    case 'active':
      return 'dark2';
    default:
      return undefined;
  }
};

const backgoundColor = (theme, props, state) => {
  if (props.disabled && state) return undefined;

  switch (props.type) {
    case 'default':
      return color(
        theme,
        props,
        !props.disabled ? state && backgroundColorVariantByState(theme, props, state) : 'light4'
      );
    case 'flat':
      return state && lightBackgoundColorByState(theme, props, state);
    case 'alt':
      return state && lightBackgoundColorByState(theme, props, state);
    default:
      return undefined;
  }
};

const lightBackgoundColorByState = (theme, props, state) => {
  switch (state) {
    case 'hover':
      return color(theme, props, 'light1');
    case 'focus':
      return color(theme, props, 'light1');
    case 'active':
      return color(theme, props, 'light1');
    default:
      return undefined;
  }
};

const borderWidth = (theme, props) => {
  switch (props.type) {
    case 'default':
      return 0;
    case 'flat':
      return 0;
    case 'alt':
      return undefined;
    default:
      return undefined;
  }
};

const borderColor = (theme, props, state) => {
  if (props.disabled) {
    return color(theme, props, 'base');
  }

  switch (state) {
    case 'hover':
      return color(theme, props, 'dark2');
    case 'focus':
      return color(theme, props, 'dark2');
    case 'active':
      return color(theme, props, 'dark2');
    default:
      return undefined;
  }
};

const color = (theme, props, variantKey) => {
  return jssColorLookup(theme, props.color, variantKey);
};

export default injectSheet(styles)(Button);
