import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import SiteProfileDetailBody from './body/SiteProfileDetailBody';
import SiteProfileSummaryBody from './body/SiteProfileSummaryBody';

export default class SlideOutSiteProfileBody extends PureComponent {
  static propTypes = {
    /**
     * Show summary body.
     */
    showSummary: PropTypes.bool,

    /**
     * Show detail body.
     */
    showDetail: PropTypes.bool,
  };

  static defaultProps = {
    showSummary: false,
    showDetail: false,
  };

  render() {
    const { showSummary, showDetail } = this.props;

    if (showDetail) {
      return <SiteProfileDetailBody />;
    }

    if (showSummary) {
      return <SiteProfileSummaryBody />;
    }

    return null;
  }
}
