import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import Header from '../../components/header/Header';

export default class BaseStandardPage extends PureComponent {
  static propTypes = {
    /**
     * Children components.
     */
    children: PropTypes.node.isRequired,
  };

  render() {
    return (
      <Fragment>
        <Header />
        {this.props.children}
      </Fragment>
    );
  }
}
