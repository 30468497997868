import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ChemicalsContainer from '../../../components/profile/ChemicalsContainer';
import ComplianceContainer from '../../../components/profile/ComplianceContainer';
import DocumentsContainer from '../../../components/profile/DocumentsContainer';
import EnvironmentalInterestsContainer from '../../../components/profile/EnvironmentalInterestsContainer';
import GenericContainer from '../../../components/profile/GenericContainer';
import ProfileContainer from '../../../components/profile/ProfileContainer';
import SiteMapContainer from '../../../components/profile/SiteMapContainer';

export default class SiteProfileContent extends PureComponent {
  static propTypes = {
    /**
     * Current tab.
     */
    tab: PropTypes.string.isRequired,

    /**
     * Site profile data.
     */
    siteProfile: PropTypes.shape({
      profileFacilityDetail: PropTypes.shape({
        sourceIdentifier: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  render() {
    const { tab, siteProfile } = this.props;
    const sourceId = siteProfile.profileFacilityDetail.sourceIdentifier;

    switch (tab) {
      case 'profile':
        return <ProfileContainer siteProfileDetail={siteProfile} />;
      case 'map':
        return <SiteMapContainer />;
      case 'compliance':
        return <ComplianceContainer sourceIdentifier={sourceId} />;
      case 'documents':
        return <DocumentsContainer sourceIdentifier={sourceId} />;
      case 'chemicals':
        return <ChemicalsContainer sourceIdentifier={sourceId} />;
      case 'generic':
        return <GenericContainer sourceIdentifier={sourceId} />;
      case 'ei':
        return <EnvironmentalInterestsContainer sourceIdentifier={sourceId} />;
      default:
        return <ProfileContainer siteProfileDetail={siteProfile} />;
    }
  }
}
