import { Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import LabelValuePair from './LabelValuePair';

/**
 * Component that renders a list of data in label/value format. Each row consist of a single label
 * value pair.
 */
export default class LabelValueTable extends Component {
  static propTypes = {
    /**
     * The data for the table.
     */
    data: PropTypes.array,

    /**
     * Func that takes a single element from the array and returns the label for that row.
     */
    labelFunc: PropTypes.func,

    /**
     * Label width as %.
     */
    labelWidth: PropTypes.string,

    /**
     * Func that takes a single element from the array and returns the value for that row.
     */
    valueFunc: PropTypes.func,

    /**
     * Value width as %.
     */
    valueWidth: PropTypes.string,

    /**
     * Func to generate the key.
     */
    keyFunc: PropTypes.func,

    /**
     * Option to display "None" when the dataset is empty.
     */
    showIfEmpty: PropTypes.bool,
  };

  static defaultProps = {
    data: [],
    labelFunc: row => row.label,
    valueFunc: row => row.value,
    keyFunc: undefined,
    showIfEmpty: false,
  };

  render() {
    if (this.props.data.length === 0 && this.props.showIfEmpty) {
      return (
        <View>
          <Text>None</Text>
        </View>
      );
    }

    const { keyFunc, labelFunc, labelWidth, valueFunc, valueWidth } = this.props;

    return this.props.data.map((row, index) => (
      <LabelValuePair
        key={keyFunc ? keyFunc(row) : index}
        label={labelFunc(row)}
        labelWidth={labelWidth}
        value={valueFunc(row)}
        valueWidth={valueWidth}
      />
    ));
  }
}
