import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Box, Text } from '../../../../lib/base';
import HeadingText from '../../text/HeadingText';

export default class SiteNameAndAddress extends Component {
  static propTypes = {
    /**
     * Site to display.
     */
    site: PropTypes.shape({
      siteName: PropTypes.string,
      address: PropTypes.string,
      city: PropTypes.string,
      zip: PropTypes.string,
      state: PropTypes.string,
    }).isRequired,
  };

  render() {
    if (!this.props.site) {
      return null;
    }

    const { siteName, address, city, state, zip } = this.props.site;

    return (
      <React.Fragment>
        <Box stack="smallest">
          <HeadingText>{siteName}</HeadingText>
        </Box>
        <Box>
          <Text size="smaller">{address}</Text>
        </Box>
        <Box stack="small">
          <Text size="smaller">
            {city} {state} {zip}
          </Text>
        </Box>
      </React.Fragment>
    );
  }
}
