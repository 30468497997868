import TileLayer from 'ol/layer/Tile';
import Map from 'ol/Map';
import BingMaps from 'ol/source/BingMaps';
import PropTypes from 'prop-types';
import { Component } from 'react';

/**
 * Base map types.
 */
const BING_MAPS_BASE_TYPES = ['street', 'satellite', 'hybrid'];

/**
 * Imagery set for each base map type.
 */
const BING_MAPS_IMAGERY_SETS = [
  'Aerial',
  'AerialWithLabels',
  'AerialWithLabelsOnDemand',
  'CanvasDark',
  'CanvasLight',
  'CanvasGray',
  'Road',
  'RoadOnDemand',
];

const imagerySetByBaseType = baseType => {
  switch (baseType) {
    case BING_MAPS_BASE_TYPES[0]:
      return BING_MAPS_IMAGERY_SETS[6];
    case BING_MAPS_BASE_TYPES[1]:
      return BING_MAPS_IMAGERY_SETS[0];
    case BING_MAPS_BASE_TYPES[2]:
      return BING_MAPS_IMAGERY_SETS[1];
    default:
      return BING_MAPS_IMAGERY_SETS[0];
  }
};

export default class BingMapsTileLayer extends Component {
  static propTypes = {
    /**
     * Bing Maps API key.
     */
    apiKey: PropTypes.string.isRequired,
    /**
     * A unique key to indentify this layer.
     */
    id: PropTypes.number.isRequired,
    /**
     * The instance of map this layer is attached to. The map instance
     * will be provided to the component automatically when used as a
     * child component of the <Map/>.
     */
    map: PropTypes.instanceOf(Map),
    /**
     * Base layer type - street, satellite, hybrid.
     */
    type: PropTypes.oneOf(BING_MAPS_BASE_TYPES),
    /**
     * Whether or not the layer is visible.
     */
    visible: PropTypes.bool,
    /**
     * The z-index for layer rendering. At rendering time, the layers will be ordered, first by
     * Z-index and then by position. The default Z-index is 0.
     */
    zIndex: PropTypes.number.isRequired,
    /**
     * Called after all tiles loaded.
     */
    onTilesLoadComplete: PropTypes.func,
  };

  static defaultProps = {
    map: undefined,
    type: undefined,
    visible: true,
  };

  constructor(props) {
    super(props);

    const source = this.newSource(props);

    this.tileLayer = new TileLayer({
      id: props.id,
      visible: props.visible,
      zIndex: props.zIndex,
      minZoom: 1,
      maxZoom: 17,
      source: source,
    });

    this.tileLoadCounter = 0;

    if (props.onTilesLoadComplete) {
      source.on('tileloadstart', this.handleOnTileLoadStart);
      source.on('tileloadend', this.handleOnTileLoadEnd);
    }
  }

  newSource(props) {
    return new BingMaps({
      key: props.apiKey,
      crossOrigin: 'anonymous',
      imagerySet: imagerySetByBaseType(props.type),
      hidpi: false,
    });
  }

  handleOnTileLoadStart = () => {
    this.tileLoadCounter = this.tileLoadCounter + 1;
  };

  handleOnTileLoadEnd = () => {
    this.tileLoadCounter = this.tileLoadCounter - 1;
    if (this.tileLoadCounter === 0) {
      this.props.onTilesLoadComplete();
    }
  };

  componentDidMount() {
    this.props.map.getLayers().push(this.tileLayer);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible) {
      this.tileLayer.setVisible(this.props.visible);
    }

    if (prevProps.type !== this.props.type) {
      this.tileLayer.setSource(this.newSource(this.props));
    }
  }

  componentWillUnmount() {
    this.props.map.removeLayer(this.tileLayer);
    this.tileLayer = undefined;
  }

  render() {
    return null;
  }
}
