import debounce from 'lodash/debounce';
import { singleClick, pointerMove } from 'ol/events/condition';
import Feature from 'ol/Feature';
import Select from 'ol/interaction/Select';
import Map from 'ol/Map';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SiteSearchCriteria from '../../../../../../api/impl/SiteSearchCriteria';
import { FeaturesLayer } from '../../../../../../lib/map';
import { toLonLatExtent } from '../../../../../../lib/map/core/transfomer';
import {
  deselectSite,
  multipleSiteSelect,
  searchFilterCountIfNeeded,
  searchSitesIfNeeded,
  skipMapMoveSearchOnce,
  zoomToExtent,
} from '../../../../../../store/map/map.action';
import { loadProfileDetailIfNeeded } from '../../../../../../store/profile/profile.action';
import { SEARCH_DELAY_TIME_MS } from '../../../../../Constants';
import {
  navigateCloseSubPanel,
  navigateToMapHome,
  navigateToMobileCardView,
  navigateToSummary,
} from '../../../../../routing';
import { isSmallScreen, isTouchDevice } from '../../../../../util/browser';
import {
  isCluster,
  isSharesSourceId,
  isSiteInCluster,
  isSitesEqual,
  sourceIdentifier,
  webMercatorExtent,
} from '../../../../../util/feature';
import { calculateSearchExtent } from '../../../../../util/map';
import { newStyleHovered, newStyleNotSelected, newStyleSelected } from '../../../../../util/marker';

/**
 * A unique layer identifier.
 */
export const LAYER_ID = 'SitesLayer';

/**
 * Sites feature Layer.
 */
class SitesLayer extends Component {
  static propTypes = {
    /**
     * The map.
     */
    map: PropTypes.instanceOf(Map),

    /**
     * An array
     */
    features: PropTypes.arrayOf(PropTypes.instanceOf(Feature)),

    /**
     * Fill color.
     */
    fillColor: PropTypes.string.isRequired,

    /**
     * Stroke color.
     */
    strokeColor: PropTypes.string.isRequired,

    /**
     * The index where to insert the vector layer.
     */
    zIndex: PropTypes.number.isRequired,

    /**
     * Is layer visible?
     */
    visible: PropTypes.bool,

    /**
     * OnLayerMove callback.
     */
    onLayerMove: PropTypes.func,
    dispatchZoomToExtent: PropTypes.func,
  };

  static defaultProps = {
    map: undefined,
    features: [],
    fillColor: 'rgba(255, 0, 252, 0.7)',
    strokeColor: 'rgba(56, 0, 55, 0.9)',
    visible: true,
  };

  constructor(props) {
    super(props);

    /**
     * React reference.
     */
    this.layer = React.createRef();

    /**
     * Debounce the site search function.
     */
    this.dispatchSearchSitesDebounced = debounce(
      this.props.dispatchSearchSites,
      SEARCH_DELAY_TIME_MS
    );

    /**
     * Bind event handlers.
     */
    this.handleMapMoveEnd = this.handleMapMoveEnd.bind(this);
    this.handleOnAddFeature = this.handleOnAddFeature.bind(this);
    this.handleOnClickFeature = this.handleOnClickFeature.bind(this);
    this.handleOnHoverFeature = this.handleOnHoverFeature.bind(this);
    this.handleSelectFilter = this.handleSelectFilter.bind(this);

    /**
     * Setup click and hover interactions for this layer.
     */
    this.interactionHover = new Select({
      condition: pointerMove,
      filter: this.handleSelectFilter,
    });

    this.interactionClick = new Select({
      condition: singleClick,
      filter: this.handleSelectFilter,
      multi: false,
      hitTolerance: isSmallScreen() ? 5 : 0,
    });
  }

  componentDidMount() {
    /**
     * Enable/disable map move listener, dispatches site cluster api calls.
     */
    this.toggleMoveEndListener(this.props.visible);

    /**
     * Click interaction.
     */
    this.interactionClick.on('select', this.handleOnClickFeature);
    this.props.map.addInteraction(this.interactionClick);

    /**
     * Hover interaction - for non-touch devices only.
     */
    if (!isTouchDevice()) {
      this.interactionHover.on('select', this.handleOnHoverFeature);
      this.props.map.addInteraction(this.interactionHover);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { params } = this.props.match;

    /**
     * Look for url changes.
     */
    if (!params.id && this.props.siteSelected) {
      this.props.dispatchDeselectSite();
    }

    /**
     * Handle layer visibility change.
     */
    if (prevProps.visible !== this.props.visible) {
      this.toggleMoveEndListener(this.props.visible);

      if (this.props.visible) {
        this.handleMapMoveEnd(this.props.map);
      }
    }

    /**
     * Handle layer interactivity change.
     */
    if (prevProps.interactive !== this.props.interactive) {
      if (this.props.interactive) {
        this.props.map.addInteraction(this.interactionClick);
        this.props.map.addInteraction(this.interactionHover);
      } else {
        this.props.map.removeInteraction(this.interactionClick);
        this.props.map.removeInteraction(this.interactionHover);
      }
    }

    /**
     * Handle when a site is selected/deselected.
     */
    if (prevProps.siteSelected !== this.props.siteSelected) {
      this.layer.current.vectorSource.getFeatures().forEach(f => {
        if (this.isSelectedFeature(f)) {
          f.setStyle(newStyleSelected(f, this.props.fillColor, this.props.strokeColor));
          return;
        } else {
          f.setStyle(newStyleNotSelected(f, this.props.fillColor, this.props.strokeColor));
        }
      });

      if (this.props.siteSelected === undefined) {
        this.interactionClick.getFeatures().clear();
      }
    }

    /**
     * Handle special indicator to skip site search when map is moved. This is
     * set when an item in the left-hand site list panel is selected.
     */
    if (prevProps.isSkipMapMoveSearch !== this.props.isSkipMapMoveSearch) {
      if (this.props.isSkipMapMoveSearch) {
        this.skipMapMoveSearch = true;
      }
    }
  }

  /**
   * Cleanup.
   */
  componentWillUnmount() {
    this.toggleMoveEndListener(false);
  }

  /**
   * Call the search api when the map has been moved.
   */
  handleMapMoveEnd(mapOrEvent) {
    /**
     * Skip map move search when set to true. See componentDidUpdate().
     */
    if (this.skipMapMoveSearch) {
      this.skipMapMoveSearch = false;
      this.props.dispatchResetSkipMapMoveSearchOnce();
      return;
    }

    /**
     * When a site is selected the site search api call is normally skipped but sometimes we need
     * to bypass this. The forceExtentSearchOnce bool controls this and is reset immediately.
     */
    if (!this.forceExtentSearchOnce && this.props.siteSelected) {
      return;
    }

    /**
     * We are resetting the takeWithoutClustering count on mapMove
     */
    if (this.props.criteria) {
      this.props.criteria.takeWithoutClustering = null;
    }

    this.forceExtentSearchOnce = false;

    /**
     * Adjust the search extent so that results do not fall behind the search results panel.
     */
    const map = mapOrEvent instanceof Map ? mapOrEvent : mapOrEvent.map;
    const extent = calculateSearchExtent(map);

    this.dispatchSearchSitesDebounced(this.newCriteriaForExtent(extent));

    if (this.props.onLayerMove) {
      this.props.onLayerMove();
    }
  }

  /**
   * Styles feature when added to the map.
   */
  handleOnAddFeature(feature) {
    if (this.isSelectedFeature(feature)) {
      feature.setStyle(newStyleSelected(feature, this.props.fillColor, this.props.strokeColor));
    } else {
      feature.setStyle(newStyleNotSelected(feature, this.props.fillColor, this.props.strokeColor));
    }
  }

  /**
   * Handler when feature is hovered.
   */
  handleOnHoverFeature(event) {
    const that = this;

    event.deselected.forEach(feature => {
      document.querySelector('body').classList.remove('hand');

      if (this.isSelectedFeature(feature)) {
        feature.setStyle(newStyleSelected(feature, that.props.fillColor, that.props.strokeColor));
      } else {
        feature.setStyle(
          newStyleNotSelected(feature, that.props.fillColor, that.props.strokeColor)
        );
      }
    });

    event.selected.forEach(feature => {
      document.querySelector('body').classList.add('hand');

      let style;

      if (this.isSelectedFeature(feature)) {
        style = newStyleSelected(feature, that.props.fillColor, that.props.strokeColor);
      } else {
        style = newStyleHovered(feature, that.props.fillColor, that.props.strokeColor);
      }
      feature.setStyle(style);
    });
  }

  /**
   * Handler when feature is clicked.
   */
  handleOnClickFeature(e) {
    this.setState({
      position: undefined,
      feature: undefined,
    });

    if (e.selected[0]) {
      const feature = e.selected[0];

      /**
       * Zoom to extents of cluster
       * if already at full extent highlight relevant results by finding if sites in result list
       * are in bounding box of cluster else show overlay of basic feature details.
       */
      if (isCluster(feature)) {
        let prevZoom = this.props.map.getView().getZoom();

        this.props.dispatchZoomToExtent(webMercatorExtent(feature));
        this.interactionClick.getFeatures().clear();

        if (isSiteInCluster(this.props.siteSelected, feature)) {
          this.forceExtentSearchOnce = true;
          this.interactionHover.getFeatures().clear();
        } else {
          if (isSmallScreen()) {
            navigateToMapHome(this.props);
          } else {
            navigateCloseSubPanel(this.props);
          }
        }

        if (prevZoom === this.props.map.getView().getZoom()) {
          let selectedClusterSiteList = this.props.sites.filter(site => {
            return isSiteInCluster(site, feature);
          });
          this.props.dispatchMultipleSiteSelect(selectedClusterSiteList);
        }
      } else {
        const site = this.props.sites.find(s => s.sourceIdentifier === sourceIdentifier(feature));

        if (isSitesEqual(site, this.props.siteSelected)) {
          navigateCloseSubPanel(this.props);
          this.interactionClick.getFeatures().clear();
        } else {
          const sourceId = (site && site.sourceIdentifier) || feature.get('sourceIdentifier');

          if (isSmallScreen()) {
            navigateToMobileCardView(this.props, sourceId);
          } else {
            navigateToSummary(this.props, sourceId);
          }

          if (!site) {
            this.props.dispatchFetchProfile(sourceId);
          }
        }
      }
    } else if (e.deselected[0]) {
      if (isSmallScreen()) {
        navigateToMapHome(this.props);
      } else {
        navigateCloseSubPanel(this.props);
      }

      this.interactionClick.getFeatures().clear();
    }
  }

  /**
   * Handler to determine if feature is candidate for interaction.
   */
  handleSelectFilter(feature) {
    return this.props.features && this.props.features.includes(feature);
  }

  /**
   * Whether or not the provided feature is of a selected site.
   */
  isSelectedFeature(feature) {
    return (
      isSiteInCluster(this.props.siteSelected, feature) ||
      isSharesSourceId(this.props.siteSelected, feature)
    );
  }

  /**
   * New search criteria instance copied from previous criteria.
   */
  newCriteriaForExtent(extent) {
    return new SiteSearchCriteria({
      ...this.props.criteria,
      bbox: toLonLatExtent(extent),
    });
  }

  /**
   * Enable/disable the map move listener.
   */
  toggleMoveEndListener(on) {
    if (on) {
      this.props.map.on('moveend', this.handleMapMoveEnd, this);
    } else {
      this.props.map.un('moveend', this.handleMapMoveEnd, this);
    }
  }

  render() {
    return (
      <FeaturesLayer
        id={LAYER_ID}
        ref={this.layer}
        map={this.props.map}
        zIndex={this.props.zIndex}
        features={this.props.features}
        onAddFeature={this.handleOnAddFeature}
        visible={this.props.visible}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    features: state.map.search.features,
    sites: state.map.search.sites,
    criteria: state.map.search.criteria,
    siteSelected: state.map.search.siteSelected,
    interactive: state.map.sitesLayer.interactive,
    isSkipMapMoveSearch: state.map.search.isSkipMapMoveSearch,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchSearchSites: criteria => {
      dispatch(searchSitesIfNeeded(criteria));
      dispatch(searchFilterCountIfNeeded(criteria));
    },
    dispatchResetSkipMapMoveSearchOnce: () => {
      dispatch(skipMapMoveSearchOnce(false));
    },
    dispatchDeselectSite: () => {
      dispatch(deselectSite());
    },
    dispatchMultipleSiteSelect: clusterSites => {
      dispatch(multipleSiteSelect(clusterSites));
    },
    dispatchZoomToExtent: extent => {
      dispatch(zoomToExtent(extent));
    },
    dispatchFetchProfile: id => {
      dispatch(loadProfileDetailIfNeeded(id));
    },
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SitesLayer)
);
