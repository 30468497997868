import isArray from 'lodash/isArray';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ProfileTable } from '../../../../lib/pdf';
import { formatDate } from '../../../util/format';

const COLUMNS = [
  {
    id: 'typeDesc',
    displayName: 'Description',
    width: '31%',
  },
  {
    id: 'osDesc',
    displayName: 'Source System',
    width: '30%',
  },
  {
    id: 'programId',
    displayName: 'Program Id',
    width: '15%',
  },
  {
    id: 'startDate',
    displayName: 'Start Date',
    width: '12%',
  },
  {
    id: 'endDate',
    displayName: 'End Date',
    width: '12%',
  },
];

export class EnvironmentalInterests extends PureComponent {
  static propTypes = {
    /**
     * Environmental Interests
     */
    environmentalInterests: PropTypes.arrayOf(
      PropTypes.shape({
        sourceIdentifier: PropTypes.string,
        envIntId: PropTypes.number,
        programId: PropTypes.string,
        startDateDesc: PropTypes.string,
        startDate: PropTypes.string,
        endDate: PropTypes.string,
        typeDesc: PropTypes.string,
        typeDescLong: PropTypes.string,
        contactName: PropTypes.string,
        contactPhone: PropTypes.string,
        facilityPageUrl: PropTypes.string,
        lastInspectionDate: PropTypes.string,
        envIntNotes: PropTypes.string,
        osDesc: PropTypes.string,
        endDateDesc: PropTypes.string,
      })
    ),
  };

  static defaultProps = {
    environmentalInterests: [],
  };

  hasData() {
    const { environmentalInterests } = this.props;

    if (!environmentalInterests || !isArray(environmentalInterests)) {
      return false;
    }
    return environmentalInterests.length > 0;
  }

  rows() {
    const { environmentalInterests } = this.props;

    return environmentalInterests.map(ei => ({
      typeDesc: ei.typeDesc,
      osDesc: ei.osDesc,
      programId: ei.programId,
      startDate: formatDate(ei.startDate),
      endDate: formatDate(ei.endDate),
    }));
  }

  render() {
    if (!this.hasData()) {
      return null;
    }

    return <ProfileTable columns={COLUMNS} data={this.rows()} />;
  }
}
