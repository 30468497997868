import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import injectSheet from 'react-jss';
import { jssColor, jssSize, px } from '../util/jss';
import { propTypeColor, propTypeColorVariant, propTypeSize } from '../util/propTypes';

/**
 * A paragraph of text.
 */
class Paragraph extends PureComponent {
  static propTypes = {
    /**
     * Children.
     */
    children: PropTypes.node,

    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,

    /**
     * Additional className to style the text.
     */
    className: PropTypes.string,

    /**
     * Text color - blue, red, green, gray, etc.
     */
    color: propTypeColor,

    /**
     * Variant of text color - light3, light6, dark2, etc.
     */
    colorVariant: propTypeColorVariant,

    /**
     * Indent the paragraph.
     */
    indent: PropTypes.bool,

    /**
     * Size of the text in the paragraph.
     */
    size: propTypeSize,
  };

  static defaultProps = {
    children: undefined,
    className: undefined,
    color: undefined,
    colorVariant: undefined,
    indent: undefined,
    size: undefined,
  };

  renderClassnames() {
    return [
      /**
       * Base paragrash styles.
       */
      this.props.classes.paragraph,

      /**
       * Color.
       */
      this.props.color && this.props.classes.color,

      /**
       * Indent - yes or no.
       */
      this.props.indent && this.props.classes.indent,

      /**
       * Size.
       */
      jssSize(this.props),

      /**
       * Addiditonal style selector.
       */
      this.props.className,
    ].join(' ');
  }

  render() {
    return <p className={this.renderClassnames()}>{this.props.children}</p>;
  }
}

const styles = theme => ({
  paragraph: {
    ...theme.typography.paragraph,
  },

  indent: {
    textIndent: px(theme.core.grid.unit.x2),
  },

  smallest: {
    fontSize: theme.typography.size.smallest,
  },

  smaller: {
    fontSize: theme.typography.size.smaller,
  },

  small: {
    fontSize: theme.typography.size.small,
  },

  medium: {
    fontSize: theme.typography.size.medium,
  },

  large: {
    fontSize: theme.typography.size.large,
  },

  larger: {
    fontSize: theme.typography.size.larger,
  },

  largest: {
    fontSize: theme.typography.size.largest,
  },

  color: {
    color: props => props.color && jssColor(theme, props),
  },
});

export default injectSheet(styles)(Paragraph);
