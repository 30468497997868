import uniq from 'lodash/uniq';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Box, Button, Text } from '../../../../../../../lib/base';
import { selectBaseLayer, selectReferenceLayers } from '../../../../../../../store/map/map.action';
import Select from '../../../../../../components/form/Select';
import HeadingText from '../../../../../../components/text/HeadingText';
import {
  findAllBaseLayers,
  findAllNonBaseLayers,
  findAllNonBaseLayersByGroupName,
} from '../../../../../../util/layers';
import SelectableLayer from './SelectableLayer';
import Columns from '../../../../../../../lib/base/layout/flex/Columns';
import Column from '../../../../../../../lib/base/layout/flex/Column';
import Badge from '../../../../../../components/basic/Badge';
import { COLOR_BASE_PRIMARY } from '../../../../../../Constants';
import { IconClear } from '../../../../../../components/icons/fontawesome/icons';

/**
 * Provides controls to toggle map layers.
 */
class LayerSwitcher extends Component {
  static propTypes = {
    /**
     * Connected...
     */
    layers: PropTypes.array.isRequired,

    /**
     * Connected...
     */
    activeBaseLayer: PropTypes.object.isRequired,

    /**
     * Connected...
     */
    activeReferenceLayers: PropTypes.array.isRequired,
  };

  static groups(props) {
    return uniq(findAllNonBaseLayers(props.layers).map(layer => layer.groupName)).sort();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.referenceLayerGroups === undefined && nextProps.layers) {
      return {
        referenceLayerGroups: LayerSwitcher.groups(nextProps),
      };
    }

    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      referenceLayerGroupsSelected: undefined,
      referenceLayerGroups: undefined,
    };

    this.handleOnChangeBaseLayer = this.handleOnChangeBaseLayer.bind(this);
    this.handleOnChangeReferenceLayer = this.handleOnChangeReferenceLayer.bind(this);
    this.handleOnChangeReferenceLayerGroup = this.handleOnChangeReferenceLayerGroup.bind(this);
    this.handleOnClickClear = this.handleOnClickClear.bind(this);
  }

  /**
   * Handler for changing the base layer, only one is active
   * at a time.
   */
  handleOnChangeBaseLayer(event, layer) {
    this.props.dispatchSelectBaseLayer(layer);
  }

  /**
   * Handler for selecting one or more non-base layers.
   */
  handleOnChangeReferenceLayer(layer) {
    const isLayerVisible =
      this.props.activeReferenceLayers.find(selected => selected.id === layer.id) !== undefined;

    if (!isLayerVisible) {
      const currentLayers = this.props.activeReferenceLayers;
      currentLayers.push(layer);

      this.props.dispatchSelectReferenceLayers(currentLayers);
    } else {
      const currentLayers = this.props.activeReferenceLayers.filter(l => l.id !== layer.id);

      this.props.dispatchSelectReferenceLayers(currentLayers);
    }
  }

  /**
   * Handles layer group changes.
   */
  handleOnChangeReferenceLayerGroup(event, groupName) {
    this.setState({
      referenceLayerGroupsSelected: groupName,
    });
  }

  handleOnClickClear() {
    this.props.dispatchSelectReferenceLayers([]);
  }

  groupLayers() {
    return findAllNonBaseLayersByGroupName(
      this.props.layers,
      this.state.referenceLayerGroupsSelected
    );
  }

  choiceLabel(groupName) {
    const count = this.props.activeReferenceLayers.filter(layer => layer.groupName === groupName)
      .length;

    if (count > 0) {
      return (
        <div>
          <Columns vAlign="center">
            <Column flex={1}>
              <Text>{groupName}</Text>
            </Column>
            <Column flex={0}>
              <Text size="small">
                <Badge>{count.toLocaleString()}</Badge>
              </Text>
            </Column>
          </Columns>
        </div>
      );
    }

    return <Text>{groupName}</Text>;
  }

  render() {
    return (
      <React.Fragment>
        <Box margin="medium">
          <Box stack="large" title="Change the map imagery.">
            <Box stack="medium">
              <HeadingText>Base Layer</HeadingText>
            </Box>

            <Select
              value={this.props.activeBaseLayer}
              onChange={this.handleOnChangeBaseLayer}
              placeholder="Choose..."
              choices={findAllBaseLayers(this.props.layers)}
              keyExtractor={choice => choice.id}
              labelExtractor={choice => choice.layerName}
              renderChoice={choice => <div>{choice.layerName}</div>}
            />
          </Box>

          <Box stack="large">
            <Box stack="medium" title="Change the map reference layers.">
              <HeadingText>Map Layers</HeadingText>
            </Box>

            <Box stack="medium" title="Change the map reference layers.">
              <Select
                value={this.state.referenceLayerGroupsSelected}
                onChange={this.handleOnChangeReferenceLayerGroup}
                placeholder="Choose..."
                choices={this.state.referenceLayerGroups}
                keyExtractor={choice => choice}
                labelExtractor={choice => this.choiceLabel(choice)}
                renderChoice={choice => this.choiceLabel(choice)}
              />
            </Box>

            {this.props.activeReferenceLayers.length > 0 && (
              <Box textAlign="right" mTop="medium" mBottom="medium">
                <Button
                  color={COLOR_BASE_PRIMARY}
                  onClick={this.handleOnClickClear}
                  title="Deselect all map reference layers."
                  type="flat"
                  size="small"
                >
                  <IconClear color={COLOR_BASE_PRIMARY} /> Clear
                </Button>
              </Box>
            )}

            <div style={{ marginLeft: '-16px', marginRight: '-16px' }}>
              {this.groupLayers().map(layer => {
                const isSelected =
                  this.props.activeReferenceLayers.find(l => l.id === layer.id) !== undefined;

                return (
                  <Box mBottom="smallest" key={layer.id}>
                    <SelectableLayer
                      selected={isSelected}
                      onSelectLayer={this.handleOnChangeReferenceLayer}
                      layer={layer}
                    />
                  </Box>
                );
              })}
            </div>
          </Box>
        </Box>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    layers: state.system.layers.data,
    activeBaseLayer: state.map.activeBaseLayer,
    activeReferenceLayers: state.map.activeReferenceLayers,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchSelectBaseLayer: layer => {
      dispatch(selectBaseLayer(layer));
    },

    dispatchSelectReferenceLayers: currentLayers => {
      dispatch(selectReferenceLayers(currentLayers));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LayerSwitcher);
