import { Component } from 'react';
import PropTypes from 'prop-types';
import Map from 'ol/Map';
import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';

/**
 * Base layer component for viewing WMS tiles.
 */
export default class WMSTileLayer extends Component {
  static propTypes = {
    /**
     * A unique key to identify this layer.
     */
    id: PropTypes.number.isRequired,

    /**
     * The instance of map this layer is attached to. The map instance
     * will be provided to the component automatically when used as a
     * child component of the <Map/>.
     */
    map: PropTypes.instanceOf(Map),

    /**
     * Metadata object.
     */
    metadata: PropTypes.object,

    /**
     * The opacity of the tiles.
     */
    opacity: PropTypes.number,

    /**
     * ArcGIS Rest parameters
     */
    params: PropTypes.object,

    /**
     * ArcGIS Rest service URL for a Map Service or Image Service. The url should include
     * /MapServer or /ImageServer.
     */
    url: PropTypes.string.isRequired,

    /**
     * Whether or not the layer is visible.
     */
    visible: PropTypes.bool,

    /**
     * The z-index for layer rendering. At rendering time, the layers will be ordered, first by
     * Z-index and then by position. The default Z-index is 0.
     */
    zIndex: PropTypes.number,

    /**
     * WMS server type
     */
    serverType: PropTypes.string,

    /**
     * WMS layer name
     */
    layerName: PropTypes.string,

    /**
     * layer opacity
     */
  };

  static defaultProps = {
    id: Math.random() * 1000000 + '',
    map: undefined,
    opacity: 1,
    params: { exceptions: 'application/vnd.ogc.se_inimage' },
    visible: true,
    zIndex: 999,
    serverType: 'geoserver',
  };

  constructor(props) {
    super(props);
    // https://openlayers.org/en/latest/examples/wms-tiled.html
    this.tileLayer = new TileLayer({
      id: props.id,
      opacity: props.opacity,
      visible: props.visible,
      zIndex: props.zIndex,
      source: new TileWMS({
        url: props.url,
        crossOrigin: 'anonymous',
        params: { exceptions: 'application/vnd.ogc.se_inimage', LAYERS: this.props.layerName },
        styles: [],
        serverType: props.serverType,
        transition: 0,
      }),
    });

    /**
     * Metadata is set for layer use.
     */
    if (props.metadata) {
      this.tileLayer.set('metadata', props.metadata);
    }
  }

  componentDidMount() {
    this.props.map.getLayers().push(this.tileLayer);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.visible !== this.props.visible) {
      this.tileLayer.setVisible(this.props.visible);
    }
  }

  componentWillUnmount() {
    this.props.map.removeLayer(this.tileLayer);
    this.tileLayer = undefined;
  }

  render() {
    return null;
  }
}
