import Downshift from 'downshift';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import injectSheet from 'react-jss';
import { Manager, Popper, Reference } from 'react-popper';
import { Column, Columns } from '../../../lib/base';
import { COLOR_BASE_PRIMARY, SEARCH_DELAY_TIME_MS } from '../../Constants';
import { IconClear, IconSpinner } from '../icons';

class SuggestTextInput extends PureComponent {
  static propTypes = {
    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,

    /**
     * The value of the input.
     */
    value: PropTypes.string,

    /**
     * Handle input value changes.
     */
    onChange: PropTypes.func.isRequired,

    /**
     * Handle when value is cleared.
     */
    onClear: PropTypes.func,

    /**
     * Handle when input is blurred.
     */
    onBlur: PropTypes.func,

    /**
     * Called when a suggestion item is selected.
     */
    onSelect: PropTypes.func,

    /**
     * Handle on input keypress.
     */
    onKeyPress: PropTypes.func,

    /**
     * Called when suggestions are wanted.
     */
    onRequestSuggestions: PropTypes.func,
  };

  static defaultProps = {
    children: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      term: props.value || '',
      suggestions: [],
      isLoading: false,
    };

    this.doSuggest = this.doSuggest.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
    this.handleOnClear = this.handleOnClear.bind(this);
    this.handleOnKeyPress = this.handleOnKeyPress.bind(this);
    this.debouncedDoSuggest = debounce(this.doSuggest, SEARCH_DELAY_TIME_MS);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.term !== this.props.value) {
      this.setState({ term: this.props.value || '' });
    }
  }

  doSuggest(term) {
    if (this.props.onRequestSuggestions) {
      this.setState({ isLoading: true });
      this.props
        .onRequestSuggestions(term)
        .then(suggestions => {
          this.setState({ suggestions, isLoading: false });
          if (this.scheduleUpdate) {
            this.scheduleUpdate();
          }
        })
        .catch(err => {
          if (err.name !== 'AbortError') {
            this.setState({ isLoading: false });
          }
        });
    }
  }

  handleOnChange(value) {
    this.props.onChange(value);
    this.setState({ term: value }, () => {
      this.debouncedDoSuggest(this.state.term);
    });
  }

  handleOnBlur(event) {
    this.props.onBlur(event);
  }

  hasValue(inputValue) {
    return inputValue && inputValue.length > 0;
  }

  handleOnClear(event) {
    const v = '';
    this.setState({ term: v });
    this.props.onChange(v);

    if (this.props.onClear) {
      this.props.onClear(event);
    }
  }

  renderSuggestions(getItemProps, highlightedIndex) {
    const suggestions = this.state.suggestions || [];

    return suggestions.map((item, index) => {
      const classNames = [this.props.classes.itemContainer];

      if (highlightedIndex === index) {
        classNames.push(this.props.classes.highlighted);
      }

      const startIndex = item.toUpperCase().indexOf(this.state.term.toUpperCase());
      const lastIndex = startIndex + this.state.term.length;

      let formattedItem = item;

      if (startIndex !== -1) {
        formattedItem = (
          <Fragment>
            {item.slice(0, startIndex)}
            <span className={this.props.classes.bold}>{item.substring(startIndex, lastIndex)}</span>
            {item.slice(lastIndex)}
          </Fragment>
        );
      }

      return (
        <div
          key={item}
          {...getItemProps({
            item: item,
            key: item,
            className: classNames.join(' '),
          })}
        >
          {formattedItem}
        </div>
      );
    });
  }

  handleOnKeyPress(event) {
    if (this.props.onKeyPress) {
      this.props.onKeyPress(event);
      if (event.key === 'Enter') {
        event.target.blur();
      }
    }
  }

  canShowPopper(isOpen) {
    return isOpen && this.state.suggestions.length > 0;
  }

  render() {
    return (
      <Downshift
        onChange={selection => {
          this.props.onSelect(selection);
        }}
        onStateChange={({ inputValue }) => {
          if (inputValue !== undefined) {
            this.handleOnChange(inputValue);
            return inputValue;
          }
        }}
        selectedItem={this.state.term}
        itemToString={item => item || ''}
      >
        {downshift => {
          const {
            isOpen,
            getInputProps,
            getItemProps,
            highlightedIndex,
            selectedItem,
            inputValue,
          } = downshift;

          return (
            <div>
              <Manager>
                <Reference>
                  {({ ref }) => {
                    return (
                      <div ref={ref}>
                        <Columns
                          className={this.props.classes.container}
                          vAlign="center"
                          gutter="small"
                        >
                          <Column flex={1} vAlign="center">
                            <input
                              className={this.props.classes.input}
                              {...getInputProps({
                                onBlur: this.handleOnBlur,
                                onKeyPress: this.handleOnKeyPress,
                              })}
                            />
                          </Column>
                          {this.state.isLoading && (
                            <Column flex={0} vAlign="center">
                              <IconSpinner size="sm" />
                            </Column>
                          )}
                          {this.hasValue(inputValue) && (
                            <Column flex={0} onClick={this.handleOnClear} vAlign="center">
                              <IconClear />
                            </Column>
                          )}
                        </Columns>
                      </div>
                    );
                  }}
                </Reference>
                {this.canShowPopper(isOpen) && (
                  <Popper placement="bottom">
                    {({ ref, style: { top, left, position, transform }, scheduleUpdate }) => {
                      this.scheduleUpdate = scheduleUpdate;
                      return (
                        <div
                          ref={ref}
                          style={{ top, left: left + 1, position, transform }}
                          className={this.props.classes.menuRoot}
                        >
                          {this.renderSuggestions(getItemProps, highlightedIndex, selectedItem)}
                        </div>
                      );
                    }}
                  </Popper>
                )}
              </Manager>
            </div>
          );
        }}
      </Downshift>
    );
  }
}

const styles = theme => ({
  container: {
    padding: `${theme.spacing.small} ${theme.spacing.small}`,
    height: '48px',
  },
  input: {
    ...theme.input.medium,
  },
  menuRoot: {
    zIndex: 1000,
    width: '100%',
    backgroundColor: 'white',
    border: `1px solid ${theme.color[COLOR_BASE_PRIMARY]['light3']}`,
    ...theme.elevation.shadow2,
  },
  itemContainer: {
    padding: `${theme.spacing.small} ${theme.spacing.small}`,
    fontSize: '15px',

    '&:hover': {
      backgroundColor: theme.color[COLOR_BASE_PRIMARY]['light2'],
    },
  },
  highlighted: {
    backgroundColor: theme.color[COLOR_BASE_PRIMARY]['light2'],
  },
  bold: {
    fontWeight: 600,
    display: 'inline-block',
    borderBottom: '1px solid black',
    lineHeight: '18px',
  },
});

export default injectSheet(styles)(SuggestTextInput);
