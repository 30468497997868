import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Text } from '../../../lib/base';

export default class Label extends Component {
  static propTypes = {
    /**
     * Label to render.
     */
    label: PropTypes.string.isRequired,
  };

  render() {
    return (
      <Text size="smaller" bold caps title={this.props.label} color="gray" colorVariant="dark4">
        {this.props.label}
      </Text>
    );
  }
}
