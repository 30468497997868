import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import injectSheet from 'react-jss';
import { withRouter } from 'react-router-dom';
import { Button, Column, Columns } from '../../../../../../../../lib/base';
import { IconChevronRight, IconClear } from '../../../../../../../components/icons';
import SiteNameAndAddress from '../../../../../../../components/profile/header/SiteNameAndAddress';
import TouchTarget from '../../../../../../../components/touchable/TouchTarget';
import { COLOR_BASE_PRIMARY } from '../../../../../../../Constants';
import { navigateCloseSubPanel, navigateToDetail } from '../../../../../../../routing';
import { getSiteId, toSiteNameAndAddress } from '../../../../../../../util/entities/siteProfile';
import BaseHeader from './BaseHeader';

class SiteProfileSummaryHeader extends PureComponent {
  static propTypes = {
    /**
     * JSS provided classes.
     */
    classes: PropTypes.object.isRequired,

    /**
     * Site profile data.
     */
    siteProfile: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.handleOnShowMoreInformation = this.handleOnShowMoreInformation.bind(this);
    this.handleOnClose = this.handleOnClose.bind(this);
  }

  handleOnShowMoreInformation() {
    navigateToDetail(this.props, getSiteId(this.props.siteProfile));
  }

  handleOnClose() {
    navigateCloseSubPanel(this.props);
  }

  render() {
    const { siteProfile, classes } = this.props;

    return (
      <BaseHeader>
        <Columns vAlign="top">
          <Column flex={1}>
            <SiteNameAndAddress site={toSiteNameAndAddress(siteProfile)} />
          </Column>
          <Column flex={0}>
            <TouchTarget onClick={this.handleOnClose}>
              <IconClear />
            </TouchTarget>
          </Column>
        </Columns>
        <Columns>
          <Column flex={1} hRight className={classes.buttonContainer} vBottom>
            <Button
              size="small"
              onClick={this.handleOnShowMoreInformation}
              type="flat"
              color={COLOR_BASE_PRIMARY}
            >
              Show More Information <IconChevronRight color={COLOR_BASE_PRIMARY} />
            </Button>
          </Column>
        </Columns>
      </BaseHeader>
    );
  }
}

const styles = theme => ({
  buttonContainer: {
    padding: `${theme.spacing.small} 0`,
    display: 'inline-flex',
    justifyContent: 'flex-end',
  },
});

export default withRouter(injectSheet(styles)(SiteProfileSummaryHeader));
