import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Text } from '../../../../lib/base';
import SpinnerPanel from '../../spinner/SpinnerPanel';
import ComplianceActionsDataGrid from './ComplianceActionsDataGrid';

const api = () => window.nSITE.Api;

export default class ComplianceActionsContainer extends PureComponent {
  static propTypes = {
    /**
     * Source identifier to look up.
     */
    sourceIdentifier: PropTypes.string.isRequired,

    /**
     * Filter compliance actions on whether formal or informal.
     */
    isFormal: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      complianceActions: undefined,
      isInitialized: false,
      isLoading: true,
      error: undefined,
      page: 1,
      pageSize: 5,
      total: undefined,
      sortBy: ComplianceActionsDataGrid.DEFAULT_SORT_BY,
      isAsc: false,
      criteria: {},
    };

    this.handleOnRequestData = this.handleOnRequestData.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    this.setState({ isLoading: true });

    api()
      .profile.getComplianceActions(this.props.sourceIdentifier, {
        isFormal: this.props.isFormal,
        page: this.state.page,
        pageSize: this.state.pageSize,
        total: this.state.total,
        sortBy: this.state.sortBy,
        isAsc: this.state.isAsc,
        criteria: this.state.criteria,
      })
      .then(pageResponse => {
        this.setState({
          complianceActions: pageResponse.data,
          page: pageResponse.page,
          pageSize: pageResponse.pageSize,
          total: pageResponse.total,
          sortBy: pageResponse.sortBy,
          isAsc: pageResponse.isAsc,
          criteria: pageResponse.criteria,
          isLoading: false,
          isInitialized: true,
        });
      })
      .catch(err => {
        this.setState({
          error: err,
          isLoading: false,
        });
      });
  }

  handleOnRequestData({ page, pageSize, sortBy, isAsc, criteria }) {
    this.setState(
      {
        page: page || this.state.page,
        pageSize: pageSize || this.state.pageSize,
        sortBy: sortBy || this.state.sortBy,
        isAsc: isAsc === undefined ? true : isAsc,
        criteria: criteria || this.state.criteria,
      },
      this.fetchData
    );
  }

  render() {
    if (this.state.error) {
      return <Text color="red">{JSON.stringify(this.state.error)}</Text>;
    }

    if (this.state.isInitialized) {
      return (
        <ComplianceActionsDataGrid
          data={this.state.complianceActions}
          isLoading={this.state.isLoading}
          onRequestData={this.handleOnRequestData}
          page={this.state.page}
          pageSize={this.state.pageSize}
          total={this.state.total}
          sortBy={this.state.sortBy}
          isAsc={this.state.isAsc}
          criteria={this.state.criteria}
        />
      );
    }

    return <SpinnerPanel />;
  }
}
