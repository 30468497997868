import sum from 'lodash/sum';
import { jssColorLookup } from '../../util/jss';

/**
 * Returns the total number of pages based on the total number of records and page size.
 */
export const pageCount = (pageSize, total) => {
  return Math.ceil(total / pageSize);
};

/**
 * Returns true when there's a previous page to navigate to.
 */
export const hasPreviousPage = (page, pageSize, total) => {
  return page > 1;
};

/**
 * Returns true when there's a next page to navigate to.
 */
export const hasNextPage = (page, pageSize, total) => {
  return page < pageCount(pageSize, total);
};

/**
 * Returns the sum of all column widths.
 */
export const sumColumnWidths = columns => {
  return sum(columns.map(c => c.width));
};

/**
 * Returns cell border style.
 *
 * (eg.) <code>1px solid #efefef</code>
 */
export const cellBorderStyle = theme => {
  return `1px solid ${jssColorLookup(theme, 'primary', 'light2')}`;
};
