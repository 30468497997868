import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Column, Columns } from '../../../lib/base';
import Tab from './Tab';

/**
 * Renders an array id/name pairs as tabs.
 */
export default class Tabs extends Component {
  static propTypes = {
    /**
     * Tab configuration.
     */
    tabs: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        title: PropTypes.string,
        count: PropTypes.number,
      })
    ).isRequired,

    /**
     * Active tab.
     */
    active: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      title: PropTypes.string,
      count: PropTypes.number,
    }),

    /**
     * Function to call when tab is clicked.
     */
    onClick: PropTypes.func.isRequired,
  };

  render() {
    const { tabs, active } = this.props;

    return (
      <Columns inline>
        {tabs.map(t => (
          <Column flex={0} key={t.id}>
            <Tab
              id={t.id}
              title={t.title}
              name={t.name}
              active={t.id === active.id}
              visible={t.visible}
              onClick={() => this.props.onClick(t)}
            />
          </Column>
        ))}
      </Columns>
    );
  }
}
