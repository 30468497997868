import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { Box, Column, Columns, Text } from '../../../../../../../../lib/base';
import { IconChevronDown, IconChevronUp, IconSpinner } from '../../../../../../../components/icons';
import HeadingText from '../../../../../../../components/text/HeadingText';
import { COLOR_BASE_PRIMARY } from '../../../../../../../Constants';

class FilterGroup extends Component {
  static propTypes = {
    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,

    /**
     * Name of filter group.
     */
    name: PropTypes.string.isRequired,

    /**
     * Array of filters to display.
     */
    filters: PropTypes.array,

    /**
     * Selected filters.
     */
    selected: PropTypes.array.isRequired,

    /**
     * Is the app currently fetching the latest filter count?
     */
    isFilterCountFetching: PropTypes.bool.isRequired,

    /**
     * Called when a filter is selected.
     */
    onSelect: PropTypes.func.isRequired,

    /**
     * Called when to select all filters.
     */
    onSelectAll: PropTypes.func.isRequired,
  };

  static hasSelections(props) {
    const filters = props.filters.map(f => f.value);
    const selected = props.selected;
    return selected.some(selection => filters.includes(selection));
  }

  static isAllSelected(props) {
    return props.filters.length === props.selected.length;
  }

  constructor(props) {
    super(props);

    this.state = { collapsed: !FilterGroup.hasSelections(props) };

    this.handleOnClickTitle = this.handleOnClickTitle.bind(this);
    this.handleOnSelect = this.handleOnSelect.bind(this);
    this.handleOnSelectAll = this.handleOnSelectAll.bind(this);
  }

  handleOnSelectAll(event) {
    this.props.onSelectAll(this.props.filters.map(f => f.value), event.target.checked);
  }

  handleOnSelect(event, value) {
    this.props.onSelect(value, event.target.checked);
  }

  handleOnClickTitle() {
    this.setState({ collapsed: !this.state.collapsed });
  }

  isChecked(filterValue) {
    return this.props.selected.includes(filterValue);
  }

  renderHeader() {
    const totalCount = this.props.selected && this.props.selected.length;
    return (
      <Columns vAlign="center">
        <Column className={this.props.classes.colOne}>
          <input
            type="checkbox"
            checked={FilterGroup.isAllSelected(this.props)}
            onClick={e => e.stopPropagation()}
            onChange={e => this.handleOnSelectAll(e)}
          />
        </Column>
        <Column flex={1}>
          <HeadingText>{this.props.name}</HeadingText>
          {totalCount > 0 && (
            <Text
              size="smaller"
              bold
              caps
              color={COLOR_BASE_PRIMARY}
              colorVariant="dark5"
              className={this.props.classes.countHeader}
            >
              {totalCount}
            </Text>
          )}
        </Column>
        <Column className={this.props.classes.colThree}>
          {this.state.collapsed ? (
            <IconChevronUp size="1x" color={COLOR_BASE_PRIMARY} colorVariant="dark3" />
          ) : (
            <IconChevronDown size="1x" color={COLOR_BASE_PRIMARY} colorVariant="dark3" />
          )}
        </Column>
      </Columns>
    );
  }

  renderBody() {
    if (this.state.collapsed) {
      const selectedFilters = this.props.filters.filter(f => this.props.selected.includes(f.value));

      return selectedFilters.map(f => {
        return (
          <Box
            key={f.value}
            pad="small"
            pTop="small"
            pBottom="small"
            className={this.props.classes.row}
            bgColor={!this.state.collapsed ? COLOR_BASE_PRIMARY : 'white'}
            bgColorVariant={!this.state.collapsed ? 'light1' : undefined}
          >
            <label key={f.value} title={f.label}>
              {this.renderRow(this.isChecked(f.value), f.value, f.label, f.count)}
            </label>
          </Box>
        );
      });
    } else {
      return this.props.filters.map(f => {
        return (
          <Box
            pad="small"
            pTop="small"
            pBottom="small"
            key={f.value}
            className={this.props.classes.row}
            bgColor={!this.state.collapsed ? COLOR_BASE_PRIMARY : 'white'}
            bgColorVariant={!this.state.collapsed ? 'light1' : 'light1'}
          >
            <label key={f.value} title={f.label}>
              {this.renderRow(this.isChecked(f.value), f.value, f.label, f.count)}
            </label>
          </Box>
        );
      });
    }
  }

  renderRow(checked, value, label, count) {
    return (
      <Columns vAlign="top">
        <Column flex={0} className={this.props.classes.colOne}>
          <input
            type="checkbox"
            value={value}
            checked={checked}
            onChange={e => this.handleOnSelect(e, value)}
          />
        </Column>
        <Column flex={1}>
          <Box>
            <Text size="small" color={COLOR_BASE_PRIMARY} colorVariant="dark6">
              {label}
            </Text>
          </Box>
        </Column>
        <Column flex={0} className={this.props.classes.colThree}>
          {this.renderRowCountCol(count)}
        </Column>
      </Columns>
    );
  }

  renderRowCountCol(count) {
    if (this.props.isFilterCountFetching) {
      return <IconSpinner size="sm" />;
    } else {
      return (
        <Text size="smallest" className={this.props.classes.count}>
          {count.toLocaleString()}
        </Text>
      );
    }
  }

  render() {
    const { titleBox } = this.props.classes;

    return (
      <Box stack="smallest">
        <Box
          onClick={this.handleOnClickTitle}
          className={titleBox}
          pLeft="small"
          pRight="medium"
          pTop="medium"
          pBottom="medium"
          bgColor={!this.state.collapsed ? COLOR_BASE_PRIMARY : 'white'}
          bgColorVariant={!this.state.collapsed ? 'light2' : undefined}
        >
          {this.renderHeader()}
        </Box>
        {this.renderBody()}
      </Box>
    );
  }
}

const styles = theme => ({
  titleBox: {
    '&:hover': {
      backgroundColor: theme.color[COLOR_BASE_PRIMARY]['light2'],
    },
  },

  row: {
    '&:hover': {
      backgroundColor: theme.color[COLOR_BASE_PRIMARY]['light2'],
    },
  },

  count: {
    display: 'inline-block',
    padding: `${theme.spacing.smaller} ${theme.spacing.small}`,
    borderRadius: '12px',
    backgroundColor: theme.color[COLOR_BASE_PRIMARY]['light3'],
    color: theme.color[COLOR_BASE_PRIMARY]['dark6'],
  },

  countHeader: {
    extend: 'count',
    marginLeft: theme.spacing.small,
    backgroundColor: theme.color[COLOR_BASE_PRIMARY]['light3'],
    color: theme.color[COLOR_BASE_PRIMARY]['dark6'],
  },

  colOne: {
    minWidth: '25px',
    textAlign: 'left',
  },

  colThree: {
    minWidth: '50px',
    textAlign: 'right',
  },
});

export default injectSheet(styles)(FilterGroup);
