/**
 * Math.round setup for the conversion functions.
 */
const PRECISION = 2;
const FACTOR = Math.pow(10, PRECISION);

const round = number => {
  return Math.round(number * FACTOR) / FACTOR;
};

/**
 * Functions to convert meters squared to:
 *
 * 'miles', 'feet', 'kilometers', 'meters', 'acres'
 *
 */
const metersSquaredToSquareMiles = meters => round(meters * 0.0000003861);
const metersSquaredToSquareFoot = meters => round(meters * 10.76389379999997864);
const metersSquaredToSquareKilometer = meters => round(meters / 1000000);
const metersSquaredToAcres = meters => round(meters * 0.00024711);

/**
 * Functions to convert meters length to:
 *
 * 'miles', 'feet', 'kilometers', 'meters', 'acres'
 */
const metersToMiles = meters => round(meters * 0.00062137);
const metersToFeet = meters => round(meters * 3.2808);
const metersToKilometers = meters => round(meters / 1000);

/**
 * An object literal of units organized for type of measurement. Each unit has a label and convert
 * property used to convert from meters.
 */
export const units = {
  area: {
    mile: {
      convert: metersSquaredToSquareMiles,
      label: 'miles²',
    },
    feet: {
      convert: metersSquaredToSquareFoot,
      label: 'feet²',
    },
    meter: {
      convert: m => round(m),
      label: 'meters²',
    },
    kilometer: {
      convert: metersSquaredToSquareKilometer,
      label: 'kilometers²',
    },
    acre: {
      convert: metersSquaredToAcres,
      label: 'acres',
    },
  },
  length: {
    mile: {
      convert: metersToMiles,
      label: 'miles',
    },
    feet: {
      convert: metersToFeet,
      label: 'feet',
    },
    meter: {
      convert: m => round(m),
      label: 'meters',
    },
    kilometer: {
      convert: metersToKilometers,
      label: 'kilometers',
    },
  },
};

/**
 * Helper to covert a 'units' object literal to an array.
 */
const unitsToArray = units =>
  Object.keys(units).map(k => ({
    id: k,
    ...units[k],
  }));

/**
 * Returns an array of area type units.
 */
export const areaUnitsAsArray = () => unitsToArray(units.area);

/**
 * Returns an array of length type units.
 */
export const lengthUnitsAsArray = () => unitsToArray(units.length);
