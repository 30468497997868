import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import { Link, Text } from '../../../../lib/base';
import { downloadFileImproved } from '../../../util/downloadFile';
import { IconSpinner } from '../../icons';

export default class DocumentLink extends PureComponent {
  static propTypes = {
    /**
     * Children components.
     */
    document: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
    };
  }

  onClickLink = async () => {
    const { document } = this.props;
    return downloadFileImproved({
      url: document.url,
      fileName: document.docName,
      onFetchBegin: () => this.setState({ isFetching: true }),
      onFetchEnd: () => this.setState({ isFetching: false }),
      onFetchError: e =>
        window.alert(`Unloading to download the file ${document.docName}. ${e.message}`),
    });
  };

  render() {
    const { document } = this.props;

    return (
      <Link onClick={this.onClickLink} target="_blank">
        <Text size="smaller">{document.docName}</Text>
        {this.state.isFetching && (
          <Fragment>
            {' '}
            <IconSpinner size="sm" />
          </Fragment>
        )}
      </Link>
    );
  }
}
