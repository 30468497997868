import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { Text } from '../../../lib/base';
import { COLOR_BASE_PRIMARY, COLOR_VARIANT_PRIMARY } from '../../Constants';

class Tab extends Component {
  static propTypes = {
    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,

    /**
     * Tab id.
     */
    id: PropTypes.string.isRequired,

    /**
     * Tab name.
     */
    name: PropTypes.string.isRequired,

    /**
     * Tab title.
     */
    title: PropTypes.string.isRequired,

    /**
     * Active tab.
     */
    active: PropTypes.bool.isRequired,

    /**
     * Click handler.
     */
    onClick: PropTypes.func.isRequired,

    /**
     * Determine whether to show or hide tab
     */
    visible: PropTypes.bool,

    /**
     * Bold.
     */
    bold: PropTypes.bool,
  };

  render() {
    const { container, selected } = this.props.classes;

    return (
      <div
        className={[container, this.props.active && selected].join(' ')}
        onClick={this.props.onClick}
      >
        <Text
          title={this.props.title}
          block
          nowrap
          caps
          bold={this.props.bold}
          size="smaller"
          color={COLOR_BASE_PRIMARY}
          colorVariant="dark6"
          visible
        >
          {this.props.name}
        </Text>
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    padding: theme.spacing.inset.stretch.medium,
    borderBottom: `4px solid transparent`,
    textAlign: 'center',

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.color[COLOR_BASE_PRIMARY]['light2'],
      borderBottom: `4px solid ${theme.color[COLOR_BASE_PRIMARY]['dark2']}`,
    },
    display: props => (props.visible ? '' : 'none'),
  },

  selected: {
    borderBottom: `4px solid ${theme.color[COLOR_BASE_PRIMARY][COLOR_VARIANT_PRIMARY]}`,

    '&:hover': {
      borderBottom: `4px solid ${theme.color[COLOR_BASE_PRIMARY]['dark3']}`,
    },
  },
});

export default injectSheet(styles)(Tab);
