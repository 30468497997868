import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import { IconRuler } from '../../../../../../../components/icons';
import { navigateToMeasure, navigateToResults } from '../../../../../../../routing';
import ToolbarButton from './ToolbarButton';

class MeasureButton extends PureComponent {
  constructor(props) {
    super(props);
    this.handleOnClickMeasureButton = this.handleOnClickMeasureButton.bind(this);
  }

  handleOnClickMeasureButton() {
    if (this.isPressed()) {
      navigateToResults(this.props);
    } else {
      navigateToMeasure(this.props);
    }
  }

  isPressed() {
    const { params } = this.props.match;
    return params.panel === 'measure';
  }

  render() {
    return (
      <ToolbarButton
        onClick={this.handleOnClickMeasureButton}
        title="Measure area or length on the map"
        active={this.isPressed()}
        icon={<IconRuler />}
      />
    );
  }
}

export default withRouter(MeasureButton);
