import {
  CLEAR_SEARCH_GEOCODE,
  CLEAR_SEARCH_GEOCODE_LOCAL,
  FETCH_FILTERS_BEGIN,
  FETCH_FILTERS_ERROR,
  FETCH_FILTERS_SUCCESS,
  FETCH_GEOCODE_ADDRESS_BEGIN,
  FETCH_GEOCODE_ADDRESS_ERROR,
  FETCH_GEOCODE_ADDRESS_SUCCESS,
  FETCH_SEARCH_KEYS_BEGIN,
  FETCH_SEARCH_KEYS_ERROR,
  FETCH_SEARCH_KEYS_SUCCESS,
} from './search.actionType';

/**
 * Needs to be function in order to delay access. When the code intially loads window.nSITE.Api is
 * undefined.
 */
const api = () => window.nSITE.Api;

export function clearSearchGeocode() {
  return {
    type: CLEAR_SEARCH_GEOCODE,
  };
}

export function clearSearchGeocodeLocal() {
  return {
    type: CLEAR_SEARCH_GEOCODE_LOCAL
  };
}

/**
 * //////////////////////////////////////////////////////
 * ////
 * //// Fetch all search keys
 * ////
 * //////////////////////////////////////////////////////
 */
export function fetchSearchKeysIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetchSearchKeys(getState())) {
      return dispatch(fetchSearchKeys());
    }
    return undefined;
  };
}

function shouldFetchSearchKeys(state) {
  return !state.search.searchKeys.isFetching;
}

function fetchSearchKeys() {
  return dispatch => {
    dispatch(fetchSearchKeysBegin());
    api()
      .searchKey.getAll()
      .then(results => dispatch(fetchSearchKeysSuccess(results)))
      .catch(err => dispatch(fetchSearchKeysError(err.message)));
  };
}

function fetchSearchKeysBegin() {
  return {
    type: FETCH_SEARCH_KEYS_BEGIN,
  };
}

function fetchSearchKeysSuccess(results) {
  return {
    type: FETCH_SEARCH_KEYS_SUCCESS,
    payload: { results },
  };
}

function fetchSearchKeysError(message) {
  return {
    type: FETCH_SEARCH_KEYS_ERROR,
    payload: { error: message },
  };
}

/**
 * //////////////////////////////////////////////////////
 * ////
 * //// Fetch all filters
 * ////
 * //////////////////////////////////////////////////////
 */

export function fetchFiltersIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetchFilters(getState())) {
      return dispatch(fetchFilters());
    }
    return undefined;
  };
}

function shouldFetchFilters(state) {
  return !state.search.filters.isFetching;
}

function fetchFilters() {
  return dispatch => {
    dispatch(fetchFiltersBegin());
    api()
      .filter.getAll()
      .then(results => dispatch(fetchFiltersSuccess(results)))
      .catch(err => dispatch(fetchFiltersError(err.message)));
  };
}

function fetchFiltersBegin() {
  return {
    type: FETCH_FILTERS_BEGIN,
  };
}

function fetchFiltersSuccess(results) {
  return {
    type: FETCH_FILTERS_SUCCESS,
    payload: { results },
  };
}

function fetchFiltersError(message) {
  return {
    type: FETCH_FILTERS_ERROR,
    payload: { error: message },
  };
}

/**
 * //////////////////////////////////////////////////////
 * ////
 * //// Geocode Address
 * ////
 * //////////////////////////////////////////////////////
 */

export function geocodeAddressIfNeeded(address) {
  return (dispatch, getState) => {
    const state = getState();
    if (shouldSuggest(state)) {
      return dispatch(geocodeAddress(address, state.map.myLocation));
    }
    return undefined;
  };
}

function shouldSuggest(state) {
  return !state.search.geocode.isFetching;
}

function geocodeAddress(address, myLocation) {
  return dispatch => {
    dispatch(geocodeAddressBegin(address));

    api()
      .geocode.address(address, myLocation)
      .then(results => {
        dispatch(geocodeAddressSuccess(results));
      })
      .catch(err => {
        dispatch(geocodeAddressError(err.message));
      });
  };
}

function geocodeAddressBegin(address) {
  return {
    type: FETCH_GEOCODE_ADDRESS_BEGIN,
    payload: {
      address,
    },
  };
}

function geocodeAddressSuccess(results) {
  return {
    type: FETCH_GEOCODE_ADDRESS_SUCCESS,
    payload: {
      results,
    },
  };
}

function geocodeAddressError(message) {
  return {
    type: FETCH_GEOCODE_ADDRESS_ERROR,
    payload: {
      error: message,
    },
  };
}
