import React, { Component } from 'react';
import { Box, Text } from '../../../../../../../lib/base';
import connect from 'react-redux/es/connect/connect';

class InfoPanel extends Component {
  render() {
    return (
      <Box>
        <Text>{this.props.infoPanelContent}</Text>
      </Box>
    );
  }
}

function mapStateToProps(state) {
  return {
    infoPanelContent: state.map.infoPanelContent,
  };
}

export default connect(mapStateToProps)(InfoPanel);
