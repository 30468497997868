import debounce from "lodash/debounce";
import PropTypes from "prop-types";
import React, { Component } from "react";
import injectSheet from "react-jss";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Box, Column, Columns } from "../../../../../../../lib/base";
import {
  deselectSite,
  searchFilterCountIfNeeded,
  searchInputFocusChange,
  searchSitesIfNeeded,
  setMyLocationCoords,
  zoomToExtent
} from "../../../../../../../store/map/map.action";
import { geocodeAddressIfNeeded } from "../../../../../../../store/search/search.action";
import {
  IconClear,
  IconFilters,
  IconSearch,
  IconSpinner
} from "../../../../../../components/icons";
import {
  COLOR_BASE_PRIMARY,
  COLOR_ICON_ON_LIGHT,
  COLOR_VARIANT_ICON_ON_LIGHT,
  MEDIA_BREAKPOINT,
  SEARCH_DELAY_TIME_MS
} from "../../../../../../Constants";
import {
  navigateCloseSubPanel,
  navigateToCriteria,
  navigateToResults
} from "../../../../../../routing";
import SuggestSearchInput from "../../../../../../components/form/SuggestSearchInput";
import WKT from "ol/format/WKT";

const api = () => window.nSITE.Api;

class SearchInput extends Component {
  static propTypes = {
    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,

    /**
     * Connected search criteria.
     */
    criteria: PropTypes.object.isRequired,

    /**
     * Zoom to extent
     */
    dispatchZoomToExtent: PropTypes.func,
  };

  static defaultProps = {};

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextState = prevState;

    if (prevState.stateUpdate) {
      return {
        stateUpdate: false
      };
    }

    if (nextProps.isResetNeeded) {
      nextState.term = "";
    }

    return nextState;
  }

  constructor(props) {
    super(props);

    this.state = {
      term: props.criteria.term
    };

    /**
     * Create Refs
     */
    this.input = React.createRef();

    /**
     * Setup search function for debouncing.
     */
    this.debouncedDoSearch = debounce(this.doSearch, SEARCH_DELAY_TIME_MS);

    /**
     * Bind handlers.
     */
    this.handleOnFocusInput = this.handleOnFocusInput.bind(this);
    this.handleOnBlurInput = this.handleOnBlurInput.bind(this);
    this.handleOnChangeTerm = this.handleOnChangeTerm.bind(this);
    this.handleOnSelect = this.handleOnSelect.bind(this);
    this.handleOnPositionSuccess = this.handleOnPositionSuccess.bind(this);
    this.handleOnClickAdvancedSearch = this.handleOnClickAdvancedSearch.bind(this);
    this.handleOnClickSearchIcon = this.handleOnClickSearchIcon.bind(this);
    this.handleOnClickClear = this.handleOnClickClear.bind(this);
  }

  doSearch(term) {
    const criteria = Object.assign({}, this.props.criteria, { term });
    this.props.dispatchSearch(criteria);
    this.props.dispatchDeselectSite();
    navigateToResults(this.props);
  }

  handleOnChangeTerm(value) {
    this.setState({ term: value, stateUpdate: true }, () => {
      this.debouncedDoSearch(this.state.term);
    });
  }

  handleOnSelect(item) {
    let wkt = new WKT();
    let feat = wkt.readFeature(item.locationGeocode);
    let extent = feat
      .getGeometry()
      .transform("EPSG:4326", "EPSG:3857")
      .getExtent();
    this.props.dispatchZoomToExtent(extent);
  }

  handleOnBlurInput() {
    this.props.dispatchSearchInputFocusChange(false);
  }

  handleOnFocusInput(event) {
    this.props.dispatchSearchInputFocusChange(true);

    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => this.handleOnPositionSuccess(event, position),
        err => {
          console.log(err);
        },
        { enableHighAccuracy: true, timeout: 10000, maximumAge: 3600000 }
      );
    }
  }

  handleOnClickSearchIcon(event) {
    this.input.current.focus();
  }

  handleOnClickClear(event) {
    this.setState({ term: "", stateUpdate: true }, () => {
      this.doSearch("");
    });
  }

  isAdvancedCriteriaOpen() {
    const { params } = this.props.match;

    return params.subPanel === "criteria" || params.subPanel === "filters";
  }

  handleOnClickAdvancedSearch() {
    if (this.isAdvancedCriteriaOpen()) {
      navigateCloseSubPanel(this.props);
    } else {
      navigateToCriteria(this.props);
    }
  }

  handleOnPositionSuccess(event, position) {
    this.props.dispatchSetMyLocation([position.coords.longitude, position.coords.latitude]);
  }

  render() {
    const { container, input } = this.props.classes;

    return (
      <Box
        bgColor="white"
        borderColor={this.props.isSearchInputFocused ? COLOR_BASE_PRIMARY : "gray"}
        borderColorVariant={this.props.isSearchInputFocused ? "light3" : "light1"}
        borderWidth={2}
        borderSides={["bottom"]}
        className={container}
      >
        <Columns gutter="large" vAlign="center">
          <Column flex={1}>
            <SuggestSearchInput
              onRequestSuggestions={value => api().geocode.geocodeLocal(value)}
              onChange={event => this.handleOnChangeTerm(event)}
              onSelect={value => this.handleOnSelect(value)}
              onClear={event => this.handleOnRemove(event)}
              onBlur={this.handleOnBlurInput}
              onKeyPress={this.handleOnKeyPress}
              value={this.state.term}
              placeholderText="Search By Keyword"
              className={input}
            />
          </Column>

          {this.props.isFetching && (
            <Column flex={0}>
              <Box>
                <IconSpinner
                  color={COLOR_ICON_ON_LIGHT}
                  colorVariant={COLOR_VARIANT_ICON_ON_LIGHT}
                />
              </Box>
            </Column>
          )}

          <Column flex={0}>
            <Box onClick={this.handleOnClickAdvancedSearch}>
              <IconFilters color={COLOR_ICON_ON_LIGHT} colorVariant={COLOR_VARIANT_ICON_ON_LIGHT} />
            </Box>
          </Column>

          {!this.state.term && (
            <Column flex={0}>
              <Box onClick={this.handleOnClickSearchIcon}>
                <IconSearch
                  color={COLOR_ICON_ON_LIGHT}
                  colorVariant={COLOR_VARIANT_ICON_ON_LIGHT}
                />
              </Box>
            </Column>
          )}

          {this.state.term && (
            <Column flex={0}>
              <Box onClick={this.handleOnClickClear}>
                <IconClear color={COLOR_ICON_ON_LIGHT} colorVariant={COLOR_VARIANT_ICON_ON_LIGHT} />
              </Box>
            </Column>
          )}
        </Columns>
      </Box>
    );
  }
}

const styles = theme => ({
  container: {
    padding: theme.spacing.medium
  },

  input: {
    width: "100%",
    border: "none",
    fontSize: "20px",
    color: theme.color.gray.dark2,

    "&:focus": {
      outline: "none"
    },

    "&::placeholder": {
      color: theme.color.gray.light2
    }
  },

  [`@media ${MEDIA_BREAKPOINT}`]: {
    container: {
      border: "none"
    }
  }
});

function mapStateToProps(state) {
  return {
    criteria: state.map.search.criteria,
    isSearchInputFocused: state.map.isSearchInputFocused,
    isResetNeeded: state.map.search.isResetNeeded,
    isFetching: state.map.search.isFetching
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchSearchInputFocusChange: isFocused => {
      dispatch(searchInputFocusChange(isFocused));
    },
    dispatchZoomToExtent: extent => {
      dispatch(zoomToExtent(extent));
    },
    dispatchSearch: criteria => {
      dispatch(searchSitesIfNeeded(criteria));
      dispatch(searchFilterCountIfNeeded(criteria));
      dispatch(geocodeAddressIfNeeded(criteria.term));
    },
    dispatchDeselectSite: () => {
      dispatch(deselectSite());
    },
    dispatchSetMyLocation: lonlat => {
      dispatch(setMyLocationCoords(lonlat));
    }
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(injectSheet(styles)(SearchInput))
);
