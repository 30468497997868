import { boundingExtent, containsCoordinate } from 'ol/extent';
import Config from '../../app/config';
import { isDesktopScreen, isSmallScreen } from '../../app/util/browser';
import { toWebMercatorCenter } from '../../lib/map/core/transfomer';

export const isDefaultExtentMyLocation = () => {
  return (
    (Config.isZoomToLocationDesktop() && isDesktopScreen()) ||
    (Config.isZoomToLocationMobile() && isSmallScreen())
  );
};

export const getMyCurrentLocation = (successCallback, errorCallback) => {
  const positionOptions = {
    enableHighAccuracy: false,
    timeout: 10000,
    maximumAge: 3600000,
  };

  if (navigator && navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      position => {
        successCallback([position.coords.longitude, position.coords.latitude]);
      },
      positionError => {
        errorCallback && errorCallback(positionError);
      },
      positionOptions
    );
  } else {
    successCallback(undefined);
  }
};

export const getMyCurrentLocationExtent = (extent, coords) => {
  const current = toWebMercatorCenter(coords);
  const isWithinExtent = containsCoordinate(extent, current);
  if (isWithinExtent) {
    return boundingExtent([current, current]);
  }
  return extent;
};
