import BaseEndpoint from './BaseEndpoint';
import Urls from './Urls';

/**
 * Searchkey endpoint.
 */
export default class SearchKeyEndpoint extends BaseEndpoint {
  /**
   * Returns a promise resolving search key data.
   *
   * @returns {Promise<any>} search key data
   */
  getAll() {
    const url = `${super.url(Urls.SearchKey.All)}`;

    /**
     * TODO Can we cache the data?
     */
    return super
      .get(url)
      .then(response => response.json())
      .then(json => this.parseSearchKeyJson(json));
  }

  parseSearchKeyJson(searchKeys) {
    return searchKeys.map(sk => ({
      id: sk.searchKeyId,
      key: sk.operatorCode,
      name: sk.displayName,
    }));
  }
}
