import { View } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { colors } from '../../shared/colors';
import { Text } from '../../shared/typography';

const Root = styled(View)`
  position: absolute;
  left: 72pt;
  right: 72pt;
  bottom: 36pt;
  border-top: 1pt solid ${colors.HR};
  padding-top: 4pt;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 9pt;
`;

const MetadataRow = styled(View)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

const PageNumberRow = styled(View)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 8pt;
`;

const Separator = styled(Text)`
  opacity: 0.7;
  margin-left: 4pt;
  margin-right: 4pt;
`;

const PageNumber = () => (
  <Text
    fixed
    render={({ pageNumber, totalPages }) => {
      return `Page ${pageNumber} of ${totalPages}`;
    }}
  />
);

export const Footer = ({ meta }) => {
  return (
    <Root fixed>
      <MetadataRow>
        {meta.map((m, index) => {
          const isLast = index === meta.length - 1;

          return isLast ? (
            <Text key={index} bold={m.bold} italic={m.italic}>
              {m.text}
            </Text>
          ) : (
            <Fragment key={index}>
              <Text bold={m.bold} italic={m.italic}>
                {m.text}
              </Text>
              <Separator>{'\u007C'}</Separator>
            </Fragment>
          );
        })}
      </MetadataRow>
      <PageNumberRow>
        <PageNumber />
      </PageNumberRow>
    </Root>
  );
};

Footer.propTypes = {
  meta: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      italic: PropTypes.bool,
      bold: PropTypes.bool,
    })
  ),
};

Footer.defaultProps = {
  meta: [],
};
