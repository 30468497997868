import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from '../../../../lib/base';
import Config from '../../../config';
import { IconLinkExternal, IconPrint } from '../../icons/fontawesome/icons';

class ProfilePageLink extends Component {
  static propTypes = {
    /**
     * The source identifier to use in link.
     */
    sourceIdentifier: PropTypes.string.isRequired,

    /**
     * React router.
     */
    match: PropTypes.object.isRequired,

    /**
     * Append to print parameter.
     */
    print: PropTypes.bool,
  };

  render() {
    const { print, sourceIdentifier } = this.props;
    const { params } = this.props.match;

    let to;

    if (print) {
      to = `/site/${sourceIdentifier}/print`;
    } else {
      if (params.tab) {
        to = `/site/${sourceIdentifier}/${params.tab}`;
      } else {
        to = `/site/${sourceIdentifier}/`;
      }
    }

    const url = `${Config.getApplicationBaseUrl()}${to}`;

    const title = this.props.print ? 'Print profile page' : 'Open profile page in a new window';

    return (
      <Link href={url} target="_blank" title={title}>
        {this.props.print ? <IconPrint /> : <IconLinkExternal />}
      </Link>
    );
  }
}

export default withRouter(ProfilePageLink);
