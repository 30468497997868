import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Text } from '../../../lib/base';
import injectSheet from 'react-jss';
import Box from '../../../lib/base/layout/Box';
import { COLOR_BASE_PRIMARY, COLOR_VARIANT_PRIMARY } from '../../../app/Constants';

/**
 * Pre-styled text component for headings.
 */
class HeadingText extends Component {
  static propTypes = {
    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,

    /**
     * Children text node.
     */
    children: PropTypes.node,

    /**
     * Hover title
     */
    title: PropTypes.string,

    /**
     * Text size.
     */
    size: PropTypes.string,

    /**
     * Icon.
     */
    icon: PropTypes.node,

    /**
     * Capitalize
     */
    caps: PropTypes.bool,

    /**
     * Underline heading
     */
    underline: PropTypes.bool,

    /**
     * Active
     */
    active: PropTypes.bool,
  };

  static defaultProps = {
    title: undefined,
    caps: false,
  };

  render() {
    if (!this.props.children) {
      return null;
    }

    let Icon;

    if (this.props.icon) {
      Icon = React.cloneElement(this.props.icon, {
        color: 'blue',
      });
    }

    const { selected } = this.props.classes;

    return (
      <Text
        size={this.props.size || 'small'}
        bold
        className={this.props.active ? selected : undefined}
        caps={this.props.caps}
        color={COLOR_BASE_PRIMARY}
        colorVariant="dark5"
        title={this.props.title}
      >
        {this.props.icon !== undefined && (
          <Box inline mRight="smaller">
            {Icon}
          </Box>
        )}
        {this.props.children}
      </Text>
    );
  }
}

const styles = theme => ({
  selected: {
    borderBottom: `4px solid ${theme.color[COLOR_BASE_PRIMARY][COLOR_VARIANT_PRIMARY]}`,

    '&:hover': {
      borderBottom: `4px solid ${theme.color[COLOR_BASE_PRIMARY]['dark3']}`,
    },
  },

  '&:hover': {
    borderBottom: `4px solid ${theme.color[COLOR_BASE_PRIMARY][COLOR_VARIANT_PRIMARY]}`,
    transition: 'all 350ms',
  },
});

export default injectSheet(styles)(HeadingText);
