import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import React, { Component } from 'react';
import { Box } from '../../../../lib/base';
import Label from '../../lv/Label';
import Value from '../../lv/Value';
import Columns from '../../../../lib/base/layout/flex/Columns';
import Column from '../../../../lib/base/layout/flex/Column';
import None from '../../panel/None';

export default class IndustryCodes extends Component {
  static propTypes = {
    /**
     * SIC Codes
     */
    sicCodes: PropTypes.arrayOf(
      PropTypes.shape({
        sic: PropTypes.string,
        sicDesc: PropTypes.string,
      })
    ),

    /**
     * NAICS Codes
     */
    naicsCodes: PropTypes.arrayOf(
      PropTypes.shape({
        naics: PropTypes.string,
        naicsDesc: PropTypes.string,
      })
    ),
  };

  static defaultProps = {
    sicCodes: [],
    naicsCodes: [],
  };

  render() {
    const { sicCodes, naicsCodes } = this.props;

    if ((!sicCodes || sicCodes.length === 0) && (!naicsCodes || naicsCodes.length === 0)) {
      return (
        <Box stack="medium">
          <None />
        </Box>
      );
    }

    return (
      <React.Fragment>
        {!isEmpty(sicCodes) && (
          <Box stack="large">
            <Box stack="smaller">
              <Label label="SIC" />
            </Box>
            <Box>
              {sicCodes.map((c, index) => (
                <Box key={index} mBottom="smaller">
                  <Columns>
                    <Column style={{ width: '58px' }}>
                      <Value value={c.sic} />
                    </Column>
                    <Column flex={1}>
                      <Value value={c.sicDesc} />
                    </Column>
                  </Columns>
                </Box>
              ))}
            </Box>
          </Box>
        )}
        {!isEmpty(naicsCodes) && (
          <Box stack="large">
            <Box stack="smaller">
              <Label label="NAICS" />
            </Box>
            <Box>
              {naicsCodes.map((c, index) => (
                <Box key={index} mBottom="smaller">
                  <Columns>
                    <Column style={{ width: '58px' }}>
                      <Value value={c.naics} />
                    </Column>
                    <Column flex={1}>
                      <Value value={c.naicsDesc} />
                    </Column>
                  </Columns>
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </React.Fragment>
    );
  }
}
