import {
  DEFAULT_MAP_PADDING,
  LEFT_EXTENT_BUFFER_PX,
  SEARCH_RESULT_PANEL_MARGIN_PX,
  SEARCH_RESULT_PANEL_WIDTH_PX,
} from '../Constants';
import { isSmallScreen } from './browser';

/**
 *  Calculate the position of where to center the map when the intermediate panel is open.
 */
export const centerPositionWhenPanelIsOpen = () => {
  /**
   * No need to adjust when in small screen.
   */
  if (isSmallScreen()) return undefined;

  const screenWidth = document.documentElement.clientWidth;
  const leftOffsetWhenOpen = SEARCH_RESULT_PANEL_WIDTH_PX * 2 + SEARCH_RESULT_PANEL_MARGIN_PX * 2;
  const x = (screenWidth + leftOffsetWhenOpen) / 2;
  const y = document.documentElement.clientHeight / 2;
  return [x, y];
};

/**
 * Calculate map padding based on screen size - add extra left padding for large screens.
 */
export const mapPadding = () =>
  isSmallScreen() ? DEFAULT_MAP_PADDING : [20, 20, 20, LEFT_EXTENT_BUFFER_PX];

/**
 * Calculates the extent of the map to search with depending on screen size.
 */
export const calculateSearchExtent = map => {
  const extent = map.getView().calculateExtent(map.getSize());
  const resolution = map.getView().getResolution();

  /**
   * Only apply left padding to extent on desktop mode.
   */
  if (!isSmallScreen()) {
    const adjustment =
      resolution * SEARCH_RESULT_PANEL_WIDTH_PX + SEARCH_RESULT_PANEL_MARGIN_PX * 2;
    extent[0] = extent[0] < 0 ? extent[0] + adjustment : extent[0] - adjustment;
  } else {
    /**
     * adjust extent for bottom header and top search bar.
     *
     * TODO revisit padding of small devices.
     */
    const ADJUSTMENT_FOR_BOTH = resolution * 10;
    extent[1] = extent[1] > 0 ? extent[1] + ADJUSTMENT_FOR_BOTH : extent[1] - ADJUSTMENT_FOR_BOTH;
    extent[3] = extent[3] > 0 ? extent[3] - ADJUSTMENT_FOR_BOTH : extent[3] + ADJUSTMENT_FOR_BOTH;
  }

  return extent;
};
