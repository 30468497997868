import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import { IconMapLayers } from '../../../../../../../components/icons';
import { navigateToLayers, navigateToResults } from '../../../../../../../routing';
import ToolbarButton from './ToolbarButton';

class MapLayersButton extends PureComponent {
  static propTypes = {
    /**
     * Circular button?
     */
    circular: PropTypes.bool,
  };

  static defaultProps = {
    circular: false,
  };

  constructor(props) {
    super(props);
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  handleOnClick() {
    if (this.isPressed()) {
      navigateToResults(this.props);
    } else {
      navigateToLayers(this.props);
    }
  }

  isPressed() {
    const { params } = this.props.match;
    return params.panel === 'layers';
  }

  render() {
    return (
      <ToolbarButton
        onClick={this.handleOnClick}
        active={this.isPressed()}
        circular={this.props.circular}
        title="Change the layers you see on the map."
        icon={<IconMapLayers />}
      />
    );
  }
}

export default withRouter(MapLayersButton);
