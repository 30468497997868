import PropTypes from 'prop-types';
import { Component } from 'react';
import { labelCapitalized } from '../../util/label';

export default class ResourceLabel extends Component {
  static propTypes = {
    /**
     * Key used to look up the label value.
     */
    resourceKey: PropTypes.string.isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);

    this.state = {
      value: labelCapitalized(props.resourceKey),
    };
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    return this.state.value;
  }
}
