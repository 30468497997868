/**
 * Downloads a zipped collection of files from submitted doc ids
 * docs should be in the format { id: int, loadDocPdfContent: bool }
 */
import Config from '../../../config';
import { downloadFile } from '../../../util/downloadFile';

const urlArchiveId = () => {
  return `${Config.getNCoreBaseUrl()}/ss/documentcontentarchives`;
};

const urlArchive = archiveId => {
  return `${Config.getNCoreBaseUrl()}/DownloadDocumentArchive/${archiveId}`;
};

const fetchDocumentArchiveId = async (url, docs) => {
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    credentials: 'same-origin',
    body: JSON.stringify(docs),
  });
  const json = await response.json();

  if (!json.queryResults) {
    console.error('Server failed while creating the archive.', docs);
    throw Error('Server failed while creating the archive.');
  }

  return json.queryResults[0].archiveId;
};

export async function downloadViaWSL(docs) {
  try {
    const url = urlArchiveId();
    const archiveId = await fetchDocumentArchiveId(url, docs);
    const archiveUrl = urlArchive(archiveId);
    return downloadFile(archiveUrl, 'documents.zip');
  } catch (e) {
    window.alert('Unable to download document archive. ' + e.message);
    console.error(e);
    return false;
  }
}
