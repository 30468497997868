import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setCenterOnPosition } from '../../../../../../store/map/map.action';
import { loadProfileDetailIfNeeded } from '../../../../../../store/profile/profile.action';
import WindowDocumentTitle from '../../../../../components/dom/WindowDocumentTitle';
import SpinnerPanel from '../../../../../components/spinner/SpinnerPanel';
import { getSiteName } from '../../../../../util/entities/siteProfile';
import SlideOutSiteProfile from './profile/SlideOutSiteProfile';
import SlideOutContainer from './SlideOutContainer';

class SlideOutContainerSiteProfile extends Component {
  static propTypes = {
    /**
     * The css 'top' position.
     */
    top: PropTypes.number.isRequired,

    /**
     * The css 'width' position.
     */
    width: PropTypes.number.isRequired,

    /**
     * The height of the container div.
     */
    height: PropTypes.number.isRequired,

    /**
     * slide out width
     */
    slideOutWidth: PropTypes.number,

    /**
     * Z-Index.
     */
    zIndex: PropTypes.number.isRequired,
  };

  componentDidMount() {
    const { params } = this.props.match;
    if (params.id) {
      this.props.dispatchFetchProfile(params.id);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { params: prevParams } = prevProps.match;
    const { params } = this.props.match;

    if (prevParams.id !== params.id && params.id !== undefined) {
      this.props.dispatchFetchProfile(params.id);
    }
  }

  isShowSummary() {
    const { params } = this.props.match;
    return params.subPanel === 'summary';
  }

  isShowDetail() {
    const { params } = this.props.match;
    return params.subPanel === 'detail';
  }

  renderContents() {
    const { isLoading, error, siteProfile } = this.props;

    if (isLoading) {
      return <SpinnerPanel />;
    }

    if (siteProfile) {
      return (
        <Fragment>
          <SlideOutSiteProfile
            siteProfile={siteProfile}
            showSummary={this.isShowSummary()}
            showDetail={this.isShowDetail()}
          />
          {(this.isShowSummary() || this.isShowDetail()) && (
            <WindowDocumentTitle title={getSiteName(siteProfile)} removeOnUnmount />
          )}
        </Fragment>
      );
    }

    if (error) {
      console.log('error', error);
    }

    return <div>Site not found.</div>;
  }

  render() {
    return (
      <SlideOutContainer
        zIndex={this.props.zIndex}
        left={this.props.width}
        isOpen={this.isShowSummary()}
        isOpenFull={this.isShowDetail()}
        width={this.props.width}
        slideOutWidth={this.props.slideOutWidth}
        height={this.props.height}
        top={this.props.top}
        onWindowResize={this.props.dispatchCalculateCenterOnPosition}
      >
        {this.renderContents()}
      </SlideOutContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoading: state.profile.detail.isLoading,
    error: state.profile.detail.error,
    siteProfile: state.profile.detail.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchFetchProfile: id => {
      dispatch(loadProfileDetailIfNeeded(id));
    },
    dispatchCalculateCenterOnPosition: () => {
      dispatch(setCenterOnPosition());
    },
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SlideOutContainerSiteProfile)
);
