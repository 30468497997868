import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchGenericIfNeeded } from '../../../store/profile/profile.action';

class GenericContainer extends Component {
  static propTypes = {
    /**
     * Generic Tab.
     */
    sourceIdentifier: PropTypes.string,
  };

  static defaultProps = {};

  componentDidMount() {
    this.props.dispatchLoadGenericData(this.props.sourceIdentifier);
  }

  render() {
    /**
     * The data is coming through but until we have a use case for this generic tab I'm going to leave it here.
     */

    return <div>{/* this.props.generic */}</div>;
  }
}

function mapStateToProps(state) {
  return {
    isLoading: state.profile.generic.isLoading,
    error: state.profile.generic.error,
    generic: state.profile.generic.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchLoadGenericData: sourceIdentifier => {
      dispatch(fetchGenericIfNeeded(sourceIdentifier));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenericContainer);
