/**
 * A dto object for site search criteria
 */
export default class SiteSearchCriteria {
  constructor(criteria = {}) {
    this.advanced = criteria.advanced || {};
    this.filters = criteria.filters || [];
    this.boundingBox = criteria.bbox;
    this.type = criteria.type || 'search';
    this.term = criteria.term || '';
    this.discardLocationlessFeatures =
      criteria.discardLocationlessFeatures === undefined
        ? true
        : criteria.discardLocationlessFeatures;
    this.screenWidth = document.documentElement.clientWidth;
    this.screenHeight = document.documentElement.clientHeight;
    this.takeWithoutClustering = criteria.takeWithoutClustering || null;
    this.zoom = criteria.zoom || null;
  }
}
