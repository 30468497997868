/**
 * Config keys all in one place.
 */
const ConfigKeys = {
  APPLICATION_ICON: 'applicationIcon',
  APPLICATION_INITIALS: 'applicationInitials',
  APPLICATION_NAME: 'applicationName',
  APPLICATION_BASE_URL: 'applicationBaseUrl',
  BING_API_KEY: 'bingApiKey',
  BUILD_BRANCH: 'buildBranch',
  BUILD_REVISION: 'buildRevision',
  BUILD_TIMESTAMP: 'buildTimestamp',
  CONDITIONALLY_HIDE_ORIG_SYS_ID: 'conditionallyHideOrigSysId',
  EXTENT: 'extent',
  GOOGLE_ANALYTICS_KEY: 'googleAnalyticsKey',
  IE_WARNING: 'ie11Warning',
  INITIAL_GEOMETRY: 'initialGeometry',
  MAX_ZOOM: 'maxZoom',
  MIN_ZOOM: 'minZoom',
  NCORE_APPLICATION_NAME: 'ncoreAppName',
  NCORE_BASE_URL: 'ncoreBaseUrl',
  SHOW_DOCUMENT_COUNT: 'showDocumentCount',
  SITE_CLUSTER_FILL_COLOR: 'siteClusterFillColor',
  SITE_CLUSTER_STROKE_COLOR: 'siteClusterStrokeColor',
  START_PANEL: 'startPanel',
  SUMMARIZE_EIS: 'summarizeEIs',
  ZOOM_TO_LOCATION_DESKTOP: 'zoomToLocDesktop',
  ZOOM_TO_LOCATION_MOBILE: 'zoomToLocMobile',
  HEADER_COLOR_BACKGROUND: 'header.color.background',
  HEADER_COLOR_TEXT: 'header.color.text',
  HEADER_NAVIGATION: 'header.navigation',
  META_AUTHOR: 'metaAuthor',
  META_DESCRIPTION: 'metaDescription',
  META_KEYWORDS: 'metaKeywords',
  META_ROBOT_CONTENT: 'metaRobotContent',
  RECAPTCHA_SITE_KEY: 'recaptchaSiteKey',
  LABEL_COMPLIANCE_EVALUATIONS: 'labels.compliance_evaluations',
  LABEL_COMPLIANCE_VIOLATIONS: 'labels.compliance_violations',
  LABEL_COMPLIANCE_ACTIONS: 'labels.compliance_actions',
  LABEL_ENVIRONMENTAL_INTERESTS: 'labels.environmental_interests',
  LABEL_AFFILIATES: 'labels.affiliates',
  LABEL_COMPLIANCE_HAS_VIOLATIONS: 'labels.compliance_has_violations',
  LABEL_COMPLIANCE_NO_VIOLATIONS: 'labels.compliance_no_violations',
  LABEL_VIOLATIONS_OPEN: 'labels.violations_open',
  LABEL_VIOLATIONS_CLOSED: 'labels.violations_closed',
  LABEL_COMPLIANCE_ACTIONS_FORMAL: 'labels.compliance_formal',
  LABEL_COMPLIANCE_ACTIONS_INFORMAL: 'labels.compliance_informal',
  LABEL_DOCUMENTS: 'labels.documents',
  LABEL_TAB_PROFILE: 'labels.profile_tab',
  LABEL_TAB_MAP: 'labels.map_tab',
  LABEL_TAB_COMPLIANCE: 'labels.compliance_tab',
  LABEL_TAB_DOCUMENTS: 'labels.document_tab',
  LABEL_TAB_CHEMICALS: 'labels.chemical_tab',
  LABEL_TAB_EI: 'labels.ei_tab',
  LABEL_TAB_GENERIC: 'labels.generic_tab',

  /**
   * These keys are only used on the server side.
   *
   * See: DbAppSettingKey.cs
   */
  DASHBOARD_STATISTICS: 'dashboardStatistics',
  HELP_TEXT: 'helpText',
  ABOUT_TEXT_LEFT: 'aboutTextLeft',
  ABOUT_TEXT_CENTER: 'aboutTextCenter',
  DISCLAIMER_TEXT: 'disclaimerText',
  GEOCODE_STATE_FILTER: 'geocodeStateFilter',
  GEOCODE_STRATEGY: 'geocoder',
};

export default ConfigKeys;
