import { PDFViewer } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { loadProfileDetailIfNeeded } from '../../../store/profile/profile.action';
import { startMapSystemIfNeeded } from '../../../store/system/system.action';
import PageLoading from '../../components/loading/PageLoading';
import Config from '../../config';
import ReportMap from '../../pdf/map/ReportMap';
import SiteProfileDocument from './components/SiteProfileDocument';

class SiteProfilePDFPage extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired,
    dispatchLoadMapSettings: PropTypes.func.isRequired,
    dispatchFetchProfile: PropTypes.func.isRequired,
    isStarting: PropTypes.bool,
    message: PropTypes.string,
    siteProfile: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      data: undefined,
      error: undefined,
      isLoading: true,
    };
  }

  componentDidMount() {
    const { params } = this.props.match;
    this.props.dispatchLoadMapSettings();
    this.props.dispatchFetchProfile(params.id);
  }

  handleOnMapReady = dataUrl => {
    this.setState({ mapDataUrl: dataUrl });
  };

  handleOnLogoReady = event => {
    const image = event.target;
    this.setState({
      logoUrl: image.src,
      logoHeight: image.height,
      logoWidth: image.width,
    });
  };

  render() {
    const { isStarting, message, siteProfile } = this.props;

    if (isStarting) {
      return <PageLoading message={message} />;
    }

    if (siteProfile === undefined) {
      return <div>Site not found.</div>;
    }

    const hasMapBlob = this.state.mapDataUrl !== undefined;

    const hasHeaderLogo =
      [this.state.logoUrl, this.state.logoHeight, this.state.logoWidth].indexOf(undefined) === -1;

    const mapWidth = 400;
    const mapHeight = 300;

    return (
      <div style={{ height: '100%', overflow: 'hidden' }}>
        {(!hasMapBlob || !hasHeaderLogo) && (
          <Fragment>
            <PageLoading message="Generating site detail report." />
            <div
              style={{ position: 'absolute', left: -mapWidth, height: mapHeight, width: mapWidth }}
            >
              <ReportMap
                width={mapWidth}
                height={mapHeight}
                siteProfile={siteProfile}
                onMapReady={this.handleOnMapReady}
              />
            </div>
            <div style={{ position: 'absolute', left: '100%', height: '100vh', width: '100vw' }}>
              <img src={Config.getReportHeaderImageUrl()} onLoad={this.handleOnLogoReady} alt="" />
            </div>
          </Fragment>
        )}

        {hasMapBlob && hasHeaderLogo && (
          <PDFViewer width="100%" height="100%">
            <SiteProfileDocument
              siteProfile={siteProfile}
              logoUrl={this.state.logoUrl}
              logoWidth={this.state.logoWidth}
              logoHeight={this.state.logoHeight}
              mapDataUrl={this.state.mapDataUrl}
              mapWidth={mapWidth / 2}
              mapHeight={mapHeight / 2}
            />
          </PDFViewer>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const isMapStarting = state.map.startup.isStarting || state.map.startup.isStarting === undefined;

  return {
    isStarting: isMapStarting || state.profile.detail.isLoading,
    siteProfile: state.profile.detail.data,
    message: state.map.startup.message,
    error: state.map.startup.error,
    minResolution: state.map.minResolution,
    maxResolution: state.map.maxResolution,
    activeBaseLayer: state.map.activeBaseLayer,
    activeReferenceLayers: state.map.activeReferenceLayers,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchLoadMapSettings: () => {
      dispatch(startMapSystemIfNeeded({ askForPosition: false }));
    },
    dispatchFetchProfile: id => {
      dispatch(loadProfileDetailIfNeeded(id));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SiteProfilePDFPage);
