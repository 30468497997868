import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { COLOR_BASE_PRIMARY } from '../../../app/Constants';
import { Overlay } from '../index';
import Box from '../layout/Box';
import Column from '../layout/flex/Column';
import Columns from '../layout/flex/Columns';
import Row from '../layout/flex/Row';
import Rows from '../layout/flex/Rows';
import Link from '../text/Link';
import Text from '../text/Text';
import FadeInOut from '../transition/FadeInOut';
import { isSmallScreen } from '../../../app/util/browser';
import { propTypeColor } from '../util/propTypes';

class Modal extends Component {
  static propTypes = {
    children: PropTypes.node,

    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,

    isOpen: PropTypes.bool.isRequired,

    onRequestClose: PropTypes.func,

    zIndex: PropTypes.number,

    title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),

    bgColor: propTypeColor,
  };

  static defaultProps = {
    zIndex: 999,
    title: undefined,
    bgColor: COLOR_BASE_PRIMARY,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextState = prevState;

    if (prevState.isOpen !== nextProps.isOpen) {
      nextState.isOpen = nextProps.isOpen;
    }

    return nextState;
  }

  constructor(props) {
    super(props);

    this.state = {
      isOpen: props.isOpen || false,
    };

    this.handleOnClose = this.handleOnClose.bind(this);
  }

  handleOnClose() {
    if (this.props.onRequestClose) {
      this.props.onRequestClose();
    } else {
      this.setState({ isOpen: false });
    }
  }

  renderTitle() {
    if (isFunction(this.props.title)) {
      return this.props.title();
    } else if (isString(this.props.title)) {
      return (
        <Text caps bold>
          {this.props.title}
        </Text>
      );
    }
    return null;
  }

  render() {
    return (
      <React.Fragment>
        <Overlay on={this.state.isOpen} onClick={this.handleOnClose} zIndex={this.props.zIndex} />
        <FadeInOut on={this.state.isOpen} zIndex={this.props.zIndex}>
          <div
            className={
              isSmallScreen() ? this.props.classes.container : this.props.classes.desktopContainer
            }
          >
            <Rows stretch>
              <Row flex={0}>
                <Box bgColor={this.props.bgColor} bgColorVariant="light1" pad="medium">
                  <Columns vAlign="center" gutter="medium">
                    <Column flex={1}>{this.renderTitle()}</Column>
                    <Column flex={0}>
                      <Link size="smaller" caps bold onClick={this.handleOnClose}>
                        Done
                      </Link>
                    </Column>
                  </Columns>
                </Box>
              </Row>
              <Row flex={1} style={{ overflowY: 'auto' }}>
                {this.props.children}
              </Row>
            </Rows>
          </div>
        </FadeInOut>
      </React.Fragment>
    );
  }
}

const styles = () => ({
  container: {
    backgroundColor: 'white',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: props => props.zIndex,
  },
  desktopContainer: {
    backgroundColor: 'white',
    position: 'Fixed',
    left: '50%',
    top: '50%',
    width: 500,
    marginLeft: -50,
  },
});

export default injectSheet(styles)(Modal);
