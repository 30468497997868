import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { withRouter } from 'react-router-dom';
import { animated, config, Transition } from 'react-spring/renderprops';
import { Overlay, Row, Rows } from '../../../lib/base';
import Hr from '../../../lib/base/basic/Hr';
import Column from '../../../lib/base/layout/flex/Column';
import Columns from '../../../lib/base/layout/flex/Columns';
import Config from '../../config';
import { COLOR_BASE_PRIMARY, Z_INDEX_HEADER_MENU } from '../../Constants';
import HelpButton from '../../page/map/components/shared/panel/toolbar/buttons/HelpButton';
import ToolbarButton from '../../page/map/components/shared/panel/toolbar/buttons/ToolbarButton';
import { IconElipsisV } from '../icons';
import { createNavLink } from './createNavLink';

class ApplicationMenu extends Component {
  static propTypes = {
    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);

    this.state = {
      isMenuOpen: false,
    };

    this.handleOnClickMenu = this.handleOnClickMenu.bind(this);
    this.handleOnCloseMenu = this.handleOnCloseMenu.bind(this);
  }

  /**
   * Toggle the hamburger menu.
   */
  handleOnClickMenu() {
    this.setState({ isMenuOpen: !this.state.isMenuOpen });
  }

  handleOnCloseMenu() {
    this.setState({ isMenuOpen: false });
  }

  renderMenuItem(icon, link) {
    return (
      <Columns vAlign="center" className={this.props.classes.menuItemColumns}>
        <Column className={this.props.classes.iconColumn} flex={0}>
          {icon}
        </Column>
        <Column flex={1}>{link}</Column>
      </Columns>
    );
  }

  renderHelpButton() {
    return this.renderMenuItem(
      <HelpButton inverse onClick={this.handleOnCloseMenu} />,
      <HelpButton textOnly onClick={this.handleOnCloseMenu} />
    );
  }

  render() {
    const { links, link } = this.props.classes;

    return (
      <React.Fragment>
        <ToolbarButton
          onClick={this.handleOnClickMenu}
          title="Site menu"
          active={false}
          icon={<IconElipsisV />}
        />

        <Overlay
          on={this.state.isMenuOpen}
          onClick={this.handleOnClickMenu}
          zIndex={Z_INDEX_HEADER_MENU}
        />

        <div>
          <Transition
            native
            items={this.state.isMenuOpen}
            from={{ height: 0 }}
            enter={{ height: 'auto' }}
            leave={{ height: 0 }}
            config={{ ...config.stiff, clamp: true }}
          >
            {on =>
              on &&
              (springStyles => (
                <animated.div className={links} style={springStyles}>
                  <Rows>
                    <Row flex={0}>{this.renderHelpButton()}</Row>
                    <Hr color={COLOR_BASE_PRIMARY} colorVariant="light1" />
                    {Config.getHeaderNavigation().map((nav, index) => (
                      <Row flex={0} key={index}>
                        {this.renderMenuItem(null, createNavLink(this.props.history, nav, link))}
                      </Row>
                    ))}
                  </Rows>
                </animated.div>
              ))
            }
          </Transition>
        </div>
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  menuItemColumns: {
    minHeight: '48px',
  },

  links: {
    position: 'absolute',
    minWidth: '200px',
    ...theme.elevation.shadow3,
    zIndex: Z_INDEX_HEADER_MENU,
    top: 10,
    right: 10,
    backgroundColor: theme.color.base.white,
    textAlign: 'left',
    maxHeight: '300px',
    overflowY: 'auto',
  },

  link: {
    ...theme.typography.small,
    paddingTop: theme.spacing.small,
    paddingBottom: theme.spacing.small,
    margin: theme.spacing.smaller,
    color: theme.color.base.blue,

    '&:active, &:visited': {
      textDecoration: 'none',
      color: theme.color.base.blue,
    },

    '&:hover': {
      color: theme.color.base.blue,
    },

    '&:focus': {
      outlineColor: theme.color.base.blue,
    },
  },

  iconColumn: {
    minWidth: '48px',
  },
});

export default withRouter(injectSheet(styles)(ApplicationMenu));
