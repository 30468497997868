import Circle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Icon from 'ol/style/Icon';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';
import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import {
  CLUSTER_COLOR_HOVER,
  MARKER_SELECTED_COLOR,
  MARKER_SELECTED_SIZE,
  MARKER_UN_SELECTED_SIZE,
} from '../Constants';
import Pin from '../page/map/components/shared/layer/marker/Pin';
import { isCluster } from './feature';
import { isInternetExplorer } from './browser';

export const newStyleSelected = (feature, fillColor, strokeColor) => {
  return style(feature, false, true, fillColor, strokeColor);
};

export const newStyleNotSelected = (feature, fillColor, strokeColor) => {
  return style(feature, false, false, fillColor, strokeColor);
};

export const newStyleHovered = (feature, fillColor, strokeColor) => {
  return style(feature, true, false, fillColor, strokeColor);
};

const style = (feature, isHovered, isSelected, fillColor, strokeColor) => {
  const isClusterFeature = isCluster(feature);
  const label = isClusterFeature ? feature.get('count').toLocaleString() : '';

  return new Style({
    image: image(feature, isHovered, isSelected, fillColor, strokeColor),
    text: new Text({
      text: label,
      font: '12px Calibri,sans-serif',
      fill: new Fill({
        color: strokeColor,
      }),
    }),
  });
};

const image = (feature, isHovered, isSelected, fillColor, strokeColor) => {
  let featureColor;

  if (isHovered) {
    featureColor = CLUSTER_COLOR_HOVER;
  } else if (isSelected) {
    featureColor = MARKER_SELECTED_COLOR;
  } else {
    featureColor = fillColor;
  }

  /**
   * Color circle when a cluster else use an svg image.
   */
  if (isCluster(feature)) {
    return new Circle({
      radius: isHovered ? feature.get('size') + 2 : feature.get('size'),
      stroke: new Stroke({
        color: strokeColor,
      }),
      fill: new Fill({
        color: featureColor,
      }),
    });
  } else {
    const svg = renderToStaticMarkup(
      <Pin
        color={featureColor}
        size={isSelected ? MARKER_SELECTED_SIZE : MARKER_UN_SELECTED_SIZE}
      />
    );

    let src = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svg);

    if (isInternetExplorer()) {
      src = 'http://maps.google.com/mapfiles/ms/icons/blue.png';
    }

    return new Icon({
      src: src,
      imgSize: isSelected
        ? [MARKER_SELECTED_SIZE, MARKER_SELECTED_SIZE]
        : [MARKER_UN_SELECTED_SIZE, MARKER_UN_SELECTED_SIZE],
      anchor: [0.5, 1],
      offset: [0, 0],
    });
  }
};
