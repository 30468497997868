import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Box, Text } from '../../../lib/base';
import Modal from '../../../lib/base/basic/Modal';
import HeadingText from '../text/HeadingText';
import { propTypeColor } from '../../../lib/base/util/propTypes';

export default class ClaimSitesModal extends Component {
  static propTypes = {
    /**
     * Close modal callback.
     */
    onRequestClose: PropTypes.func.isRequired,

    /**
     * Is modal visible
     */
    visible: PropTypes.bool.isRequired,

    /**
     * body text
     */
    bodyText: PropTypes.string,

    /**
     * Heading text
     */
    headingText: PropTypes.string,

    /**
     * color for modal background
     */
    bgColor: propTypeColor,
  };

  render() {
    return (
      <Modal
        title={() => <HeadingText>{this.props.headingText}</HeadingText>}
        isOpen={this.props.visible}
        onRequestClose={this.props.onRequestClose}
        bgColor={this.props.bgColor}
      >
        <Box pad="medium">
          <Text size="small">{this.props.bodyText}</Text>
        </Box>
      </Modal>
    );
  }
}
