import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Map from '../../../lib/map';
import { toWebMercatorCenter } from '../../../lib/map/core/transfomer';
import { loadProfileDetailIfNeeded } from '../../../store/profile/profile.action';
import { startMapSystemIfNeeded } from '../../../store/system/system.action';
import Config from '../../config';
import { DEFAULT_MAP_PADDING } from '../../Constants';
import RootBaseLayer from '../map/components/shared/layer/RootBaseLayer';
import SiteFeaturesLayer from '../map/components/shared/layer/SiteFeaturesLayer';

class StandaloneMapPage extends PureComponent {
  static propTypes = {
    match: PropTypes.object,
    dispatchLoadMapSettings: PropTypes.func.isRequired,
    dispatchFetchProfile: PropTypes.func.isRequired,
    isStarting: PropTypes.bool,
    siteProfile: PropTypes.object,
    minResolution: PropTypes.number,
    maxResolution: PropTypes.number,
    rootBaseLayer: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {};
    this.handleOnAfterPopulateFeatures = this.handleOnAfterPopulateFeatures.bind(this);
  }

  componentDidMount() {
    const { params } = this.props.match; // 143125 | 27200
    this.props.dispatchLoadMapSettings();
    this.props.dispatchFetchProfile(params.id);
  }

  handleOnAfterPopulateFeatures({ vectorSource }) {
    this.setState({
      extent: vectorSource.getExtent(),
    });
  }

  render() {
    const { isStarting, siteProfile, minResolution, maxResolution, rootBaseLayer } = this.props;

    if (isStarting) {
      return null;
    }

    if (siteProfile === undefined) {
      return <div>Site not found.</div>;
    }

    const { longitude, latitude } = siteProfile.profileFacilityDetail;

    if (!longitude || !latitude) {
      return null;
    }

    const center = toWebMercatorCenter([longitude, latitude]);

    return (
      <div>
        <Map
          id="map"
          minResolution={minResolution}
          maxResolution={maxResolution}
          extent={this.state.extent}
          height={`${400}px`}
          width={`${400}px`}
          padding={DEFAULT_MAP_PADDING}
          center={center}
          scaleLine
          interactive={false}
        >
          <RootBaseLayer layer={rootBaseLayer} bingApiKey={Config.getBingApiKey()} />
          <SiteFeaturesLayer onAfterPopulateFeatures={this.handleOnAfterPopulateFeatures} />
        </Map>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const isMapStarting = state.map.startup.isStarting || state.map.startup.isStarting === undefined;

  return {
    isStarting: isMapStarting || state.profile.detail.isLoading,
    siteProfile: state.profile.detail.data,
    message: state.map.startup.message,
    error: state.map.startup.error,
    minResolution: state.map.minResolution,
    maxResolution: state.map.maxResolution,
    rootBaseLayer: state.map.activeBaseLayer,
    activeReferenceLayers: state.map.activeReferenceLayers,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchLoadMapSettings: () => {
      dispatch(startMapSystemIfNeeded());
    },
    dispatchFetchProfile: id => {
      dispatch(loadProfileDetailIfNeeded(id));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StandaloneMapPage);
