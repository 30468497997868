import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { withRouter } from 'react-router-dom';
import WindowResizeListener from '../../../../../components/listener/WindowResizeListener';
import {
  Z_INDEX_MOBILE_DRAWER_BLIND_PRIMARY,
  Z_INDEX_MOBILE_DRAWER_BLIND_SECONDARY,
} from '../../../../../Constants';
import SitesListPanel from '../../shared/panel/results/SitesListPanel';
import SiteProfileMobile from '../profile/SiteProfileMobile';
import SiteProfileQuickView from '../profile/SiteProfileQuickView';
import DrawerBar from './DrawerBar';
import DrawerBlind from './DrawerBlind';
import { isViewingCard, isViewingDetail, isViewingProfile, isViewingResults } from './isViewing';

const DRAWER_HEIGHT_PX = 100;

class BottomDrawerContainer extends Component {
  static propTypes = {
    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,

    /**
     * The height of the drawer container.
     */
    height: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.handleOnWindowResize = this.handleOnWindowResize.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.setState({ prevPath: this.props.location.pathname });
    }
  }

  handleOnWindowResize() {
    this.forceUpdate();
  }

  isPrimaryDrawerOpen() {
    return (
      isViewingResults(this.props) || isViewingDetail(this.props) || isViewingProfile(this.props)
    );
  }

  isSecondaryDrawerOpen() {
    return isViewingCard(this.props);
  }

  getPrimaryDrawerHeightOpen() {
    return document.documentElement.clientHeight - this.props.height;
  }

  renderPrimaryDrawerContents() {
    if (isViewingProfile(this.props)) {
      return <SiteProfileMobile />;
    } else if (isViewingDetail(this.props)) {
      return <SiteProfileMobile />;
    } else if (isViewingResults(this.props)) {
      return <SitesListPanel isDirectToDetail />;
    }
    return null;
  }

  renderSecondaryDrawerContents() {
    return this.isSecondaryDrawerOpen() ? <SiteProfileQuickView /> : null;
  }

  render() {
    const { classes, height } = this.props;

    return (
      <div className={classes.container}>
        <WindowResizeListener onResize={this.handleOnWindowResize} />

        <DrawerBlind
          isOpen={this.isPrimaryDrawerOpen()}
          zIndex={Z_INDEX_MOBILE_DRAWER_BLIND_PRIMARY}
          heightOpen={this.getPrimaryDrawerHeightOpen()}
          bottom={height}
        >
          {this.renderPrimaryDrawerContents()}
        </DrawerBlind>

        <DrawerBlind
          isOpen={this.isSecondaryDrawerOpen()}
          zIndex={Z_INDEX_MOBILE_DRAWER_BLIND_SECONDARY}
          heightOpen={DRAWER_HEIGHT_PX}
          bottom={0}
        >
          {this.renderSecondaryDrawerContents()}
        </DrawerBlind>

        <DrawerBar />
      </div>
    );
  }
}

const styles = () => ({
  container: {
    position: 'relative',
    height: '100%',
  },
});

export default withRouter(injectSheet(styles)(BottomDrawerContainer));
