import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ExportType from './ExportType';

export default class ExportGroup extends Component {
  static propTypes = {
    exportTypes: PropTypes.array.isRequired,
  };

  render() {
    return (
      <React.Fragment>
        {this.props.exportTypes.map((et, index) => (
          <ExportType
            key={index}
            name={et.exportName}
            id={et.exportTypeId}
            description={et.exportDescr}
          />
        ))}
      </React.Fragment>
    );
  }
}
