import {
  FETCH_FILTERS_BEGIN,
  FETCH_FILTERS_ERROR,
  FETCH_FILTERS_SUCCESS,
  FETCH_GEOCODE_ADDRESS_BEGIN,
  FETCH_GEOCODE_ADDRESS_ERROR,
  FETCH_GEOCODE_ADDRESS_SUCCESS,
  FETCH_SEARCH_KEYS_BEGIN,
  FETCH_SEARCH_KEYS_ERROR,
  FETCH_SEARCH_KEYS_SUCCESS,
  CLEAR_SEARCH_GEOCODE,
  CLEAR_SEARCH_GEOCODE_LOCAL
} from './search.actionType';

/**
 * Initial state.
 */
const initialState = {
  geocode: {
    address: undefined,
    isFetching: false,
    data: undefined,
    error: undefined,
  },
  searchKeys: {
    isFetching: false,
    data: undefined,
    error: undefined,
  },
  filters: {
    isFetching: false,
    data: undefined,
    error: undefined,
  },
};

/**
 * Site search reducer function.
 *
 * @param state
 * @param action
 * @returns {*}
 */
const profile = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    /**
     * Geocode address
     */
    case FETCH_GEOCODE_ADDRESS_BEGIN:
      return Object.assign({}, state, {
        geocode: {
          ...state.geocode,
          isFetching: true,
          address: payload.address,
          localResults: payload.localResults,
        },
      });
    case FETCH_GEOCODE_ADDRESS_SUCCESS:
      return Object.assign({}, state, {
        geocode: {
          isFetching: false,
          data: payload.results,
          error: undefined,
        },
      });
    case FETCH_GEOCODE_ADDRESS_ERROR:
      return Object.assign({}, state, {
        geocode: {
          isFetching: false,
          data: undefined,
          error: payload.error,
        },
      });

    /**
     * Fetch all search keys.
     */
    case FETCH_SEARCH_KEYS_BEGIN:
      return Object.assign({}, state, {
        searchKeys: {
          ...state.searchKeys,
          isFetching: true,
          error: undefined,
          data: undefined,
        },
      });
    case FETCH_SEARCH_KEYS_SUCCESS:
      return Object.assign({}, state, {
        searchKeys: {
          isFetching: false,
          data: payload.results,
        },
      });
    case FETCH_SEARCH_KEYS_ERROR:
      return Object.assign({}, state, {
        searchKeys: {
          isFetching: false,
          error: payload.error,
        },
      });

    /**
     * Fetch all filters.
     */
    case FETCH_FILTERS_BEGIN:
      return Object.assign({}, state, {
        filters: {
          ...state.filters,
          isFetching: true,
          error: undefined,
          data: undefined,
        },
      });
    case FETCH_FILTERS_SUCCESS:
      return Object.assign({}, state, {
        filters: {
          isFetching: false,
          data: payload.results,
        },
      });
    case FETCH_FILTERS_ERROR:
      return Object.assign({}, state, {
        filters: {
          isFetching: false,
          error: payload.error,
        },
      });
    case CLEAR_SEARCH_GEOCODE:
      return Object.assign({}, state, {
        geocode: {
          ...state.geocode,
          data: {},
        },
      });
    case CLEAR_SEARCH_GEOCODE_LOCAL:
      return Object.assign({}, state, {
        geocode: {
          ...state.geocodeLocal,
          data: {},
        },
      });

    default:
      return Object.assign({}, state, {});
  }
};
export default profile;
