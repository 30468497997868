import uniq from 'lodash/uniq';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Box, Column, Columns, Text } from '../../../../../../../lib/base';
import MiniMap from '../../../../../../components/profile/detail/map/MiniMap';
import SpinnerPanel from '../../../../../../components/spinner/SpinnerPanel';
import ClaimSite from '../../../../../../../app/page/themes/ClaimSite';
import HeadingText from '../../../../../../components/text/HeadingText';
import ResourceLabel from '../../../../../../components/text/ResourceLabel';
import Config from '../../../../../../config';
import ConfigKeys from '../../../../../../config/keys';
import { COLOR_BASE_PRIMARY, CLAIM_SITE } from '../../../../../../Constants';
import { navigateToDetailTab } from '../../../../../../routing';
import { getSiteId } from '../../../../../../util/entities/siteProfile';
import { label } from '../../../../../../util/label';

class SiteProfileSummary extends Component {
  static propTypes = {
    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,
    mode: PropTypes.string,
    profile: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.handleOnClickCompliance = this.handleOnClickCompliance.bind(this);
    this.handleOnClickDocuments = this.handleOnClickDocuments.bind(this);
    this.handleOnClickEnvInt = this.handleOnClickEnvInt.bind(this);
  }

  handleOnClickCompliance() {
    const { profile } = this.props;
    const nextTab = profile.tabCounts.complianceCount > 0 ? 'compliance' : 'profile';
    navigateToDetailTab(this.props, getSiteId(this.props.profile), nextTab);
  }

  handleOnClickDocuments() {
    const { profile } = this.props;
    const nextTab = profile.tabCounts.documentCount > 0 ? 'documents' : 'profile';
    navigateToDetailTab(this.props, getSiteId(this.props.profile), nextTab);
  }

  handleOnClickEnvInt() {
    const { profile } = this.props;
    const nextTab = profile.tabCounts.environmentalInterestsCount > 0 ? 'ei' : 'profile';
    navigateToDetailTab(this.props, getSiteId(this.props.profile), nextTab);
  }

  renderEnvironmentalInterestsNotSummarized() {
    const { profileEnvInt } = this.props.profile;

    const eis = uniq(profileEnvInt.map(ei => ei.typeDesc)).sort();

    return (
      <Box onClick={this.handleOnClickEnvInt} className={this.props.classes.container}>
        <Box stack="large">
          <Box mBottom="small">
            <HeadingText>
              <ResourceLabel resourceKey={ConfigKeys.LABEL_ENVIRONMENTAL_INTERESTS} />
            </HeadingText>
          </Box>
          {eis.map((ei, index) => (
            <Box key={index} mLeft="small" mRight="small">
              <Text size="smaller">{ei}</Text>
            </Box>
          ))}
        </Box>
      </Box>
    );
  }

  renderEnvironmentalInterests() {
    if (Config.isSummarizeEIs()) {
      return this.renderEnvironmentalInterestsSummarized();
    } else {
      return this.renderEnvironmentalInterestsNotSummarized();
    }
  }

  renderEnvironmentalInterestsSummarized() {
    const { profileEnvInt } = this.props.profile;

    const eis = uniq(profileEnvInt.map(ei => ei.typeDesc)).sort();
    if (eis.length > 0) {
      return (
        <Box onClick={this.handleOnClickEnvInt} className={this.props.classes.container}>
          <Box stack="large">
            <Box mBottom="small">
              <HeadingText>
                <ResourceLabel resourceKey={ConfigKeys.LABEL_ENVIRONMENTAL_INTERESTS} />
              </HeadingText>
            </Box>
            {eis.length > 1 && (
              <Columns>
                <Column flex={1}>
                  <Text size="smaller">Total</Text>
                </Column>
                <Column>
                  <Text size="smaller" bold>
                    {eis.length}
                  </Text>
                </Column>
              </Columns>
            )}
            {eis.length === 1 && (
              <Box mLeft="small" mRight="small">
                <Text size="smaller">{eis[0]}</Text>
              </Box>
            )}
          </Box>
        </Box>
      );
    }
  }

  renderComplianceRow(label, value) {
    return (
      <Box stack="smaller" mLeft="small" mRight="small">
        <Columns>
          <Column flex={1}>
            <Text size="smaller">{label}</Text>
          </Column>
          <Column>
            <Text size="smaller" bold>
              {value || 0}
            </Text>
          </Column>
        </Columns>
      </Box>
    );
  }

  renderEvaluations(profileFacilityDetail) {
    if (profileFacilityDetail.isShowTotalEvaluationsOnly) {
      return this.renderComplianceRow('Total', profileFacilityDetail.totalComplianceEvaluations);
    } else {
      return (
        <div>
          {this.renderComplianceRow(
            label(ConfigKeys.LABEL_COMPLIANCE_HAS_VIOLATIONS, 'Violations Found'),
            profileFacilityDetail.evalHasViolY
          )}
          {this.renderComplianceRow(
            label(ConfigKeys.LABEL_COMPLIANCE_NO_VIOLATIONS, 'Violations Not Found'),
            profileFacilityDetail.evalHasViolN
          )}
        </div>
      );
    }
  }

  renderActions(profileFacilityDetail) {
    if (profileFacilityDetail.isShowTotalComplianceActionsOnly) {
      return this.renderComplianceRow('Total', profileFacilityDetail.totalComplianceActions);
    } else {
      return (
        <div>
          {this.renderComplianceRow(
            label(ConfigKeys.LABEL_COMPLIANCE_ACTIONS_FORMAL, 'Formal'),
            profileFacilityDetail.complActTypeF
          )}
          {this.renderComplianceRow(
            label(ConfigKeys.LABEL_COMPLIANCE_ACTIONS_INFORMAL, 'Informal'),
            profileFacilityDetail.complActTypeI
          )}
        </div>
      );
    }
  }

  renderViolations(profileFacilityDetail) {
    if (profileFacilityDetail.isShowTotalViolationsOnly) {
      return this.renderComplianceRow('Total', profileFacilityDetail.totalComplianceViolations);
    } else {
      return (
        <div>
          {this.renderComplianceRow(
            label(ConfigKeys.LABEL_VIOLATIONS_OPEN, 'Open'),
            profileFacilityDetail.violResolvedN
          )}
          {this.renderComplianceRow(
            label(ConfigKeys.LABEL_VIOLATIONS_CLOSED, 'Closed'),
            profileFacilityDetail.violResolvedY
          )}
        </div>
      );
    }
  }

  renderDocumentsSummary() {
    const { profileFacilityDetail } = this.props.profile;
    if (profileFacilityDetail && profileFacilityDetail.totalDocuments > 0) {
      return (
        <Box onClick={this.handleOnClickDocuments} className={this.props.classes.container}>
          {Config.isShowDocumentCount() && (
            <Box stack="medium">
              <Box stack="smaller">
                <HeadingText>
                  <ResourceLabel resourceKey={ConfigKeys.LABEL_DOCUMENTS} />
                </HeadingText>
              </Box>
              {this.renderDocuments(profileFacilityDetail)}
            </Box>
          )}
        </Box>
      );
    }
  }

  renderDocuments(profileFacilityDetail) {
    return this.renderDocumentsRow('Total', profileFacilityDetail.totalDocuments);
  }

  renderDocumentsRow(label, value) {
    return (
      <Box stack="smaller" mLeft="small" mRight="small">
        <Columns>
          <Column flex={1}>
            <Text size="smaller">{label}</Text>
          </Column>
          <Column>
            <Text size="smaller" bold>
              {value || 0}
            </Text>
          </Column>
        </Columns>
      </Box>
    );
  }

  renderComplianceSummary() {
    const { profileFacilityDetail } = this.props.profile;

    if (
      profileFacilityDetail &&
      (profileFacilityDetail.totalComplianceViolations > 0 ||
        profileFacilityDetail.totalComplianceActions > 0 ||
        profileFacilityDetail.totalComplianceEvaluations > 0)
    ) {
      return (
        <Box onClick={this.handleOnClickCompliance} className={this.props.classes.container}>
          {profileFacilityDetail.totalComplianceEvaluations > 0 && (
            <Box stack="medium">
              <Box stack="smaller">
                <HeadingText>
                  <ResourceLabel resourceKey={ConfigKeys.LABEL_COMPLIANCE_EVALUATIONS} />
                </HeadingText>
              </Box>
              {this.renderEvaluations(profileFacilityDetail)}
            </Box>
          )}
          {profileFacilityDetail.totalComplianceViolations > 0 && (
            <Box stack="medium">
              <Box stack="smaller">
                <HeadingText>
                  <ResourceLabel resourceKey={ConfigKeys.LABEL_COMPLIANCE_VIOLATIONS} />
                </HeadingText>
              </Box>
              {this.renderViolations(profileFacilityDetail)}
            </Box>
          )}
          {profileFacilityDetail.totalComplianceActions > 0 && (
            <Box stack="medium">
              <Box stack="smaller">
                <HeadingText>
                  <ResourceLabel resourceKey={ConfigKeys.LABEL_COMPLIANCE_ACTIONS} />
                </HeadingText>
              </Box>
              {this.renderActions(profileFacilityDetail)}
            </Box>
          )}
        </Box>
      );
    }
  }

  renderMap() {
    return (
      <Box stack="large">
        <MiniMap />
      </Box>
    );
  }

  renderTheme() {
    if (this.props.mode) {
      switch (this.props.mode.toLowerCase()) {
        case CLAIM_SITE:
          return <ClaimSite accountId={this.props.profile.profileFacilityDetail.accountId} />;
        default:
          break;
      }
    }
  }

  render() {
    if (this.props.isLoading) {
      return <SpinnerPanel />;
    }

    if (!this.props.profile) {
      return <div>Site not found</div>;
    }

    return (
      <div>
        {this.renderMap()}
        {this.renderTheme()}
        {this.renderEnvironmentalInterests()}
        {this.renderComplianceSummary()}
        {this.renderDocumentsSummary()}
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    marginLeft: `-${theme.spacing.medium}`,
    padding: theme.spacing.medium,

    '&:hover': {
      backgroundColor: theme.color[COLOR_BASE_PRIMARY]['light1'],
      transition: 'background-color 350ms',
    },
  },
});

function mapStateToProps(state) {
  return {
    profile: state.profile.detail.data,
    isLoading: state.profile.detail.isLoading,
    mode: state.system.mode,
  };
}

export default withRouter(connect(mapStateToProps)(injectSheet(styles)(SiteProfileSummary)));
