import Downshift from "downshift";
import debounce from "lodash/debounce";
import PropTypes from "prop-types";
import React, { Fragment, PureComponent } from "react";
import injectSheet from "react-jss";
import { Manager, Popper, Reference } from "react-popper";
import { Column, Columns } from "../../../lib/base";
import { COLOR_BASE_PRIMARY, SEARCH_DELAY_TIME_MS, MEDIA_BREAKPOINT } from "../../Constants";
import { IconSpinner } from "../icons";

class SuggestSearchInput extends PureComponent {
  static propTypes = {
    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,

    /**
     * Additional css for container.
     */
    className: PropTypes.string,

    /**
     * The value of the input.
     */
    value: PropTypes.string,

    /**
     * Handle input value changes.
     */
    onChange: PropTypes.func.isRequired,

    /**
     * Handle when value is cleared.
     */
    onClear: PropTypes.func,

    /**
     * Handle when input is blurred.
     */
    onBlur: PropTypes.func,

    /**
     * Called when a suggestion item is selected.
     */
    onSelect: PropTypes.func,

    /**
     * Handle on input keypress.
     */
    onKeyPress: PropTypes.func,

    /**
     * Called when suggestions are wanted.
     */
    onRequestSuggestions: PropTypes.func,

    /**
     * placeholder text
     */
    placeholderText: PropTypes.string
  };

  static defaultProps = {
    children: null,
    placeholderText: ""
  };

  constructor(props) {
    super(props);

    this.state = {
      term: props.value || "",
      suggestions: [],
      isLoading: false
    };

    this.doSuggest = this.doSuggest.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
    this.handleOnKeyPress = this.handleOnKeyPress.bind(this);
    this.debouncedDoSuggest = debounce(this.doSuggest, SEARCH_DELAY_TIME_MS);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.term !== this.props.value) {
      this.setState({ term: this.props.value || "" });
    }
  }

  doSuggest(term) {
    if (this.props.onRequestSuggestions) {
      this.setState({ isLoading: true });
      this.props
        .onRequestSuggestions(term)
        .then(suggestions => {
          this.setState({ suggestions, isLoading: false });
          if (this.scheduleUpdate) {
            this.scheduleUpdate();
          }
        })
        .catch(err => {
          if (err.name !== "AbortError") {
            this.setState({ isLoading: false });
          }
        });
    }
  }

  handleOnChange(value) {
    this.props.onChange(value);
    this.setState({ term: value }, () => {
      this.debouncedDoSuggest(this.state.term);
    });
  }

  handleOnBlur(event) {
    this.props.onBlur(event);
  }

  hasValue(inputValue) {
    return inputValue && inputValue.length > 0;
  }

  renderSuggestions(getItemProps, highlightedIndex) {
    const suggestions = this.state.suggestions || [];

    return suggestions.map((item, index) => {
      const classNames = [this.props.classes.itemContainer];

      if (highlightedIndex === index) {
        classNames.push(this.props.classes.highlighted);
      }

      const startIndex = item.label.toUpperCase().indexOf(this.state.term.toUpperCase());
      const lastIndex = startIndex + this.state.term.length;

      let formattedItem = item.label;

      if (startIndex !== -1) {
        formattedItem = (
          <Fragment>
            {item.label.slice(0, startIndex)}
            <span className={this.props.classes.bold}>
              {item.label.substring(startIndex, lastIndex)}
            </span>
            {item.label.slice(lastIndex)}
          </Fragment>
        );
      }

      return (
        <div
          key={item}
          {...getItemProps({
            item: item,
            key: item.id,
            className: classNames.join(" ")
          })}
        >
          {formattedItem}
        </div>
      );
    });
  }

  handleOnKeyPress(event) {
    if (this.props.onKeyPress) {
      this.props.onKeyPress(event);
      if (event.key === "Enter") {
        event.target.blur();
      }
    }
  }

  canShowPopper(isOpen) {
    return isOpen && this.state.suggestions.length > 0;
  }

  render() {
    return (
      <Downshift
        onChange={selection => {
          this.props.onSelect(selection);
        }}
        onStateChange={({ inputValue }) => {
          if (inputValue !== undefined) {
            if (typeof inputValue === "object") {
              this.handleOnChange(inputValue.label);
              return inputValue.label;
            } else {
              this.handleOnChange(inputValue);
              return inputValue;
            }
          }
        }}
        selectedItem={this.state.term}
        itemToString={item => {
          if (typeof item === "object") {
            return item.label;
          } else {
            return item || "";
          }
        }}
      >
        {downshift => {
          const { isOpen, getInputProps, getItemProps, highlightedIndex, selectedItem } = downshift;

          return (
            <div>
              <Manager>
                <Reference>
                  {({ ref }) => {
                    return (
                      <div ref={ref}>
                        <Columns>
                          <Column>
                            <input
                              placeholder={this.props.placeholderText}
                              className={this.props.className}
                              {...getInputProps({
                                onBlur: this.handleOnBlur,
                                onKeyPress: this.handleOnKeyPress
                              })}
                            />
                          </Column>
                          {this.state.isLoading && (
                            <Column flex={1} vAlign="center">
                              <IconSpinner size="sm" />
                            </Column>
                          )}
                        </Columns>
                      </div>
                    );
                  }}
                </Reference>
                {this.canShowPopper(isOpen) && (
                  <Popper placement="bottom">
                    {({ ref, style: { top, left, position, transform }, scheduleUpdate }) => {
                      this.scheduleUpdate = scheduleUpdate;
                      return (
                        <div
                          ref={ref}
                          style={{ top, left: left + 1, position, transform }}
                          className={this.props.classes.menuRoot}
                        >
                          {this.renderSuggestions(getItemProps, highlightedIndex, selectedItem)}
                        </div>
                      );
                    }}
                  </Popper>
                )}
              </Manager>
            </div>
          );
        }}
      </Downshift>
    );
  }
}

const styles = theme => ({
  container: {
    padding: theme.spacing.medium
  },
  menuRoot: {
    zIndex: 1000,
    width: "100%",
    backgroundColor: "white",
    border: `1px solid ${theme.color[COLOR_BASE_PRIMARY]["light3"]}`,
    ...theme.elevation.shadow2
  },
  itemContainer: {
    padding: `${theme.spacing.small} ${theme.spacing.small}`,
    fontSize: "15px",

    "&:hover": {
      backgroundColor: theme.color[COLOR_BASE_PRIMARY]["light2"]
    }
  },
  highlighted: {
    backgroundColor: theme.color[COLOR_BASE_PRIMARY]["light2"]
  },
  bold: {
    fontWeight: 600,
    display: "inline-block",
    borderBottom: "1px solid black",
    lineHeight: "18px"
  },
  [`@media ${MEDIA_BREAKPOINT}`]: {
    container: {
      border: "none"
    }
  }
});

export default injectSheet(styles)(SuggestSearchInput);
