import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Box, Modal } from '../../../../../../lib/base';
import HeadingText from '../../../../../components/text/HeadingText';
import { navigateToMapHome } from '../../../../../routing';
import SearchOptionsPanel from '../../shared/panel/criteria/SearchOptionsPanel';

class ModalSearchCriteria extends Component {
  constructor(props) {
    super(props);
    this.handleOnClose = this.handleOnClose.bind(this);
  }

  handleOnClose() {
    navigateToMapHome(this.props);
  }

  isOpen() {
    const { params } = this.props.match;
    return params.subPanel === 'criteria' || params.subPanel === 'filters';
  }

  render() {
    return (
      <Modal
        title={() => <HeadingText>Advanced Search Criteria</HeadingText>}
        isOpen={this.isOpen()}
        onRequestClose={this.handleOnClose}
      >
        <Box pad="medium">
          <SearchOptionsPanel />
        </Box>
      </Modal>
    );
  }
}

export default withRouter(ModalSearchCriteria);
