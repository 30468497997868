import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Column, Columns, Text } from '../../../../../../lib/base';
import { IconChevronLeft, IconMapMarker, IconSpinner } from '../../../../../components/icons';
import { IconList } from '../../../../../components/icons/fontawesome/icons';
import { COLOR_BASE_PRIMARY } from '../../../../../Constants';
import {
  navigateToMapHome,
  navigateToMobileCardView,
  navigateToMobileProfileView,
  navigateToResults,
  navigateToResultsDirect,
} from '../../../../../routing';
import {
  isViewingCard,
  isViewingDetail,
  isViewingMap,
  isViewingProfile,
  isViewingResults,
} from './isViewing';

/**
 * The drawer bar displays navigation links based the
 * current address location path.
 *
 * /map                        <<  show list
 * /map/results                <<  show map
 * /map/results/detail/11      <<  back to list
 * /map/results/card/11
 * /map/results/profile/11     <<  close
 *
 */
class DrawerBar extends Component {
  static propTypes = {
    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,

    /**
     * Connected - Is site search in progress.
     */
    isSearching: PropTypes.bool.isRequired,

    /**
     * Connected - The number of search results.
     */
    count: PropTypes.number,
  };

  static defaultProps = {
    count: undefined,
  };

  static withStyledText(what) {
    return (
      <Text size="small" bold color={COLOR_BASE_PRIMARY} colorVariant="dark5" title={what}>
        {what}
      </Text>
    );
  }

  constructor(props) {
    super(props);
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  handleOnClick() {
    const { params } = this.props.match;

    if (isViewingProfile(this.props)) {
      navigateToMobileCardView(this.props, params.id);
    } else if (isViewingCard(this.props)) {
      navigateToMobileProfileView(this.props);
    } else if (isViewingDetail(this.props)) {
      navigateToResultsDirect(this.props);
    } else if (isViewingResults(this.props)) {
      navigateToMapHome(this.props);
    } else if (isViewingMap(this.props)) {
      navigateToResults(this.props);
    }
  }

  renderTitle() {
    if (isViewingProfile(this.props)) {
      return (
        <span>
          <span className={this.props.classes.icon}>
            <IconChevronLeft color={COLOR_BASE_PRIMARY} colorVariant="dark5" />
          </span>
          {DrawerBar.withStyledText('Close')}
        </span>
      );
    }

    if (isViewingDetail(this.props)) {
      return (
        <span>
          <span className={this.props.classes.icon}>
            <IconChevronLeft color={COLOR_BASE_PRIMARY} colorVariant="dark5" />
          </span>
          {DrawerBar.withStyledText('Back to list')}
        </span>
      );
    }

    if (isViewingMap(this.props)) {
      return (
        <span>
          <span className={this.props.classes.icon}>
            <IconList color={COLOR_BASE_PRIMARY} colorVariant="dark5" />
          </span>
          {DrawerBar.withStyledText('Show list')}
        </span>
      );
    }

    if (isViewingResults(this.props)) {
      return (
        <span>
          <span className={this.props.classes.icon}>
            <IconMapMarker color={COLOR_BASE_PRIMARY} colorVariant="dark5" />
          </span>
          {DrawerBar.withStyledText('Show map')}
        </span>
      );
    }

    return null;
  }

  renderCount() {
    if (this.props.isSearching) {
      return <IconSpinner color={COLOR_BASE_PRIMARY} colorVariant="dark5" />;
    } else {
      return DrawerBar.withStyledText(
        this.props.count ? `(${this.props.count.toLocaleString()} sites)` : ''
      );
    }
  }

  render() {
    return (
      <Columns
        containerRef={this.container}
        vAlign="center"
        onClick={this.handleOnClick}
        className={this.props.classes.container}
        gutter="small"
        stretch
      >
        <Column>{this.renderTitle()}</Column>
        <Column>{this.renderCount()}</Column>
      </Columns>
    );
  }
}

const styles = theme => ({
  container: {
    zIndex: 100,
    backgroundColor: '#efefef',
    paddingLeft: theme.spacing.medium,
    position: 'absolute',
    width: '100%',
    height: '100%',
  },

  icon: {
    marginRight: '8px',
  },
});

function mapStateToProps(state) {
  return {
    isSearching: state.map.search.isFetching,
    siteProfile: state.profile.detail.data,
    count: state.map.search.count,
  };
}

export default withRouter(connect(mapStateToProps)(injectSheet(styles)(DrawerBar)));
