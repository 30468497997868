import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Box, DataGrid } from '../../../lib/base';
import Select from '../form/Select';

const api = () => window.nSITE.Api;

export default class WarehouseStatsContainer extends Component {
  static propTypes = {
    /**
     * List of dashboards from the API.
     */
    dashboards: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      selection: props.dashboards ? props.dashboards[0] : undefined,
      dashboardChart: undefined,
      isLoading: false,
    };

    this.handleOnChangeChart = this.handleOnChangeChart.bind(this);
  }

  componentDidMount() {
    this.fetchDashboardChart();
  }

  handleOnChangeChart(event, choice) {
    this.setState({ selection: choice }, this.fetchDashboardChart);
  }

  fetchDashboardChart() {
    this.setState({ isLoading: true });

    api()
      .system.getDashboardChart(this.state.selection.key)
      .then(dashboardChart => {
        this.setState({
          dashboardChart,
          isLoading: false,
        });
      })
      .catch(err => {
        this.setState({
          error: err,
          isLoading: false,
        });
      });
  }

  columns() {
    return [
      {
        headerLabel: 'Identifier',
        accessor: 'key',
        width: 300,
      },
      {
        headerLabel: 'Quantity',
        accessor: row => (row.value ? row.value.toLocaleString() : '—'),
        width: 130,
        align: 'right',
      },
    ];
  }

  render() {
    return (
      <div>
        <Box>
          <h1>Data Warehouse Statistics</h1>
        </Box>

        {this.props.dashboards.length > 1 && (
          <Box pBottom="medium">
            <Select
              value={this.state.selection}
              onChange={this.handleOnChangeChart}
              placeholder="Choose..."
              choices={this.props.dashboards}
              keyExtractor={choice => choice.key}
              labelExtractor={choice => choice.value}
              renderChoice={choice => choice.value}
            />
          </Box>
        )}

        {this.state.dashboardChart && (
          <Box>
            <DataGrid
              loading={this.state.isLoading}
              columns={this.columns()}
              data={this.state.dashboardChart}
              keyExtractor={row => row.key}
              showPaginationBottom={false}
              enableFiltering={false}
              striped
            />
          </Box>
        )}
      </div>
    );
  }
}
