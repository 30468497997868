import PropTypes from 'prop-types';
import qs from 'qs';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import SpinnerPanel from '../spinner/SpinnerPanel';
import EnvironmentalInterestsDataGrid from './EnvironmentalInterestsDataGrid';

const api = () => window.nSITE.Api;

class EnvironmentalInterestsContainer extends Component {
  static propTypes = {
    /**
     * Source identifier to look up.
     */
    sourceIdentifier: PropTypes.string.isRequired,
  };

  static parseDescriptionQueryParam(props) {
    const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    return params.description;
  }

  constructor(props) {
    super(props);

    this.state = {
      data: undefined,
      isInitialized: false,
      isLoading: false,
      error: undefined,
      page: 1,
      pageSize: 5,
      total: undefined,
      sortBy: EnvironmentalInterestsDataGrid.DEFAULT_SORT_BY,
      isAsc: true,
      criteria: {
        description: EnvironmentalInterestsContainer.parseDescriptionQueryParam(props),
      },
    };

    this.handleOnRequestData = this.handleOnRequestData.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    this.setState({ isLoading: true });

    api()
      .profile.getEnvInterests(this.props.sourceIdentifier, {
        page: this.state.page,
        pageSize: this.state.pageSize,
        total: this.state.total,
        sortBy: this.state.sortBy,
        isAsc: this.state.isAsc,
        criteria: this.state.criteria,
      })
      .then(pageResponse => {
        this.setState({
          data: pageResponse.data,
          page: pageResponse.page,
          pageSize: pageResponse.pageSize,
          total: pageResponse.total,
          sortBy: pageResponse.sortBy,
          isAsc: pageResponse.isAsc,
          criteria: pageResponse.criteria,
          isLoading: false,
          isInitialized: true,
        });
      })
      .catch(err => {
        this.setState({
          error: err,
          isLoading: false,
        });
      });
  }

  handleOnRequestData({ page, pageSize, sortBy, isAsc, criteria }) {
    this.setState(
      {
        page: page || this.state.page,
        pageSize: pageSize || this.state.pageSize,
        sortBy: sortBy || this.state.sortBy,
        isAsc: isAsc === undefined ? true : isAsc,
        criteria: criteria || this.state.criteria,
      },
      this.fetchData
    );
  }

  render() {
    if (this.state.isInitialized) {
      return (
        <EnvironmentalInterestsDataGrid
          data={this.state.data}
          isLoading={this.state.isLoading}
          onRequestData={this.handleOnRequestData}
          page={this.state.page}
          pageSize={this.state.pageSize}
          total={this.state.total}
          sortBy={this.state.sortBy}
          isAsc={this.state.isAsc}
          criteria={this.state.criteria}
        />
      );
    }
    return <SpinnerPanel />;
  }
}

export default withRouter(EnvironmentalInterestsContainer);
