import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import injectSheet from 'react-jss';
import { jssColor, jssSize } from '../util/jss';
import { propTypeColor, propTypeColorVariant, propTypeSize } from '../util/propTypes';

/**
 * A low-level component for a span of text.
 */
class Text extends PureComponent {
  static propTypes = {
    /**
     * Is a block element.
     */
    block: PropTypes.bool,

    /**
     * Bold.
     */
    bold: PropTypes.bool,

    /**
     * All capital letters.
     */
    caps: PropTypes.bool,

    /**
     * Children components.
     */
    children: PropTypes.node,

    /**
     * JSS classes.
     */
    classes: PropTypes.object.isRequired,

    /**
     * Additional className to style the text.
     */
    className: PropTypes.string,

    /**
     * Text color - blue, red, green, gray, etc.
     */
    color: propTypeColor,

    /**
     * Variant of text color - light3, light6, dark2, etc.
     */
    colorVariant: propTypeColorVariant,

    /**
     * Elipsis when text overflows.
     */
    elipsis: PropTypes.bool,

    /**
     * Italics
     */
    italic: PropTypes.bool,

    /**
     * Underlined
     */
    underline: PropTypes.bool,

    /**
     * Make text unselectable.
     */
    noselect: PropTypes.bool,

    /**
     * Do not allow text to wrap.
     */
    nowrap: PropTypes.bool,

    /**
     * Text size.
     */
    size: propTypeSize,

    /**
     * Title of the text when hovering.
     */
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    /**
     * Color white.
     *
     * @deprecated
     */
    white: PropTypes.bool,

    /**
     * Additional styles.
     */
    style: PropTypes.object,
  };

  static defaultProps = {
    children: undefined,
    className: undefined,
    title: undefined,
    noselect: false,
    nowrap: false,
    elipsis: false,
    block: false,
    white: false,
    caps: false,
    bold: false,
    italic: false,
    underline: false,
    size: undefined,
    color: undefined,
    colorVariant: undefined,
    style: undefined,
  };

  renderClassNames() {
    return [
      /**
       * Base text styles.
       */
      this.props.classes.text,

      /**
       * Color.
       */
      this.props.color && this.props.classes.color,

      /**
       * Size.
       */
      jssSize(this.props),

      /**
       * Addiditonal style selector.
       */
      this.props.className,
    ].join(' ');
  }

  render() {
    return (
      <span title={this.props.title} className={this.renderClassNames()} style={this.props.style}>
        {this.props.children}
      </span>
    );
  }
}

const styles = theme => ({
  text: {
    ...theme.typography.base,
    display: props => (props.block ? 'block' : undefined),
    userSelect: props => (props.noselect ? 'none' : undefined),
    whiteSpace: props => (props.nowrap ? 'nowrap' : undefined),
    overflow: props => (props.elipsis ? 'hidden' : undefined),
    textOverflow: props => (props.elipsis ? 'ellipsis' : undefined),
    textTransform: props => (props.caps ? 'uppercase' : undefined),
    fontWeight: props => (props.bold ? '600' : undefined),
    fontStyle: props => (props.italic ? 'italic' : undefined),
    textDecoration: props => (props.underline ? 'underline' : undefined),
  },

  smallest: {
    ...theme.typography.smallest,
  },

  smaller: {
    ...theme.typography.smaller,
  },

  small: {
    ...theme.typography.small,
  },

  medium: {
    ...theme.typography.medium,
  },

  large: {
    ...theme.typography.large,
  },

  larger: {
    ...theme.typography.larger,
  },

  largest: {
    ...theme.typography.largest,
  },

  color: {
    color: props => props.color && jssColor(theme, props),
  },
});

export default injectSheet(styles)(Text);
