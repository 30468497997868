import { StyleSheet, View } from '@react-pdf/renderer';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import LabelValueTable from '../../../pdf/lv/LabelValueTable';
import { ProfileSection } from '../../../../lib/pdf';

export class SiteCodesSection extends PureComponent {
  static propTypes = {
    /**
     * SIC Codes
     */
    sicCodes: PropTypes.arrayOf(
      PropTypes.shape({
        sic: PropTypes.string,
        sicDesc: PropTypes.string,
      })
    ),

    /**
     * NAICS Codes
     */
    naicsCodes: PropTypes.arrayOf(
      PropTypes.shape({
        naics: PropTypes.string,
        naicsDesc: PropTypes.string,
      })
    ),
  };

  static defaultProps = {
    sicCodes: [],
    naicsCodes: [],
  };

  render() {
    const { sicCodes, naicsCodes } = this.props;

    if (isEmpty(sicCodes) && isEmpty(naicsCodes)) {
      return null;
    }

    return (
      <View style={styles.root}>
        <View style={[styles.column, styles.leftColumn]}>
          <ProfileSection title="SIC Codes">
            <LabelValueTable
              data={sicCodes}
              labelFunc={row => row.sic}
              labelWidth="25%"
              valueFunc={row => row.sicDesc}
              valueWidth="75%"
            />
          </ProfileSection>
        </View>
        <View style={[styles.column, styles.rightColumn]}>
          <ProfileSection title="NAICS Codes">
            <LabelValueTable
              data={naicsCodes}
              labelFunc={row => row.naics}
              labelWidth="25%"
              valueFunc={row => row.naicsDesc}
              valueWidth="75%"
            />
          </ProfileSection>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    flex: 1,
  },
  leftColumn: {
    marginRight: 4,
  },
  rightColumn: {
    marginLeft: 4,
  },
});
