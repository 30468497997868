import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Box, Column, Columns, Text } from '../../../../../../../lib/base';
import { DEFAULT_TEXT_SIZE_DESCRIPTIONS } from '../../../../../../Constants';

export default class WMSLegend extends Component {
  static propTypes = {
    /**
     * layer data.
     */
    layer: PropTypes.object.isRequired,
  };

  render() {
    const { layer } = this.props;

    return (
      <Columns vCenter>
        <Column flex={4 / 5}>
          {/* add a legend description */}
          <Text size={DEFAULT_TEXT_SIZE_DESCRIPTIONS}>{layer.legendDesc}</Text>
        </Column>
        <Column flex={1 / 5}>
          <Box textAlign="right">
            <img
              width="100%"
              height="100%"
              alt={layer.legendDesc}
              src={layer.layerLegendUrl}
              style={{ backgroundColor: 'white' }}
            />
          </Box>
        </Column>
      </Columns>
    );
  }
}
