import BaseEndpoint from './BaseEndpoint';
import Urls from './Urls';

/**
 * AppSettings endpoint.
 */
export default class AppSettingsEndpoint extends BaseEndpoint {
  /**
   * Converts the array of settings from the API to an object.
   */
  toObject(json) {
    const settings = {};
    json.map(v => (settings[v.settingKey] = v.settingValue));
    return settings;
  }

  /**
   * Returns a promise resolving system app settings data.
   *
   * @returns {Promise<any>} system app settings data.
   */
  getAppSettings() {
    const url = `${super.url(Urls.AppSettings.GetAppSettings)}`;

    return super
      .get(url)
      .then(response => response.json())
      .then(json => this.toObject(json));
  }

  getDashboardList() {
    const url = `${super.url(Urls.AppSettings.GetDashboardList)}`;

    return super.get(url).then(response => response.json());
  }

  getDashboardChart(key) {
    const url = `${super.url(Urls.AppSettings.GetDashboardChart)}?key=${key}`;

    return super.get(url).then(response => response.json());
  }
}
