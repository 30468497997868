import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import injectSheet from 'react-jss';
import { COLOR_BASE_PRIMARY } from '../../../../../../../Constants';

class BaseHeader extends PureComponent {
  static propTypes = {
    /**
     * Jss provided property.
     */
    classes: PropTypes.object.isRequired,

    /**
     * Children components.
     */
    children: PropTypes.node.isRequired,
  };

  render() {
    return <div className={this.props.classes.header}>{this.props.children}</div>;
  }
}

const styles = theme => ({
  header: {
    backgroundColor: theme.color[COLOR_BASE_PRIMARY]['light1'],
    paddingTop: theme.spacing.medium,
    paddingRight: theme.spacing.medium,
    paddingLeft: theme.spacing.large,
    boxShadow: 'inset 7px 0 9px -7px rgba(0,0,0,0.4)',
  },
});

export default injectSheet(styles)(BaseHeader);
