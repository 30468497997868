import { PureComponent } from 'react';
import Config from '../../config';

const ID_META_AUTHOR = 'MetaAuthor';
const ID_META_DESC = 'MetaDescription';
const ID_META_KEYWORDS = 'MetaKeywords';
const ID_META_ROBOTS = 'MetaRobots';

export default class MetaTagsUpdater extends PureComponent {
  componentDidMount() {
    this.updateMetaTags();
  }

  componentDidUpdate() {
    this.updateMetaTags();
  }

  updateMetaTags() {
    document.getElementById(ID_META_AUTHOR).setAttribute('content', Config.getMetaAuthor());
    document.getElementById(ID_META_DESC).setAttribute('content', Config.getMetaDescription());
    document.getElementById(ID_META_KEYWORDS).setAttribute('content', Config.getMetaKeywords());
    document.getElementById(ID_META_ROBOTS).setAttribute('content', Config.getMetaRobotContent());
  }

  render() {
    return null;
  }
}
