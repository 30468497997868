import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Box, Column, Columns, Text } from '../../../../../../../lib/base';
import { jssColorLookup } from '../../../../../../../lib/base/util/jss';
import { toWebMercatorExtent } from '../../../../../../../lib/map/core/transfomer';
import {
  resetSearchInput,
  searchFilterCountIfNeeded,
  searchSitesIfNeeded,
  zoomToExtent,
} from '../../../../../../../store/map/map.action';
import { clearSearchGeocode } from '../../../../../../../store/search/search.action';
import { IconClear, IconMapMarker } from '../../../../../../components/icons';
import {
  COLOR_BASE_PRIMARY,
  COLOR_ICON_ON_LIGHT,
  COLOR_VARIANT_ICON_ON_LIGHT,
} from '../../../../../../Constants';
import { navigateToResultsDirect } from '../../../../../../routing';

class GeocodeAddressResult extends Component {
  static propTypes = {
    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,

    /**
     * Handle geocode result click.
     */
    dispatchClickGeocodeResult: PropTypes.func.isRequired,

    /**
     * Handle clearing of geocode result.
     */
    dispatchClearGeocodeResult: PropTypes.func.isRequired,

    /**
     * The search criteria.
     */
    criteria: PropTypes.object,

    /**
     * The geocode result.
     */
    result: PropTypes.object,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.handleOnClickResult = this.handleOnClickResult.bind(this);
    this.handleOnClickClear = this.handleOnClickClear.bind(this);
  }

  handleOnClickResult(event, extent) {
    navigateToResultsDirect(this.props);
    const criteria = { ...this.props.criteria, term: '' };
    this.props.dispatchClickGeocodeResult(toWebMercatorExtent(extent), criteria);
  }

  handleOnClickClear(e) {
    e.stopPropagation();
    const criteria = { ...this.props.criteria, term: '' };
    this.props.dispatchClearGeocodeResult(criteria);
  }

  render() {
    if (isEmpty(this.props.result)) return null;

    const doOnClick = e =>
      this.handleOnClickResult(e, this.props.result.extent, this.props.result.point);

    return (
      <Box pad="medium" onClick={doOnClick} className={this.props.classes.hover}>
        <Columns
          gutter="medium"
          onClick={doOnClick}
          className={this.props.classes.hover}
          vAlign="top"
        >
          <Column>
            <Text size="smaller" italic nowrap>
              Did you mean:
            </Text>
          </Column>
          <Column vAlign="top">
            <Box inline mLeft="smaller" mTop="smallest">
              <IconMapMarker color={COLOR_BASE_PRIMARY} colorVariant="dark4" />
            </Box>
          </Column>
          <Column>
            <Text size="smaller" bold>
              {this.props.result.name}
            </Text>
          </Column>
          <Column flex={0} onClick={this.handleOnClickClear}>
            <Box pad="small" title="Clears address suggestion">
              <IconClear color={COLOR_ICON_ON_LIGHT} colorVariant={COLOR_VARIANT_ICON_ON_LIGHT} />
            </Box>
          </Column>
        </Columns>
      </Box>
    );
  }
}

const styles = theme => ({
  hover: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: jssColorLookup(theme, 'blue', 'light1'),
    },
  },
});

function mapStateToProps(state) {
  return {
    criteria: state.map.search.criteria,
    result: state.search.geocode.data,
    zoom: state.map.zoom,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchClickGeocodeResult: (extent, criteria) => {
      dispatch(zoomToExtent(extent));
      dispatch(searchSitesIfNeeded(criteria));
      dispatch(searchFilterCountIfNeeded(criteria));
    },
    dispatchClearGeocodeResult: () => {
      dispatch(clearSearchGeocode());
    },
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(injectSheet(styles)(GeocodeAddressResult))
);
