import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import injectSheet from 'react-jss';
import { Text } from '../../../lib/base';

/**
 * A full screen page loading spinner.
 */
class SpinnerPage extends PureComponent {
  static propTypes = {
    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,
  };

  /**
   * Renders the component.
   */
  render() {
    const { container, message } = this.props.classes;

    return (
      <div className={container}>
        <svg
          width="300px"
          height="300px"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
          style={{ background: 'none', padding: '50px' }}
        >
          <circle
            cx="50"
            cy="50"
            fill="none"
            stroke="#EFEFEF"
            strokeWidth="3"
            r="35"
            strokeDasharray="164.93361431346415 56.97787143782138"
            transform="rotate(330 50 50)"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              calcMode="linear"
              values="0 50 50;360 50 50"
              keyTimes="0;1"
              dur="1s"
              begin="0s"
              repeatCount="indefinite"
            />
          </circle>
        </svg>
        <div className={message}>
          <Text size="smaller" bold color="gray" colorVariant="gray6">
            {this.props.message}
          </Text>
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    margin: 'auto',
    height: '400px',
    width: '300px',
    zIndex: '100',
    backgroundColor: 'transparent',
  },

  message: {
    width: '100%',
    textAlign: 'center',
  },
});

export default injectSheet(styles)(SpinnerPage);
