import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Box, Row, Rows } from '../../../../lib/base';
import { loadProfileDetailIfNeeded } from '../../../../store/profile/profile.action';
import { startMapSystemIfNeeded } from '../../../../store/system/system.action';
import WindowDocumentTitle from '../../../components/dom/WindowDocumentTitle';
import PageLoading from '../../../components/loading/PageLoading';
import { ROUTE_NOT_FOUND } from '../../../Constants';
import { getSiteName } from '../../../util/entities/siteProfile';
import SiteProfileContent from './SiteProfileContent';
import SiteProfileHeader from './SiteProfileHeader';

class SiteProfileContainer extends PureComponent {
  static propTypes = {
    /**
     * Active tab key.
     */
    tab: PropTypes.string.isRequired,

    /**
     * Site id.
     */
    id: PropTypes.string.isRequired,
  };

  static defaultProps = {
    tab: 'detail',
  };

  componentDidMount() {
    this.props.dispatchFetchProfile(this.props.id);
    this.props.dispatchLoadMapSettings();
  }

  render() {
    if (this.props.isStarting) {
      return <PageLoading message={this.props.message} />;
    }

    const { tab, siteProfile } = this.props;

    if (!siteProfile) {
      return <Redirect to={ROUTE_NOT_FOUND} />;
    }

    return (
      <Fragment>
        <WindowDocumentTitle title={getSiteName(siteProfile)} />
        <Rows stretch>
          <Row>
            <SiteProfileHeader tab={tab} siteProfile={siteProfile} />
          </Row>
          <Row flex={1}>
            <Box pad="medium" style={{ height: '100%' }}>
              <SiteProfileContent tab={tab} siteProfile={siteProfile} />
            </Box>
          </Row>
        </Rows>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const isMapStarting = state.map.startup.isStarting || state.map.startup.isStarting === undefined;

  return {
    isStarting: isMapStarting || state.profile.detail.isLoading,
    message: state.map.startup.message,
    siteProfile: state.profile.detail.data,
  };
}

function mapDispatchToProps(dispatch) {
  const fetchProfile = id => dispatch(loadProfileDetailIfNeeded(id));
  const loadMapSettings = () => dispatch(startMapSystemIfNeeded({ askForPosition: false }));

  return {
    dispatchFetchProfile: fetchProfile,
    dispatchLoadMapSettings: loadMapSettings,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SiteProfileContainer);
