import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { propTypeColor, propTypeColorVariant } from '../../util/propTypes';
import Cell from './Cell';

class CheckboxCell extends Component {
  static propTypes = {
    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,

    /**
     * Called when checkbox changes.
     */
    onChange: PropTypes.func,

    /**
     * Row data object.
     */
    rowData: PropTypes.object,

    /**
     * Whether or not the checkbox is checked on.
     */
    checked: PropTypes.bool,

    /**
     * Checkbox is indeterminate state.
     */
    indeterminate: PropTypes.bool,

    /**
     * Do not show bottom border.
     */
    hideBorderBottom: PropTypes.bool,

    /**
     * Background color.
     */
    bgColor: propTypeColor,

    /**
     * Background color variant.
     */
    bgColorVariant: propTypeColorVariant,

    /**
     * Vertical alignment.
     */
    vAlign: PropTypes.oneOf(['top', 'center', 'bottom', 'stretch']),
  };

  static defaultProps = {
    onChange: undefined,
    checked: false,
    hideBorderBottom: false,
    vAlign: 'top',
  };

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.inputRef.current.indeterminate = this.props.indeterminate;

    if (this.props.checked) {
      this.inputRef.current.indeterminate = false;
    }
  }

  handleOnChange(event) {
    this.props.onChange(this.props.rowData, event.target.checked);
  }

  renderInput() {
    return (
      <input
        className={this.props.classes.input}
        ref={this.inputRef}
        type="checkbox"
        onChange={this.handleOnChange}
        checked={this.props.checked}
      />
    );
  }

  render() {
    const { classes, ...rest } = this.props;
    return (
      <Cell
        hideBorderBottom={this.props.hideBorderBottom}
        bgColor={this.props.bgColor}
        bgColorVariant={this.props.bgColorVariant}
        width={50}
        {...rest}
      >
        <div className={classes.container}>{this.renderInput()}</div>
      </Cell>
    );
  }
}

const vAlignToFlex = props => {
  switch (props.vAlign) {
    case 'top':
      return 'flex-start';
    case 'bottom':
      return 'flex-end';
    default:
      return props.vAlign;
  }
};

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: props => vAlignToFlex(props),
    height: '100%',
  },

  input: {
    transform: 'scale(1.2)',
  },
});

export default injectSheet(styles)(CheckboxCell);
