import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import injectSheet from 'react-jss';
import { Box, Column, Columns, Text } from '../../../../../lib/base';
import { COLOR_BASE_PRIMARY, COLOR_VARIANT_PRIMARY } from '../../../../Constants';
import HeadingText from '../../../text/HeadingText';

class ComplianceDataTab extends PureComponent {
  static propTypes = {
    /**
     * Tab is active.
     */
    active: PropTypes.bool.isRequired,

    /**
     * Tab title.
     */
    title: PropTypes.string.isRequired,

    /**
     * An array of sections each having a label and value property.
     */
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired,
      })
    ).isRequired,

    /**
     * onClick handler.
     */
    onClick: PropTypes.func,
  };

  render() {
    const { activeTab, tab } = this.props.classes;

    return (
      <Box
        className={this.props.active ? activeTab : tab}
        onClick={this.props.onClick}
        title={this.props.title}
      >
        <Box stack="medium">
          <HeadingText title={this.props.title} active={this.props.active}>
            {this.props.title}
          </HeadingText>
        </Box>
        {this.props.sections.map(s => (
          <Box stack="smaller" key={s.label}>
            <Columns>
              <Column flex={1}>
                <Text size="smaller">{s.label}</Text>
              </Column>
              <Column>
                <Text size="smaller" bold>
                  {s.count || 0}
                </Text>
              </Column>
            </Columns>
          </Box>
        ))}
      </Box>
    );
  }
}

const styles = theme => ({
  tab: {
    minHeight: '120px',
    height: '100%',
    padding: theme.spacing.medium,
    backgroundColor: 'transparent',
    border: `1px solid ${theme.color[COLOR_BASE_PRIMARY]['light2']}`,
    '&:hover': {
      backgroundColor: theme.color[COLOR_BASE_PRIMARY]['light1'],
      border: `1px solid ${theme.color[COLOR_BASE_PRIMARY]['dark3']}`,
      transition: 'all 350ms',
    },
  },

  activeTab: {
    extend: 'tab',
    backgroundColor: theme.color[COLOR_BASE_PRIMARY]['light1'],
    border: `1px solid ${theme.color[COLOR_BASE_PRIMARY][COLOR_VARIANT_PRIMARY]}`,

    '&:hover': {
      backgroundColor: theme.color[COLOR_BASE_PRIMARY]['light1'],
      border: `1px solid ${theme.color[COLOR_BASE_PRIMARY]['dark3']}`,
      transition: 'all 350ms',
    },
  },
});

export default injectSheet(styles)(ComplianceDataTab);
