import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Map, { MeasureTool } from '../../../../lib/map';
import Config from '../../../config';
import { isSmallScreen } from '../../../util/browser';
import OnMapButtonLayers from './mobile/button/OnMapButtonLayers';
import LayerMetadataOverlay from './shared/layer/LayerMetadataOverlay';
import MyLocationLayer from './shared/layer/MyLocationLayer';
import ReferenceLayers from './shared/layer/ReferenceLayers';
import RootBaseLayer from './shared/layer/RootBaseLayer';
import SearchedGeolocationLayer from './shared/layer/SearchedGeolocationLayer';
import SitesLayer from './shared/layer/SitesLayer';

class ConnectedMap extends PureComponent {
  static propTypes = {
    measureToolUnit: PropTypes.object,
  };

  static defaultProps = {};

  render() {
    const {
      extent,
      center,
      centerPosition,
      zoom,
      minResolution,
      maxResolution,
      padding,
      activeBaseLayer: rootBaseLayer,
      activeReferenceLayers: referenceLayers,
      sitesLayerFeatures,
      myLocation,
      geocodePoint,
      activeMeasureTool,
      measureToolUnit,
      measureToolRef,
    } = this.props;

    return (
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        {isSmallScreen() && <OnMapButtonLayers />}

        <Map
          id="themap"
          extent={extent}
          center={center}
          centerPosition={centerPosition}
          zoom={zoom}
          minResolution={minResolution}
          maxResolution={maxResolution}
          padding={padding}
          units="us"
          scaleLine={true}
        >
          <RootBaseLayer layer={rootBaseLayer} bingApiKey={Config.getBingApiKey()} />

          <ReferenceLayers extent={extent} layers={referenceLayers} />

          <SitesLayer
            fillColor={Config.getSiteClusterFillColor()}
            strokeColor={Config.getSiteClusterStrokeColor()}
            features={sitesLayerFeatures}
            zIndex={200}
          />

          <MyLocationLayer myLocation={myLocation} />

          <SearchedGeolocationLayer point={geocodePoint} />

          <MeasureTool
            ref={measureToolRef}
            drawingType={activeMeasureTool}
            unit={measureToolUnit}
            zIndex={300}
          />

          {!isSmallScreen() && <LayerMetadataOverlay />}
        </Map>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    // <Map />
    extent: state.map.extent,
    center: state.map.center,
    centerPosition: state.map.centerPosition,
    zoom: state.map.zoom,
    minResolution: state.map.minResolution,
    maxResolution: state.map.maxResolution,
    padding: state.map.padding,

    // <RootBaseLayer />
    activeBaseLayer: state.map.activeBaseLayer,

    // <Reference Layers />
    activeReferenceLayers: state.map.activeReferenceLayers,

    // <SitesLayer />
    sitesLayerFeatures: state.map.search.features,

    // <MyLocationLayer />
    myLocation: state.map.myLocation,

    // <SearchedGeolocationLayer />
    geocodePoint: state.search.geocode.data && state.search.geocode.data.point,

    // <MeasureTool />
    activeMeasureTool: state.map.activeMeasureTool,
  };
}

export default connect(mapStateToProps)(ConnectedMap);
