import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';

class SpinnerOverlay extends Component {
  static propTypes = {
    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,
  };

  render() {
    const { containerWrapper, container, centered } = this.props.classes;

    return (
      <div className={containerWrapper}>
        <div className={container}>
          <div className={centered}>
            <svg
              width="300px"
              height="300px"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              preserveAspectRatio="xMidYMid"
              style={{ background: 'none', padding: '50px' }}
            >
              <circle
                cx="50"
                cy="50"
                fill="none"
                stroke="#AAA"
                strokeWidth="3"
                r="35"
                strokeDasharray="164.93361431346415 56.97787143782138"
                transform="rotate(330 50 50)"
              >
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  calcMode="linear"
                  values="0 50 50;360 50 50"
                  keyTimes="0;1"
                  dur="1s"
                  begin="0s"
                  repeatCount="indefinite"
                />
              </circle>
            </svg>
          </div>
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  containerWrapper: {
    position: 'absolute',
    backgroundColor: 'rgba(0,0,0,0.25)',
    zIndex: 500,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: '100%',
    width: '100%',

    '&:hover': {
      cursor: 'not-allowed',
    },
  },

  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },

  centered: {
    flex: 1,
    textAlign: 'center',
  },
});

export default injectSheet(styles)(SpinnerOverlay);
