import max from 'lodash/max';
import { containsCoordinate } from 'ol/extent';
import BaseEndpoint from './BaseEndpoint';
import Urls from './Urls';
import Point from 'ol/geom/Point';
import { fromLonLat } from 'ol/proj';
import Feature from 'ol/Feature';
import isEmpty from 'lodash/isEmpty';
import { CLUSTER_RADIUS_PX } from '../../app/Constants';

/**
 * Returns the source identifier from a site feature - a site feature that's seen on
 * the map.
 *
 * @param feature site feature.
 * @returns {*}
 */
const sourceIdentifier = feature => {
  if (feature) return feature.get('sourceIdentifier');
};

/**
 * Logic to figure out the cluster size scaling. Find the max count and then put
 * each feature depending on it's count into the appropriate bucket.
 */
const setFeatureClusterPixelSize = features => {
  const maxCount = max(features.map(f => f.get('count')));

  const range = maxCount / 5;
  const level4 = range * 4;
  const level3 = range * 3;
  const level2 = range * 2;
  const level1 = range;

  features.forEach(f => {
    const count = f.get('count');

    if (count) {
      let size = CLUSTER_RADIUS_PX;

      if (count >= level4) {
        size = CLUSTER_RADIUS_PX * 1.4;
      } else if (count >= level3) {
        size = CLUSTER_RADIUS_PX * 1.2;
      } else if (count >= level2) {
        size = CLUSTER_RADIUS_PX * 1;
      } else if (count >= level1) {
        size = CLUSTER_RADIUS_PX * 0.9;
      } else {
        size = CLUSTER_RADIUS_PX * 0.8;
      }
      f.set('size', size);
    }
  });
};

/**
 * Helper to transform the search result (sites & features) from the API to OpenLayers features.
 */
const toSearchResult = (json, criteria) => {
  const features = [];
  const sites = [];

  json.features.forEach(f => {
    /**
     * If feature doesn't have coordinates skip it, we won't map it
     */
    if (f.geometry.coordinates == null) {
      return;
    }
    /**
     * Create new OpenLayers feature and set the coordinates.
     */
    const coords = fromLonLat(f.geometry.coordinates);

    const newFeature = new Feature({
      geometry: new Point(coords),
    });

    newFeature.set('coords', coords);

    /**
     * Feature ID is a server generated guid.
     */
    newFeature.setId(f.id);

    /**
     * Set the bounding box on the feature, when clicked we'll fit the map extent to it.
     */
    if (f.bbox) {
      newFeature.set('bbox', f.bbox);
    }

    /**
     * Set each property onto the OL feature instance.
     */
    const props = f.properties;
    Object.keys(props).forEach(key => newFeature.set(key, props[key]));

    features.push(newFeature);
  });

  /**
   * For each site returned we need to identify the associated feature for easier lookup later on.
   */
  json.sites.forEach(s => {
    const siteFeature = features.find(f => sourceIdentifier(f) === s.sourceIdentifier);

    if (siteFeature) {
      /**
       * Site has a matching feature that's not a cluster.
       */
      s.feature = {
        id: siteFeature.getId(),
        coordinates: siteFeature.get('coords'),
        isCluster: false,
      };
    } else {
      /**
       * Site is in a cluster so find the first cluster feature and associate them.
       */
      for (let i = 0; i < features.length; i++) {
        const f = features[i];
        if (f.get('bbox') && containsCoordinate(f.get('bbox'), [s.longitude, s.latitude])) {
          s.feature = {
            id: f.getId(),
            coordinates: f.get('coords'),
            isCluster: true,
          };
          break;
        }
      }
    }

    sites.push(s);
  });

  setFeatureClusterPixelSize(features);

  return { features, sites, count: json.count, criteria };
};

const addFeature = json => {
  let cryptoObj = window.crypto || window.msCrypto;
  let array = [];
  if (window.msCrypto) {
    let randValues = cryptoObj.getRandomValues(new Uint32Array(4));
    for (var i = 0; i < randValues.length; i++) {
      array.push(randValues[i]);
    }
  } else {
    array = cryptoObj.getRandomValues(new Uint32Array(4));
  }

  json.feature = {
    id: array.join('-'), // random id is okay
    coordinates: [json.latitude, json.longitude],
    isCluster: false,
  };
  return json;
};

/**
 * Helper to apply the advanced search criteria. The advanced criteria is embedded with the search
 * term as 'site_name: "Ravine".
 */
export const applyAdvancedCriteria = criteria => {
  if (!criteria.advanced) return criteria;

  const extra = [criteria.term];

  const pushOntoExtra = key => {
    if (!isEmpty(criteria.advanced[key])) {
      extra.push(encodeURIComponent(`${key}:"${criteria.advanced[key]}"`));
    }
  };

  Object.keys(criteria.advanced).forEach(k => pushOntoExtra(k));

  return Object.assign({}, criteria, { term: extra.join(' ') });
};

/**
 * Endpoint to search for sites.
 */
export default class SiteEndpoint extends BaseEndpoint {
  /**
   * Returns a promise resolving an array of OpenLayers features.
   *
   * @param criteria the criteria used to search for sites.
   * @param options additional fetch options.
   * @returns {Promise<any>} features
   */
  search(criteria, options) {
    const newCriteria = applyAdvancedCriteria({ ...criteria });

    const url = `${super.url(Urls.Site.Search)}?${super.toParameterString(newCriteria)}`;

    return super
      .get(url, options)
      .then(response => response.json())
      .then(json => {
        return toSearchResult(json, criteria);
      });
  }

  /**
   * Returns a promise resolving a single site
   *
   * @param sourceIdentifier the criteria used to search for sites.
   * @returns {Promise<any>} array of site search results
   */
  getSiteBySourceIdentifier(sourceIdentifier) {
    const url = `${super.url(Urls.Site.GetSite)}?SourceIdentifier=${sourceIdentifier}`;
    return super
      .get(url)
      .then(response => response.json())
      .then(json => {
        return addFeature(json);
      });
  }

  /**
   * Posts a request to claim a site via nSite
   */
  claimSite(info) {
    const url = `${super.url(Urls.Site.ClaimSite)}`;
    return super
      .post(url, info)
      .then(response => response.json())
      .then(json => {
        return json;
      });
  }
}
