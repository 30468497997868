import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { jssColorLookup } from '../../util/jss';
import { propTypeColor, propTypeColorVariant } from '../../util/propTypes';
import { cellBorderStyle } from './helper';

class Cell extends Component {
  static propTypes = {
    /**
     * Text alignment.
     */
    align: PropTypes.oneOf(['left', 'center', 'right']),

    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,

    /**
     * Cell width. Default is 200.
     */
    width: PropTypes.number,

    /**
     * Click handler.
     */
    onClick: PropTypes.func,

    /**
     * Background color.
     */
    bgColor: propTypeColor,

    /**
     * Background color variant.
     */
    bgColorVariant: propTypeColorVariant,

    /**
     * Disable border bottom.
     */
    hideBorderBottom: PropTypes.bool,

    /**
     * Tabindex to allow user's to focus element.
     */
    tabIndex: PropTypes.number,
  };

  static defaultProps = {
    width: 200,
    align: 'left',
    onClick: undefined,
    bgColor: undefined,
    bgColorVariant: undefined,
    hideBorderBottom: false,
  };

  render() {
    return (
      <div
        className={this.props.classes.container}
        onClick={this.props.onClick}
        tabIndex={this.props.tabIndex}
      >
        {this.props.children}
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    position: 'relative',
    contain: 'layout',
    width: props => `${props.width}px`,
    borderRight: cellBorderStyle(theme),
    borderBottom: props => !props.hideBorderBottom && cellBorderStyle(theme),
    backgroundColor: props =>
      props.bgColor && jssColorLookup(theme, props.bgColor, props.bgColorVariant),
    overflow: 'hidden',
    padding: theme.spacing.smaller,
    textAlign: props => props.align,

    '&:hover': {
      cursor: props => (props.onClick ? 'pointer' : undefined),
    },
  },
});

export default injectSheet(styles)(Cell);
