import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Box from '../../../lib/base/layout/Box';
import HeadingText from './HeadingText';

/**
 * Pre-styled text component for headings.
 */
export default class HeadingBlockText extends Component {
  static propTypes = {
    /**
     * Hover title
     */
    title: PropTypes.string,

    /**
     * Text size.
     */
    size: PropTypes.string,
  };

  static defaultProps = {
    title: undefined,
  };

  render() {
    return (
      <Box
        bgColor="gray"
        bgColorVariant="light1"
        mTop="small"
        pTop="small"
        pLeft="smallest"
        pBottom="small"
        stack="medium"
      >
        <HeadingText size={this.props.size}>{this.props.title}</HeadingText>
      </Box>
    );
  }
}
