import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import injectSheet from 'react-jss';
import { FadeInOut } from '../index';

/**
 * A fullscreen translucent overlay the will fade in or out when the boolean value of the 'on'
 * property changes.
 */
class Overlay extends PureComponent {
  static propTypes = {
    /**
     * JSS classes.
     */
    classes: PropTypes.object.isRequired,

    /**
     * Whether or not the overlay is turned on.
     */
    on: PropTypes.bool.isRequired,

    /**
     * Callback when overlay is clicked, typically used to to turn overlay off.
     */
    onClick: PropTypes.func,

    /**
     * Z-Index of overlay.
     */
    zIndex: PropTypes.number,
  };

  static defaultProps = {
    onClick: undefined,
    zIndex: undefined,
  };

  constructor(props) {
    super(props);
    this.handleOnClickClose = this.handleOnClickClose.bind(this);
  }

  /**
   * Handles when overlay is clicked, calls onClick prop.
   */
  handleOnClickClose(event) {
    if (this.props.onClick) {
      this.props.onClick(event);
    }
  }

  render() {
    const { overlay } = this.props.classes;

    return (
      <FadeInOut on={this.props.on}>
        <div className={overlay} onClick={this.handleOnClickClose} />
      </FadeInOut>
    );
  }
}

const styles = () => ({
  /**
   * The overlay styles.
   */
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    height: '100%',
    width: '100%',
    zIndex: props => props.zIndex,
    backgroundColor: 'rgba(0, 0, 0, 0.1)',

    '&:hover': {
      cursor: 'pointer',
    },
  },
});

export default injectSheet(styles)(Overlay);
