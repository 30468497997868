import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import injectSheet from 'react-jss';
import { CHILD_COLUMN_SELECTOR } from './Columns';

class Column extends PureComponent {
  static propTypes = {
    /**
     * Children components.
     */
    children: PropTypes.node,

    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,

    /**
     * Css classname.
     */
    className: PropTypes.string,

    /**
     * Container style.
     */
    style: PropTypes.object,

    /**
     * Shorthand form for flex-grow, flex-shrink & flex-basis.
     */
    flex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    /**
     * Vertical alignment.
     *
     * [top|center|bottom|stretch]
     */
    vAlign: PropTypes.oneOf(['top', 'center', 'bottom', 'stretch']),

    /**
     * OnClick function.
     */
    onClick: PropTypes.func,

    /**
     * React ref callback to the root container.
     */
    containerRef: PropTypes.object,

    /**
     * Title to show on hover.
     */
    title: PropTypes.string,
  };

  propsAsClassnames() {
    const classNames = [];

    switch (this.props.vAlign) {
      case 'top':
        classNames.push(this.props.classes.vAlignTop);
        break;
      case 'center':
        classNames.push(this.props.classes.vAlignCenter);
        break;
      case 'bottom':
        classNames.push(this.props.classes.vAlignBottom);
        break;
      case 'stretch':
        classNames.push(this.props.classes.vAlignStretch);
        break;
      default:
        break;
    }

    return classNames;
  }

  render() {
    const classNames = [
      CHILD_COLUMN_SELECTOR, // so the parents Columns componet can find this and add gutter
      this.props.classes.column,
    ].concat(this.propsAsClassnames());
    classNames.push(this.props.className);

    return (
      <div
        ref={this.props.containerRef}
        onClick={this.props.onClick}
        className={classNames.join(' ')}
        style={this.props.style}
        title={this.props.title}
      >
        {this.props.children}
      </div>
    );
  }
}

const styles = theme => ({
  column: {
    flex: props => props.flex || '0 1 auto',

    '&:hover': {
      cursor: props => props.onClick && 'pointer',
    },
  },

  vAlignTop: {
    alignSelf: 'flex-start',
  },

  vAlignBottom: {
    alignSelf: 'flex-end',
  },

  vAlignCenter: {
    alignSelf: 'center',
  },

  vAlignStretch: {
    alignSelf: 'stretch',
  },
});

export default injectSheet(styles)(Column);
