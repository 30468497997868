import ScaleLine from 'ol/control/ScaleLine';
import OLMap from 'ol/Map';
import View from 'ol/View';
import PropTypes from 'prop-types';
import React, { Children, Component } from 'react';
import { DEFAULT_MAP_PADDING } from '../../../app/Constants';

const DEFAULT_DURATION = 200;

/**
 * Top-level component for displaying a map.
 *
 * The most interesting aspect about this component is that it creates an instance of an OpenLayers
 * Map and stores it as an instance variable on the component. To use this component you will need
 * to add the ol.css file yourself.
 *
 * <code>this.map = new Map({})</code>
 *
 * This component will operate on that instance variable at various points in it's lifecycle.
 * Primarily what this component does is react to property changes by updating that map instance
 * variable.  For instance, if the <Map zoom={...}/> changes we'll need to handle that property
 * change and update the map instance by calling the OpenLayers Map API. For example:
 *
 * <code>this.map.setZoom(x);</code>
 *
 */
class Map extends Component {
  /**
   * List of properties this component accepts. The usage of PropTypes is for type checking of the
   * properties.
   *
   */
  static propTypes = {
    /**
     * Children components.
     */
    children: PropTypes.node,
    /**
     * Dom id where the map will be mounted. The id should not change between between renders.
     */
    id: PropTypes.string.isRequired,
    /**
     * Width of the map, can be a string or number. Defaults
     * to 100%.
     */
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * Height of the map, can be a string or number.Defaults
     * to 100%.
     */
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * The center location of the map as Web Mercator projection.
     */
    center: PropTypes.array,
    /**
     * The [X,Y] of the map view to position the center on.
     */
    centerPosition: PropTypes.array,
    /**
     * The zoom level of the map.
     */
    zoom: PropTypes.number,
    /**
     * The minimum zoom level of the map.
     */
    minResolution: PropTypes.number,
    /**
     * The maximum zoom level of the map.
     */
    maxResolution: PropTypes.number,
    /**
     * max zoom level from db
     */
    maxZoom: PropTypes.number,
    /**
     * min zoom level from db
     */
    minZoom: PropTypes.number,
    /**
     * The type of units the controls will use for distance and area. US is the
     * default.
     */
    units: PropTypes.string,
    /**
     * Is scaline visible on the map? Default is true.
     */
    scaleLine: PropTypes.bool,
    /**
     * The extent of the map as Web Mercator projection.
     */
    extent: PropTypes.array,
    /**
     * The padding around the map fitting the extent.
     */
    padding: PropTypes.array,
    /**
     * Interactive, yes or no.
     */
    interactive: PropTypes.bool,
  };

  /**
   * Default property values for those that are not required.
   */
  static defaultProps = {
    children: undefined,
    center: [-122.419416, 37.774929], // san francisco, why not?
    centerPosition: undefined,
    zoom: 20,
    maxZoom: 20,
    minZoom: 1,
    width: '100%',
    minResolution: .5,
    maxResolution: 6000,
    height: '100%',
    units: 'us',
    scaleLine: true,
    extent: undefined,
    padding: DEFAULT_MAP_PADDING,
    interactive: true,
  };

  /**
   * ~~ React Lifecycle ~~
   *
   * New in v16.3
   *
   * @see https://reactjs.org/docs/react-component.html#static-getderivedstatefromprops
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    const nextState = prevState;

    if (prevState.width !== nextProps.width) {
      nextState.width = nextProps.width;
    }

    if (prevState.height !== nextProps.height) {
      nextState.height = nextProps.height;
    }

    return nextState;
  }

  /**
   * The constructor is where you setup the component's internal state. Component state is where you
   * internally store data this component needs in order to fulfil it's purpose. This is also where
   * the map instance is created.
   */
  constructor(props) {
    super(props);

    /**
     * Initial state supplied by props.
     */
    this.state = {
      width: props.width,
      height: props.height,
    };

    /**
     * Initializes a new View and Map instance on 'this' that is used later in the lifecycle
     * when/if this component properties are updated. This is NOT typically what you'll see in
     * React components - we're wrapping the OpenLayers 4 library.
     */
    this.view = new View({
      projection: 'EPSG:3857',
      center: props.center,
      minZoom: props.minZoom,
      maxZoom: props.maxZoom,
      minResolution: props.minResolution,
      maxResolution: props.maxResolution,
      zoom: props.zoom,
    });

    /**
     * Scale-line to show rough y-axis distances.
     */
    this.scaleLine = new ScaleLine({
      units: props.units,
    });

    this.map = new OLMap({
      view: this.view,
      layers: [],
      loadTilesWhileInteracting: true,
    });

    /**
     * blur focus on map click so the keyboard hides
     */
    this.map.on('click', function() {
      if (document.activeElement) {
        document.activeElement.blur();
      }
    });

    /**
     * Remove the default interactions if non-interactive map.
     */
    if (!this.props.interactive) {
      this.map.getInteractions().forEach(i => i.setActive(false));
    }

    this.updateSize = this.updateSize.bind(this);
  }

  /**
   * ~~ React Lifecycle ~~
   *
   * After the components mounts in React land apply the map instance to a dom target - in this
   * case a dom element id.
   */
  componentDidMount() {
    this.map.setTarget(this.props.id);

    /**
     * Add scale-line to map.
     */
    if (this.props.scaleLine) {
      this.scaleLine.setMap(this.map);
    }

    if (this.canFitMap()) {
      this.fitMap(true);
    } else {
      this.centerMap();
    }
  }

  /**
   * ~~ React Lifecycle ~~
   *
   * Handle when Map component properties have been updated by parent components. Note that we only
   * care about the properties that make sense to have changed during the lifecycle of the <Map/>
   * such as width, height, or zoom. Prior to React v16.3 this logic would have been seen in the
   * componentWillReceiveProps() lifecycle function. Also note, this logic depends on the state
   * being updated with the new values (ie. zoom, width, height) by the getDerivedStateFromProps()
   * function, which again is new in v16.3. Google it to know more.
   *
   *    minResolution     - Prevents blank tiles when zooming to clusters that very
   *                        close together.
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    /**
     * Set the view properties.
     */
    if (prevProps.minResolution !== this.props.minResolution) {
      this.view.setMinResolution(this.props.minResolution);
    }

    if (prevProps.maxResolution !== this.props.maxResolution) {
      this.view.setMaxResolution(this.props.maxResolution);
    }

    if (this.props.maxZoom && prevProps.maxZoom !== this.props.maxZoom) {
      this.view.setMaxZoom(this.props.maxZoom);
    }

    if (prevProps.minZoom !== this.props.minZoom) {
      this.view.setMinZoom(this.props.minZoom);
    }

    if (prevProps.zoom !== this.props.zoom) {
      let zoom = this.props.maxZoom < this.props.zoom ? this.props.maxZoom : this.props.zoom;
      this.view.setZoom(zoom);
    }

    /**
     * Update the map size by detecting width/height prop changes.
     */
    if (prevProps.width !== this.props.width || prevProps.height !== this.props.height) {
      this.map.updateSize();
    }

    const paddingUpdated = !this.isPaddingEqual(prevProps.padding, this.props.padding);

    /**
     * Extent.
     */
    if (prevProps.extent !== this.props.extent || paddingUpdated) {
      this.fitMap(true);
    } else if (prevProps.center !== this.props.center) {
      this.centerMap();
    }
  }

  isPaddingEqual(left, right) {
    return (
      left[0] === right[0] && left[1] === right[1] && left[2] === right[2] && left[3] === right[3]
    );
  }

  /**
   * ~~ React Lifecycle ~~
   *
   * The intent here is to clean up after ourselves and avoid memory leaks. I'm not certain that
   * would be the case if we haven't implemented the logic below, just wanted to point it out by
   * providing it anyways.
   */
  componentWillUnmount() {
    this.map.setTarget(undefined);
    this.map = undefined;
    this.view = undefined;
  }

  calculateCenter = (coordinate, size, position) => {
    // calculate rotated position
    const rotation = this.view.getRotation();
    const cosAngle = Math.cos(-rotation);
    let sinAngle = Math.sin(-rotation);
    let rotX = coordinate[0] * cosAngle - coordinate[1] * sinAngle;
    let rotY = coordinate[1] * cosAngle + coordinate[0] * sinAngle;
    const resolution = this.view.getResolution();
    rotX += (size[0] / 2 - position[0]) * resolution;
    rotY += (position[1] - size[1] / 2) * resolution;

    // go back to original angle
    sinAngle = -sinAngle; // go back to original rotation
    const centerX = rotX * cosAngle - rotY * sinAngle;
    const centerY = rotY * cosAngle + rotX * sinAngle;
    return [centerX, centerY];
  };

  centerMap() {
    let zoom = this.props.maxZoom < this.props.zoom ? this.props.maxZoom : this.props.zoom;
    if (this.props.centerPosition) {
      const newCenter = this.calculateCenter(
        this.props.center,
        this.map.getSize(),
        this.props.centerPosition
      );

      this.view.animate({
        duration: DEFAULT_DURATION,
        center: newCenter,
      });
    } else {
      this.view.animate({
        duration: DEFAULT_DURATION,
        center: this.props.center,
      });
    }
  }

  canFitMap() {
    return this.props.extent !== undefined;
  }

  fitMap(constrain = false) {
    const fitOptions = {
      constrainResolution: constrain,
      duration: DEFAULT_DURATION,
      padding: this.props.padding,
      maxZoom: this.props.maxZoom,
    };

    this.view.fit(this.props.extent, fitOptions);
  }

  updateSize() {
    this.map.updateSize();
  }

  /**
   * ~~ React Lifecycle ~~
   *
   * Renders the map using the internal state and provided properties.
   */
  render() {
    const style = {
      width: this.state.width,
      height: this.state.height,
    };

    const { id, children } = this.props;

    /**
     * Below is not something you will typically use in your components.
     *
     * Anyways, map over the child components, clone them and append an additional 'map' property
     * which is the map instance we created in the constructor. Child components like LayerBingMaps
     * will add themselves to the provided map instance.
     */
    return (
      <div id={id} style={style} tabIndex="0">
        {Children.map(children, childElement => {
          if (React.isValidElement(childElement)) {
            return React.cloneElement(childElement, {
              map: this.map,
            });
          } else {
            return childElement;
          }
        })}
      </div>
    );
  }
}

export default Map;
