import ApiContext from './impl/ApiContext';
import AppSettingsEndpoint from './impl/AppSettingsEndpoint';
import ExportEndpoint from './impl/ExportEndpoint';
import FilterEndpoint from './impl/FilterEndpoint';
import GeocodeEndpoint from './impl/GeocodeEndpoint';
import LayerEndpoint from './impl/LayerEndpoint';
import ProfileEndpoint from './impl/ProfileEndpoint';
import SearchEndpoint from './impl/SearchEndpoint';
import SearchKeyEndpoint from './impl/SearchKeyEndpoint';
import SiteEndpoint from './impl/SiteEndpoint';

/**
 * nSITE Javascript Client.
 */
class NSiteClient {
  constructor(options) {
    /**
     * The API context.
     */
    this.apiContext = new ApiContext({
      baseUrl: options.baseUrl,
    }).configure();

    /**
     * Endpoint cache - this is only necessary if the construction of endpoints are expensive.
     */
    this.endpoints = {
      profile: undefined,
      appSettings: undefined,
      layer: undefined,
    };
  }

  get geocode() {
    if (!this.endpoints.geocode) {
      this.endpoints.geocode = new GeocodeEndpoint(this.apiContext);
    }
    return this.endpoints.geocode;
  }

  get profile() {
    if (!this.endpoints.profile) {
      this.endpoints.profile = new ProfileEndpoint(this.apiContext);
    }
    return this.endpoints.profile;
  }

  get system() {
    if (!this.endpoints.appSettings) {
      this.endpoints.appSettings = new AppSettingsEndpoint(this.apiContext);
    }
    return this.endpoints.appSettings;
  }

  get layer() {
    if (!this.endpoints.layer) {
      this.endpoints.layer = new LayerEndpoint(this.apiContext);
    }
    return this.endpoints.layer;
  }

  get site() {
    if (!this.endpoints.site) {
      this.endpoints.site = new SiteEndpoint(this.apiContext);
    }
    return this.endpoints.site;
  }

  get search() {
    if (!this.endpoints.search) {
      this.endpoints.search = new SearchEndpoint(this.apiContext);
    }
    return this.endpoints.search;
  }

  get searchKey() {
    if (!this.endpoints.searchkey) {
      this.endpoints.searchkey = new SearchKeyEndpoint(this.apiContext);
    }
    return this.endpoints.searchkey;
  }

  get filter() {
    if (!this.endpoints.filter) {
      this.endpoints.filter = new FilterEndpoint(this.apiContext);
    }
    return this.endpoints.filter;
  }

  get exportGroups() {
    if (!this.endpoints.exportGroups) {
      this.endpoints.exportGroups = new ExportEndpoint(this.apiContext);
    }
    return this.endpoints.exportGroups;
  }
}

export default NSiteClient;
