/*******************************************************************************
 *
 * Utility functions to work with the array of layers coming back from the nSITE api.
 *
 *******************************************************************************/

/**
 * Helper to sort layers by displayOrder property.
 */
export const sortByDisplayOrder = (layerA, layerB) => {
  const a = layerA.displayOrder;
  const b = layerB.displayOrder;

  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

/**
 * Is default layer.
 */
const isDefault = layer => layer.isDefault;

/**
 * Is base layer.
 */
const isBaseLayer = layer => layer.isBaseLayer;

/**
 * Is non-base layer.
 */
const isNonBaseLayer = layer => !layer.isBaseLayer;

/**
 * Is non-base layer.
 */
const hasGroupName = groupName => layer => layer.groupName === groupName;

/**
 * Base layers.
 */
export const findAllBaseLayers = layers => layers.filter(isBaseLayer).sort(sortByDisplayOrder);

/**
 * Default base layer.
 */
export const findDefaultBaseLayer = layers => findAllBaseLayers(layers).filter(isDefault)[0];

/**
 * Non base layers.
 */
export const findAllNonBaseLayers = layers =>
  layers.filter(isNonBaseLayer).sort(sortByDisplayOrder);

/**
 * Non base layers by group name.
 */
export const findAllNonBaseLayersByGroupName = (layers, groupName) =>
  layers.filter(hasGroupName(groupName)).sort(sortByDisplayOrder);
