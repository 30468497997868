import { buffer } from 'ol/extent';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Map from '../../../lib/map';
import { toWebMercatorCenter } from '../../../lib/map/core/transfomer';
import { startMapSystemIfNeeded } from '../../../store/system/system.action';
import Config from '../../config';
import RootBaseLayer from '../../page/map/components/shared/layer/RootBaseLayer';
import SiteFeaturesLayer from '../../page/map/components/shared/layer/SiteFeaturesLayer';

class ReportMap extends PureComponent {
  static propTypes = {
    siteProfile: PropTypes.object.isRequired,
    onMapReady: PropTypes.func.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    minResolution: PropTypes.number,
    maxResolution: PropTypes.number,
    rootBaseLayer: PropTypes.object,
    isStarting: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {};
    this.mapRef = React.createRef();
    this.handleOnAfterPopulateFeatures = this.handleOnAfterPopulateFeatures.bind(this);
    this.onTilesLoadComplete = this.onTilesLoadComplete.bind(this);
  }

  onTilesLoadComplete() {
    const onMapReady = dataURL => {
      this.props.onMapReady(dataURL);
    };
    const map = this.mapRef.current.map;
    if (map) {
      map.once('rendercomplete', event => {
        const canvas = event.context.canvas;
        const dataURL = canvas.toDataURL();
        onMapReady(dataURL);
      });
      map.renderSync();
    }
  }

  handleOnAfterPopulateFeatures({ vectorSource }) {
    this.setState({
      extent: buffer(vectorSource.getExtent(), 150),
    });
  }

  render() {
    const { siteProfile, minResolution, maxResolution, rootBaseLayer, width, height } = this.props;

    const { longitude, latitude } = siteProfile.profileFacilityDetail;

    if (!longitude || !latitude) {
      return null;
    }

    const center = toWebMercatorCenter([longitude, latitude]);

    return (
      <Map
        id="profileMap"
        minResolution={minResolution}
        maxResolution={maxResolution}
        extent={this.state.extent}
        height={`${height}px`}
        width={`${width}px`}
        center={center}
        scaleLine={false}
        interactive={false}
        ref={this.mapRef}
      >
        <RootBaseLayer
          layer={rootBaseLayer}
          bingApiKey={Config.getBingApiKey()}
          onTilesLoadComplete={this.onTilesLoadComplete}
        />
        <SiteFeaturesLayer onAfterPopulateFeatures={this.handleOnAfterPopulateFeatures} />
      </Map>
    );
  }
}

function mapStateToProps(state) {
  return {
    minResolution: state.map.minResolution,
    maxResolution: state.map.maxResolution,
    rootBaseLayer: state.map.activeBaseLayer,
    isStarting: state.map.startup.isStarting,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchLoadMapSettings: () => {
      dispatch(startMapSystemIfNeeded());
    },
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ReportMap)
);
