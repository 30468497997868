import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Box } from '../../../../../lib/base';
import HeadingText from '../../../text/HeadingText';

export default class ComplianceDataSection extends PureComponent {
  static propTypes = {
    /**
     * Children.
     */
    children: PropTypes.node.isRequired,

    /**
     * Section title.
     */
    title: PropTypes.string.isRequired,

    /**
     * Is visible on the screen.
     */
    visible: PropTypes.bool.isRequired,
  };

  render() {
    return (
      <Box stack="large" visible={this.props.visible}>
        <Box stack="medium">
          <HeadingText title={this.props.title}>{this.props.title}</HeadingText>
        </Box>
        {this.props.children}
      </Box>
    );
  }
}
