import { Page as PageComponent, View } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
import { fontSizes } from '../../shared/typography';

export const Page = styled(PageComponent)`
  padding-top: 72pt;
  padding-right: 72pt;
  padding-left: 72pt;
  padding-bottom: 92pt;
`;

export const Body = styled(View)`
  font-family: 'OpenSans';
  font-size: ${fontSizes.small};
`;
