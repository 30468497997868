import isArray from 'lodash/isArray';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import LabelValueTable from '../../../pdf/lv/LabelValueTable';
import { ProfileSection } from '../../../../lib/pdf';
export class GeopoliticalSection extends PureComponent {
  static propTypes = {
    /**
     * Site profile object.
     */
    siteProfile: PropTypes.object,
  };

  static defaultProps = {
    siteProfile: false,
  };

  hasData() {
    const { siteProfile } = this.props;

    if (!siteProfile || !isArray(siteProfile.facilityGeopolitical)) {
      return false;
    }

    return siteProfile.facilityGeopolitical.length > 0;
  }

  render() {
    const { siteProfile } = this.props;

    if (!this.hasData()) {
      return null;
    }

    const geoPoliticalData = siteProfile.facilityGeopolitical.filter(
      g => g.geoPolTypeVisible === 'Y'
    );

    return (
      <ProfileSection size="small" hr={false}>
        <LabelValueTable
          data={geoPoliticalData}
          labelFunc={row => row.geoPolTypeLabel}
          valueFunc={row => row.geoPolValue}
        />
      </ProfileSection>
    );
  }
}
