import {
  BOX_DEFAULT_BACKGROUND_COLOR,
  BOX_DEFAULT_BORDER_COLOR,
  BOX_DEFAULT_BORDER_WIDTH,
} from './variables';

/**
 * Box, for putting things in.
 */

const box = {
  default: {
    backgroundColor: BOX_DEFAULT_BACKGROUND_COLOR,
    borderWidth: BOX_DEFAULT_BORDER_WIDTH,
    borderColor: BOX_DEFAULT_BORDER_COLOR,
  },
};

export default box;
