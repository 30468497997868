import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Box, Column, Columns, Text } from '../../../lib/base';
import SpinnerPanel from '../../components/spinner/SpinnerPanel';
import ConfigKeys from '../../config/keys';
import { isSmallScreen } from '../../util/browser';
import { label } from '../../util/label';
import Select from '../form/Select';
import HeadingText from '../text/HeadingText';
import ComplianceActionsContainer from './compliance/ComplianceActionsContainer';
import ComplianceDataSection from './compliance/component/ComplianceDataSection';
import ComplianceDataSectionEmpty from './compliance/component/ComplianceDataSectionEmpty';
import ComplianceDataTab from './compliance/component/ComplianceDataTab';
import EvaluationsContainer from './compliance/EvaluationsContainer';
import ViolationsContainer from './compliance/ViolationsContainer';

const COMPLIANCE_TABS = ['EVALUATIONS', 'VIOLATIONS', 'COMPLIANCE_ACTIONS'];

const api = () => window.nSITE.Api;

class ComplianceContainer extends Component {
  static propTypes = {
    /**
     * The source identifier.
     */
    sourceIdentifier: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      sourceIdentifier: undefined,
      isLoading: true,
      tabs: undefined,
      activeTab: undefined,
    };

    this.handleOnClickTab = this.handleOnClickTab.bind(this);
    this.handleOnChangeTab = this.handleOnChangeTab.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sourceIdentifier !== this.props.sourceIdentifier) {
      this.fetchData();
    }
  }

  handleOnClickTab(tab) {
    this.setState({ activeTab: tab });
  }

  handleOnChangeTab(event, tab) {
    this.setState({ activeTab: tab });
  }

  fetchData() {
    api()
      .profile.getComplianceSummary(this.props.sourceIdentifier)
      .then(complianceSummary => {
        const tabs = this.tabData(complianceSummary);

        this.setState({
          complianceSummary,
          tabs,
          isLoading: false,
          activeTab: tabs[0],
        });
      })
      .catch(err => {
        this.setState({
          error: err,
          isLoading: false,
        });
      });
  }

  tabData(complianceSummary) {
    if (!complianceSummary) {
      return;
    }

    const tabs = [];

    const evaluationSections = complianceSummary.isShowTotalEvaluationsOnly
      ? [{ label: 'Total', count: this.props.evaluationsCount }]
      : [
          {
            label: label(ConfigKeys.LABEL_COMPLIANCE_HAS_VIOLATIONS, 'Violations Found'),
            count: complianceSummary.evaluationsViolationFoundCount,
          },
          {
            label: label(ConfigKeys.LABEL_COMPLIANCE_NO_VIOLATIONS, 'Violations Not Found'),
            count: complianceSummary.evaluationsViolationNotFoundCount,
          },
        ];

    const violationsSections = complianceSummary.isShowTotalViolationsOnly
      ? [{ label: 'Total', count: complianceSummary.violationsTotal }]
      : [
          {
            label: label(ConfigKeys.LABEL_VIOLATIONS_OPEN, 'Open'),
            count: complianceSummary.violationsOpenCount,
          },
          {
            label: label(ConfigKeys.LABEL_VIOLATIONS_CLOSED, 'Closed'),
            count: complianceSummary.violationsClosedCount,
          },
        ];

    const actionsSections = complianceSummary.isShowTotalComplianceActionsOnly
      ? [{ label: 'Total', count: complianceSummary.complianceActionsTotal }]
      : [
          {
            label: label(ConfigKeys.LABEL_COMPLIANCE_ACTIONS_FORMAL, 'Formal'),
            count: complianceSummary.complianceActionsFormalCount,
          },
          {
            label: label(ConfigKeys.LABEL_COMPLIANCE_ACTIONS_INFORMAL, 'Informal'),
            count: complianceSummary.complianceActionsInformalCount,
          },
        ];

    tabs.push({
      id: COMPLIANCE_TABS[0],
      title: label(ConfigKeys.LABEL_COMPLIANCE_EVALUATIONS, 'Evaluations'),
      sections: evaluationSections,
    });

    tabs.push({
      id: COMPLIANCE_TABS[1],
      title: label(ConfigKeys.LABEL_COMPLIANCE_VIOLATIONS, 'Violations'),
      sections: violationsSections,
    });

    tabs.push({
      id: COMPLIANCE_TABS[2],
      title: label(ConfigKeys.LABEL_COMPLIANCE_ACTIONS, 'Compliance Actions'),
      sections: actionsSections,
    });

    return tabs;
  }

  isTabActive(tab) {
    return this.state.activeTab && this.state.activeTab.id === tab.id;
  }

  renderComplianceEvaluationsPanel() {
    return this.state.complianceSummary.isShowTotalEvaluationsOnly
      ? this.renderComplianceEvaluationsPanelTotal(this.state.complianceSummary)
      : this.renderComplianceEvaluationsPanelEach(this.state.complianceSummary);
  }

  renderComplianceEvaluationsPanelTotal() {
    const count = this.props.evaluationsCount;

    if (count === 0) {
      return (
        <ComplianceDataSectionEmpty>No compliance evaluations found.</ComplianceDataSectionEmpty>
      );
    }

    return (
      <ComplianceDataSection visible={count > 0} title="Total">
        <EvaluationsContainer sourceIdentifier={this.props.sourceIdentifier} />
      </ComplianceDataSection>
    );
  }

  renderComplianceEvaluationsPanelEach(complianceSummary) {
    const foundCount = complianceSummary.evaluationsViolationFoundCount;
    const notFoundCount = complianceSummary.evaluationsViolationNotFoundCount;

    if (foundCount === 0 && notFoundCount === 0) {
      return (
        <ComplianceDataSectionEmpty>No compliance evaluations found.</ComplianceDataSectionEmpty>
      );
    } else {
      const foundTitle =
        label(ConfigKeys.LABEL_COMPLIANCE_HAS_VIOLATIONS, 'Violations Found') + ` (${foundCount})`;
      const notFoundTitle =
        label(ConfigKeys.LABEL_COMPLIANCE_NO_VIOLATIONS, 'Violations Not Found') +
        ` (${notFoundCount})`;

      return (
        <Box>
          <ComplianceDataSection visible={foundCount > 0} title={foundTitle}>
            <EvaluationsContainer
              sourceIdentifier={this.props.sourceIdentifier}
              violationsFound={true}
            />
          </ComplianceDataSection>
          <ComplianceDataSection visible={notFoundCount > 0} title={notFoundTitle}>
            <EvaluationsContainer
              sourceIdentifier={this.props.sourceIdentifier}
              violationsFound={false}
            />
          </ComplianceDataSection>
        </Box>
      );
    }
  }

  renderViolationsPanel() {
    return this.state.complianceSummary.isShowTotalViolationsOnly
      ? this.renderViolationsPanelTotal(this.state.complianceSummary)
      : this.renderViolationsPanelEach(this.state.complianceSummary);
  }

  renderViolationsPanelTotal(complianceSummary) {
    const count = complianceSummary.violationsTotal;

    if (count === 0) {
      return <ComplianceDataSectionEmpty>No violations found.</ComplianceDataSectionEmpty>;
    }

    return (
      <ComplianceDataSection visible={count > 0} title="Total">
        <ViolationsContainer sourceIdentifier={this.props.sourceIdentifier} />
      </ComplianceDataSection>
    );
  }

  renderViolationsPanelEach(complianceSummary) {
    const openCount = complianceSummary.violationsOpenCount;
    const closedCount = complianceSummary.violationsClosedCount;

    if (openCount === 0 && closedCount === 0) {
      return <ComplianceDataSectionEmpty>No violations found.</ComplianceDataSectionEmpty>;
    } else {
      const openTitle = label(ConfigKeys.LABEL_VIOLATIONS_OPEN, 'Open') + ` (${openCount})`;
      const closedTitle = label(ConfigKeys.LABEL_VIOLATIONS_CLOSED, 'Closed') + ` (${closedCount})`;

      return (
        <Box>
          <ComplianceDataSection visible={openCount > 0} title={openTitle}>
            <ViolationsContainer sourceIdentifier={this.props.sourceIdentifier} isOpen={true} />
          </ComplianceDataSection>
          <ComplianceDataSection visible={closedCount > 0} title={closedTitle}>
            <ViolationsContainer sourceIdentifier={this.props.sourceIdentifier} isOpen={false} />
          </ComplianceDataSection>
        </Box>
      );
    }
  }

  renderComplianceActions() {
    return this.state.complianceSummary.isShowTotalComplianceActionsOnly
      ? this.renderComplianceActionsTotal(this.state.complianceSummary)
      : this.renderComplianceActionsEach(this.state.complianceSummary);
  }

  renderComplianceActionsTotal(complianceSummary) {
    const count = complianceSummary.complianceActionsTotal;

    if (count === 0) {
      return <ComplianceDataSectionEmpty>No compliance actions found.</ComplianceDataSectionEmpty>;
    }

    return (
      <ComplianceDataSection visible={count > 0} title="Total">
        <ComplianceActionsContainer sourceIdentifier={this.props.sourceIdentifier} />
      </ComplianceDataSection>
    );
  }

  renderComplianceActionsEach(complianceSummary) {
    const formalCount = complianceSummary.complianceActionsFormalCount;
    const informalCount = complianceSummary.complianceActionsInformalCount;

    if (formalCount === 0 && informalCount === 0) {
      return <ComplianceDataSectionEmpty>No compliance actions found.</ComplianceDataSectionEmpty>;
    } else {
      const formalTitle =
        label(ConfigKeys.LABEL_COMPLIANCE_ACTIONS_FORMAL, 'Formal') + ` (${formalCount})`;
      const informalTitle =
        label(ConfigKeys.LABEL_COMPLIANCE_ACTIONS_INFORMAL, 'Informal') + ` (${informalCount})`;

      return (
        <Box mTop="medium">
          <ComplianceDataSection visible={formalCount > 0} title={formalTitle}>
            <ComplianceActionsContainer
              sourceIdentifier={this.props.sourceIdentifier}
              isFormal={true}
            />
          </ComplianceDataSection>
          <ComplianceDataSection visible={informalCount > 0} title={informalTitle}>
            <ComplianceActionsContainer
              sourceIdentifier={this.props.sourceIdentifier}
              isFormal={false}
            />
          </ComplianceDataSection>
        </Box>
      );
    }
  }

  renderTabs() {
    if (isSmallScreen()) {
      const label = choice => (
        <Box title={choice.title}>
          <HeadingText title={choice.title}>{choice.title}</HeadingText>
          {choice.sections.map(s => (
            <Box stack="smaller" key={s.label}>
              <Columns>
                <Column flex={1}>
                  <Text size="smaller">{s.label}</Text>
                </Column>
                <Column>
                  <Text size="smaller" bold>
                    {s.count || 0}
                  </Text>
                </Column>
              </Columns>
            </Box>
          ))}
        </Box>
      );

      return (
        <Select
          value={this.state.activeTab}
          onChange={this.handleOnChangeTab}
          placeholder="Choose..."
          choices={this.state.tabs}
          keyExtractor={choice => choice.id}
          labelExtractor={choice => label(choice)}
          renderChoice={choice => label(choice)}
        />
      );
    } else {
      return (
        <Columns gutter="medium">
          {this.state.tabs.map(tab => (
            <Column flex={1} key={tab.id}>
              <ComplianceDataTab
                title={tab.title}
                active={this.isTabActive(tab)}
                onClick={() => this.handleOnClickTab(tab)}
                sections={tab.sections}
              />
            </Column>
          ))}
        </Columns>
      );
    }
  }

  render() {
    if (this.state.isLoading) {
      return <SpinnerPanel />;
    }

    if (this.state.error) {
      return <Text>{this.state.error}</Text>;
    }

    if (!this.state.tabs) {
      return null;
    }

    return (
      <Box mTop="medium">
        {this.renderTabs()}

        <Box pTop="large">
          {this.state.activeTab.id === COMPLIANCE_TABS[0] &&
            this.renderComplianceEvaluationsPanel()}

          {this.state.activeTab.id === COMPLIANCE_TABS[1] && this.renderViolationsPanel()}

          {this.state.activeTab.id === COMPLIANCE_TABS[2] && this.renderComplianceActions()}
        </Box>
      </Box>
    );
  }
}

function mapStateToProps(state) {
  return {
    evaluationsCount: state.profile.detail.data.profileFacilityDetail.totalComplianceEvaluations,
  };
}

export default connect(mapStateToProps)(ComplianceContainer);
