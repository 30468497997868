import {
  FETCH_EXPORTGROUPS_BEGIN,
  FETCH_EXPORTGROUPS_ERROR,
  FETCH_EXPORTGROUPS_SUCCESS,
  FETCH_EXPORTCSV_BEGIN,
  FETCH_EXPORTCSV_ERROR,
  FETCH_EXPORTCSV_SUCCESS,
} from './export.actionType';

/**
 * initial state
 */
const initialState = {
  exportTypeGroups: {
    isLoading: false,
    data: undefined,
    error: undefined,
  },
  csv: {
    isLoading: false,
    data: undefined,
    error: undefined,
    idArray: [],
  },
};

/**
 * profile reducer function
 * @param state
 * @param action
 * @returns {*}
 */

const exportData = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_EXPORTGROUPS_BEGIN:
      return Object.assign({}, state, {
        exportTypeGroups: {
          ...state.exportTypeGroups,
          isLoading: true,
        },
      });
    case FETCH_EXPORTGROUPS_SUCCESS:
      return Object.assign({}, state, {
        exportTypeGroups: {
          isLoading: false,
          data: payload.results,
          error: undefined,
        },
      });
    case FETCH_EXPORTGROUPS_ERROR:
      return Object.assign({}, state, {
        exportTypeGroups: {
          isLoading: false,
          data: undefined,
          error: payload.error,
        },
      });
    case FETCH_EXPORTCSV_BEGIN:
      return Object.assign({}, state, {
        csv: {
          isLoading: true,
          error: undefined,
        },
      });
    case FETCH_EXPORTCSV_SUCCESS:
      return Object.assign({}, state, {
        csv: {
          isLoading: false,
        },
      });
    case FETCH_EXPORTCSV_ERROR:
      return Object.assign({}, state, {
        csv: {
          isLoading: false,
          error: payload.error,
        },
      });

    default:
      return Object.assign({}, state, {});
  }
};

export default exportData;
