import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Link } from '../../../../lib/base';
import { isSmallScreen } from '../../../util/browser';

export default class CasLink extends PureComponent {
  static propTypes = {
    /**
     * The url to the CAS.
     */
    url: PropTypes.string,

    /**
     * The mobile url to the CAS.
     */
    mobileUrl: PropTypes.string,

    /**
     * The CAS number to construct link with.
     */
    casNumber: PropTypes.string,
  };

  render() {
    const url = isSmallScreen() ? this.props.mobileUrl : this.props.url;

    return (
      <Link size="smaller" href={url} target="_blank">
        {this.props.casNumber}
      </Link>
    );
  }
}
