import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import injectSheet from 'react-jss';
import WindowResizeListener from '../../../app/components/listener/WindowResizeListener';
import SpinnerOverlay from '../../../app/components/spinner/SpinnerOverlay';
import { domRect } from '../../../app/util/react';
import Box from '../layout/Box';
import { propTypeColor } from '../util/propTypes';
import FilterHeader from './grid/FilterHeader';
import { sumColumnWidths } from './grid/helper';
import HorizontalScrollView from './grid/HorizontalScrollView';
import Paginator from './grid/Paginator';
import Rows from './grid/Rows';
import SortHeader from './grid/SortHeader';

class DataGrid extends PureComponent {
  static propTypes = {
    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,

    /**
     * The base color of the data grid.
     */
    color: propTypeColor,

    /**
     * The columns.
     */
    columns: PropTypes.array,

    /**
     * The data to display.
     */
    data: PropTypes.array,

    /**
     * Function to extract the row id.
     */
    keyExtractor: PropTypes.func.isRequired,

    /**
     * Callback to load data on request.
     */
    onRequestData: PropTypes.func,

    /**
     * Current page to display.
     */
    page: PropTypes.number,

    /**
     * The number of rows in each page.
     */
    pageSize: PropTypes.number,

    /**
     * Total numbers of records.
     */
    total: PropTypes.number,

    /**
     * Is data loading.
     */
    loading: PropTypes.bool,

    /**
     * Show top pagination?
     */
    showPaginationTop: PropTypes.bool,

    /**
     * Show bottom pagination?
     */
    showPaginationBottom: PropTypes.bool,

    /**
     * Add zebra-striping to rows.
     */
    striped: PropTypes.bool,

    /**
     * Renders subsection when extender is open.
     */
    renderSubsection: PropTypes.func,

    /**
     * The property name to sort the results by.
     */
    sortBy: PropTypes.string,

    /**
     * Is the sort ascending?
     */
    isAsc: PropTypes.bool,

    /**
     * The search criteria.
     */
    criteria: PropTypes.object,

    /**
     * Callback when a row is selected.
     */
    onSelect: PropTypes.func,

    /**
     * Enable filters
     */
    enableFiltering: PropTypes.bool,

    /**
     * Pre-selections
     */
    selections: PropTypes.array,
  };

  static defaultProps = {
    color: 'primary',
    loading: false,
    showPaginationTop: false,
    showPaginationBottom: true,
    striped: false,
    keyExtractor: row => row.id,
    sortBy: undefined,
    isAsc: true,
    criteria: {},
    onSelect: undefined,
    enableFiltering: true,
    selections: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      selections: props.selections || [],
    };
    this.containerRef = React.createRef();
    this.handleOnPageChange = this.handleOnPageChange.bind(this);
    this.handleOnSortChange = this.handleOnSortChange.bind(this);
    this.handleOnCriteriaChange = this.handleOnCriteriaChange.bind(this);
    this.handleOnSelect = this.handleOnSelect.bind(this);
    this.handleOnSelectAll = this.handleOnSelectAll.bind(this);
    this.handleOnWindowResize = this.handleOnWindowResize.bind(this);
  }

  componentDidMount() {
    if (this.containerRef && this.containerRef.current) {
      this.updateWidthState();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.width !== this.getNextWidth()) {
      this.updateWidthState();
    }

    if (prevState.selections !== this.props.selections) {
      this.setState({
        selections: this.props.selections,
      });
    }
  }

  handleOnSortChange(column) {
    const isAsc = this.props.sortBy === column.colName ? !this.props.isAsc : true;

    if (this.props.onRequestData) {
      this.props.onRequestData({
        page: 1,
        pageSize: this.props.pageSize,
        sortBy: column.colName,
        isAsc: isAsc,
        criteria: this.props.criteria,
      });
    }
  }

  handleOnPageChange({ page, pageSize }) {
    if (this.props.onRequestData) {
      this.props.onRequestData({
        page: page,
        pageSize: pageSize,
        sortBy: this.props.sortBy,
        isAsc: this.props.isAsc,
        criteria: this.props.criteria,
      });
    }
  }

  handleOnCriteriaChange(criteria) {
    if (this.props.onRequestData) {
      this.props.onRequestData({
        page: 1,
        pageSize: this.props.pageSize,
        sortBy: this.props.sortBy,
        isAsc: this.props.isAsc,
        criteria: criteria,
      });
    }
  }

  handleOnSelect(rowData, checked) {
    const { keyExtractor, onSelect } = this.props;

    if (!onSelect) {
      return undefined;
    }

    const cb = (onSelect, selections) => onSelect && onSelect(selections);

    if (checked) {
      this.setState(
        prevState => ({
          selections: [...prevState.selections, rowData],
        }),
        () => cb(onSelect, this.state.selections)
      );
    } else {
      const id = keyExtractor(rowData);
      this.setState(
        prevState => ({
          selections: prevState.selections.filter(s => keyExtractor(s) !== id),
        }),
        () => cb(onSelect, this.state.selections)
      );
    }
  }

  handleOnSelectAll({ isAllSelected, indeterminate }) {
    const { onSelect, data, keyExtractor } = this.props;

    const cb = (onSelect, selections) => onSelect && onSelect(selections);

    let newSelections = [];

    if (isAllSelected) {
      const dataIds = data.map(d => keyExtractor(d));
      newSelections = this.state.selections.filter(s => !dataIds.includes(keyExtractor(s)));
    } else if (!isAllSelected || indeterminate) {
      const selectedIds = this.state.selections.map(s => keyExtractor(s));
      const toAdd = data.filter(d => !selectedIds.includes(keyExtractor(d)));
      newSelections = [...this.state.selections, ...toAdd];
    } else {
      newSelections = [...this.state.selections];
    }

    this.setState(
      () => ({
        selections: newSelections,
      }),
      () => cb(onSelect, this.state.selections)
    );
  }

  handleOnWindowResize() {
    this.updateWidthState();
  }

  getNextWidth() {
    const containerRect = domRect(this.containerRef);

    if (containerRect) {
      const columnsWidth = sumColumnWidths(this.props.columns);
      return columnsWidth < containerRect.width ? columnsWidth : containerRect.width;
    }

    return undefined;
  }

  updateWidthState() {
    this.setState({
      width: this.getNextWidth(),
    });
  }

  render() {
    const { showPaginationBottom, showPaginationTop } = this.props;

    return (
      <div className={this.props.classes.container} ref={this.containerRef}>
        <WindowResizeListener onResize={this.handleOnWindowResize} />

        {this.props.loading && <SpinnerOverlay />}

        {showPaginationTop && (
          <Box mBottom="small">
            <Paginator
              columns={this.props.columns}
              color={this.props.color}
              page={this.props.page}
              pageSize={this.props.pageSize}
              total={this.props.total}
              onPageChange={this.handleOnPageChange}
              width={this.state.width}
            />
          </Box>
        )}

        <HorizontalScrollView columns={this.props.columns} paginationVisible={showPaginationBottom}>
          <SortHeader
            data={this.props.data}
            columns={this.props.columns}
            sortBy={this.props.sortBy}
            isAsc={this.props.isAsc}
            keyExtractor={this.props.keyExtractor}
            filtersEnabled={true}
            onSortChange={this.handleOnSortChange}
            selections={this.state.selections}
            onSelectAll={this.props.onSelect && this.handleOnSelectAll}
          />

          {this.props.enableFiltering && (
            <FilterHeader
              columns={this.props.columns}
              criteria={this.props.criteria}
              onCriteriaChange={this.handleOnCriteriaChange}
              hasSortSpacer={this.props.onSelect !== undefined}
            />
          )}

          <Rows
            data={this.props.data}
            keyExtractor={this.props.keyExtractor}
            columns={this.props.columns}
            striped={this.props.striped}
            renderSubsection={this.props.renderSubsection}
            selections={this.state.selections}
            onSelect={this.props.onSelect && this.handleOnSelect}
          />
        </HorizontalScrollView>

        {showPaginationBottom && (
          <Box mTop="small">
            <Paginator
              columns={this.props.columns}
              color={this.props.color}
              page={this.props.page}
              pageSize={this.props.pageSize}
              total={this.props.total}
              onPageChange={this.handleOnPageChange}
              width={this.state.width}
            />
          </Box>
        )}
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    position: 'relative',
    '-webkit-overflow-scrolling': 'touch',
  },
});

export default injectSheet(styles)(DataGrid);
