/**
 * This context has various things all of the api endpoints will need in order execute correctly
 * and efficiently.
 *
 * As of right now it's just a placeholder and may go away - who knows how things will pan out.
 *
 * Potential things that could go in the context are:
 *
 * (1) Authentication state
 * (2) Cache(s)
 * (3) API configuration
 * (4) Provide a simple interface to the underlying api infrastructure.
 */
export default class ApiContext {
  constructor(options) {
    this.baseUrl = options.baseUrl;
    //this.baseUrl = 'http://localhost:5000/api';
  }

  configure() {
    return this;
  }
}
