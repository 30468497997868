import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import injectSheet from 'react-jss';
import { IconSortAsc, IconSortDesc, IconSortNone } from '../../../../app/components/icons';
import { COLOR_BASE_PRIMARY, COLOR_ICON_ON_LIGHT } from '../../../../app/Constants';

const isSortActive = props => props.sortBy === props.column.colName;

class SortHeaderIcon extends PureComponent {
  static propTypes = {
    /**
     * Jss provided property.
     */
    classes: PropTypes.object.isRequired,

    /**
     * Column definition.
     */
    column: PropTypes.object.isRequired,

    /**
     * Property to sort by.
     */
    sortBy: PropTypes.string.isRequired,

    /**
     * Is sort ascending order.
     */
    isAsc: PropTypes.bool.isRequired,
  };

  render() {
    const { classes, column, sortBy } = this.props;

    if (!column.colName) {
      return null;
    }

    let Icon = IconSortNone;
    let color = COLOR_ICON_ON_LIGHT;
    let colorVariant = 'light3';

    if (sortBy === column.colName) {
      Icon = this.props.isAsc ? IconSortAsc : IconSortDesc;
      color = COLOR_BASE_PRIMARY;
      colorVariant = 'dark3';
    }

    return (
      <div className={classes.container} tabIndex="0">
        <Icon size="xs" color={color} colorVariant={colorVariant} />
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    backgroundColor: theme.color[COLOR_BASE_PRIMARY]['light1'],
    padding: theme.spacing.small,
    borderRadius: '3px',
    border: props =>
      isSortActive(props)
        ? `1px solid ${theme.color[COLOR_BASE_PRIMARY]['light3']}`
        : `1px solid ${theme.color[COLOR_BASE_PRIMARY]['light1']}`,

    '&:hover': {
      border: props =>
        isSortActive(props)
          ? `1px solid ${theme.color[COLOR_BASE_PRIMARY]['dark5']}`
          : `1px solid ${theme.color[COLOR_BASE_PRIMARY]['light3']}`,
      transition: 'all 350ms',
    },
  },
});

export default injectSheet(styles)(SortHeaderIcon);
