import PropTypes from 'prop-types';
import React, { Component } from 'react';
import LabelValueTable from '../../lv/LabelValueTable';

export default class GeoPolitical extends Component {
  static propTypes = {
    /**
     * Site facility geopolitical data.
     */
    facilityGeopolitical: PropTypes.arrayOf(
      PropTypes.shape({
        geoPolTypeVisible: PropTypes.string,
        geoPolTypeLabel: PropTypes.string,
        geoPolValue: PropTypes.string,
      })
    ),
  };

  static defaultProps = {
    facilityGeopolitical: [],
  };

  render() {
    const { facilityGeopolitical } = this.props;

    return (
      <LabelValueTable
        data={facilityGeopolitical.filter(geop => geop.geoPolTypeVisible === 'Y')}
        labelFunc={row => row.geoPolTypeLabel}
        valueFunc={row => row.geoPolValue}
      />
    );
  }
}
