import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ExportType from './ExportType';
import HeadingText from '../../../../../../components/text/HeadingText';

export default class ExportGroups extends Component {
  static propTypes = {
    exportGroups: PropTypes.array.isRequired,
  };

  render() {
    return (
      <React.Fragment>
        {this.props.exportGroups.map((ex, index) => (
          <div key={ex.name + index}>
            <HeadingText size="large">{ex.name}</HeadingText>
            <div>
              {ex.exportTypes.map((et, index) => (
                <ExportType
                  key={index}
                  name={et.exportName}
                  id={et.exportTypeId}
                  description={et.exportDescr}
                />
              ))}
            </div>
          </div>
        ))}
      </React.Fragment>
    );
  }
}
