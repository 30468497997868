import PropTypes from 'prop-types';
import React, { Component } from 'react';
import LayerMetadata from './LayerMetadata';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

const api = () => window.nSITE.Api;

class LayerMetadataContainer extends Component {
  static propTypes = {
    /**
     * The layer metadata to display.
     */
    metadata: PropTypes.object.isRequired,

    /**
     * Latitude
     */
    latitude: PropTypes.number.isRequired,

    /**
     * Longitude
     */
    longitude: PropTypes.number.isRequired,

    /**
     * URL for WMS layer types
     */
    wmsUrl: PropTypes.string,

    /**
     * Key
     */
    key: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      layerName: '',
      metadata: [],
    };
  }

  componentDidMount() {
    if (this.props.metadata) {
      this.setState({
        layerName: this.props.metadata.layerName,
      });

      if (
        this.props.metadata.layerType &&
        this.props.metadata.layerType.toLowerCase() === 'sqlspatial'
      ) {
        let keyValuePairs = [];

        if (this.props.metadata.layerName === 'Features') {
          this.setState({
            layerName: this.props.metadata.name + ' - (Features Layer)',
          });
          api()
            .layer.getFeatureMetadata(this.props.metadata.id)
            .then(dbMetadata => {
              dbMetadata.forEach(item => {
                keyValuePairs.push({ label: item.key, value: item.value });
              });

              this.setState({
                metadata: keyValuePairs,
              });
            });
        } else {
          this.fetchSqlSpatialMetadata(this.props.metadata.layerName).then(dbMetadata => {
            dbMetadata.forEach(item => {
              keyValuePairs.push({ label: item.keyName, value: item.attributeValue });
            });

            this.setState({
              metadata: keyValuePairs,
            });
          });
        }
      } else if (this.props.metadata.layerType === 'wms' && this.props.wmsUrl !== undefined) {
        this.fetchWMSMetadata(this.props.wmsUrl);
      } else {
        if (this.props.metadata.layerMetadataUrl) {
          const regexLon = /\{0\}/g;
          const regexLat = /\{1\}/g;

          const url = this.props.metadata.layerMetadataUrl
            .replace(regexLon, this.props.longitude)
            .replace(regexLat, this.props.latitude);

          this.fetchESRIMEtadata(url);
        }
      }
    }
  }

  fetchWMSMetadata(url) {
    const get = fetch(url);
    get.then(response => response.json()).then(json => parse(json));
    const parse = json => {
      if (json && json.features && json.features[0] && json.features[0].properties) {
        const remoteData = json.features[0].properties;
        const keys = Object.keys(remoteData);
        const keyValuePairs = [];
        keys.map(key => keyValuePairs.push({ label: key, value: remoteData[key] }));
        this.setState({
          metadata: keyValuePairs,
        });
      }
    };
  }

  fetchESRIMEtadata(url) {
    const get = fetch(url);
    const parse = json => {
      if (json && json.features && json.features[0] && json.features[0].attributes) {
        const remoteData = json.features[0].attributes;
        const keys = Object.keys(remoteData);
        const keyValuePairs = [];
        keys.map(key =>
          keyValuePairs.push({
            label: `${key ? json.fieldAliases[key] : key}`,
            value: remoteData[key],
          })
        );
        this.setState({
          metadata: keyValuePairs,
        });
      }
    };
    get
      .then(response => response.json())
      .then(json => parse(json))
      .catch(error => {
        console.warn(error.message);
        toast.error(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  }

  fetchSqlSpatialMetadata(layerName) {
    let mapInfo = {
      latitude: this.props.latitude,
      longitude: this.props.longitude,
      screenWidth: this.props.criteria.screenWidth,
      screenHeight: this.props.criteria.screenHeight,
      featureLayers: layerName,
      leftBound: this.props.criteria.boundingBox[0],
      bottomBound: this.props.criteria.boundingBox[1],
      rightBound: this.props.criteria.boundingBox[2],
      topBound: this.props.criteria.boundingBox[3],
    };
    return api().layer.getSqlSpatialMetadata(mapInfo);
  }

  render() {
    return <LayerMetadata title={this.state.layerName} metadata={this.state.metadata} />;
  }
}

function mapStateToProps(state) {
  return {
    criteria: state.map.search.criteria,
    map: state.map,
  };
}

export default connect(mapStateToProps)(LayerMetadataContainer);
