import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Modal } from '../../../../../../lib/base';
import Box from '../../../../../../lib/base/layout/Box';
import HeadingText from '../../../../../components/text/HeadingText';
import { navigateToMapHome } from '../../../../../routing';
import HelpPanel from '../../shared/panel/help/HelpPanel';

class ModalHelp extends Component {
  constructor(props) {
    super(props);
    this.handleOnClose = this.handleOnClose.bind(this);
  }

  handleOnClose() {
    navigateToMapHome(this.props);
  }

  isOpen() {
    const { params } = this.props.match;
    return params.panel === 'help';
  }

  render() {
    return (
      <Modal
        title={() => <HeadingText>Help</HeadingText>}
        isOpen={this.isOpen()}
        onRequestClose={this.handleOnClose}
      >
        <Box pad="medium">
          <HelpPanel />
        </Box>
      </Modal>
    );
  }
}

export default withRouter(ModalHelp);
