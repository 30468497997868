import { buffer } from 'ol/extent';
import Config from '../../app/config';
import { findDefaultBaseLayer } from '../../app/util/layers';
import { getSiteIds } from '../../app/util/urlParams';
import { setMyLocationCoords } from '../map/map.action';
import {
  MAP_STARTUP_BEGIN,
  MAP_STARTUP_ERROR,
  MAP_STARTUP_MESSAGE,
  MAP_STARTUP_SUCCESS,
} from '../map/map.actionType';
import {
  getMyCurrentLocation,
  getMyCurrentLocationExtent,
  isDefaultExtentMyLocation,
} from './helper';
import { REPORT_ERROR } from './system.actionType';

/**
 * Needs to be function in order to delay access - when the page intially loads window.nSITE.Api is
 * undefined.
 */
const api = () => window.nSITE.Api;

/**
 * //////////////////////////////////////////////////////
 * ////
 * //// Load Map Settings
 * ////
 * //////////////////////////////////////////////////////
 */

export function startMapSystemIfNeeded(options = { askForPosition: true }) {
  return (dispatch, getState) => {
    if (shouldStartMapSystem(getState())) {
      return dispatch(startMapSystem(options));
    }
    return undefined;
  };
}

function shouldStartMapSystem(state) {
  return !state.map.startup.isStarting;
}

function startMapSystem(options) {
  return dispatch => {
    dispatch(startMapSystemBegin());

    /**
     * Begin the startup process by creating a 'context' object literal to hold data as we progress
     * through the promise chain.
     */
    const start = () =>
      new Promise((resolve, reject) => {
        try {
          resolve({
            startTime: new Date(),
            extent: Config.getExtent(),
          });
        } catch (err) {
          reject(err);
        }
      });

    /**
     * We're going to attempt to ask the user for current coordinates. We'll use
     * the coordinates to center the map. When the app settings tell us NOT to
     * zoom to my location we'll call getMyCurrentLocation within a setTimeout
     * to not block the screen from loading.
     */
    const askUserCoordinates = context =>
      new Promise(resolve => {
        if (!options.askForPosition) {
          resolve(context);
        }

        if (isDefaultExtentMyLocation()) {
          getMyCurrentLocation(
            coords => {
              // bufferValue should probably be an app setting.
              const bufferValue = 250;
              resolve(
                Object.assign({}, context, {
                  myLocation: coords,
                  extent: buffer(getMyCurrentLocationExtent(context.extent, coords), bufferValue),
                })
              );
            },
            () => {
              console.log("Unable to obtain user's current location");
            }
          );
        } else {
          setTimeout(() => {
            getMyCurrentLocation(
              coords => {
                dispatch(setMyLocationCoords(coords));
              },
              () => {
                console.log("Unable to obtain user's current location");
              }
            );
          }, 0);

          resolve(context);
        }
      });

    /**
     * Load map layers data from the server.
     */
    const loadMapLayerSettings = context =>
      api()
        .layer.getAll()
        .then(layers => {
          return {
            ...context,
            layers,
            map: {
              activeBaseLayer: findDefaultBaseLayer(layers), // default selected base layer.
              activeReferenceLayers: [], // selected reference layers - none to start with.
            },
          };
        });

    /**
     * Get and parse url params
     */
    const parseUrlParams = function(context) {
      return {
        ...context,
        search: {
          criteria: {
            term: getSiteIds(),
          },
        },
      };
    };

    /**
     * Helper to dispatch startup messages.
     */
    const dispatchMessage = message => status =>
      new Promise(resolve => {
        dispatch(startMapSystemMessage(message));
        resolve(status);
      });

    /**
     * Tie is all together.
     */
    start()
      .then(parseUrlParams)
      .then(dispatchMessage("Asking for user's location."))
      .then(askUserCoordinates)
      .then(dispatchMessage('Retrieving map layers.'))
      .then(loadMapLayerSettings)
      .then(dispatchMessage('Map initialization complete, lets go.'))
      .then(context => {
        if (context.myLocation) {
          dispatch(setMyLocationCoords(context.myLocation));
        }
        dispatch(startMapSystemSuccess(context));
      })
      .catch(err => {
        console.error(err);
        dispatch(startMapSystemError(err.message));
      });
  };
}

function startMapSystemBegin() {
  return {
    type: MAP_STARTUP_BEGIN,
  };
}

function startMapSystemSuccess(context) {
  return {
    type: MAP_STARTUP_SUCCESS,
    payload: {
      layers: context.layers,
      map: context.map,
      search: context.search,
      extent: context.extent,
      center: context.center,
    },
  };
}

function startMapSystemError(message) {
  return {
    type: MAP_STARTUP_ERROR,
    payload: {
      error: message,
    },
  };
}

function startMapSystemMessage(message) {
  return {
    type: MAP_STARTUP_MESSAGE,
    payload: {
      message,
    },
  };
}

/**
 * //////////////////////////////////////////////////////
 * ////
 * //// System-wide UI related actions.
 * ////
 * //////////////////////////////////////////////////////
 */

export function reportError(error) {
  return {
    type: REPORT_ERROR,
    payload: {
      error,
    },
  };
}
