/**
 * Helpers to test current path.
 */

export const isViewingMap = ({ location }) => {
  return location.pathname === '/map';
};

export const isViewingResults = ({ location }) => {
  return location.pathname === '/map/results';
};

export const isViewingProfile = ({ location }) => {
  return location.pathname.startsWith('/map/results/profile');
};

export const isViewingCard = ({ location }) => {
  return location.pathname.startsWith('/map/results/card');
};

export const isViewingDetail = ({ location }) => {
  return location.pathname.startsWith('/map/results/detail');
};
