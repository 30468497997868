import { px, pxXY } from '../../util/jss';
import {
  BUTTON_DEFAULT_BORDER_COLOR,
  BUTTON_DEFAULT_BORDER_STYLE,
  BUTTON_DEFAULT_BORDER_WIDTH,
  BUTTON_SIZE_LARGE_BORDER_WIDTH,
  BUTTON_SIZE_LARGE_FONT_SIZE,
  BUTTON_SIZE_LARGE_HEIGHT,
  BUTTON_SIZE_LARGE_LINE_HEIGHT,
  BUTTON_SIZE_LARGE_PADDING_X,
  BUTTON_SIZE_LARGE_PADDING_Y,
  BUTTON_SIZE_LARGE_RADIUS,
  BUTTON_SIZE_LARGER_BORDER_WIDTH,
  BUTTON_SIZE_LARGER_FONT_SIZE,
  BUTTON_SIZE_LARGER_HEIGHT,
  BUTTON_SIZE_LARGER_LINE_HEIGHT,
  BUTTON_SIZE_LARGER_PADDING_X,
  BUTTON_SIZE_LARGER_PADDING_Y,
  BUTTON_SIZE_LARGER_RADIUS,
  BUTTON_SIZE_LARGEST_BORDER_WIDTH,
  BUTTON_SIZE_LARGEST_FONT_SIZE,
  BUTTON_SIZE_LARGEST_HEIGHT,
  BUTTON_SIZE_LARGEST_LINE_HEIGHT,
  BUTTON_SIZE_LARGEST_PADDING_X,
  BUTTON_SIZE_LARGEST_PADDING_Y,
  BUTTON_SIZE_LARGEST_RADIUS,
  BUTTON_SIZE_MEDIUM_BORDER_WIDTH,
  BUTTON_SIZE_MEDIUM_FONT_SIZE,
  BUTTON_SIZE_MEDIUM_HEIGHT,
  BUTTON_SIZE_MEDIUM_LINE_HEIGHT,
  BUTTON_SIZE_MEDIUM_PADDING_X,
  BUTTON_SIZE_MEDIUM_PADDING_Y,
  BUTTON_SIZE_MEDIUM_RADIUS,
  BUTTON_SIZE_SMALL_BORDER_WIDTH,
  BUTTON_SIZE_SMALL_FONT_SIZE,
  BUTTON_SIZE_SMALL_HEIGHT,
  BUTTON_SIZE_SMALL_LINE_HEIGHT,
  BUTTON_SIZE_SMALL_PADDING_X,
  BUTTON_SIZE_SMALL_PADDING_Y,
  BUTTON_SIZE_SMALL_RADIUS,
  BUTTON_SIZE_SMALLER_BORDER_WIDTH,
  BUTTON_SIZE_SMALLER_FONT_SIZE,
  BUTTON_SIZE_SMALLER_HEIGHT,
  BUTTON_SIZE_SMALLER_LINE_HEIGHT,
  BUTTON_SIZE_SMALLER_PADDING_X,
  BUTTON_SIZE_SMALLER_PADDING_Y,
  BUTTON_SIZE_SMALLER_RADIUS,
  BUTTON_SIZE_SMALLEST_BORDER_WIDTH,
  BUTTON_SIZE_SMALLEST_FONT_SIZE,
  BUTTON_SIZE_SMALLEST_HEIGHT,
  BUTTON_SIZE_SMALLEST_LINE_HEIGHT,
  BUTTON_SIZE_SMALLEST_PADDING_X,
  BUTTON_SIZE_SMALLEST_PADDING_Y,
  BUTTON_SIZE_SMALLEST_RADIUS,
  TYPE_FONT_FAMILY_SAN_SERIF,
} from './variables';

/**
 * Button - the things that you press on the screen.
 *
 * size = [smallest, smaller, small, medium, large, larger, largest]    - (height, font-size,
 * lineHeight)   / layout
 *
 * type = [default, flat, alt]                                          - (bg, border, text)
 *         / style
 *
 * rounded= true|false                                                  - (radius)
 *
 * circle= true|false                                                   - (radius 50%, height =
 * width)
 *
 * color = [default, brand, primary, red, green, blue, orange]
 *         / color
 *
 * state = [default, hover, active, disabled, focused]
 */

const button = {
  reset: {
    margin: 0,
    padding: 0,
    border: 'none',
    font: 'inherit',
    color: 'inherit',
    background: 'none',
    outline: 'none',
    display: 'inline-block',
  },

  base: {
    display: 'inline-block',
    fontFamily: TYPE_FONT_FAMILY_SAN_SERIF,
    color: 'inherit',
    background: 'none',
    outline: 'none',
    letterSpacing: px(0.75),
    textTransform: 'uppercase',
    borderWidth: BUTTON_DEFAULT_BORDER_WIDTH,
    borderColor: BUTTON_DEFAULT_BORDER_COLOR,
    borderStyle: BUTTON_DEFAULT_BORDER_STYLE,
  },

  height: {
    smallest: {
      height: px(BUTTON_SIZE_SMALLEST_HEIGHT),
      fontSize: px(BUTTON_SIZE_SMALLEST_FONT_SIZE),
      lineHeight: px(BUTTON_SIZE_SMALLEST_LINE_HEIGHT),
      borderWidth: px(BUTTON_SIZE_SMALLEST_BORDER_WIDTH),
      padding: pxXY(BUTTON_SIZE_SMALLEST_PADDING_X, BUTTON_SIZE_SMALLEST_PADDING_Y),
      borderRadius: px(BUTTON_SIZE_SMALLEST_RADIUS),
    },
    smaller: {
      height: px(BUTTON_SIZE_SMALLER_HEIGHT),
      fontSize: px(BUTTON_SIZE_SMALLER_FONT_SIZE),
      lineHeight: px(BUTTON_SIZE_SMALLER_LINE_HEIGHT),
      borderWidth: px(BUTTON_SIZE_SMALLER_BORDER_WIDTH),
      padding: pxXY(BUTTON_SIZE_SMALLER_PADDING_X, BUTTON_SIZE_SMALLER_PADDING_Y),
      borderRadius: px(BUTTON_SIZE_SMALLER_RADIUS),
    },
    small: {
      height: px(BUTTON_SIZE_SMALL_HEIGHT),
      fontSize: px(BUTTON_SIZE_SMALL_FONT_SIZE),
      lineHeight: px(BUTTON_SIZE_SMALL_LINE_HEIGHT),
      borderWidth: px(BUTTON_SIZE_SMALL_BORDER_WIDTH),
      padding: pxXY(BUTTON_SIZE_SMALL_PADDING_X, BUTTON_SIZE_SMALL_PADDING_Y),
      borderRadius: px(BUTTON_SIZE_SMALL_RADIUS),
    },
    medium: {
      height: px(BUTTON_SIZE_MEDIUM_HEIGHT),
      fontSize: px(BUTTON_SIZE_MEDIUM_FONT_SIZE),
      lineHeight: px(BUTTON_SIZE_MEDIUM_LINE_HEIGHT),
      borderWidth: px(BUTTON_SIZE_MEDIUM_BORDER_WIDTH),
      padding: pxXY(BUTTON_SIZE_MEDIUM_PADDING_X, BUTTON_SIZE_MEDIUM_PADDING_Y),
      borderRadius: px(BUTTON_SIZE_MEDIUM_RADIUS),
    },
    large: {
      height: px(BUTTON_SIZE_LARGE_HEIGHT),
      fontSize: px(BUTTON_SIZE_LARGE_FONT_SIZE),
      lineHeight: px(BUTTON_SIZE_LARGE_LINE_HEIGHT),
      borderWidth: px(BUTTON_SIZE_LARGE_BORDER_WIDTH),
      padding: pxXY(BUTTON_SIZE_LARGE_PADDING_X, BUTTON_SIZE_LARGE_PADDING_Y),
      borderRadius: px(BUTTON_SIZE_LARGE_RADIUS),
    },
    larger: {
      height: px(BUTTON_SIZE_LARGER_HEIGHT),
      fontSize: px(BUTTON_SIZE_LARGER_FONT_SIZE),
      lineHeight: px(BUTTON_SIZE_LARGER_LINE_HEIGHT),
      borderWidth: px(BUTTON_SIZE_LARGER_BORDER_WIDTH),
      padding: pxXY(BUTTON_SIZE_LARGER_PADDING_X, BUTTON_SIZE_LARGER_PADDING_Y),
      borderRadius: px(BUTTON_SIZE_LARGER_RADIUS),
    },
    largest: {
      height: px(BUTTON_SIZE_LARGEST_HEIGHT),
      fontSize: px(BUTTON_SIZE_LARGEST_FONT_SIZE),
      lineHeight: px(BUTTON_SIZE_LARGEST_LINE_HEIGHT),
      borderWidth: px(BUTTON_SIZE_LARGEST_BORDER_WIDTH),
      padding: pxXY(BUTTON_SIZE_LARGEST_PADDING_X, BUTTON_SIZE_LARGEST_PADDING_Y),
      borderRadius: px(BUTTON_SIZE_LARGEST_RADIUS),
    },
  },
};

export default button;
