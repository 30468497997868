import { px } from '../../util/jss';
import {
  TYPE_FONT_FAMILY_SAN_SERIF,
  TYPE_FONT_SIZE_DEFAULT,
  TYPE_FONT_SIZE_LARGE,
  TYPE_FONT_SIZE_LARGER,
  TYPE_FONT_SIZE_LARGEST,
  TYPE_FONT_SIZE_MEDIUM,
  TYPE_FONT_SIZE_SMALL,
  TYPE_FONT_SIZE_SMALLER,
  TYPE_FONT_SIZE_SMALLEST,
  TYPE_FONT_WEIGHT_DEFAULT,
  TYPE_FONT_WEIGHT_HEAVIER,
  TYPE_FONT_WEIGHT_HEAVIEST,
  TYPE_FONT_WEIGHT_HEAVY,
  TYPE_FONT_WEIGHT_LIGHT,
  TYPE_FONT_WEIGHT_LIGHTER,
  TYPE_FONT_WEIGHT_LIGHTEST,
  TYPE_FONT_WEIGHT_NORMAL,
  TYPE_LINE_HEIGHT_RATIO_HEADING,
} from './variables';

/**
 * TODO Heading font sizes
 */

const FONT_SIZE_UNIT = 0.0625;

const base = {
  fontFamily: TYPE_FONT_FAMILY_SAN_SERIF,
};

const typography = ({ core }) => ({
  size: {
    smallest: TYPE_FONT_SIZE_SMALLEST,
    smaller: TYPE_FONT_SIZE_SMALLER,
    small: TYPE_FONT_SIZE_SMALL,
    medium: TYPE_FONT_SIZE_MEDIUM,
    large: TYPE_FONT_SIZE_LARGE,
    larger: TYPE_FONT_SIZE_LARGER,
    largest: TYPE_FONT_SIZE_LARGEST,
    default: TYPE_FONT_SIZE_DEFAULT,
  },

  weight: {
    lightest: TYPE_FONT_WEIGHT_LIGHTEST,
    lighter: TYPE_FONT_WEIGHT_LIGHTER,
    light: TYPE_FONT_WEIGHT_LIGHT,
    normal: TYPE_FONT_WEIGHT_NORMAL,
    heavy: TYPE_FONT_WEIGHT_HEAVY,
    heavier: TYPE_FONT_WEIGHT_HEAVIER,
    heaviest: TYPE_FONT_WEIGHT_HEAVIEST,
    default: TYPE_FONT_WEIGHT_DEFAULT,
  },

  base: {
    ...base,
  },

  smallest: {
    ...base,
    fontSize: px(TYPE_FONT_SIZE_SMALLEST),
    lineHeight: px(core.grid.unit.x2),
    fontWeight: TYPE_FONT_WEIGHT_NORMAL,
  },

  smaller: {
    ...base,
    fontSize: px(TYPE_FONT_SIZE_SMALLER),
    lineHeight: px(core.grid.unit.x2),
    fontWeight: TYPE_FONT_WEIGHT_NORMAL,
  },

  small: {
    ...base,
    fontSize: px(TYPE_FONT_SIZE_SMALL),
    lineHeight: px(core.grid.unit.x2),
    fontWeight: TYPE_FONT_WEIGHT_NORMAL,
  },

  medium: {
    ...base,
    fontSize: px(TYPE_FONT_SIZE_MEDIUM),
    lineHeight: px(core.grid.unit.x3),
    fontWeight: TYPE_FONT_WEIGHT_LIGHTER,
  },

  large: {
    ...base,
    fontSize: px(TYPE_FONT_SIZE_LARGE),
    lineHeight: px(core.grid.unit.x3),
    fontWeight: TYPE_FONT_WEIGHT_LIGHTER,
  },

  larger: {
    ...base,
    fontSize: px(TYPE_FONT_SIZE_LARGER),
    lineHeight: px(core.grid.unit.x4),
    fontWeight: TYPE_FONT_WEIGHT_NORMAL,
  },

  largest: {
    ...base,
    fontSize: px(TYPE_FONT_SIZE_LARGEST),
    lineHeight: px(core.grid.unit.x6),
    fontWeight: TYPE_FONT_WEIGHT_NORMAL,
  },

  paragraph: {
    ...base,
    fontSize: px(TYPE_FONT_SIZE_DEFAULT),
    lineHeight: px(core.grid.unit.x3),
    fontWeight: TYPE_FONT_WEIGHT_LIGHTER,
    maxWidth: px(core.grid.unit.x2 * 38),
    paddingBottom: px(core.grid.unit.x2),
  },

  h1: {
    ...base,
    fontSize: `${96 * FONT_SIZE_UNIT}rem`,
    lineHeight: TYPE_LINE_HEIGHT_RATIO_HEADING,
    letterSpacing: -1.5,
    fontWeight: TYPE_FONT_WEIGHT_LIGHTEST,

    '&::first-letter': {
      marginLeft: '-9px',
    },
  },

  h2: {
    ...base,
    fontSize: `${60 * FONT_SIZE_UNIT}rem`,
    lineHeight: TYPE_LINE_HEIGHT_RATIO_HEADING,
    letterSpacing: -0.5,
    fontWeight: TYPE_FONT_WEIGHT_LIGHTER,

    '&::first-letter': {
      marginLeft: '-6px',
    },
  },

  h3: {
    ...base,
    fontSize: `${48 * FONT_SIZE_UNIT}rem`,
    lineHeight: TYPE_LINE_HEIGHT_RATIO_HEADING,
    letterSpacing: 0,
    fontWeight: TYPE_FONT_WEIGHT_LIGHT,

    '&::first-letter': {
      marginLeft: '-4px',
    },
  },

  h4: {
    ...base,
    fontSize: `${34 * FONT_SIZE_UNIT}rem`,
    lineHeight: TYPE_LINE_HEIGHT_RATIO_HEADING,
    letterSpacing: 0.25,
    fontWeight: TYPE_FONT_WEIGHT_LIGHT,

    '&::first-letter': {
      marginLeft: '-3px',
    },
  },

  h5: {
    ...base,
    fontSize: `${24 * FONT_SIZE_UNIT}rem`,
    lineHeight: TYPE_LINE_HEIGHT_RATIO_HEADING,
    letterSpacing: 0,
    fontWeight: TYPE_FONT_WEIGHT_LIGHT,

    '&::first-letter': {
      marginLeft: '-1px',
    },
  },

  h6: {
    ...base,
    fontSize: `${20 * FONT_SIZE_UNIT}rem`,
    lineHeight: TYPE_LINE_HEIGHT_RATIO_HEADING,
    letterSpacing: 0.15,
    fontWeight: TYPE_FONT_WEIGHT_LIGHT,
  },
});
export default typography;
