import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import Row from '../../../../../lib/base/layout/flex/Row';
import Rows from '../../../../../lib/base/layout/flex/Rows';
import WindowResizeListener from '../../../../components/listener/WindowResizeListener';
import SpinnerOverlay from '../../../../components/spinner/SpinnerOverlay';
import {
  SEARCH_RESULT_PANEL_MARGIN_PX,
  SEARCH_RESULT_PANEL_WIDTH_PX,
  Z_INDEX_SEARCH_BAR,
} from '../../../../Constants';
import { domRect } from '../../../../util/react';
import DesktopOmniContent from './DesktopOmniContent';
import DesktopSearchBar from './DesktopSearchBar';
import SlideOutContainerSearchCriteria from './slideout/SlideOutContainerSearchCriteria';
import SlideOutContainerSiteProfile from './slideout/SlideOutContainerSiteProfile';

class DesktopOmniBox extends Component {
  static propTypes = {
    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      /**
       * Holds the dimensions of the omni box.
       */
      slideOutDimensions: undefined,
    };

    this.containerRef = React.createRef();
    this.handleWindowResize = this.handleWindowResize.bind(this);
  }

  componentDidMount() {
    this.updateSlideOutDimensions();
  }

  handleWindowResize() {
    this.updateSlideOutDimensions();
  }

  updateSlideOutDimensions() {
    if (this.containerRef && this.containerRef.current) {
      const rect = domRect(this.containerRef);
      const screenWidth = document.documentElement.clientWidth;
      const slideOutWidth = screenWidth - SEARCH_RESULT_PANEL_MARGIN_PX - rect.width;

      const slideOutDimensions = {
        top: SEARCH_RESULT_PANEL_MARGIN_PX,
        width: rect.width,
        height: rect.height,
        slideOutWidth: slideOutWidth,
      };

      this.setState({ slideOutDimensions });
    }
  }

  render() {
    const { slideOutDimensions } = this.state;

    return (
      <React.Fragment>
        <WindowResizeListener onResize={this.handleWindowResize} />

        {slideOutDimensions && (
          <Fragment>
            <SlideOutContainerSearchCriteria
              zIndex={Z_INDEX_SEARCH_BAR - 2}
              top={slideOutDimensions.top}
              width={slideOutDimensions.width}
              height={slideOutDimensions.height}
            />
            <SlideOutContainerSiteProfile
              zIndex={Z_INDEX_SEARCH_BAR - 1}
              top={slideOutDimensions.top}
              width={slideOutDimensions.width}
              height={slideOutDimensions.height}
            />
          </Fragment>
        )}

        <div className={this.props.classes.container} ref={this.containerRef}>
          {this.props.isFetching && <SpinnerOverlay />}

          <Rows stretch>
            <Row flex={0}>
              <DesktopSearchBar />
            </Row>
            <Row flex={1} style={{ overflowY: 'auto', position: 'relative' }}>
              <DesktopOmniContent
                onClickClearMeasurements={this.props.onClickClearMeasurements}
                onUnitChange={this.props.onUnitChange}
              />
            </Row>
          </Rows>
        </div>
      </React.Fragment>
    );
  }
}

const styles = () => ({
  container: {
    position: 'absolute',
    backgroundColor: 'white',
    zIndex: Z_INDEX_SEARCH_BAR,
    left: `${SEARCH_RESULT_PANEL_MARGIN_PX}px`,
    top: `${SEARCH_RESULT_PANEL_MARGIN_PX}px`,
    bottom: `${SEARCH_RESULT_PANEL_MARGIN_PX}px`,
    minWidth: `${SEARCH_RESULT_PANEL_WIDTH_PX}px`,
    maxWidth: `${SEARCH_RESULT_PANEL_WIDTH_PX}px`,
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.4)',
  },
});

function mapStateToProps(state) {
  return {
    isFetching: state.map.search.isFetching,
  };
}

export default connect(mapStateToProps)(injectSheet(styles)(DesktopOmniBox));
