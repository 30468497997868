import { Font } from '@react-pdf/renderer';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const applicationBaseUrl = window.location.origin + baseUrl.replace(/\/$/, '');

const getFontSrc = name => `${applicationBaseUrl}/fonts/open-sans/${name}.ttf`;

Font.register({
  family: 'OpenSans',
  src: getFontSrc('OpenSans-Regular'),
  fontStyle: 'normal',
});

Font.register({
  family: 'OpenSans',
  src: getFontSrc('OpenSans-Italic'),
  fontWeight: 'normal',
  fontStyle: 'italic',
});

Font.register({
  family: 'OpenSans',
  src: getFontSrc('OpenSans-SemiBold'),
  fontWeight: 600,
  fontStyle: 'normal',
});

Font.register({
  family: 'OpenSans',
  src: getFontSrc('OpenSans-SemiBoldItalic'),
  fontWeight: 600,
  fontStyle: 'italic',
});

Font.register({
  family: 'OpenSans',
  src: getFontSrc('OpenSans-Bold'),
  fontWeight: 700,
  fontStyle: 'normal',
});

Font.register({
  family: 'OpenSans',
  src: getFontSrc('OpenSans-BoldItalic'),
  fontWeight: 700,
  fontStyle: 'italic',
});
