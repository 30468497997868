import {
  PROFILE_TAB_CHEMICALS,
  PROFILE_TAB_COMPLIANCE,
  PROFILE_TAB_DETAIL,
  PROFILE_TAB_DOCUMENTS,
  PROFILE_TAB_EI,
  PROFILE_TAB_GENERIC,
  PROFILE_TAB_MAP,
} from '../components/profile/header/ProfileTabs';
import ConfigKeys from '../config/keys';
import { isSmallScreen } from '../util/browser';
import { label } from './label';

export const allTabs = tabCounts => {
  const profileLabel = label(ConfigKeys.LABEL_TAB_PROFILE);
  const mapLabel = label(ConfigKeys.LABEL_TAB_MAP);
  const complianceLabel = label(ConfigKeys.LABEL_TAB_COMPLIANCE);
  const documentLabel = label(ConfigKeys.LABEL_TAB_DOCUMENTS);
  const chemicalLabel = label(ConfigKeys.LABEL_TAB_CHEMICALS);
  const eiLabel = label(ConfigKeys.LABEL_TAB_EI);
  const genericLabel = label(ConfigKeys.LABEL_TAB_GENERIC);

  return [
    {
      id: PROFILE_TAB_DETAIL,
      altId: 'profile',
      title: profileLabel,
      name: profileLabel,
      visible: true,
    },
    {
      id: PROFILE_TAB_MAP,
      altId: 'map',
      title: mapLabel,
      name: mapLabel,
      visible: !isSmallScreen() && tabCounts.featureCount > 0,
    },
    {
      id: PROFILE_TAB_EI,
      altId: 'ei',
      title: eiLabel,
      name: eiLabel,
      visible: tabCounts.environmentalInterestsCount > 0,
    },
    {
      id: PROFILE_TAB_COMPLIANCE,
      altId: 'compliance',
      title: complianceLabel,
      name: complianceLabel,
      visible: tabCounts.complianceCount > 0,
    },
    {
      id: PROFILE_TAB_DOCUMENTS,
      altId: 'documents',
      title: documentLabel,
      name: documentLabel,
      visible: tabCounts.documentCount > 0,
    },
    {
      id: PROFILE_TAB_CHEMICALS,
      altId: 'chemicals',
      title: chemicalLabel,
      name: chemicalLabel,
      visible: tabCounts.chemicalCount > 0,
    },
    {
      id: PROFILE_TAB_GENERIC,
      altId: 'generic',
      title: genericLabel,
      name: genericLabel,
      visible: tabCounts.genericCount > 0, //service will need to be expanded to include
    },
  ];
};
