import React, { PureComponent } from 'react';
import { Text } from '../../../../../../lib/base';
import Config from '../../../../../config';
import { formatDateTime } from '../../../../../util/format';

export default class ApplicationBuildStamp extends PureComponent {
  prettyDate(isoDateString) {
    const date = new Date(Date.parse(isoDateString));

    if (Object.prototype.toString.call(date) !== '[object Date]' || Number.isNaN(date.getTime())) {
      return '??/??/????';
    }

    return formatDateTime(date);
  }

  buildStamp() {
    const buildBranch = Config.getBuildBranch();
    const buildRevision = Config.getBuildRevision();
    const buildTimestamp = Config.getBuildTimestamp();
    return `ver: ${buildBranch}/${buildRevision} - ${this.prettyDate(buildTimestamp)}`;
  }

  render() {
    return <Text size="smallest">{this.buildStamp()}</Text>;
  }
}
