import { StyleSheet, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ProfileSection, Text } from '../../../../lib/pdf';

export class AlternateNamesSection extends PureComponent {
  static propTypes = {
    /**
     * Alias IDs
     */
    alternateNames: PropTypes.arrayOf(
      PropTypes.shape({
        aliasType: PropTypes.string,
        aliasName: PropTypes.string,
      })
    ),
  };

  static defaultProps = {
    alternateNames: [],
  };

  hasData() {
    const { alternateNames } = this.props;
    return alternateNames.length > 0;
  }

  render() {
    const { alternateNames } = this.props;

    if (!this.hasData()) {
      return null;
    }

    const halfLength = Math.ceil(alternateNames.length / 2);
    const dataLeftColumn = alternateNames.slice(0, halfLength);
    const dataRightColumn = alternateNames.slice(halfLength, alternateNames.length);

    return (
      <ProfileSection title="Alternate Names">
        <View style={styles.root}>
          <View style={[styles.column, styles.firstColumn]}>
            {dataLeftColumn.map((row, index) => (
              <View key={index} style={styles.value}>
                <Text>{row.aliasName}</Text>
              </View>
            ))}
          </View>
          <View style={styles.column}>
            {dataRightColumn.map((row, index) => (
              <View key={index} style={styles.value}>
                <Text>{row.aliasName}</Text>
              </View>
            ))}
          </View>
        </View>
      </ProfileSection>
    );
  }
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    flex: 1,
  },
  firstColumn: {
    marginRight: 4,
  },
  value: {
    marginBottom: 2,
  },
});
