import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import Link from '../../../../../../../../lib/base/text/Link';
import { IconInfo } from '../../../../../../../components/icons/fontawesome/icons';
import { navigateToHelp, navigateToResults } from '../../../../../../../routing';
import ToolbarButton from './ToolbarButton';

class HelpButton extends PureComponent {
  static propTypes = {
    /**
     * Inverse the colors of the button.
     */
    inverse: PropTypes.bool,

    /**
     * Button shows only text.
     */
    textOnly: PropTypes.bool,

    /**
     * Click handler.
     */
    onClick: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  handleOnClick() {
    if (this.isPressed()) {
      navigateToResults(this.props);
    } else {
      navigateToHelp(this.props);
    }

    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  isPressed() {
    const { params } = this.props.match;
    return params.panel === 'help';
  }

  render() {
    if (this.props.textOnly) {
      return (
        <Link onClick={this.handleOnClick} size="small">
          Help
        </Link>
      );
    }

    return (
      <ToolbarButton
        onClick={this.handleOnClick}
        title="See help information"
        active={this.isPressed()}
        inverse={this.props.inverse}
        icon={<IconInfo />}
      />
    );
  }
}

export default withRouter(HelpButton);
