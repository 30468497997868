import { Text } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class Value extends Component {
  static propTypes = {
    /**
     * Value is empty.
     */
    empty: PropTypes.bool,

    /**
     * Value to render.
     */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    empty: false,
  };

  render() {
    return <Text>{this.props.value}</Text>;
  }
}
