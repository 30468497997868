/**
 * Fetch search keys advanced search.
 */
export const FETCH_SEARCH_KEYS_BEGIN = 'FETCH_SEARCH_KEYS_BEGIN';
export const FETCH_SEARCH_KEYS_SUCCESS = 'FETCH_SEARCH_KEYS_SUCCESS';
export const FETCH_SEARCH_KEYS_ERROR = 'FETCH_SEARCH_KEYS_ERROR';
/**
 * Geocode
 */
export const FETCH_GEOCODE_ADDRESS_BEGIN = 'FETCH_GEOCODE_ADDRESS_BEGIN';
export const FETCH_GEOCODE_ADDRESS_SUCCESS = 'FETCH_GEOCODE_ADDRESS_SUCCESS';
export const FETCH_GEOCODE_ADDRESS_ERROR = 'FETCH_GEOCODE_ADDRESS_ERROR';
/**
 * Fetch search keys advanced search.
 */
export const FETCH_FILTERS_BEGIN = 'FETCH_FILTERS_BEGIN';
export const FETCH_FILTERS_SUCCESS = 'FETCH_FILTERS_SUCCESS';
export const FETCH_FILTERS_ERROR = 'FETCH_FILTERS_ERROR';
/**
 * Clear search geocode object
 */
export const CLEAR_SEARCH_GEOCODE = 'CLEAR_SEARCH_GEOCODE';
export const CLEAR_SEARCH_GEOCODE_LOCAL = 'CLEAR_SEARCH_GEOCODE_LOCAL';
