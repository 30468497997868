import SiteSearchCriteria from '../../api/impl/SiteSearchCriteria';
import { DEFAULT_MAP_PADDING } from '../../app/Constants';
import { centerPositionWhenPanelIsOpen, mapPadding } from '../../app/util/map';
import { getSiteIds } from '../../app/util/urlParams';
import {
  ACTIVE_BASE_LAYER,
  ACTIVE_REFERENCE_LAYERS,
  DESELECT_SITE,
  FETCH_SEARCH_FILTER_COUNT_BEGIN,
  FETCH_SEARCH_FILTER_COUNT_ERROR,
  FETCH_SEARCH_FILTER_COUNT_SUCCESS,
  MAP_STARTUP_BEGIN,
  MAP_STARTUP_ERROR,
  MAP_STARTUP_MESSAGE,
  MAP_STARTUP_SUCCESS,
  MULTIPLE_SITE_SELECT,
  RESET_MAP,
  RESET_SEARCH_INPUT,
  SEARCH_INPUT_FOCUS_CHANGE,
  SEARCH_SITES_BEGIN,
  SEARCH_SITES_ERROR,
  SEARCH_SITES_SUCCESS,
  SELECT_MEASURE_TOOL,
  SELECT_SITE,
  SET_CENTER_ON_POSITION,
  SET_INFO_PANEL_CONTENT,
  SET_MY_LOCATION_COORDS,
  SKIP_MAP_MOVE_SEARCH_ONCE,
  ZOOM_TO_EXTENT,
  ZOOM_TO_LOCATION,
} from './map.actionType';

/**
 * Initial state.
 */
const initialState = {
  startup: {
    isStarting: undefined,
    error: undefined,
    message: 'Starting nSITE Explorer',
  },

  /**
   * Primary map state, basic properties of the map.
   */
  minResolution: .5,
  maxResolution: 6000,
  extent: undefined,
  center: undefined,
  centerPosition: centerPositionWhenPanelIsOpen(),
  zoom: 8,
  padding: mapPadding(),

  /**
   * Map layers state
   */
  activeBaseLayer: undefined,
  activeReferenceLayers: [],

  /**
   * State related to the panels used for hosting the various map and search tools like the search
   * results list, profile tabs, measure tool, etc.
   */
  activeMeasureTool: undefined,
  isSearchInputFocused: false,

  /**
   * The user's current location using navigator.geolocation api
   */
  myLocation: undefined,

  /**
   * Search related properties. The search endpoint produces am array of sites and features (for
   * display on the map).
   */
  search: {
    isFetching: false,
    error: undefined,
    criteria: new SiteSearchCriteria({
      term: getSiteIds(),
    }),
    features: undefined,
    sites: undefined,
    siteSelected: undefined,
    count: undefined,

    /**
     * This flags helps with resetting the search input. When the home button is pressed this flag
     * is set to true - the SearchInput component will then clear the input value. The next search
     * will then reset the flag back to false.
     */
    isResetNeeded: false,
  },

  /**
   * State related to the counts per filter - can be seen in the search filters screen
   */
  serverSpatialLayer: {
    isFetching: false,
    data: undefined,
    error: undefined,
  },

  /**
   * State related to the Spatial Layer
   */
  searchFilterCount: {
    isFetching: false,
    data: undefined,
    error: undefined,
  },

  /**
   * State for the sites layer -
   */
  sitesLayer: {
    visible: true,
    interactive: true,
  },
};

/**
 * Map reducer function.
 */
const map = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case MAP_STARTUP_BEGIN:
      return Object.assign({}, state, {
        startup: {
          ...state.startup,
          isStarting: true,
        },
      });
    case MAP_STARTUP_MESSAGE:
      return Object.assign({}, state, {
        startup: {
          ...state.startup,
          message: payload.message,
        },
      });
    case MAP_STARTUP_ERROR:
      return Object.assign({}, state, {
        startup: {
          ...state.startup,
          error: payload.error,
        },
      });
    case MAP_STARTUP_SUCCESS:
      return Object.assign({}, state, {
        startup: {
          ...state.startup,
          isStarting: false,
        },
        extent: payload.extent,
        center: payload.center,
        activeBaseLayer: payload.map.activeBaseLayer,
        activeReferenceLayers: payload.map.activeReferenceLayers,
      });

    case SET_MY_LOCATION_COORDS:
      return Object.assign({}, state, {
        myLocation: payload.coordinates,
        centerPosition: undefined,
      });

    case SET_CENTER_ON_POSITION:
      return Object.assign({}, state, {
        centerPosition: payload.position,
      });

    case ZOOM_TO_LOCATION:
      return Object.assign({}, state, {
        center: payload.coordinates,
        zoom: payload.zoom,
      });

    case ZOOM_TO_EXTENT:
      return Object.assign({}, state, {
        extent: payload.extent,
      });

    case RESET_MAP:
      return Object.assign({}, state, {
        extent: payload.extent,
        search: {
          ...state.search,
          isResetNeeded: true,
        },
      });

    case RESET_SEARCH_INPUT:
      return Object.assign({}, state, {
        search: {
          ...state.search,
          isResetNeeded: true,
        },
      });

    case SET_INFO_PANEL_CONTENT:
      return Object.assign({}, state, {
        infoPanelContent: payload.content,
      });

    case ACTIVE_BASE_LAYER:
      return Object.assign({}, state, {
        activeBaseLayer: payload.layer,
      });

    case ACTIVE_REFERENCE_LAYERS:
      return Object.assign({}, state, {
        activeReferenceLayers: [...payload.layers],
      });

    case SEARCH_INPUT_FOCUS_CHANGE:
      return Object.assign({}, state, {
        isSearchInputFocused: payload.isFocused,
      });

    case SEARCH_SITES_BEGIN:
      return Object.assign({}, state, {
        search: {
          ...state.search,
          isFetching: true,
          criteria: payload.criteria,
        },
      });

    case SEARCH_SITES_SUCCESS:
      return Object.assign({}, state, {
        search: {
          ...state.search,
          isFetching: false,
          features: payload.features,
          sites: payload.sites,
          count: payload.count,
          criteria: payload.criteria,
          error: undefined,
          isResetNeeded: false,
        },
        padding: payload.sites ? mapPadding() : DEFAULT_MAP_PADDING,
      });

    case SEARCH_SITES_ERROR:
      return Object.assign({}, state, {
        search: {
          ...state.search,
          isFetching: false,
          error: payload.error,
        },
      });

    case FETCH_SEARCH_FILTER_COUNT_BEGIN:
      return Object.assign({}, state, {
        searchFilterCount: {
          ...state.searchFilterCount,
          isFetching: true,
        },
      });

    case FETCH_SEARCH_FILTER_COUNT_SUCCESS:
      return Object.assign({}, state, {
        searchFilterCount: {
          isFetching: false,
          data: payload.filterCounts,
          error: undefined,
        },
      });

    case FETCH_SEARCH_FILTER_COUNT_ERROR:
      return Object.assign({}, state, {
        searchFilterCount: {
          ...state.searchFilterCount,
          isFetching: false,
          error: payload.error,
        },
      });

    case SELECT_MEASURE_TOOL:
      return Object.assign({}, state, {
        activeMeasureTool: payload.activeMeasureTool,
        sitesLayer: {
          ...state.sitesLayer,
          interactive: payload.activeMeasureTool === undefined,
        },
      });

    case SELECT_SITE:
      return Object.assign({}, state, {
        search: {
          ...state.search,
          siteSelected: action.payload.site,
          isMapSelection: action.payload.options && action.payload.options.isMapSelection,
        },
      });

    case DESELECT_SITE:
      return Object.assign({}, state, {
        search: {
          ...state.search,
          siteSelected: undefined,
          isMapSelection: false,
        },
      });

    case MULTIPLE_SITE_SELECT:
      return Object.assign({}, state, {
        search: {
          ...state.search,
          selectedClusterSiteList: payload.selectedClusterSiteList,
        },
      });

    case SKIP_MAP_MOVE_SEARCH_ONCE:
      return Object.assign({}, state, {
        search: {
          ...state.search,
          isSkipMapMoveSearch: payload.isSkip,
        },
      });

    default:
      return Object.assign({}, state, {});
  }
};
export default map;
