import get from 'lodash/get';
import { getSiteId } from '../../app/util/entities/siteProfile';
import { selectSite } from '../map/map.action';
import {
  FETCH_GENERIC_BEGIN,
  FETCH_GENERIC_ERROR,
  FETCH_GENERIC_SUCCESS,
  LOAD_PROFILE_DETAIL_BEGIN,
  LOAD_PROFILE_DETAIL_ERROR,
  LOAD_PROFILE_DETAIL_SUCCESS,
} from './profile.actionType';

/**
 * Needs to be function in order to delay access. When the code intially loads window.nSITE.Api is
 * undefined.
 */
const api = () => window.nSITE.Api;

/**
 * //////////////////////////////////////////////////////
 * ////
 * //// Profile detail actions
 * ////
 * //////////////////////////////////////////////////////
 */

export function loadProfileDetailIfNeeded(sourceIdentifier) {
  return (dispatch, getState) => {
    if (shouldLoadProfileDetail(getState())) {
      return dispatch(loadProfileDetail(sourceIdentifier, getState()));
    }
    return undefined;
  };
}

function shouldLoadProfileDetail(state) {
  return !state.profile.detail.isLoading;
}

function loadProfileDetail(sourceIdentifier, state) {
  return dispatch => {
    dispatch(loadProfileDetailBegin());
    api()
      .profile.getProfileDetails(sourceIdentifier)
      .then(profileDetail => {
        const sites = get(state, 'map.search.sites');

        if (sites) {
          const found = sites.find(s => s.sourceIdentifier === getSiteId(profileDetail));

          if (found) {
            dispatch(selectSite(found));
          } else {
            api()
              .site.getSiteBySourceIdentifier(getSiteId(profileDetail))
              .then(site => {
                dispatch(selectSite(site));
              });
          }
        }

        dispatch(loadProfileDetailSuccess(profileDetail));
      })
      .catch(err => dispatch(loadProfileDetailError(err.message)));
  };
}

function loadProfileDetailBegin() {
  return {
    type: LOAD_PROFILE_DETAIL_BEGIN,
  };
}

function loadProfileDetailSuccess(detail) {
  return {
    type: LOAD_PROFILE_DETAIL_SUCCESS,
    payload: { detail },
  };
}

function loadProfileDetailError(message) {
  return {
    type: LOAD_PROFILE_DETAIL_ERROR,
    payload: { error: message },
  };
}

/**
 * //////////////////////////////////////////////////////
 * ////
 * //// Profile Generic
 * ////
 * //////////////////////////////////////////////////////
 */

export function fetchGenericIfNeeded(sourceIdentifier) {
  return (dispatch, getState) => {
    if (shouldGeneric(getState())) {
      return dispatch(fetchGeneric(sourceIdentifier));
    }
    return undefined;
  };
}

function shouldGeneric(state) {
  return !state.profile.generic.isLoading;
}

function fetchGeneric(sourceIdentifier) {
  return dispatch => {
    dispatch(loadGenericBegin());

    api()
      .profile.getGeneric(sourceIdentifier)
      .then(generic => {
        dispatch(loadGenericSuccess(generic));
      })
      .catch(err => dispatch(loadGenericError(err.message)));
  };
}

function loadGenericBegin() {
  return {
    type: FETCH_GENERIC_BEGIN,
  };
}

function loadGenericSuccess(generic) {
  return {
    type: FETCH_GENERIC_SUCCESS,
    payload: { generic },
  };
}

function loadGenericError(message) {
  return {
    type: FETCH_GENERIC_ERROR,
    payload: { error: message },
  };
}
