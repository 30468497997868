import GeoJSON from 'ol/format/GeoJSON';
import { toWebMercatorExtent } from '../../../lib/map/core/transfomer';

const asGeometry = json => {
  const format = new GeoJSON();
  const features = format.readFeatures(json);
  return features[0].getGeometry();
};

const extent = initialGeometry => {
  return toWebMercatorExtent(asGeometry(initialGeometry).getExtent());
};

export default extent;
