import Map from 'ol/Map';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ArcGISTileLayer, BingMapsTileLayer } from '../../../../../../lib/map';

export default class RootBaseLayer extends PureComponent {
  static propTypes = {
    /**
     * The map instance will be provided to the component automatically when used as a
     * child component of the <Map/>.
     */
    map: PropTypes.instanceOf(Map),

    /**
     * Root or bottom-most layer seen on the map.
     */
    layer: PropTypes.shape({
      id: PropTypes.any.isRequired,
      layerType: PropTypes.string.isRequired,
      layerName: PropTypes.string.isRequired,
      displayOrder: PropTypes.number.isRequired,
      opacity: PropTypes.number.isRequired,
      layerUrl: PropTypes.string,
      layerUrlShowVal: PropTypes.string,
      baseType: PropTypes.any,
    }),

    /**
     * Api key for Bing Map services.
     */
    bingApiKey: PropTypes.string,

    /**
     * Called after all tiles have loaded.
     */
    onTilesLoadComplete: PropTypes.func,
  };

  render() {
    const { map, layer, bingApiKey } = this.props;

    if (!layer) {
      return null;
    }

    switch (layer.layerType.toLowerCase()) {
      case 'arcgis93rest':
      case 'arcgis':
        return (
          <ArcGISTileLayer
            id={layer.id}
            map={map}
            url={layer.layerUrl}
            zIndex={layer.displayOrder}
            opacity={layer.opacity}
            onTilesLoadComplete={this.props.onTilesLoadComplete}
          />
        );
      case 'bing':
        return (
          <BingMapsTileLayer
            id={layer.id}
            map={map}
            zIndex={layer.displayOrder}
            apiKey={bingApiKey}
            type={layer.baseType}
            onTilesLoadComplete={this.props.onTilesLoadComplete}
          />
        );
      default:
        return null;
    }
  }
}
