import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { animated, config, Transition } from 'react-spring/renderprops';

/**
 * A CSS transition component to fade children in and out.
 */
class FadeInOut extends Component {
  static propTypes = {
    /**
     * Child component to slide in/out.
     */
    children: PropTypes.any.isRequired,

    /**
     * On - enter or exit the transition.
     */
    on: PropTypes.bool.isRequired,
  };

  render() {
    return (
      <Transition
        native
        items={this.props.on}
        from={{ opacity: 0.1 }}
        enter={{ opacity: 1 }}
        leave={{ opacity: 0.1 }}
        config={{ ...config.stiff, duration: 150, clamp: true }}
      >
        {on =>
          on &&
          (springStyles => (
            <animated.div className={this.props.classes.container} style={springStyles}>
              {this.props.children}
            </animated.div>
          ))
        }
      </Transition>
    );
  }
}

const styles = () => ({
  container: {
    position: 'absolute',
    zIndex: props => props.zIndex,
  },
});

export default injectSheet(styles)(FadeInOut);
