import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Modal } from '../../../../../../lib/base';
import HeadingText from '../../../../../components/text/HeadingText';
import { navigateToMapHome } from '../../../../../routing';
import LayerSwitcher from '../../shared/panel/layers/LayerSwitcher';

class ModalMapLayers extends Component {
  constructor(props) {
    super(props);
    this.handleOnClose = this.handleOnClose.bind(this);
  }

  handleOnClose() {
    navigateToMapHome(this.props);
  }

  isOpen() {
    const { params } = this.props.match;
    return params.panel === 'layers';
  }

  render() {
    return (
      <Modal
        title={() => <HeadingText>Map layers</HeadingText>}
        isOpen={this.isOpen()}
        onRequestClose={this.handleOnClose}
      >
        <LayerSwitcher />
      </Modal>
    );
  }
}

export default withRouter(ModalMapLayers);
