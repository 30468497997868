import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Text } from '../../../lib/pdf';

export default class Label extends Component {
  static propTypes = {
    /**
     * Label to render.
     */
    label: PropTypes.string.isRequired,
  };

  render() {
    return <Text bold>{this.props.label}</Text>;
  }
}
