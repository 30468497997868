/**
 * Map startup.
 */
export const MAP_STARTUP_BEGIN = 'MAP_STARTUP_BEGIN';
export const MAP_STARTUP_SUCCESS = 'MAP_STARTUP_SUCCESS';
export const MAP_STARTUP_ERROR = 'MAP_STARTUP_ERROR';
export const MAP_STARTUP_MESSAGE = 'MAP_STARTUP_MESSAGE';

/**
 * Map related actions.
 */
export const RESET_MAP = 'RESET_MAP';
export const RESET_SEARCH_INPUT = 'RESET_SEARCH_INPUT';
export const SELECT_MEASURE_TOOL = 'SELECT_MEASURE_TOOL';
export const ZOOM_TO_EXTENT = 'ZOOM_TO_EXTENT';
export const ZOOM_TO_LEVEL = 'ZOOM_TO_LEVEL';
export const ZOOM_TO_LOCATION = 'ZOOM_TO_LOCATION';
export const SET_MY_LOCATION_COORDS = 'SET_MY_LOCATION_COORDS';
export const SET_CENTER_ON_POSITION = 'SET_CENTER_ON_POSITION';
export const SEARCH_INPUT_FOCUS_CHANGE = 'SEARCH_INPUT_FOCUS_CHANGE';
export const SET_INFO_PANEL_CONTENT = 'SET_INFO_PANEL_CONTENT';

/**
 * Map layer selections.
 */
export const ACTIVE_BASE_LAYER = 'ACTIVE_BASE_LAYER';
export const ACTIVE_REFERENCE_LAYERS = 'ACTIVE_REFERENCE_LAYERS';
/**
 * Search sites.
 */
export const SEARCH_SITES_BEGIN = 'SEARCH_SITES_BEGIN';
export const SEARCH_SITES_SUCCESS = 'SEARCH_SITES_SUCCESS';
export const SEARCH_SITES_ERROR = 'SEARCH_SITES_ERROR';

export const SELECT_SITE = 'SELECT_SITE';
export const DESELECT_SITE = 'DESELECT_SITE';
export const SKIP_MAP_MOVE_SEARCH_ONCE = 'SKIP_MAP_MOVE_SEARCH_ONCE';

/**
 * Highlight sites in list when the user cannot zoom further
 */
export const MULTIPLE_SITE_SELECT = 'MULTIPLE_SITE_SELECT';

/**
 * Fetch search filter counts.
 */
export const FETCH_SEARCH_FILTER_COUNT_BEGIN = 'FETCH_SEARCH_FILTER_COUNT_BEGIN';
export const FETCH_SEARCH_FILTER_COUNT_SUCCESS = 'FETCH_SEARCH_FILTER_COUNT_SUCCESS';
export const FETCH_SEARCH_FILTER_COUNT_ERROR = 'FETCH_SEARCH_FILTER_COUNT_ERROR';
