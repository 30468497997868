import Map from 'ol/Map';
import View from 'ol/View';
import Polygon from 'ol/geom/Polygon';
import { transformExtent, transform } from 'ol/proj';

export const extent = obj => {
  if (obj instanceof Map) {
    return obj.getView().calculateExtent(obj.getSize());
  } else if (obj instanceof Polygon) {
    return obj.getExtent();
  } else if (obj instanceof View) {
    return obj.calculateExtent(obj.getSize());
  } else if (obj instanceof Array) {
    return obj;
  }
  console.warn(`Unable to return extents`, obj);
};

export const toLonLatExtent = obj => {
  if (obj instanceof Map) {
    return transformExtent(obj.getView().calculateExtent(obj.getSize()), 'EPSG:3857', 'EPSG:4326');
  } else if (obj instanceof Polygon) {
    return transformExtent(obj.getExtent(), 'EPSG:3857', 'EPSG:4326');
  } else if (obj instanceof View) {
    return transformExtent(obj.calculateExtent(obj.getSize()), 'EPSG:3857', 'EPSG:4326');
  } else if (obj instanceof Array) {
    return transformExtent(obj, 'EPSG:3857', 'EPSG:4326');
  }
  console.warn(`Unable to transform to lon lat extents`, obj);
};

export const toWebMercatorExtent = obj => {
  if (obj instanceof Map) {
    return transformExtent(obj.getView().calculateExtent(obj.getSize()), 'EPSG:4326', 'EPSG:3857');
  } else if (obj instanceof Polygon) {
    return transformExtent(obj.getExtent(), 'EPSG:4326', 'EPSG:3857');
  } else if (obj instanceof View) {
    return transformExtent(obj.calculateExtent(obj.getSize()), 'EPSG:4326', 'EPSG:3857');
  } else if (obj instanceof Array) {
    return transformExtent(obj, 'EPSG:4326', 'EPSG:3857');
  }
  console.warn(`Unable to transform to web mercator extents`, obj);
};

export const toWebMercatorCenter = array => {
  return transform(array, 'EPSG:4326', 'EPSG:3857');
};

export const toLonLatCenter = array => {
  return transform(array, 'EPSG:3857', 'EPSG:4326');
};
