import { Text, View } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
import PropTypes from 'prop-types';
import React from 'react';

const colors = {
  outerBorder: '#e6e6e6',
  headerBackground: '#a3a3a3',
  bodyRowBackground: 'white',
  bodyRowBackgroundShaded: '#e6e6e6',
};

const RootView = styled(View)`
  display: flex;
  flex-direction: column;
`;

const Row = styled(View)`
  display: flex;
  flex-direction: row;
  background-color: ${props =>
    props.shade ? colors.bodyRowBackgroundShaded : colors.bodyRowBackground};
`;

const Header = styled(Row)`
  background-color: ${colors.headerBackground};
  font-weight: bold;
`;

const Cell = styled(View)`
  padding: 5pt;
  font-size: 9pt;
  width: ${props => props.width};
`;

const cellText = ({ column, row }) => {
  if (column.accessor) {
    return column.accessor(row);
  }
  return row[column.id];
};

const Table = ({ columns, data }) => {
  return (
    <RootView>
      <Header fixed>
        {columns.map(column => (
          <Cell key={column.id} width={column.width}>
            <Text>{column.displayName}</Text>
          </Cell>
        ))}
      </Header>
      {data.map((row, index) => (
        <Row key={index} shade={index % 2 !== 0} wrap={false}>
          {columns.map(column => (
            <Cell key={column.id} width={column.width}>
              <Text>{cellText({ column, row })}</Text>
            </Cell>
          ))}
        </Row>
      ))}
    </RootView>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      width: PropTypes.string.isRequired,
    })
  ).isRequired,
  data: PropTypes.array,
};

Table.defaultProps = {
  data: [],
};

export default Table;
