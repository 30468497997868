import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { IconClear, IconFilter } from '../../../../app/components/icons';
import { COLOR_BASE_PRIMARY } from '../../../../app/Constants';
import { propTypeColor, propTypeColorVariant } from '../../util/propTypes';
import Cell from './Cell';

class FilterHeaderCell extends Component {
  static propTypes = {
    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,

    /**
     * Column definition.
     */
    column: PropTypes.object.isRequired,

    /**
     * Called when filter value changes.
     */
    onChange: PropTypes.func,

    /**
     * Called when filter is cleared.
     */
    onClear: PropTypes.func,

    /**
     * Background color.
     */
    bgColor: propTypeColor,

    /**
     * Background color variant.
     */
    bgColorVariant: propTypeColorVariant,

    /**
     * Value of the criteria
     */
    initialValue: PropTypes.string,
  };

  static defaultProps = {
    onChange: undefined,
    onClear: undefined,
    bgColor: 'primary',
    bgColorVariant: 'light1',
    initialValue: undefined,
  };

  constructor(props) {
    super(props);

    this.state = {
      value: props.initialValue || '',
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnClear = this.handleOnClear.bind(this);
    this.callOnChange = this.callOnChange.bind(this);
    this.debouncedCallOnChange = debounce(this.callOnChange, 500);
  }

  callOnChange() {
    if (this.props.onChange) {
      this.props.onChange(this.props.column, this.state.value);
    }
  }

  handleOnChange(event) {
    this.setState(
      {
        value: event.target.value,
      },
      this.debouncedCallOnChange
    );
  }

  handleOnClear(event) {
    event.stopPropagation();

    if (this.props.onClear) {
      this.setState({
        value: '',
      });

      this.props.onClear(this.props.column);
    }
  }

  renderFilterIcon() {
    return (
      <div className={this.props.classes.filterIcon} data-icon="filter">
        <IconFilter size="xs" color="gray" colorVariant="light2" />
      </div>
    );
  }

  renderClearIcon() {
    if (!this.state.value) {
      return null;
    }

    return (
      <div className={this.props.classes.clearIcon} onClick={this.handleOnClear}>
        <IconClear size="xs" color="primary" colorVariant="dark3" />
      </div>
    );
  }

  renderInput() {
    return (
      <input
        className={this.props.classes.input}
        type="text"
        onChange={this.handleOnChange}
        value={this.state.value}
      />
    );
  }

  render() {
    const { column, classes, ...rest } = this.props;

    return (
      <Cell width={column.width} {...rest}>
        <div className={classes.container}>
          {this.renderInput()}
          {this.renderFilterIcon()}
          {this.renderClearIcon()}
        </div>
      </Cell>
    );
  }
}

const styles = theme => ({
  container: {
    position: 'relative',
  },

  input: {
    width: 'calc(100% - 2px)',
    borderRadius: '3px',
    border: `1px solid ${theme.color[COLOR_BASE_PRIMARY]['light2']}`,
    backgroundColor: 'white',
    overflow: 'hidden',
    padding: theme.spacing.smaller,
    paddingLeft: '34px',
    paddingRight: '24px',
    lineHeight: '18px',
    fontSize: '14px',
    color: '#282828',

    '&:focus': {
      border: `1px solid ${theme.color[COLOR_BASE_PRIMARY]['light4']}`,
      outline: 0,
    },

    '&:focus + [data-icon="filter"] > span': {
      color: theme.color[COLOR_BASE_PRIMARY]['light2'],
    },
  },

  filterIcon: {
    position: 'absolute',
    bottom: 0,
    lineHeight: '30px',
    paddingLeft: '12px',
  },

  clearIcon: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    lineHeight: '30px',
    paddingRight: '12px',

    '&:hover': {
      cursor: 'pointer',
    },
  },
});

export default injectSheet(styles)(FilterHeaderCell);
