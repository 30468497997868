import BaseEndpoint from './BaseEndpoint';
import Urls from './Urls';

/**
 * Get a reference to the AbortController class.
 */
const AbortController = window.AbortController;
let suggestionsAbortController = new AbortController();

/**
 * Search endpoint.
 */
export default class SearchEndpoint extends BaseEndpoint {
  /**
   * Returns a promise resolving suggestions.
   *
   * @returns {Promise<any>} suggestions
   */
  suggest(searchKeyId, term) {
    suggestionsAbortController.abort();
    suggestionsAbortController = new AbortController();

    const url = `${super.url(Urls.Search.Suggest)}?${super.toParameterString({
      key: searchKeyId,
      term: term,
    })}`;

    return super
      .get(url, { signal: suggestionsAbortController.signal })
      .then(response => response.json())
      .then(json => json);
  }
}
