import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Box } from '../../../../../../../lib/base';
import ExportGroup from './ExportGroup';
import ExportGroups from './ExportGroups';
import Select from '../../../../../../components/form/Select';
import HeadingText from '../../../../../../components/text/HeadingText';

export default class ExportPanel extends Component {
  static propTypes = {
    /**
     * Export groups to display.
     */
    exportGroups: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      activeExportGroup: props.exportGroups[0],
    };

    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnChange(event, exportGroup) {
    this.setState({ activeExportGroup: exportGroup });
  }

  render() {
    const { activeExportGroup } = this.state;

    const label = choice => {
      return <HeadingText>{choice.name}</HeadingText>;
    };

    let exportListSize = 0;
    this.props.exportGroups.forEach(element => {
      exportListSize += element.exportTypes.length;
    });
    const showExportSelect = exportListSize > 29;

    return (
      <Box pad="medium">
        {showExportSelect && (
          <React.Fragment>
            <Select
              value={activeExportGroup}
              onChange={this.handleOnChange}
              placeholder="Choose..."
              choices={this.props.exportGroups}
              keyExtractor={choice => choice.name}
              labelExtractor={choice => label(choice)}
              renderChoice={choice => label(choice)}
            />
            <ExportGroup
              name={activeExportGroup.name}
              exportTypes={activeExportGroup.exportTypes}
            />
          </React.Fragment>
        )}
        {!showExportSelect && (
          <Box mTop="medium">
            <ExportGroups exportGroups={this.props.exportGroups} />
          </Box>
        )}
      </Box>
    );
  }
}
