import Map from 'ol/Map';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FeaturesLayer } from '../../../../../../lib/map';
import { lonLatAsFeature } from '../../../../../util/feature';

/**
 * Displays the geo-coded address on the map.
 */
export default class SearchedGeolocationLayer extends Component {
  static propTypes = {
    /**
     * The instance of map this layer is attached to. The map instance
     * will be provided to the component automatically when used as a
     * child component of the <Map/>.
     */
    map: PropTypes.instanceOf(Map),

    /**
     * Lon/lat coordinates.
     */
    point: PropTypes.array,
  };

  static defaultProps = {
    map: undefined,
    point: undefined,
  };

  render() {
    if (!this.props.point) {
      return null;
    }

    const point = [...this.props.point].reverse();

    return (
      <FeaturesLayer
        id="SearchedGeolocationLayer"
        map={this.props.map}
        zIndex={201}
        features={[lonLatAsFeature(point)]}
        visible={true}
      />
    );
  }
}
