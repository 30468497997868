import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import injectSheet from 'react-jss';
import { Redirect, withRouter } from 'react-router-dom';
import { animated, config, Transition } from 'react-spring/renderprops';
import { Box, Row, Rows } from '../../../../../lib/base';
import { Z_INDEX_SEARCH_BAR } from '../../../../Constants';
import ApplicationBuildStamp from '../shared/panel/ApplicationBuildStamp';
import CurrentCriteriaChips from '../shared/panel/criteria/chip/CurrentCriteriaChips';
import ExportContainer from '../shared/panel/export/ExportContainer';
import HelpPanel from '../shared/panel/help/HelpPanel';
import InfoPanel from '../shared/panel/help/InfoPanel';
import LayerSwitcher from '../shared/panel/layers/LayerSwitcher';
import MeasurePanel from '../shared/panel/measure/MeasurePanel';
import SitesListPanel from '../shared/panel/results/SitesListPanel';

class DesktopOmniContent extends Component {
  static propTypes = {
    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,

    /**
     * Called clear measurements is clicked.
     */
    onClickClearMeasurements: PropTypes.func.isRequired,

    /**
     * Called when measure tool unit selection changes.
     */
    onUnitChange: PropTypes.func.isRequired,
  };

  panels = {
    results: (
      <Fragment>
        <CurrentCriteriaChips />
        <SitesListPanel />
      </Fragment>
    ),

    export: <ExportContainer />,

    help: (
      <Box pad="medium">
        <Rows stretch>
          <Row flex="1 0 0">
            <HelpPanel />
          </Row>
          <Row flex={0}>
            <Box textAlign="center" mTop="medium">
              <ApplicationBuildStamp />
            </Box>
          </Row>
        </Rows>
      </Box>
    ),
    info: (
      <Box pad="medium">
        <InfoPanel />
      </Box>
    ),
    layers: <LayerSwitcher />,
    measure: (
      <MeasurePanel
        onClickClearMeasurements={this.props.onClickClearMeasurements}
        onUnitChange={this.props.onUnitChange}
      />
    ),
  };

  springConfig = { ...config.stiff, velocity: 9, clamp: true };

  render() {
    const { container } = this.props.classes;

    const params = this.props.match.params;

    if (params.panel === undefined) {
      return <Redirect to="/map/results" />;
    }

    return (
      <div className={container}>
        <Transition
          native
          reset
          unique
          items={params.panel}
          from={{ opacity: 0, transform: 'translateX(-100%)' }}
          enter={{ opacity: 1, transform: 'translateX(0%)' }}
          leave={{ opacity: 0, transform: 'translateX(-100%)' }}
          config={this.springConfig}
        >
          {activePanel => style => (
            <animated.div className={container} style={style}>
              {this.panels[activePanel]}
            </animated.div>
          )}
        </Transition>
      </div>
    );
  }
}

const styles = () => ({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflowY: 'auto',
    overflowX: 'hidden',
    zIndex: Z_INDEX_SEARCH_BAR - 1,
  },

  panel: {
    height: '100%',
    overflowY: 'auto',
    backgroundColor: 'white',
  },
});

export default withRouter(injectSheet(styles)(DesktopOmniContent));
