import { containsCoordinate } from 'ol/extent';
import Feature from 'ol/Feature';
import WKT from 'ol/format/WKT';
import Point from 'ol/geom/Point';
import Circle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import { toWebMercatorCenter, toWebMercatorExtent } from '../../lib/map/core/transfomer';
import { COLOR_BASE_PRIMARY } from '../Constants';
import { newStyleNotSelected } from '../util/marker';

/**
 * Returns the source identifier from a site feature - a site feature that's seen on
 * the map.
 *
 * @param feature site feature.
 * @returns {*}
 */
export const sourceIdentifier = feature => {
  if (feature) return feature.get('sourceIdentifier');
};

/**
 * Returns the source identifier from a site feature - a site feature that's seen on
 * the map.
 *
 * @param feature site feature.
 * @returns {*}
 */
export const bbox = feature => {
  if (feature) return feature.get('bbox');
};

/**
 * Util function to determine if feature is a cluster - a cluster that's seen on
 * the map.
 *
 * @param feature site feature.
 * @returns {*}
 */
export const isCluster = feature => {
  return bbox(feature) !== undefined;
};

/**
 * Returns the extent of the feature (should be a cluster) as Web Mercator extent.
 *
 * @param feature
 */
export const webMercatorExtent = feature => {
  return toWebMercatorExtent(bbox(feature));
};

/**
 * Returns whether or not site is in the feature cluster.
 *
 * @param site site to test.
 * @param feature feature to feature.
 * @returns {boolean} whether or not site is in the feature cluster.
 */
export const isSiteInCluster = (site, feature) => {
  if (site && site.feature && isCluster(feature)) {
    return containsCoordinate(webMercatorExtent(feature), site.feature.coordinates);
  }
  return false;
};

/**
 * Does this site have the same feature id as this feature?
 */
export const isSharesSourceId = (site, feature) => {
  return site && feature && site.sourceIdentifier === sourceIdentifier(feature);
};

/**
 * Are both sites the same.
 */
export const isSitesEqual = (site1, site2) => {
  return site1 && site2 && site1.sourceIdentifier === site2.sourceIdentifier;
};

/**
 * Converts a site profile features to OpenLayers features.
 *
 * @param profile site profile
 * @returns {Array} open layers feature instances.
 */
export const profileToFeatures = profile => {
  if (profile && profile.profileFeature && Array.isArray(profile.profileFeature)) {
    const features = [];
    const format = new WKT();

    profile.profileFeature.forEach(f => {
      const feature = format.readFeature(f.geographyWkt, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857',
      });

      feature.setId(f.geographyWkt);
      feature.set('profileFeature', f);
      features.push(feature);
    });

    return features;
  }

  return [];
};

/**
 * Returns a single element array of features to place on the map for the given lonlat coordinates.
 */
export const myLocationToFeature = lonLat => {
  const positionFeature = new Feature();

  const styles = [
    new Style({
      image: new Circle({
        radius: 14,
        fill: new Fill({
          color: 'rgba(51, 153, 204, 0.3)',
        }),
      }),
    }),
    new Style({
      image: new Circle({
        radius: 6,
        fill: new Fill({
          color: 'rgba(51, 153, 204, 0.9)',
        }),
        stroke: new Stroke({
          color: '#fff',
          width: 2,
        }),
      }),
    }),
  ];

  positionFeature.setStyle(styles);
  positionFeature.setGeometry(lonLat ? new Point(toWebMercatorCenter(lonLat)) : null);
  return positionFeature;
};

export const lonLatAsFeature = lonLat => {
  const positionFeature = new Feature();
  positionFeature.setGeometry(lonLat ? new Point(toWebMercatorCenter(lonLat)) : null);
  positionFeature.setStyle(newStyleNotSelected(positionFeature, COLOR_BASE_PRIMARY));
  return positionFeature;
};
