import { Component } from 'react';
import { connect } from 'react-redux';
import Map from 'ol/Map';
import PropTypes from 'prop-types';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import WKT from 'ol/format/WKT';
import OLStyle from 'ol/style/Style';
import OLStroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Circle from 'ol/style/Circle';

/**
 * Needs to be function in order to delay access - when the page intially loads window.nSITE.Api is
 * undefined.
 */
const api = () => window.nSITE.Api;

/**
 * Layer component for displaying an array of Openlayers features.
 */
class SqlServerLayer extends Component {
  /**
   * Property types.
   */
  static propTypes = {
    /**
     * A unique key to indentify this layer.
     */
    id: PropTypes.number,
    /**
     * Layer Name
     */
    layerName: PropTypes.string.isRequired,
    /**
     * The instance of map this layer is attached to. The map instance
     * will be provided to the component automatically when used as a
     * child component of the <Map/>.
     */
    map: PropTypes.instanceOf(Map),
    /**
     * Called when the feature is just about to be added to the vector source.
     */
    onAddFeature: PropTypes.func,
    /**
     * Called when layer has been added to the map.
     */
    onAddLayer: PropTypes.func,
    /**
     * The style function applied to each feature.
     */
    onFeatureStyle: PropTypes.func,
    /**
     * Called when layer has been removed from the map.
     */
    onRemoveLayer: PropTypes.func,
    /**
     * Whether or not this layer is visible.
     */
    visible: PropTypes.bool,
    /**
     * The z-index to order this layer in the map layers stack.
     */
    zIndex: PropTypes.number,
    /**
     * current extent of the map
     */
    extent: PropTypes.array,

    /**
     * The layer opacity for the polygons
     */
    opacity: PropTypes.number,
  };

  /**
   * Default properties for those props that are not required.
   */
  static defaultProps = {
    features: undefined,
    id: Math.random() * 1000000 + '',
    map: undefined,
    onAddFeature: undefined,
    onAddLayer: undefined,
    onFeatureStyle: undefined,
    onRemoveLayer: undefined,
    visible: true,
    zIndex: 999,
    opacity: 0.6,
  };

  constructor(props) {
    super(props);

    /**
     * Provides source of features for vector layers.
     */
    this.vectorSource = new VectorSource();

    /**
     * The layer hosting the source vector.
     */
    this.vectorLayer = new VectorLayer({
      id: props.id,
      visible: props.visible,
      source: this.vectorSource,
      style: props.onFeatureStyle,
      zIndex: props.zIndex,
      opacity: props.opacity,
    });

    this.wktFormat = new WKT();
    this.formatCriteria = this.formatCriteria.bind(this);
    this.isFetching = false;
  }

  componentDidMount() {
    this.props.map.getLayers().push(this.vectorLayer);

    if (this.props.onAddLayer) {
      this.props.onAddLayer(this.vectorLayer);
    }

    this.popuplateLayerFeatures();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.popuplateLayerFeatures();
  }

  componentWillUnmount() {
    this.props.map.removeLayer(this.vectorLayer);

    if (this.props.removeLayer) {
      this.props.onRemoveLayer(this.vectorLayer);
    }

    this.vectorSource = null;
    this.vectorLayer = null;
  }

  formatCriteria() {
    return {
      screenWidth: this.props.criteria.screenWidth,
      screenHeight: this.props.criteria.screenHeight,
      featureLayers: this.props.layerName,
      zoom: this.props.map.getView().getZoom(),
      leftBound: this.props.criteria.boundingBox[0],
      bottomBound: this.props.criteria.boundingBox[1],
      rightBound: this.props.criteria.boundingBox[2],
      topBound: this.props.criteria.boundingBox[3],
    };
  }

  /**
   * Adds the features to the vector layer.
   */

  popuplateLayerFeatures() {
    let AbortController = window.AbortController;

    if (this.isFetching && this.getSpatialObjectsAbortController) {
      this.getSpatialObjectsAbortController.abort();
    }
    this.isFetching = true;
    this.getSpatialObjectsAbortController = new AbortController();

    var self = this;
    let mapInfo = this.formatCriteria();
    api()
      .layer.getServerSpatialObjects(mapInfo, {
        signal: this.getSpatialObjectsAbortController.signal,
      })
      .then(function(response) {
        self.vectorSource.clear();

        var features = [];
        response.forEach(item => {
          var feature = self.wktFormat.readFeature(item.geographyWKT, {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857',
          });

          let polygonStyle = new OLStyle({
            stroke: new OLStroke({ color: 'black' }),
            fill: new Fill({
              color: item.color,
            }),
          });

          let pointStyle = new Style({
            fill: new Fill({
              color: item.color,
            }),
            stroke: new Stroke({
              color: 'black',
              width: 2,
            }),
            image: new Circle({
              radius: 7,
              fill: new Fill({
                color: item.color,
              }),
              stroke: new Stroke({
                color: 'black',
                width: 2,
              }),
            }),
          });
          if (feature.getGeometry().getType() === 'Point') {
            feature.setStyle(pointStyle);
          } else {
            feature.setStyle(polygonStyle);
          }
          item.layerType = 'sqlSpatial';
          feature.set('metadata', item);

          features.push(feature);
        });

        self.vectorSource.addFeatures(features);
        self.isFetching = false;
      })
      .catch(error => console.log(error));
  }

  render() {
    return this.props.children || null;
  }
}

function mapStateToProps(state) {
  return {
    criteria: state.map.search.criteria,
    extent: state.map.extent,
    zoom: state.map.zoom,
  };
}

export default connect(mapStateToProps)(SqlServerLayer);
