import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Map from '../../../lib/map';
import { toWebMercatorCenter } from '../../../lib/map/core/transfomer';
import Config from '../../config';
import { DEFAULT_MAP_PADDING } from '../../Constants';
import LayerMetadataOverlay from '../../page/map/components/shared/layer/LayerMetadataOverlay';
import ReferenceLayers from '../../page/map/components/shared/layer/ReferenceLayers';
import RootBaseLayer from '../../page/map/components/shared/layer/RootBaseLayer';
import SiteFeaturesLayer from '../../page/map/components/shared/layer/SiteFeaturesLayer';
import { isSmallScreen } from '../../util/browser';
import WindowSizeAwareMapContainer from './detail/map/WindowSizeAwareMapContainer';

class SiteMapContainer extends Component {
  static propTypes = {
    layers: PropTypes.array,
    profile: PropTypes.object,
    minResolution: PropTypes.number,
    maxResolution: PropTypes.number,
    /**
     * API key for bing maps
     */
    bingApiKey: PropTypes.string,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);

    this.state = {
      extent: undefined,
    };

    this.handleOnAfterPopulateFeatures = this.handleOnAfterPopulateFeatures.bind(this);
  }

  handleOnAfterPopulateFeatures({ vectorSource }) {
    this.setState({
      extent: vectorSource.getExtent(),
    });
  }

  render() {
    const { longitude, latitude } = this.props.profile.profileFacilityDetail;

    if (!longitude || !latitude) {
      return null;
    }

    const {
      minResolution,
      maxResolution,
      activeBaseLayer: rootBaseLayer,
      activeReferenceLayers: referenceLayers,
    } = this.props;

    const center = toWebMercatorCenter([longitude, latitude]);

    return (
      <WindowSizeAwareMapContainer
        renderView={({ width, height }) => (
          <div>
            <Map
              id="profileMap"
              minResolution={minResolution}
              maxResolution={maxResolution}
              extent={this.state.extent}
              height={`${height}px`}
              width={`${width}px`}
              padding={DEFAULT_MAP_PADDING}
              center={center}
              scaleLine
              interactive
            >
              <RootBaseLayer layer={rootBaseLayer} bingApiKey={Config.getBingApiKey()} />

              <ReferenceLayers extent={this.state.extent} layers={referenceLayers} />

              <SiteFeaturesLayer onAfterPopulateFeatures={this.handleOnAfterPopulateFeatures} />

              {!isSmallScreen() && <LayerMetadataOverlay />}
            </Map>
          </div>
        )}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile.detail.data,
    minResolution: state.map.minResolution,
    maxResolution: state.map.maxResolution,
    activeBaseLayer: state.map.activeBaseLayer,
    activeReferenceLayers: state.map.activeReferenceLayers,
  };
}

export default connect(mapStateToProps)(SiteMapContainer);
