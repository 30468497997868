import BaseEndpoint from './BaseEndpoint';
import Urls from './Urls';
import { applyAdvancedCriteria } from './SiteEndpoint';

/**
 * Layer endpoint.
 */
export default class LayerEndpoint extends BaseEndpoint {
  /**
   * Returns a promise resolving layer data.
   *
   * @returns {Promise<any>} layer data
   */
  getAll() {
    const url = `${super.url(Urls.Layer.All)}`;

    /**
     * TODO Can we cache the layer data?
     */
    return super
      .get(url)
      .then(response => response.json())
      .then(json => json);
  }

  getSqlSpatialMetadata(mapInfo, options) {
    const newCriteria = applyAdvancedCriteria({ ...mapInfo });
    const url = `${super.url(Urls.Layer.GetSpatialLayerMetadata)}?${super.toParameterString(
      newCriteria
    )}`;

    return super
      .get(url, options)
      .then(response => response.json())
      .then(json => json);
  }

  getServerSpatialObjects(mapInfo, options) {
    const newCriteria = applyAdvancedCriteria({ ...mapInfo });

    const url = `${super.url(Urls.Layer.GetDatabaseLayers)}?${super.toParameterString(
      newCriteria
    )}`;

    return super
      .get(url, options)
      .then(response => response.json())
      .then(json => json);
  }

  getFeatureMetadata(id) {
    const url = `${super.url(Urls.Layer.GetFeatureLayerMetadata)}?id=${id.toString()}`;
    return super
      .get(url)
      .then(response => response.json())
      .then(json => json);
  }
}
