import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import Row from './Row';

class Rows extends Component {
  static propTypes = {
    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,

    /**
     * Columns definition.
     */
    columns: PropTypes.array.isRequired,

    /**
     * Data grid data.
     */
    data: PropTypes.array.isRequired,

    /**
     * Function to extract the row id.
     */
    keyExtractor: PropTypes.func.isRequired,

    /**
     * Adds zebra-striping to rows.
     */
    striped: PropTypes.bool,

    /**
     * Function to render subsection when extender is open.
     */
    renderSubsection: PropTypes.func,

    /**
     * Callback when a row is selected.
     */
    onSelect: PropTypes.func,

    /**
     * The selected entities.
     */
    selections: PropTypes.array,
  };

  isStriped(index) {
    return this.props.striped && index % 2 !== 0;
  }

  render() {
    return (
      <div className={this.props.classes.container}>
        {this.props.data &&
          this.props.data.map((rowData, rowIndex) => {
            return (
              <Row
                key={this.props.keyExtractor(rowData)}
                rowData={rowData}
                columns={this.props.columns}
                striped={this.isStriped(rowIndex)}
                keyExtractor={this.props.keyExtractor}
                renderSubsection={this.props.renderSubsection}
                onSelect={this.props.onSelect}
                selections={this.props.selections}
              />
            );
          })}
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    display: 'flex 1 0 auto',
    flexDirection: 'column',
  },
});

export default injectSheet(styles)(Rows);
