import { Component } from 'react';
import { connect } from 'react-redux';
import Map from 'ol/Map';
import PropTypes from 'prop-types';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import EsriJSON from 'ol/format/EsriJSON';

/**
 * Layer component for displaying an array of Openlayers features.
 */
class ArcGISFeatureLayer extends Component {
  /**
   * Property types.
   */
  static propTypes = {
    /**
     * Features to display in this layer.
     */
    features: PropTypes.array,
    /**
     * A unique key to indentify this layer.
     */
    id: PropTypes.number,
    /**
     * The instance of map this layer is attached to. The map instance
     * will be provided to the component automatically when used as a
     * child component of the <Map/>.
     */
    map: PropTypes.instanceOf(Map),
    /**
     * Called when the feature is just about to be added to the vector source.
     */
    onAddFeature: PropTypes.func,
    /**
     * Called when layer has been added to the map.
     */
    onAddLayer: PropTypes.func,
    /**
     * The style function applied to each feature.
     */
    onFeatureStyle: PropTypes.func,
    /**
     * Called when layer has been removed from the map.
     */
    onRemoveLayer: PropTypes.func,
    /**
     * Called after vector source populated with features.
     */
    onAfterPopulateFeatures: PropTypes.func,
    /**
     * Whether or not this layer is visible.
     */
    visible: PropTypes.bool,
    /**
     * The z-index to order this layer in the map layers stack.
     */
    zIndex: PropTypes.number,

    /**
     * current extent of the map
     */
    extent: PropTypes.array,

    /**
     * layer url from db
     */
    url: PropTypes.string,
  };

  /**
   * Default properties for those props that are not required.
   */
  static defaultProps = {
    features: undefined,
    id: Math.random() * 1000000 + '',
    map: undefined,
    onAddFeature: undefined,
    onAddLayer: undefined,
    onFeatureStyle: undefined,
    onRemoveLayer: undefined,
    visible: true,
    zIndex: 999,
  };

  constructor(props) {
    super(props);

    /**
     * Provides source of features for vector layers.
     */
    this.vectorSource = new VectorSource();

    /**
     * The layer hosting the source vector.
     */
    this.vectorLayer = new VectorLayer({
      id: props.id,
      visible: props.visible,
      source: this.vectorSource,
      style: props.onFeatureStyle,
      zIndex: props.zIndex,
    });

    this.esriJsonFormat = new EsriJSON();
  }

  componentDidMount() {
    this.props.map.getLayers().push(this.vectorLayer);

    if (this.props.onAddLayer) {
      this.props.onAddLayer(this.vectorLayer);
    }

    this.popuplateLayerFeatures();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.visible !== prevProps.visible) {
      this.vectorLayer.setVisible(this.props.visible);
    }

    if (this.props.visible) {
      this.popuplateLayerFeatures();
    }
  }

  componentWillUnmount() {
    this.props.map.removeLayer(this.vectorLayer);

    if (this.props.removeLayer) {
      this.props.onRemoveLayer(this.vectorLayer);
    }

    this.vectorSource = null;
    this.vectorLayer = null;
  }

  /**
   * Adds the features to the vector layer.
   */
  popuplateLayerFeatures() {
    var self = this;
    this.fetchFeatureVectors(this.props.url, this.props.extent)
      .then(response => {
        self.vectorSource.clear();

        var features = self.esriJsonFormat.readFeatures(response, {
          featureProjection: '102100',
        });

        self.vectorSource.addFeatures(features);
      })
      .catch(error => console.log(error));
  }

  fetchFeatureVectors(url, extent) {
    let urlFormatted =
      url +
      `query?f=json&returnGeometry=true&spatialRel=esriSpatialRelIntersects&` +
      `geometry={"xmin":${extent[0]},"ymin":${extent[1]},"xmax":${extent[2]},"ymax":${
        extent[3]
      },"spatialReference":{"wkid":102100}}&` +
      `geometryType=esriGeometryEnvelope&inSR=102100&outFields=*&outSR=102100&resultType=tile`;

    return fetch(urlFormatted)
      .then(response => response.json())
      .catch(() => console.log('error at fetchFeatureVectors'));
  }

  render() {
    return this.props.children || null;
  }
}

function mapStateToProps(state) {
  return {
    criteria: state.map.search.criteria,
    extent: state.map.extent,
    zoom: state.map.zoom,
  };
}

export default connect(mapStateToProps)(ArcGISFeatureLayer);
