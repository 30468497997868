import Map from 'ol/Map';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import {
  ArcGISFeatureLayer,
  ArcGISTileLayer,
  SqlServerLayer,
  WMSTileLayer,
} from '../../../../../../lib/map';
import Config from '../../../../../config';
import { sortByDisplayOrder } from '../../../../../util/layers';

export default class ReferenceLayers extends PureComponent {
  static propTypes = {
    /**
     * The map instance will be provided to the component automatically when used as a
     * child component of the <Map/>.
     */
    map: PropTypes.instanceOf(Map),

    /**
     * Layers to render onto the map.
     */
    layers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.any.isRequired,
        layerType: PropTypes.string.isRequired,
        layerName: PropTypes.string.isRequired,
        displayOrder: PropTypes.number.isRequired,
        opacity: PropTypes.number.isRequired,
        layerUrl: PropTypes.string,
        layerUrlShowVal: PropTypes.string,
        baseType: PropTypes.any,
      })
    ),

    /**
     * Current extent of the map
     */
    extent: PropTypes.array,
  };

  static defaultProps = {
    layers: [],
  };

  render() {
    const { map, layers, extent } = this.props;

    return layers.sort(sortByDisplayOrder).map(layer => {
      switch (layer.layerType.toLowerCase()) {
        case 'arcgis93rest':
        case 'arcgis':
          return (
            <ArcGISTileLayer
              map={map}
              key={layer.id}
              id={layer.id}
              metadata={layer}
              url={layer.layerUrl}
              zIndex={layer.displayOrder}
              params={{ LAYERS: `show:${layer.layerUrlShowVal}` }}
              opacity={layer.opacity}
              minZoom={Config.getMinZoom()}
              maxZoom={Config.getMaxZoom()}
            />
          );
        case 'arcgisfeature':
          return (
            <ArcGISFeatureLayer
              map={map}
              key={layer.id}
              extent={extent}
              id={layer.id}
              metadata={layer}
              url={layer.layerUrl}
              zIndex={layer.displayOrder}
              params={{ LAYERS: `show:${layer.layerUrlShowVal}` }}
              opacity={layer.opacity}
            />
          );
        case 'wms':
          return (
            <WMSTileLayer
              map={map}
              key={layer.id}
              id={layer.id}
              opacity={layer.opacity}
              metadata={layer}
              url={layer.layerUrl}
              zIndex={layer.displayOrder}
              layerName={layer.layerUrlShowVal}
            />
          );
        case 'sqlspatial':
          return (
            <SqlServerLayer
              map={map}
              key={layer.id}
              id={layer.id}
              layerName={layer.layerName}
              zIndex={layer.displayOrder}
              visible={true}
              opacity={layer.opacity}
            />
          );
        default:
          return null;
      }
    });
  }
}
