import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { Row, Rows } from '../../../lib/base';
import InfoButton from '../../page/map/components/shared/panel/profile/InfoButton';
import HeadingText from '../text/HeadingText';

class Card extends Component {
  static propTypes = {
    /**
     * Additional css for container.
     */
    className: PropTypes.string,

    /**
     * Card title.
     */
    title: PropTypes.string.isRequired,

    /**
     * info text for side panel
     */
    infoText: PropTypes.string,

    /**
     * ViewPort render function.
     */
    renderBody: PropTypes.func.isRequired,
  };

  static defaultProps = {
    value: undefined,
  };

  render() {
    return (
      <Rows className={this.props.className}>
        <Row flex={0} className={this.props.classes.header}>
          <HeadingText>
            {this.props.title}
            {this.props.infoText && <InfoButton infoText={this.props.infoText} />}
          </HeadingText>
        </Row>
        <Row flex={1} className={this.props.classes.body}>
          <div>{this.props.renderBody()}</div>
        </Row>
      </Rows>
    );
  }
}

const styles = theme => ({
  header: {
    padding: `${theme.spacing.small} ${theme.spacing.smallest}`,
    backgroundColor: theme.color['gray']['light1'],
  },

  body: {
    padding: `${theme.spacing.small} ${theme.spacing.smallest}`,
  },
});

export default injectSheet(styles)(Card);
