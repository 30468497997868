import React, { Component } from 'react';
import { Box, Text } from '../../../../../../../lib/base';
import { IconEmptyResults } from '../../../../../../components/icons/fontawesome/icons';
import { COLOR_BASE_PRIMARY, DEFAULT_TEXT_SIZE_DESCRIPTIONS } from '../../../../../../Constants';

export default class EmptySiteListPanel extends Component {
  render() {
    return (
      <Box margin="medium">
        <Box mBottom="large">
          <Text size={DEFAULT_TEXT_SIZE_DESCRIPTIONS} color="gray" colorVariant="dark6">
            Sorry, we couldn't find any results matching your search criteria.
          </Text>
        </Box>
        <Box mBottom="large">
          <Text size={DEFAULT_TEXT_SIZE_DESCRIPTIONS} color="gray" colorVariant="dark6">
            Try zooming out on the map or updating your search criteria to be less restrictive.
          </Text>
        </Box>
        <Box textAlign="center">
          <IconEmptyResults size="10x" color={COLOR_BASE_PRIMARY} colorVariant="light1" />
        </Box>
      </Box>
    );
  }
}
