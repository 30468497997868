/**
 * Values defining whitespace in a systematic way using the 8pt grid.
 */
import {
  SPACING_LARGE,
  SPACING_LARGER,
  SPACING_LARGEST,
  SPACING_MEDIUM,
  SPACING_SMALL,
  SPACING_SMALLER,
  SPACING_SMALLEST,
} from './variables';

const spacing = {
  /**
   * Apply padding or margin to a single side of an element.
   */
  smallest: `${SPACING_SMALLEST}px`,
  smaller: `${SPACING_SMALLER}px`,
  small: `${SPACING_SMALL}px`,
  medium: `${SPACING_MEDIUM}px`,
  large: `${SPACING_LARGE}px`,
  larger: `${SPACING_LARGER}px`,
  largest: `${SPACING_LARGEST}px`,

  /**
   * Use to assign margin or padding to elements.
   */
  inset: {
    /**
     * Equal spacing on all four sides.
     */
    smallest: `${SPACING_SMALLEST}px ${SPACING_SMALLEST}px ${SPACING_SMALLEST}px ${SPACING_SMALLEST}px`,
    smaller: `${SPACING_SMALLER}px ${SPACING_SMALLER}px ${SPACING_SMALLER}px ${SPACING_SMALLER}px`,
    small: `${SPACING_SMALL}px ${SPACING_SMALL}px ${SPACING_SMALL}px ${SPACING_SMALL}px`,
    medium: `${SPACING_MEDIUM}px ${SPACING_MEDIUM}px ${SPACING_MEDIUM}px ${SPACING_MEDIUM}px`,
    large: `${SPACING_LARGE}px ${SPACING_LARGE}px ${SPACING_LARGE}px ${SPACING_LARGE}px`,
    larger: `${SPACING_LARGER}px ${SPACING_LARGER}px ${SPACING_LARGER}px ${SPACING_LARGER}px`,
    largest: `${SPACING_LARGEST}px ${SPACING_LARGEST}px ${SPACING_LARGEST}px ${SPACING_LARGEST}px`,

    /**
     * Larger amount of spacing applied to left and right sides as if the element has been
     * stretched.
     */
    stretch: {
      smallest: `1px ${SPACING_SMALLEST}px 1px ${SPACING_SMALLEST}px`,
      smaller: `${SPACING_SMALLEST}px ${SPACING_SMALLER}px ${SPACING_SMALLEST}px ${SPACING_SMALLER}px`,
      small: `${SPACING_SMALLER}px ${SPACING_SMALL}px ${SPACING_SMALLER}px ${SPACING_SMALL}px`,
      medium: `${SPACING_SMALL}px ${SPACING_MEDIUM}px ${SPACING_SMALL}px ${SPACING_MEDIUM}px`,
      large: `${SPACING_MEDIUM}px ${SPACING_LARGE}px ${SPACING_MEDIUM}px ${SPACING_LARGE}px`,
      larger: `${SPACING_LARGE}px ${SPACING_LARGER}px ${SPACING_LARGE}px ${SPACING_LARGER}px`,
      largest: `${SPACING_LARGER}px ${SPACING_LARGEST}px ${SPACING_LARGER}px ${SPACING_LARGEST}px`,
    },

    /**
     * Smaller amount of spacing applied to left and right sides as if the element has been
     * squished.
     */
    squish: {
      smallest: `${SPACING_SMALLEST}px 1px ${SPACING_SMALLEST}px 1px`,
      smaller: `${SPACING_SMALLER}px ${SPACING_SMALLEST}px ${SPACING_SMALLER}px ${SPACING_SMALLEST}px`,
      small: `${SPACING_SMALL}px ${SPACING_SMALLER}px ${SPACING_SMALL}px ${SPACING_SMALLER}px`,
      medium: `${SPACING_MEDIUM}px ${SPACING_SMALL}px ${SPACING_MEDIUM}px ${SPACING_SMALL}px`,
      large: `${SPACING_LARGE}px ${SPACING_MEDIUM}px ${SPACING_LARGE}px ${SPACING_MEDIUM}px`,
      larger: `${SPACING_LARGER}px ${SPACING_LARGE}px ${SPACING_LARGER}px ${SPACING_LARGE}px`,
      largest: `${SPACING_LARGEST}px ${SPACING_LARGER}px ${SPACING_LARGEST}px ${SPACING_LARGER}px`,
    },
  },

  /**
   * Spacing applied to the bottom.
   */
  stack: {
    smallest: `0 0 ${SPACING_SMALLEST}px 0`,
    smaller: `0  0 ${SPACING_SMALLER}px 0`,
    small: `0  0 ${SPACING_SMALL}px 0`,
    medium: `0  0 ${SPACING_MEDIUM}px 0`,
    large: `0  0 ${SPACING_LARGE}px 0`,
    larger: `0  0 ${SPACING_LARGER}px 0`,
    largest: `0  0 ${SPACING_LARGEST}px 0`,
  },
};

export default spacing;
