import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Text } from '../../../../../../../lib/base';
import HeadingText from '../../../../../../components/text/HeadingText';
import { connect } from 'react-redux';
import { fetchCsv } from '../../../../../../../store/export/export.action';
import injectSheet from 'react-jss';
import Box from '../../../../../../../lib/base/layout/Box';
import { IconFileDownload } from '../../../../../../components/icons/fontawesome/icons';
import Columns from '../../../../../../../lib/base/layout/flex/Columns';
import Column from '../../../../../../../lib/base/layout/flex/Column';
import ListItemBox from '../../../../../../components/list/ListItemBox';
import { COLOR_BASE_PRIMARY, DEFAULT_TEXT_SIZE_DESCRIPTIONS } from '../../../../../../Constants';

class ExportType extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,

    id: PropTypes.string.isRequired,

    description: PropTypes.string,
  };

  static defaultProps = {
    description: '',
  };

  constructor(props) {
    super(props);
    this.downloadCsv = this.downloadCsv.bind(this);
  }

  downloadCsv() {
    this.props.dispatchFetchCsv(this.props.id, this.props.name);
  }

  render() {
    return (
      <ListItemBox stack="small" className={this.props.classes.container} pad="medium">
        <Box pLeft="medium" pRight="medium">
          <Columns vAlign="center" gutter="medium">
            <Column flex={1}>
              <HeadingText>{this.props.name}</HeadingText>
            </Column>
            <Column flex={0}>
              <Button
                onClick={this.downloadCsv}
                size="smaller"
                type="flat"
                color={COLOR_BASE_PRIMARY}
                title="Download"
              >
                <IconFileDownload color={COLOR_BASE_PRIMARY} size="1x" /> Download
              </Button>
            </Column>
          </Columns>
          <Box stack="small" pTop="small">
            <Text size={DEFAULT_TEXT_SIZE_DESCRIPTIONS}>{this.props.description}</Text>
          </Box>
        </Box>
      </ListItemBox>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoading: state.exportData.csv.isLoading,
    error: state.exportData.csv.error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchFetchCsv: (id, fileName) => {
      dispatch(fetchCsv(id, fileName));
    },
  };
}

const styles = theme => ({
  container: {
    marginLeft: `-${theme.spacing.medium}`,
    marginRight: `-${theme.spacing.medium}`,
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectSheet(styles)(ExportType));
