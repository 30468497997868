import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import injectSheet from 'react-jss';
import DesktopOmniBox from './desktop/DesktopOmniBox';
import ConnectedMap from './ConnectedMap';

class MapDesktopContainer extends PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      measureToolUnit: undefined,
    };

    this.measureToolRef = React.createRef();
    this.handleOnClearMeasurements = this.handleOnClearMeasurements.bind(this);
    this.handleOnUnitChange = this.handleOnUnitChange.bind(this);
  }

  handleOnClearMeasurements() {
    this.measureToolRef.current.clearMeasurements();
  }

  handleOnUnitChange(event, unit) {
    this.setState({ measureToolUnit: unit });
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.map}>
        <DesktopOmniBox
          onClickClearMeasurements={this.handleOnClearMeasurements}
          onUnitChange={this.handleOnUnitChange}
        />

        <ConnectedMap
          measureToolRef={this.measureToolRef}
          onClearMeasurements={this.handleOnClearMeasurements}
          measureToolUnit={this.state.measureToolUnit}
        />
      </div>
    );
  }
}

const styles = () => ({
  map: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
});

export default injectSheet(styles)(MapDesktopContainer);
