import React, { Component } from 'react';
import { Box } from '../../../../../../../lib/base';
import Config from '../../../../../../config';
import { getAppMode } from '../../../../../../util/urlParams';

export default class HelpPanel extends Component {
  constructor(props) {
    super(props);
    this.helpRef = React.createRef();
  }

  componentDidMount() {
    let mode = getAppMode();

    fetch(Config.getApplicationBaseUrl() + `/content/help?mode=${mode}`)
      .then(response => response.text())
      .then(text => {
        if (this.helpRef && this.helpRef.current) {
          this.helpRef.current.innerHTML = text;
        }
      });
  }

  render() {
    return <Box containerRef={this.helpRef}>&nbsp;</Box>;
  }
}
