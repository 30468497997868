import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import injectSheet from 'react-jss';
import Row from '../../../../lib/base/layout/flex/Row';
import Rows from '../../../../lib/base/layout/flex/Rows';
import ConnectedMap from './ConnectedMap';
import BottomDrawerContainer from './mobile/drawer/BottomDrawerContainer';
import SearchBarMobile from './mobile/SearchBarMobile';

class MapMobileContainer extends PureComponent {
  static propTypes = {
    /**
     * Jss provided property.
     */
    classes: PropTypes.object.isRequired,

    /**
     * Children components.
     */
    children: PropTypes.node,
  };

  static defaultProps = {
    children: null,
  };

  constructor(props) {
    super(props);
    this.searchBarMobileRef = React.createRef();
  }

  render() {
    const { classes } = this.props;

    return (
      <Rows stretch>
        <Row flex={0}>
          <SearchBarMobile containerRef={this.searchBarMobileRef} />
        </Row>
        <Row flex={1} style={{ height: 0 }}>
          <ConnectedMap />
        </Row>
        <Row flex={0} className={classes.mobileDrawerRow}>
          <BottomDrawerContainer height={48} />
        </Row>
      </Rows>
    );
  }
}

const styles = () => ({
  mobileDrawerRow: {
    height: '48px',
  },
});

export default injectSheet(styles)(MapMobileContainer);
