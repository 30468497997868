import format from 'date-fns/format';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Box, DataGrid, Text } from '../../../../lib/base';
import Config from '../../../config';
import { COLOR_BASE_PRIMARY } from '../../../Constants';
import DocumentLink from '../../download/DocumentLink';
import { IconMinus, IconPlus } from '../../icons';
import Label from '../../lv/Label';
import LabelValuePair from '../../lv/LabelValuePair';

export default class ViolationsDataGrid extends Component {
  static DEFAULT_SORT_BY = 'DETERMINATION_DATE';

  static propTypes = {
    /**
     * The chemicals data set.
     */
    data: PropTypes.array.isRequired,

    /**
     * Is tier2 data loading.
     */
    isLoading: PropTypes.bool.isRequired,

    /**
     * Callback to fetch data.
     */
    onRequestData: PropTypes.func.isRequired,

    /**
     * The page number of the data set.
     */
    page: PropTypes.number.isRequired,

    /**
     * The page size of the data set.
     */
    pageSize: PropTypes.number.isRequired,

    /**
     * Total number of records in data set.
     */
    total: PropTypes.number.isRequired,

    /**
     * The property name to sort the results by.
     */
    sortBy: PropTypes.string,

    /**
     * Is the sort ascending?
     */
    isAsc: PropTypes.bool,

    /**
     * The criteria object.
     */
    criteria: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.gridColumns = [
      {
        headerLabel: '',
        Extender: (violation, isOpen) => {
          return (
            <Box textAlign="center">
              {isOpen ? (
                <IconMinus color={COLOR_BASE_PRIMARY} />
              ) : (
                <IconPlus color={COLOR_BASE_PRIMARY} />
              )}
            </Box>
          );
        },
        width: 32,
      },

      {
        headerLabel: 'Determination Date',
        accessor: violation => format(violation.determinationDate, 'MM/DD/YYYY'),
        width: 200,
        colName: 'DETERMINATION_DATE',
        criteriaPropName: 'date',
      },

      {
        headerLabel: 'Program',
        accessor: violation => `${violation.programCode} - ${violation.programDesc}`,
        width: 600,
        colName: 'PROGRAM_DESC',
        criteriaPropName: 'program',
      },
    ];

    this.renderSubsection = this.renderSubsection.bind(this);
  }

  columns() {
    return this.gridColumns;
  }

  renderSubsection(rowData) {
    const conditionallyHide = Config.isConditionallyHideOrigSysId();

    return (
      <Box pad="medium" pLeft="large">
        {(rowData.evaluationUrl || conditionallyHide) && (
          <LabelValuePair
            label="Originating System"
            value={
              <a href={rowData.violationUrl} target="_blank" rel="noopener noreferrer">
                <Text size="smaller" bold>
                  {rowData.origSysViolationId}
                </Text>
              </a>
            }
          />
        )}
        <LabelValuePair label="Notes" value={rowData.notes} />

        <Label label="Citations" />
        {rowData.citations.map((citation, index) => (
          <div key={index}>
            <a href={citation.citationUrl} target="_blank" rel="noopener noreferrer">
              <Text size="smaller" bold>
                {citation.citation}:{' '}
              </Text>
            </a>
            <Text size="smaller">{citation.citationNarrative}</Text>
          </div>
        ))}

        <DocumentLink documents={rowData.documents} label="Documents" />
      </Box>
    );
  }

  render() {
    return (
      <DataGrid
        columns={this.columns()}
        data={this.props.data}
        keyExtractor={row => row.id}
        onRequestData={this.props.onRequestData}
        page={this.props.page}
        pageSize={this.props.pageSize}
        sortBy={this.props.sortBy}
        isAsc={this.props.isAsc}
        criteria={this.props.criteria}
        total={this.props.total}
        loading={this.props.isLoading}
        striped
        showPaginationBottom={this.props.total > 5}
        renderSubsection={this.renderSubsection}
      />
    );
  }
}
