import { Text as PdfText } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
import './fonts';

export const fontSizes = {
  small: '9pt',
  normal: '10pt',
  medium: '11pt',
  large: '12pt',
  larger: '14pt',
  largest: '16pt',
  huge: '18pt',
};

export const headingSizes = {
  small: fontSizes.large,
  default: fontSizes.larger,
  large: fontSizes.largest,
};

export const Text = styled(PdfText)`
  font-family: 'OpenSans';
  font-size: ${fontSizes.small};
  ${({ semiBold }) => semiBold && 'font-weight: semibold;'}
  ${({ bold }) => bold && 'font-weight: bold;'}
  ${({ italic }) => italic && 'font-style: italic;'}
  ${({ size }) => size && `font-size: ${fontSizes[size]}};`}
`;
