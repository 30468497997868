import 'core-js/features/array/entries';
import 'core-js/features/array/filter';
import 'core-js/features/array/find';
import 'core-js/features/array/from';
import 'core-js/features/array/includes';
import 'core-js/features/array/keys';
import 'core-js/features/array/sort';
import 'core-js/features/array/values';

/**
 * IE doesn't support Number.parseInt() or Number.isNaN  ¯\_(ツ)_/¯
 */
if (Number.parseInt === undefined) {
  Number.parseInt = window.parseInt;
}

/*eslint-disable */
//https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/isNaN
Number.isNaN =
  Number.isNaN ||
  function(value) {
    return value !== value;
  };
/*eslint-enable */
