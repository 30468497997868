import { faEdit } from '@fortawesome/free-regular-svg-icons/faEdit';
import { faCheckSquare } from '@fortawesome/free-regular-svg-icons/faCheckSquare';
import { faSquare } from '@fortawesome/free-regular-svg-icons/faSquare';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons/faArrowCircleLeft';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons/faArrowCircleRight';
import { faBinoculars } from '@fortawesome/free-solid-svg-icons/faBinoculars';
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons/faCaretLeft';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons/faCaretRight';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons/faClipboardList';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons/faAngleDoubleUp';
import { faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons/faAngleDoubleDown';
import { faCompress } from '@fortawesome/free-solid-svg-icons/faCompress';
import { faCrosshairs } from '@fortawesome/free-solid-svg-icons/faCrosshairs';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons/faEllipsisV';
import { faEraser } from '@fortawesome/free-solid-svg-icons/faEraser';
import { faExpand } from '@fortawesome/free-solid-svg-icons/faExpand';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons/faFileAlt';
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter';
import { faFlask } from '@fortawesome/free-solid-svg-icons/faFlask';
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faList } from '@fortawesome/free-solid-svg-icons/faList';
import { faMap } from '@fortawesome/free-solid-svg-icons/faMap';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons/faFilePdf';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faPrint } from '@fortawesome/free-solid-svg-icons/faPrint';
import { faQuestion } from '@fortawesome/free-solid-svg-icons/faQuestion';
import { faRuler } from '@fortawesome/free-solid-svg-icons/faRuler';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faUsersCog } from '@fortawesome/free-solid-svg-icons/faUsersCog';
import { faSlidersH } from '@fortawesome/free-solid-svg-icons/faSlidersH';
import { faSort } from '@fortawesome/free-solid-svg-icons/faSort';
import { faSortUp } from '@fortawesome/free-solid-svg-icons/faSortUp';
import { faSortDown } from '@fortawesome/free-solid-svg-icons/faSortDown';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import injectSheet from 'react-jss';
import { jssColor } from '../../../../lib/base/util/jss';
import { propTypeColor, propTypeColorVariant } from '../../../../lib/base/util/propTypes';

/**
 * Prop types defintion.
 */
const propTypes = {
  color: PropTypes.string,
  colorVariant: PropTypes.string,
  faicon: PropTypes.object.isRequired,
  spin: PropTypes.bool,
  size: PropTypes.string,
  visible: PropTypes.bool,
};

const defaultProps = {
  color: undefined,
  colorVariant: undefined,
  faicon: faQuestion,
  spin: false,
  size: undefined,
  visible: true,
};

/**
 * Jss style function to inject BaseFontAwesomeIcon with.
 */
const styles = theme => ({
  color: props => {
    return {
      color: props.color ? jssColor(theme, props) : theme.icon.default.color,
    };
  },
  visible: props => {
    return {
      display: props.visible ? '' : 'none',
    };
  },
});

/**
 * Base font awesome component.
 */
const BaseFontAwesomeIcon = injectSheet(styles)(props => {
  return (
    <span className={[props.classes.color, props.classes.visible].join(' ')}>
      <FontAwesomeIcon icon={props.faicon} spin={props.spin} size={props.size} />
    </span>
  );
});

BaseFontAwesomeIcon.propTypes = propTypes;
BaseFontAwesomeIcon.defaultProps = defaultProps;

const iconPropTypes = {
  /**
   * Icon color.
   */
  color: propTypeColor,

  /**
   * Icon color.
   */
  colorVariant: propTypeColorVariant,

  /**
   * Should icon spin?
   */
  spin: PropTypes.bool,

  /**
   * Size of icon -
   */
  size: PropTypes.oneOf([
    'lg',
    'xs',
    'sm',
    '1x',
    '2x',
    '3x',
    '4x',
    '5x',
    '6x',
    '7x',
    '8x',
    '9x',
    '10x',
  ]),

  /**
   * Icon visibility.
   */
  visible: PropTypes.bool,
};

const iconDefaultProps = {
  /**
   * Should icon spin?
   */
  spin: false,

  /**
   * Size of icon - css font size (px, em, etc.)
   */
  size: '1x',
};

/**
 * Search icon.
 */
export const IconSearch = props => (
  <BaseFontAwesomeIcon
    faicon={faSearch}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
  />
);
IconSearch.propTypes = iconPropTypes;
IconSearch.defaultProps = iconDefaultProps;

/**
 * Empty results icon.
 */
export const IconEmptyResults = props => (
  <BaseFontAwesomeIcon
    faicon={faSearch}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
  />
);
IconEmptyResults.propTypes = iconPropTypes;
IconEmptyResults.defaultProps = iconDefaultProps;

/**
 * Clear icon.
 */
export const IconClear = props => (
  <BaseFontAwesomeIcon
    faicon={faTimes}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
  />
);
IconClear.propTypes = iconPropTypes;
IconClear.defaultProps = iconDefaultProps;

/**
 * Eraser icon.
 */
export const IconErase = props => (
  <BaseFontAwesomeIcon
    faicon={faEraser}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
  />
);
IconErase.propTypes = iconPropTypes;
IconErase.defaultProps = iconDefaultProps;

/**
 * Spinner icon.
 */
export const IconSpinner = props => (
  <BaseFontAwesomeIcon
    faicon={faSpinner}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
  />
);
IconSpinner.propTypes = iconPropTypes;
IconSpinner.defaultProps = { ...iconDefaultProps };
IconSpinner.defaultProps.spin = true;

/**
 * Explore icon.
 */
export const IconExplore = props => (
  <BaseFontAwesomeIcon
    faicon={faBinoculars}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
  />
);
IconExplore.propTypes = iconPropTypes;
IconExplore.defaultProps = iconDefaultProps;

/**
 * Chevron icon.
 */
export const IconChevronUp = props => (
  <BaseFontAwesomeIcon
    faicon={faChevronUp}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
  />
);
IconChevronUp.propTypes = iconPropTypes;
IconChevronUp.defaultProps = { ...iconDefaultProps };

/**
 * Chevron icon.
 */
export const IconChevronDown = props => (
  <BaseFontAwesomeIcon
    faicon={faChevronDown}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
  />
);
IconChevronDown.propTypes = iconPropTypes;
IconChevronDown.defaultProps = { ...iconDefaultProps };

/**
 * Chevron icon.
 */
export const IconChevronLeft = props => (
  <BaseFontAwesomeIcon
    faicon={faChevronLeft}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
  />
);
IconChevronLeft.propTypes = iconPropTypes;
IconChevronLeft.defaultProps = { ...iconDefaultProps };

/**
 * Chevron icon.
 */
export const IconChevronRight = props => (
  <BaseFontAwesomeIcon
    faicon={faChevronRight}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
  />
);
IconChevronRight.propTypes = iconPropTypes;
IconChevronRight.defaultProps = { ...iconDefaultProps };

/**
 * Double angle down
 */
export const IconAngleDoubleDown = props => (
  <BaseFontAwesomeIcon
    faicon={faAngleDoubleDown}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
  />
);
IconAngleDoubleDown.propTypes = iconPropTypes;
IconAngleDoubleDown.defaultProps = { ...iconDefaultProps };

/**
 * Double angle up
 */
export const IconAngleDoubleUp = props => (
  <BaseFontAwesomeIcon
    faicon={faAngleDoubleUp}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
  />
);
IconAngleDoubleUp.propTypes = iconPropTypes;
IconAngleDoubleUp.defaultProps = { ...iconDefaultProps };

/**
 * Filter icon.
 */
export const IconFilter = props => (
  <BaseFontAwesomeIcon
    faicon={faFilter}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
  />
);
IconFilter.propTypes = iconPropTypes;
IconFilter.defaultProps = iconDefaultProps;

/**
 * Filters icon.
 */
export const IconFilters = props => (
  <BaseFontAwesomeIcon
    faicon={faSlidersH}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
  />
);
IconFilters.propTypes = iconPropTypes;
IconFilters.defaultProps = iconDefaultProps;

/**
 * Map marker.
 */
export const IconMapMarker = props => (
  <BaseFontAwesomeIcon
    faicon={faMapMarkerAlt}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
  />
);
IconMapMarker.propTypes = iconPropTypes;
IconMapMarker.defaultProps = iconDefaultProps;

/**
 * Plus icon.
 */
export const IconPlus = props => (
  <BaseFontAwesomeIcon
    faicon={faPlus}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
  />
);
IconPlus.propTypes = iconPropTypes;
IconPlus.defaultProps = iconDefaultProps;

/**
 * Minus icon.
 */
export const IconMinus = props => (
  <BaseFontAwesomeIcon
    faicon={faMinus}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
  />
);
IconMinus.propTypes = iconPropTypes;
IconMinus.defaultProps = iconDefaultProps;

/**
 * Home icon.
 */
export const IconHome = props => (
  <BaseFontAwesomeIcon
    faicon={faHome}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
  />
);
IconHome.propTypes = iconPropTypes;
IconHome.defaultProps = iconDefaultProps;

/**
 * List icon.
 */
export const IconList = props => (
  <BaseFontAwesomeIcon
    faicon={faList}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
  />
);
IconList.propTypes = iconPropTypes;
IconList.defaultProps = iconDefaultProps;

/**
 * MyLocation icon.
 */
export const IconMyLocation = props => (
  <BaseFontAwesomeIcon
    faicon={faCrosshairs}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
  />
);
IconMyLocation.propTypes = iconPropTypes;
IconMyLocation.defaultProps = iconDefaultProps;

/**
 * Map layers icon.
 */
export const IconMapLayers = props => (
  <BaseFontAwesomeIcon
    faicon={faMap}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
  />
);
IconMapLayers.propTypes = iconPropTypes;
IconMapLayers.defaultProps = iconDefaultProps;

/**
 * Ruler icon.
 */
export const IconRuler = props => (
  <BaseFontAwesomeIcon
    faicon={faRuler}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
  />
);
IconRuler.propTypes = iconPropTypes;
IconRuler.defaultProps = iconDefaultProps;

/**
 * cog-user
 */
export const IconUsersCog = props => (
  <BaseFontAwesomeIcon
    faicon={faUsersCog}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
  />
);
IconUsersCog.propTypes = iconPropTypes;
IconUsersCog.defaultProps = iconDefaultProps;

/**
 * Enter fullscreen icon.
 */
export const IconFullscreen = props => (
  <BaseFontAwesomeIcon
    faicon={faExpand}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
  />
);
IconFullscreen.propTypes = iconPropTypes;
IconFullscreen.defaultProps = iconDefaultProps;

/**
 * Exit fullscreen icon.
 */
export const IconFullscreenExit = props => (
  <BaseFontAwesomeIcon
    faicon={faCompress}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
  />
);
IconFullscreenExit.propTypes = iconPropTypes;
IconFullscreenExit.defaultProps = iconDefaultProps;

/**
 * Checkbox Off.
 */
export const IconCheckboxOff = props => (
  <BaseFontAwesomeIcon
    faicon={faSquare}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
  />
);
IconCheckboxOff.propTypes = iconPropTypes;
IconCheckboxOff.defaultProps = iconDefaultProps;

/**
 * Checkbox On.
 */
export const IconCheckboxOn = props => (
  <BaseFontAwesomeIcon
    faicon={faCheckSquare}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
  />
);
IconCheckboxOn.propTypes = iconPropTypes;
IconCheckboxOn.defaultProps = iconDefaultProps;

/**
 * Vertical Ellipsis
 */
export const IconElipsisV = props => (
  <BaseFontAwesomeIcon
    faicon={faEllipsisV}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
  />
);
IconElipsisV.propTypes = iconPropTypes;
IconElipsisV.defaultProps = iconDefaultProps;

/**
 * Arrow Circle Left
 */
export const IconArrowCircleLeft = props => (
  <BaseFontAwesomeIcon
    faicon={faArrowCircleLeft}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
  />
);
IconArrowCircleLeft.propTypes = iconPropTypes;
IconArrowCircleLeft.defaultProps = iconDefaultProps;

/**
 * Arrow Circle Right
 */
export const IconArrowCircleRight = props => (
  <BaseFontAwesomeIcon
    faicon={faArrowCircleRight}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
  />
);
IconArrowCircleRight.propTypes = iconPropTypes;
IconArrowCircleRight.defaultProps = iconDefaultProps;

/**
 * Download export files.
 */
export const IconFileDownload = props => (
  <BaseFontAwesomeIcon
    faicon={faDownload}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
  />
);
IconFileDownload.propTypes = iconPropTypes;
IconFileDownload.defaultProps = iconDefaultProps;

/**
 * An icon for an external link.
 */
export const IconLinkExternal = props => (
  <BaseFontAwesomeIcon
    faicon={faExternalLinkAlt}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
  />
);
IconLinkExternal.propTypes = iconPropTypes;
IconLinkExternal.defaultProps = iconDefaultProps;

/**
 * An Icon Indicating no user location
 */
export const IconNoLocation = props => (
  <BaseFontAwesomeIcon
    faicon={faEyeSlash}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
    visible={props.visible}
  />
);
IconNoLocation.propTypes = iconPropTypes;
IconNoLocation.defaultProps = iconDefaultProps;

/**
 * Icon indicating user to show visible sites
 */
export const IconWithLocation = props => (
  <BaseFontAwesomeIcon
    faicon={faEye}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
    visible={props.visible}
  />
);
IconWithLocation.propTypes = iconPropTypes;
IconWithLocation.defaultProps = iconDefaultProps;

/**
 * Icon linking to nCore
 */
export const IconNcore = props => (
  <BaseFontAwesomeIcon
    faicon={faEdit}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
    visible={props.visible}
  />
);
IconNcore.propTypes = iconPropTypes;
IconNcore.defaultProps = iconDefaultProps;

/**
 * Icon indicating user to show visible sites
 */
export const IconPrint = props => (
  <BaseFontAwesomeIcon
    faicon={faPrint}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
    visible={props.visible}
  />
);
IconPrint.propTypes = iconPropTypes;
IconPrint.defaultProps = iconDefaultProps;

/**
 * Icon left caret
 */
export const IconCaretLeft = props => (
  <BaseFontAwesomeIcon
    faicon={faCaretLeft}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
    visible={props.visible}
  />
);
IconCaretLeft.propTypes = iconPropTypes;
IconCaretLeft.defaultProps = iconDefaultProps;

/**
 * Icon right caret
 */
export const IconCaretRight = props => (
  <BaseFontAwesomeIcon
    faicon={faCaretRight}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
    visible={props.visible}
  />
);
IconCaretRight.propTypes = iconPropTypes;
IconCaretRight.defaultProps = iconDefaultProps;

/**
 * Icon indicating user to show visible sites
 */
export const IconInfo = props => (
  <BaseFontAwesomeIcon
    faicon={faInfoCircle}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
    visible={props.visible}
  />
);
IconInfo.propTypes = iconPropTypes;
IconInfo.defaultProps = iconDefaultProps;

/**
 * Icon representing chemicals
 */
export const IconChemical = props => (
  <BaseFontAwesomeIcon
    faicon={faFlask}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
    visible={props.visible}
  />
);
IconChemical.propTypes = iconPropTypes;
IconChemical.defaultProps = iconDefaultProps;

/**
 * Checkmark Icon
 */
export const IconCheck = props => (
  <BaseFontAwesomeIcon
    faicon={faCheck}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
    visible={props.visible}
  />
);
IconCheck.propTypes = iconPropTypes;
IconCheck.defaultProps = iconDefaultProps;

/**
 * Icon representing cme
 */
export const IconCME = props => (
  <BaseFontAwesomeIcon
    faicon={faClipboardList}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
    visible={props.visible}
  />
);
IconCME.propTypes = iconPropTypes;
IconCME.defaultProps = iconDefaultProps;

/**
 * Icon representing document
 */
export const IconDocument = props => (
  <BaseFontAwesomeIcon
    faicon={faFileAlt}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
    visible={props.visible}
  />
);
IconDocument.propTypes = iconPropTypes;
IconDocument.defaultProps = iconDefaultProps;

/**
 * Icon not sorted
 */
export const IconSortNone = props => (
  <BaseFontAwesomeIcon
    faicon={faSort}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
    visible={props.visible}
  />
);
IconSortNone.propTypes = iconPropTypes;
IconSortNone.defaultProps = iconDefaultProps;

/**
 * Icon sort ascending
 */
export const IconSortAsc = props => (
  <BaseFontAwesomeIcon
    faicon={faSortUp}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
    visible={props.visible}
  />
);
IconSortAsc.propTypes = iconPropTypes;
IconSortAsc.defaultProps = iconDefaultProps;

/**
 * Icon sort descending
 */
export const IconSortDesc = props => (
  <BaseFontAwesomeIcon
    faicon={faSortDown}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
    visible={props.visible}
  />
);
IconSortDesc.propTypes = iconPropTypes;
IconSortDesc.defaultProps = iconDefaultProps;

/**
 * Icon pdf
 */
export const IconPdf = props => (
  <BaseFontAwesomeIcon
    faicon={faFilePdf}
    color={props.color}
    colorVariant={props.colorVariant}
    size={props.size}
    spin={props.spin}
    visible={props.visible}
  />
);
IconPdf.propTypes = iconPropTypes;
IconPdf.defaultProps = iconDefaultProps;
