import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Box } from '../../../../../../../lib/base';
import ListItemBox from '../../../../../../components/list/ListItemBox';
import HeadingText from '../../../../../../components/text/HeadingText';
import ArcGisLegend from './ArcGisLegend';
import WMSLegend from './WMSLegend';

export default class SelectableLayer extends Component {
  static propTypes = {
    onSelectLayer: PropTypes.func.isRequired,

    layer: PropTypes.object.isRequired,

    selected: PropTypes.bool.isRequired,
  };

  renderLegend(layer) {
    if (
      layer.layerType.toLowerCase() === 'arcgis' &&
      layer.legend &&
      layer.legend.length &&
      layer.legend[0].imageData
    ) {
      return layer.legend.map((legend, index) => <ArcGisLegend key={index} legend={legend} />);
    } else if (layer.layerType.toLowerCase() === 'wms') {
      return <WMSLegend key={layer.id} layer={layer} />;
    } else {
      return;
    }
  }

  render() {
    const { layer, onSelectLayer, selected } = this.props;

    const title = `${selected ? 'Hide' : 'Show'} this layer on the map.`;

    return (
      <ListItemBox
        key={layer.id}
        onClick={() => onSelectLayer(layer)}
        active={selected}
        pTop="medium"
        pBottom="medium"
      >
        <Box title={title}>
          <HeadingText>{layer.layerName}</HeadingText>
          <Box mTop="medium">{this.renderLegend(layer)}</Box>
        </Box>
      </ListItemBox>
    );
  }
}
