import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Box } from '../../../../../lib/base';
import Map, { ArcGISTileLayer, BingMapsTileLayer } from '../../../../../lib/map';
import { toWebMercatorCenter } from '../../../../../lib/map/core/transfomer';
import Config from '../../../../config';
import { DEFAULT_MAP_PADDING } from '../../../../Constants';
import SiteFeaturesLayer from '../../../../page/map/components/shared/layer/SiteFeaturesLayer';
import WindowSizeAwareMapContainer from './WindowSizeAwareMapContainer';

const MAP_ID = 'minimap';

class MiniMap extends Component {
  static propTypes = {
    /**
     * Profile object from the api.
     */
    profile: PropTypes.object.isRequired,

    /**
     * Longitude coordinate.
     */
    longitude: PropTypes.number,

    /**
     * Latitude coordinate.
     */
    latitude: PropTypes.number,

    /**
     * Interactive
     */
    interactive: PropTypes.bool,

    /**
     * minResolution: PropTypes.number
     */
    minResolution: PropTypes.number,

    /**
     * maxResolution: PropTypes.number
     */
    maxResolution: PropTypes.number,

    /**
     * max zoom: number
     */
    maxZoom: PropTypes.number,

    /**
     * current baselayer
     */
    activeBaseLayer: PropTypes.object,
  };

  static defaultProps = {
    interactive: true,
    maxZoom: 20,
  };

  constructor(props) {
    super(props);

    this.state = {
      extent: undefined,
    };

    this.handleOnAfterPopulateFeatures = this.handleOnAfterPopulateFeatures.bind(this);
  }

  handleOnAfterPopulateFeatures({ vectorSource }) {
    this.setState({
      extent: vectorSource.getExtent(),
    });
  }

  componentDidMount() {
    if (!this.props.interactive) {
      setTimeout(() => {
        try {
          const viewport = document.getElementById(MAP_ID).querySelectorAll('.ol-viewport')[0];
          viewport.style.touchAction = 'auto';
        } catch (e) {}
      }, 1000);
    }
  }

  render() {
    const { longitude, latitude } = this.props.profile.profileFacilityDetail;

    if (!longitude || !latitude) {
      return null;
    }

    const baseLayer = this.props.activeBaseLayer;
    const lonlat = [longitude, latitude];
    const center = toWebMercatorCenter(lonlat);

    return (
      <Box>
        <WindowSizeAwareMapContainer
          renderView={({ width, height }) => (
            <Map
              id={MAP_ID}
              height={`${height}px`}
              width={`${width}px`}
              extent={this.state.extent}
              minResolution={this.props.minResolution}
              maxResolution={this.props.maxResolution}
              maxZoom={this.props.maxZoom}
              padding={DEFAULT_MAP_PADDING}
              center={center}
              scaleLine={true}
              interactive={this.props.interactive}
            >
              {baseLayer.layerType.toLowerCase() === 'bing' && (
                <BingMapsTileLayer
                  key={baseLayer.id}
                  id={baseLayer.id}
                  zIndex={baseLayer.displayOrder}
                  apiKey={Config.getBingApiKey()}
                  type={baseLayer.baseType}
                />
              )}
              {baseLayer.layerType.toLowerCase() === 'arcgis' && (
                <ArcGISTileLayer
                  key={baseLayer.id}
                  id={baseLayer.id}
                  url={baseLayer.layerUrl}
                  zIndex={baseLayer.displayOrder}
                  opacity={baseLayer.opacity}
                  minZoom={Config.getMinZoom()}
                  maxZoom={Config.getMaxZoom()}
                />
              )}
              {/* add support for esri layers on the mini map */}
              <SiteFeaturesLayer
                interactive={false}
                onAfterPopulateFeatures={this.handleOnAfterPopulateFeatures}
              />
            </Map>
          )}
        />
      </Box>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile.detail.data,
    minResolution: state.map.minResolution,
    maxResolution: state.map.maxResolution,
    activeBaseLayer: state.map.activeBaseLayer,
  };
}

export default connect(mapStateToProps)(MiniMap);
