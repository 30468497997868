import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import injectSheet from 'react-jss';
import { Box, Column, Columns, Text } from '../../../../lib/base';
import { COLOR_BASE_PRIMARY } from '../../../Constants';
import Label from '../../lv/Label';
import LabelValuePair from '../../lv/LabelValuePair';
import CasLink from './CasLink';

class ChemicalMix extends PureComponent {
  static propTypes = {
    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,

    /**
     * The chemical to which this chemical mix belongs to.
     */
    chemical: PropTypes.object.isRequired,

    /**
     * The chemical mix to render.
     */
    chemicalMix: PropTypes.object.isRequired,
  };

  render() {
    const { container, header, footer } = this.props.classes;

    const { chemical, chemicalMix } = this.props;

    return (
      <Box className={container} stack="small">
        <Box className={header}>
          <Text bold size="smaller" caps>
            {chemicalMix.component}
          </Text>
        </Box>
        <Box textAlign="center" pad="medium">
          <Text size="large" block>
            {chemicalMix.componentPercentage}%
          </Text>
          <Text size="smaller" caps block>
            {chemicalMix.weightOrVolume}
          </Text>
        </Box>
        <Box textAlign="center" className={footer}>
          <Columns gutter="medium" inline>
            <Column>
              {chemical && (
                <Box stack="large">
                  <Box stack="smaller">
                    <Label label="CAS Number" />
                  </Box>
                  <Box>
                    <CasLink
                      casNumber={chemicalMix.casNumber}
                      url={chemicalMix.url}
                      mobileUrl={chemicalMix.urlMobile}
                    />
                  </Box>
                </Box>
              )}
            </Column>
            <Column>
              <LabelValuePair label="Is EHS?" value={chemicalMix.isEhs ? 'Yes' : 'No'} />
            </Column>
          </Columns>
        </Box>
      </Box>
    );
  }
}

const styles = theme => ({
  container: {
    width: '220px',
    border: `1px solid ${theme.color[COLOR_BASE_PRIMARY]['light2']}`,
  },

  header: {
    backgroundColor: theme.color[COLOR_BASE_PRIMARY]['light1'],
    textAlign: 'center',
    padding: theme.spacing.smaller,
    minHeight: '42px',
  },

  footer: {
    paddingTop: theme.spacing.medium,
    borderTop: `1px solid ${theme.color[COLOR_BASE_PRIMARY]['light2']}`,
  },
});

export default injectSheet(styles)(ChemicalMix);
