import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import { Button } from '../../../../lib/base';
import { COLOR_BASE_PRIMARY } from '../../../Constants';
import { IconSpinner } from '../../icons';
import { downloadViaWSL } from './downloadViaWSL';

export default class DownloadSelectionsButton extends PureComponent {
  static propTypes = {
    /**
     * Document selections
     */
    children: PropTypes.node,
  };

  static defaultProps = {
    selections: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };

    this.handleOnDownloadDocs = this.handleOnDownloadDocs.bind(this);
  }

  async handleOnDownloadDocs() {
    this.setState({ isDownloading: true });

    try {
      let queryParams = this.props.selections.map(document => ({
        id: document.docId,
        loadDocPdfContent: document.hasPdfContent,
      }));

      await downloadViaWSL({ queryParams });

      this.setState({ isDownloading: false });
    } catch (e) {
      window.alert('An error occurred while downloading documents. ' + e.message);
      this.setState({ isDownloading: false });
    }
  }

  isButtonDisabled() {
    return this.props.selections.length === 0;
  }

  buttonText() {
    return `Download ${this.props.selections.length} documents`;
  }

  render() {
    return (
      <Button
        color={COLOR_BASE_PRIMARY}
        onClick={this.handleOnDownloadDocs}
        disabled={this.isButtonDisabled()}
      >
        {this.buttonText()}
        {this.state.isDownloading && (
          <Fragment>
            {' '}
            <IconSpinner color="white" />
          </Fragment>
        )}
      </Button>
    );
  }
}
