import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class Pin extends Component {
  static propTypes = {
    /**
     * Color.
     */
    color: PropTypes.string.isRequired,

    /**
     * Size
     */
    size: PropTypes.number.isRequired,
  };

  render() {
    return (
      <svg
        width={this.props.size}
        height={this.props.size}
        viewBox="0 0 365 560"
        style={{ filter: 'drop-shadow( 0px 0px 0px rgba(0,0,0,0.8) )' }}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            fill={this.props.color}
            d="M182.9,551.7c0,0.1,0.2,0.3,0.2,0.3S358.3,283,358.3,194.6c0-130.1-88.8-186.7-175.4-186.9
		C96.3,7.9,7.5,64.5,7.5,194.6c0,88.4,175.3,357.4,175.3,357.4S182.9,551.7,182.9,551.7z M122.2,187.2c0-33.6,27.2-60.8,60.8-60.8
		c33.6,0,60.8,27.2,60.8,60.8S216.5,248,182.9,248C149.4,248,122.2,220.8,122.2,187.2z"
          />
        </g>
      </svg>
    );
  }
}
