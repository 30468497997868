/**
 * Helper to create new route functions.
 *
 * @param history
 * @param path
 * @returns {function()}
 */
import Config from '../config';
import { ROUTE_HOME } from '../Constants';
import { isSmallScreen } from '../util/browser';

const makeRouteFunc = (history, path) => {
  const route = {
    pathname: path,
  };
  return () => history.push(route);
};

export const defaultMapRoutePath = () => {
  if (!isSmallScreen()) {
    return '/map/' + Config.getStartPanel();
  }
  return '/map';
};

/**
 * Map page related routing helper functions.
 */
const navigateToPanel = ({ history, match, location }, panel) => {
  const { subPanel } = match.params;

  const nextPath = subPanel !== undefined ? `/map/${panel}/${subPanel}` : `/map/${panel}`;

  history.push({
    pathname: nextPath,
    search: location.search,
  });
};

const navigateToSubPanel = ({ history, match, location }, subPanel) => {
  const { panel } = match.params;

  const nextPath = panel !== undefined ? `/map/${panel}/${subPanel}` : `/map/results/${subPanel}`;

  history.push({
    pathname: nextPath,
  });
};

const navigateToSite = ({ history, match, location }, subPanel, siteId, tab) => {
  const params = match.params;

  const panel = params.panel !== undefined ? params.panel : 'results';

  const newPath =
    tab !== undefined
      ? `/map/${panel}/${subPanel}/${siteId}/${tab}`
      : `/map/${panel}/${subPanel}/${siteId}`;

  history.push({
    pathname: newPath,
  });
};

export const navigateCloseSubPanel = ({ history, match }) => {
  const params = match.params;

  const newPath = params.panel !== undefined ? `/map/${params.panel}` : '/map';

  history.push({
    pathname: newPath,
  });
};

export const navigateToMapHome = ({ history }) => {
  history.push({
    pathname: '/map',
  });
};

export const navigateToInfo = props => navigateToPanel(props, 'info');

export const navigateToHelp = props => navigateToPanel(props, 'help');

export const navigateToResults = props => navigateToPanel(props, 'results');

export const navigateToResultsDirect = ({ history }) => {
  history.push({
    pathname: '/map/results',
  });
};

export const navigateToMeasure = props => navigateToPanel(props, 'measure');

export const navigateToLayers = props => navigateToPanel(props, 'layers');

export const navigateToExport = props => navigateToPanel(props, 'export');

export const navigateToCriteria = props => navigateToSubPanel(props, 'criteria');

export const navigateToFilters = props => navigateToSubPanel(props, 'filters');

export const navigateToSummary = (props, siteId) =>
  navigateToSite(props, 'summary', siteId, undefined);

export const navigateToMobileCardView = (props, siteId) =>
  navigateToSite(props, 'card', siteId, undefined);

export const navigateToMobileProfileView = (props, siteId) =>
  navigateToSite(props, 'profile', siteId, undefined);

export const navigateToDetail = (props, siteId) =>
  navigateToSite(props, 'detail', siteId, undefined);

export const navigateToDetailTab = (props, siteId, tab) =>
  navigateToSite(props, 'detail', siteId, tab);

export const navigateToDetailEnvIntTab = ({ history, match }, siteId, descriptionFilter) => {
  history.push({
    pathname: `/map/results/detail/${siteId}/ei`,
    search: `?description=${encodeURIComponent(descriptionFilter)}`,
  });
};

/**
 * Public: React router functions to navigate to pages.
 */
export const toHome = history => makeRouteFunc(history, ROUTE_HOME);
