import difference from 'lodash/difference';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import injectSheet from 'react-jss';
import Cell from './Cell';
import CheckboxCell from './CheckboxCell';
import { cellBorderStyle, sumColumnWidths } from './helper';
import SortHeaderCell from './SortHeaderCell';

class SortHeader extends PureComponent {
  static propTypes = {
    /**
     * Jss classes.
     */
    classes: PropTypes.object.isRequired,

    /**
     * Columns definition.
     */
    columns: PropTypes.array.isRequired,

    /**
     * The data grid rows.
     */
    data: PropTypes.array,

    /**
     * Property to sort by.
     */
    sortBy: PropTypes.string,

    /**
     * Is sort ascending order.
     */
    isAsc: PropTypes.bool,

    /**
     * Called when the sort has changed.
     */
    onSortChange: PropTypes.func,

    /**
     * Are filters enabled? Disables the bottom border when they are.
     */
    filtersEnabled: PropTypes.bool,

    /**
     * The selected entities.
     */
    selections: PropTypes.array,

    /**
     * Callback when all rows are selected.
     */
    onSelectAll: PropTypes.func,

    /**
     * Function to extract the row id.
     */
    keyExtractor: PropTypes.func,
  };

  static defaultProps = {
    sortBy: undefined,
    isAsc: undefined,
    data: [],
    selections: [],
    filtersEnabled: true,
    onSortChange: undefined,
    onSelectAll: undefined,
    keyExtractor: undefined,
  };

  constructor(props) {
    super(props);
    this.handleOnSortChange = this.handleOnSortChange.bind(this);
    this.handleOnSelectAll = this.handleOnSelectAll.bind(this);
  }

  handleOnSortChange(column) {
    if (this.props.onSortChange) {
      this.props.onSortChange(column);
    }
  }

  handleOnSelectAll() {
    if (this.props.onSelectAll) {
      const { data, selections } = this.props;
      const isAllSelected = this.isAllSelected(data, selections);

      this.props.onSelectAll({
        isAllSelected: isAllSelected,
        indeterminate: this.isIndeterminate(data, selections, isAllSelected),
      });
    }
  }

  isAllSelected(data, selections) {
    const { keyExtractor } = this.props;

    if (!keyExtractor) {
      return false;
    }

    const selectedIds = selections.map(s => keyExtractor(s));
    const dataIds = data.map(s => keyExtractor(s));
    const differenceAB = difference(dataIds, selectedIds);

    return differenceAB.length === 0;
  }

  isIndeterminate(data, selections, isAllSelected) {
    const { keyExtractor } = this.props;

    if (!keyExtractor || isAllSelected) {
      return false;
    }

    const selectedIds = selections.map(s => keyExtractor(s));
    const dataIds = data.map(s => keyExtractor(s));
    const differenceAB = difference(dataIds, selectedIds);

    if (differenceAB.length === dataIds.length) {
      return false;
    }

    return differenceAB.length < dataIds.length;
  }

  render() {
    const { data, sortBy, isAsc, selections, filtersEnabled, onSelectAll, classes } = this.props;

    const columns = [...this.props.columns];

    if (onSelectAll) {
      const isAllSelected = this.isAllSelected(data, selections);
      const isIndeterminate = this.isIndeterminate(data, selections, isAllSelected);

      columns.unshift(
        <CheckboxCell
          key="select"
          onChange={this.handleOnSelectAll}
          checked={isAllSelected}
          indeterminate={isIndeterminate}
          hideBorderBottom={filtersEnabled}
          bgColor="primary"
          bgColorVariant={'light1'}
          vAlign="center"
        />
      );
    }

    return (
      <div className={classes.container}>
        {columns.map((column, index) => {
          if (React.isValidElement(column)) {
            return column;
          }

          if (column.Extender) {
            return (
              <Cell
                key={index}
                width={column.width}
                bgColor="primary"
                bgColorVariant={sortBy !== column.colName ? 'light1' : 'light2'}
                hideBorderBottom={filtersEnabled}
              />
            );
          }

          return (
            <SortHeaderCell
              key={index}
              column={column}
              onClickCell={this.handleOnSortChange}
              sortBy={sortBy}
              isAsc={isAsc}
              bgColor="primary"
              bgColorVariant={sortBy !== column.colName ? 'light1' : 'light2'}
              hideBorderBottom={filtersEnabled}
            />
          );
        })}
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    borderTop: cellBorderStyle(theme),
    borderLeft: cellBorderStyle(theme),
    width: props => sumColumnWidths(props.columns),
    borderCollapse: 'collapse',
    display: 'flex',
    flexDirection: 'row',
  },
});

export default injectSheet(styles)(SortHeader);
