import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import injectSheet from 'react-jss';
import { jssSize } from '../util/jss';
import { propTypeSize } from '../util/propTypes';

/**
 * A link renders as an html anchor.
 */
class Link extends PureComponent {
  static propTypes = {
    /**
     * Should be a block element.
     */
    block: PropTypes.bool,

    /**
     * Bold text.
     */
    bold: PropTypes.bool,

    /**
     * Capitalize text.
     */
    caps: PropTypes.bool,

    /**
     * Children components.
     */
    children: PropTypes.node,

    /**
     * JSS classes.
     */
    classes: PropTypes.object.isRequired,

    /**
     * Additional className to style the text.
     */
    className: PropTypes.string,

    /**
     * Elipsis when text overflows
     */
    elipsis: PropTypes.bool,

    /**
     * Links to...
     */
    href: PropTypes.string,

    /**
     * Do not allow text to wrap.
     */
    nowrap: PropTypes.bool,

    /**
     * Links to...
     */
    onClick: PropTypes.func,

    /**
     * Text size.
     */
    size: propTypeSize,

    /**
     * Title
     */
    title: PropTypes.string,

    /**
     * Href target
     */
    target: PropTypes.string,
  };

  static defaultProps = {
    block: false,
    caps: false,
    children: undefined,
    className: undefined,
    elipsis: false,
    href: undefined,
    nowrap: false,
    onClick: undefined,
    size: undefined,
    title: undefined,
    target: undefined,
  };

  renderClassNames() {
    return [
      /**
       * Base anchor styles.
       */
      this.props.classes.anchor,

      /**
       * Size.
       */
      jssSize(this.props),

      /**
       *  Addiditonal style selector.
       */
      this.props.className,
    ].join(' ');
  }

  render() {
    const { href, onClick, title, children, target } = this.props;

    const classNames = this.renderClassNames();

    return (
      <a
        href={href}
        onClick={onClick}
        title={title}
        aria-label={title}
        className={classNames}
        target={target}
      >
        {children}
      </a>
    );
  }
}

const styles = theme => ({
  anchor: {
    ...theme.typography.text,
    color: `${theme.color.blue.base} !important`,
    fontWeight: props => (props.bold ? 'bold' : undefined),
    display: props => (props.block ? 'block' : 'inline-block'),
    userSelect: 'none',
    whiteSpace: props => (props.nowrap ? 'nowrap' : undefined),
    textOverflow: props => (props.elipsis ? 'ellipsis' : undefined),
    overflow: props => (props.elipsis ? 'hidden' : undefined),
    textTransform: props => (props.caps ? 'uppercase' : undefined),
    textDecoration: 'none',

    '&:hover': {
      color: `${theme.color.blue.dark4} !important`,
      cursor: props => (props.onClick ? 'pointer' : undefined),
    },

    '&:focus': {
      color: `${theme.color.blue.dark4} !important`,
    },

    '&:active': {
      color: `${theme.color.blue.dark4} !important`,
    },

    '&:visited': {
      color: `${theme.color.blue.base}`,
    },
  },

  smallest: {
    ...theme.typography.smallest,
  },

  smaller: {
    ...theme.typography.smaller,
  },

  small: {
    ...theme.typography.small,
  },

  medium: {
    ...theme.typography.medium,
  },

  large: {
    ...theme.typography.large,
  },

  larger: {
    ...theme.typography.larger,
  },

  largest: {
    ...theme.typography.largest,
  },
});

export default injectSheet(styles)(Link);
