import VectorLayer from 'ol/layer/Vector';
import Map from 'ol/Map';
import VectorSource from 'ol/source/Vector';
import PropTypes from 'prop-types';
import { Component } from 'react';

/**
 * Layer component for displaying an array of Openlayers features.
 */
export default class FeaturesLayer extends Component {
  /**
   * Property types.
   */
  static propTypes = {
    /**
     * Children.
     */
    children: PropTypes.node,
    /**
     * Features to display in this layer.
     */
    features: PropTypes.array,
    /**
     * A unique key to indentify this layer.
     */
    id: PropTypes.string,
    /**
     * The instance of map this layer is attached to. The map instance
     * will be provided to the component automatically when used as a
     * child component of the <Map/>.
     */
    map: PropTypes.instanceOf(Map),
    /**
     * Called when the feature is just about to be added to the vector source.
     */
    onAddFeature: PropTypes.func,
    /**
     * Called when layer has been added to the map.
     */
    onAddLayer: PropTypes.func,
    /**
     * The style function applied to each feature.
     */
    onFeatureStyle: PropTypes.func,
    /**
     * Called when layer has been removed from the map.
     */
    onRemoveLayer: PropTypes.func,
    /**
     * Called after vector source populated with features.
     */
    onAfterPopulateFeatures: PropTypes.func,
    /**
     * Whether or not this layer is visible.
     */
    visible: PropTypes.bool,
    /**
     * The z-index to order this layer in the map layers stack.
     */
    zIndex: PropTypes.number,
  };

  /**
   * Default properties for those props that are not required.
   */
  static defaultProps = {
    children: null,
    features: undefined,
    id: Math.random() * 1000000 + '',
    map: undefined,
    onAddFeature: undefined,
    onAddLayer: undefined,
    onFeatureStyle: undefined,
    onRemoveLayer: undefined,
    visible: true,
    zIndex: 999,
  };

  constructor(props) {
    super(props);

    /**
     * Provides source of features for vector layers.
     */
    this.vectorSource = new VectorSource();

    /**
     * The layer hosting the source vector.
     */
    this.vectorLayer = new VectorLayer({
      id: props.id,
      visible: props.visible,
      source: this.vectorSource,
      style: props.onFeatureStyle,
      zIndex: props.zIndex,
    });
  }

  componentDidMount() {
    this.props.map.getLayers().push(this.vectorLayer);

    if (this.props.onAddLayer) {
      this.props.onAddLayer(this.vectorLayer);
    }

    this.popuplateLayerFeatures();
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible) {
      this.vectorLayer.setVisible(this.props.visible);
    }

    if (this.props.visible && this.props.features !== prevProps.features) {
      this.popuplateLayerFeatures();
    }
  }

  componentWillUnmount() {
    this.props.map.removeLayer(this.vectorLayer);

    if (this.props.onRemoveLayer) {
      this.props.onRemoveLayer(this.vectorLayer);
    }

    this.vectorSource = null;
    this.vectorLayer = null;
  }

  /**
   * Adds the features to the vector layer.
   */
  popuplateLayerFeatures() {
    this.vectorSource.clear();

    if (this.props.features && this.props.features.length > 0) {
      this.props.features.forEach(f => {
        if (this.props.onAddFeature) {
          this.props.onAddFeature(f);
        }
        this.vectorSource.addFeature(f);
      });

      if (this.props.onAfterPopulateFeatures) {
        this.props.onAfterPopulateFeatures({ vectorSource: this.vectorSource });
      }
    }
  }

  render() {
    return this.props.children || null;
  }
}
