import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * List of resize listeners.
 */
const LISTENERS = [];

/**
 * The debounced function instance.
 */
let FUNC_DEBOUNCED_RESIZE;

/**
 * Time to rebounce in ms.
 */
const DEBOUNCE_TIME = 300;

/**
 * React component for listening to window resize events.
 *
 * Inspired by...
 *
 * https://github.com/cesarandreu/react-window-resize-listener
 */
export default class WindowResizeListener extends React.Component {
  static propTypes = {
    /**
     * Resize handler.
     */
    onResize: PropTypes.func.isRequired,
  };

  /**
   * Setup the debounced function if not already and push the onResize prop onto the statck of
   * listeners.
   */
  componentDidMount() {
    /**
     * Defer creating debouncedResize until it's mounted. This allows users to change DEBOUNCE_TIME
     * if they want If there's no listeners, we need to attach the window listener.
     */
    if (!LISTENERS.length) {
      FUNC_DEBOUNCED_RESIZE = debounce(this.handleOnResize, DEBOUNCE_TIME);
      //FUNC_DEBOUNCED_RESIZE = this.handleOnResize;
      window.addEventListener('resize', FUNC_DEBOUNCED_RESIZE, false);
    }

    LISTENERS.push(this.props.onResize);
    FUNC_DEBOUNCED_RESIZE();
  }

  /**
   * Update the listener stack with the update onResize func prop.
   */
  componentWillReceiveProps(nextProps) {
    if (nextProps.onResize !== this.props.onResize) {
      const idx = LISTENERS.indexOf(this.props.onResize);
      LISTENERS.splice(idx, 1, nextProps.onResize);
    }
  }

  /**
   * Cleanup.
   */
  componentWillUnmount() {
    const idx = LISTENERS.indexOf(this.props.onResize);

    LISTENERS.splice(idx, 1);
    if (!LISTENERS.length) {
      window.removeEventListener('resize', FUNC_DEBOUNCED_RESIZE, false);
    }
  }

  /**
   * Never update, unless the onResize func changes.
   */
  shouldComponentUpdate(nextProps) {
    return nextProps.onResize !== this.props.onResize;
  }

  /**
   * Resize handler - gets the window size and calls each listener.
   */
  handleOnResize() {
    const windowWidth =
      window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    const windowHeight =
      window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    LISTENERS.forEach(function(listener) {
      listener({
        windowWidth: windowWidth,
        windowHeight: windowHeight,
      });
    });
  }

  render() {
    return null;
  }
}
