import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Text } from '../../../../../../../lib/base';
import ExportPanel from './ExportPanel';
import { fetchExportGroupsIfNeeded } from '../../../../../../../store/export/export.action';
import SpinnerPanel from '../../../../../../components/spinner/SpinnerPanel';

class ExportContainer extends Component {
  componentDidMount() {
    this.props.dispatchFetchExportGroupTypes();
  }

  render() {
    if (this.props.isLoading || this.props.csvIsLoading) {
      return <SpinnerPanel />;
    }

    if (this.props.error) {
      return <Text>{this.props.error}</Text>;
    }

    if (this.props.exportGroups) {
      return <ExportPanel exportGroups={this.props.exportGroups} />;
    }

    return null;
  }
}

function mapStateToProps(state) {
  return {
    isLoading: state.exportData.exportTypeGroups.isLoading,
    csvIsLoading: state.exportData.csv.isLoading,
    error: state.exportData.exportTypeGroups.error,
    exportGroups: state.exportData.exportTypeGroups.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchFetchExportGroupTypes: () => {
      dispatch(fetchExportGroupsIfNeeded());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExportContainer);
