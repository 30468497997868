import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Box, Text } from '../../../../../../../lib/base';
import Modal from '../../../../../../../lib/base/basic/Modal';
import HeadingText from '../../../../../../components/text/HeadingText';

export default class UserDeniedGeoLocationModal extends Component {
  static propTypes = {
    /**
     * Close modal callback.
     */
    onRequestClose: PropTypes.func.isRequired,

    /**
     * Is modal visible
     */
    visible: PropTypes.bool.isRequired,
  };

  render() {
    return (
      <Modal
        title={() => <HeadingText>User Denied GeoLocation</HeadingText>}
        isOpen={this.props.visible}
        onRequestClose={this.props.onRequestClose}
      >
        <Box pad="medium">
          <Text size="small">
            We tried to zoom to your current location but it looks like you have you disabled
            geo-location requests for this website.
          </Text>
        </Box>
      </Modal>
    );
  }
}
