import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { Box, Column, Columns, DataGrid } from '../../../../lib/base';
import { COLOR_BASE_PRIMARY } from '../../../Constants';
import { IconMinus, IconPlus } from '../../icons';
import HeadingText from '../../text/HeadingText';
import ChemicalMix from '../chemical/ChemicalMix';
import CasLink from './CasLink';
import { isSmallScreen } from '../../../util/browser';
import LabelValuePair from '../../lv/LabelValuePair';
import Label from '../../lv/Label';

const CommonName = ({ name }) => {
  return <LabelValuePair label="Common Name" value={name} />;
};

const EHSName = ({ ehsName }) => {
  return <LabelValuePair label="EHS Name" value={ehsName} />;
};

const HazardTypes = ({ hazardTypes }) => {
  return <LabelValuePair label="Hazard Type(s)" value={hazardTypes} />;
};

const DotHazardClass = ({ dotHazClass }) => {
  return <LabelValuePair label="DOT Hazard Class" value={dotHazClass} />;
};

const CasNumber = ({ number, url, mobileUrl }) => {
  return (
    <Box stack="large">
      <Box stack="smaller">
        <Label label="CAS Number" />
      </Box>
      <CasLink casNumber={number} url={url} mobileUrl={mobileUrl} />
    </Box>
  );
};

const HealthEffects = ({ value }) => {
  return <LabelValuePair label="Health Effect(s)" value={value} />;
};

class ChemicalsDataGrid extends Component {
  static propTypes = {
    /**
     * The data set.
     */
    data: PropTypes.array.isRequired,

    /**
     * Is data loading.
     */
    isLoading: PropTypes.bool.isRequired,

    /**
     * Callback to fetch data.
     */
    onRequestData: PropTypes.func.isRequired,

    /**
     * The page number of the data set.
     */
    page: PropTypes.number.isRequired,

    /**
     * The page size of the data set.
     */
    pageSize: PropTypes.number.isRequired,

    /**
     * Total number of records in data set.
     */
    total: PropTypes.number.isRequired,

    /**
     * The property name to sort the results by.
     */
    sortBy: PropTypes.string,

    /**
     * Is the sort ascending?
     */
    isAsc: PropTypes.bool,

    /**
     * The criteria object.
     */
    criteria: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.renderSubSection = this.renderSubSection.bind(this);
  }

  renderSubSectionDetail(chemical) {
    const { detailColumn } = this.props.classes;

    return isSmallScreen() ? (
      <div>
        <CommonName name={chemical.commonName} />
        <EHSName ehsName={chemical.ehsName} />
        <HazardTypes hazardTypes={chemical.tier2HazardLabel} />
        <DotHazardClass dotHazClass={chemical.dotHazClass} />
        <CasNumber number={chemical.casNumber} url={chemical.url} mobileUrl={chemical.urlMobile} />
        <HealthEffects value={chemical.tier2HealthEffectLabel} />
      </div>
    ) : (
      <Columns wrap gutter="medium">
        <Column className={detailColumn}>
          <CommonName name={chemical.commonName} />
        </Column>

        <Column className={detailColumn}>
          <EHSName ehsName={chemical.ehsName} />
        </Column>

        <Column className={detailColumn}>
          <HazardTypes hazardTypes={chemical.tier2HazardLabel} />
        </Column>

        <Column className={detailColumn}>
          <DotHazardClass dotHazClass={chemical.dotHazClass} />
        </Column>

        <Column className={detailColumn}>
          <CasNumber
            number={chemical.casNumber}
            url={chemical.url}
            mobileUrl={chemical.urlMobile}
          />
        </Column>

        <Column className={detailColumn}>
          <HealthEffects value={chemical.tier2HealthEffectLabel} />
        </Column>
      </Columns>
    );
  }

  renderSubSectionChemicalMixes(chemical) {
    if (chemical.chemicalMixes.length === 0) {
      return <LabelValuePair label="Chemical Mixes" value={null} />;
    }

    return isSmallScreen() ? (
      <div>
        <Box stack="medium">
          <Label label="Chemical Mixes" />
        </Box>
        {chemical.chemicalMixes.map(cm => (
          <ChemicalMix key={cm.tier2ChemicalMixSeq} chemicalMix={cm} chemical={chemical} />
        ))}
      </div>
    ) : (
      <div>
        <Box stack="small">
          <Label label="Chemical Mixes" />
        </Box>
        <Columns wrap gutter="small">
          {chemical.chemicalMixes.map(cm => (
            <Column key={cm.tier2ChemicalMixSeq}>
              <ChemicalMix chemicalMix={cm} chemical={chemical} />
            </Column>
          ))}
        </Columns>
      </div>
    );
  }

  renderSubSection(chemical) {
    return (
      <Box pad="medium" pLeft="large">
        {this.renderSubSectionDetail(chemical)}
        {this.renderSubSectionChemicalMixes(chemical)}
      </Box>
    );
  }

  columns() {
    return [
      {
        headerLabel: '',
        Extender: (chemical, isOpen) => {
          return (
            <Box textAlign="center">
              {isOpen ? (
                <IconMinus color={COLOR_BASE_PRIMARY} />
              ) : (
                <IconPlus color={COLOR_BASE_PRIMARY} />
              )}
            </Box>
          );
        },
        width: 32,
      },

      {
        headerLabel: 'Name',
        accessor: 'chemicalName',
        width: 350,
        colName: 'CHEMICAL_NAME',
        criteriaPropName: 'chemicalName',
      },

      {
        headerLabel: 'Max Daily Amount / Unit',
        accessor: chemical => `${chemical.maxDailyAmountRange} ${chemical.maxDailyAmountUnit}`,
        width: 220,
        colName: 'MAX_DAILY_AMOUNT_RANGE',
        criteriaPropName: 'maxDailyAmountRange',
      },

      {
        headerLabel: 'Avg Daily Amount / Unit',
        accessor: chemical =>
          `${chemical.averageDailyAmountRange} ${chemical.averageDailyAmountUnit}`,
        width: 220,
        colName: 'AVERAGE_DAILY_AMOUNT_RANGE',
        criteriaPropName: 'averageDailyAmountRange',
      },

      {
        headerLabel: 'Days Onsite',
        accessor: chemical => chemical.daysOnSite,
        width: 125,
        colName: 'DAYS_ONSITE',
        criteriaPropName: 'daysOnSite',
      },

      {
        headerLabel: 'Physical State(S)',
        accessor: chemical => chemical.tier2PhysicalStateLabel,
        width: 165,
        colName: 'TIER2_PHYSICAL_STATE_LABEL',
        criteriaPropName: 'tier2PhysicalStateLabel',
      },
    ];
  }

  render() {
    return (
      <React.Fragment>
        <Box stack="medium">
          <HeadingText>Chemicals</HeadingText>
        </Box>
        <DataGrid
          columns={this.columns()}
          data={this.props.data}
          keyExtractor={row => row.tier2ChemicalSeq}
          onRequestData={this.props.onRequestData}
          page={this.props.page}
          pageSize={this.props.pageSize}
          total={this.props.total}
          sortBy={this.props.sortBy}
          isAsc={this.props.isAsc}
          criteria={this.props.criteria}
          loading={this.props.isLoading}
          striped
          showPaginationBottom={this.props.total > 5}
          renderSubsection={this.renderSubSection}
        />
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  detailColumn: {
    width: `calc(33% - ${theme.spacing.medium})`,
  },
});

export default injectSheet(styles)(ChemicalsDataGrid);
