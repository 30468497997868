import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Text } from '../../../lib/base';

export default class Value extends Component {
  static propTypes = {
    /**
     * Value is empty.
     */
    empty: PropTypes.bool,

    /**
     * Value to render.
     */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    empty: false,
  };

  render() {
    return (
      <Text
        size="smaller"
        title={this.props.value}
        block
        color="gray"
        colorVariant={this.props.empty ? 'light5' : 'dark6'}
      >
        {this.props.value}
      </Text>
    );
  }
}
