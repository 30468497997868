import { MAP_STARTUP_SUCCESS } from '../map/map.actionType';
import { REPORT_ERROR } from './system.actionType';
import { getAppMode } from '../../app/util/urlParams';

/**
 * Initial state.
 */
const initialState = {
  layers: {
    isLoading: false,
    data: undefined,
    error: undefined,
  },
  mode: undefined,
  errors: {},
};

/**
 * System reducer function.
 *
 * @param state
 * @param action
 * @returns {*}
 */
const system = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case MAP_STARTUP_SUCCESS:
      return Object.assign({}, state, {
        layers: {
          ...state.layers,
          data: payload.layers,
        },
        mode: getAppMode(),
      });

    case REPORT_ERROR:
      return Object.assign({}, state, {
        errors: {
          ...state.errors,
          ...payload.error,
        },
      });

    default:
      return Object.assign({}, state, {});
  }
};
export default system;
