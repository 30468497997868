import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { IconCheck } from '../../components/icons/fontawesome/icons';
import { Box, Column, Columns, Text, Button } from '../../../lib/base';
import { COLOR_BASE_PRIMARY, COLOR_BASE_RED } from '../../Constants';
import Labeled from '../../components/form/Labeled';
import TextInput from '../../components/form/TextInput';
import { isEmail } from '../../util/string';
import Config from '../../config';
import Reaptcha from 'reaptcha';
import { getCookieByName } from '../../util/cookieHelper';
import SpinnerPanel from '../../components/spinner/SpinnerPanel';
import ClaimSitesModal from '../../components/modal/ClaimSitesModal';
import HeadingText from '../../components/text/HeadingText';

const api = () => window.nSITE.Api;

class ClaimSite extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    accountId: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.userData = JSON.parse(decodeURIComponent(getCookieByName('userData')));
    this.handleOnClickClaim = this.handleOnClickClaim.bind(this);
    this.handleOnNameChange = this.handleOnNameChange.bind(this);
    this.handleOnPhoneChange = this.handleOnPhoneChange.bind(this);
    this.handleOnEmailChange = this.handleOnEmailChange.bind(this);
    this.onVerify = this.onVerify.bind(this);
    this.onExpire = this.onExpire.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handleOnCloseModal = this.handleOnCloseModal.bind(this);

    this.captcha = null;
    this.recaptchaSiteKey = Config.getRecaptchaSiteKey();

    this.state = {
      claimFormVisible: false,
      claimUserEmail: this.userData ? this.userData.email : undefined,
      claimUserName: this.userData ? this.userData.displayName : undefined,
      claimUserPhone: this.userData ? this.userData.phone : undefined,
      claimEmailIsValid: this.userData && this.userData.email ? true : false,
      claimPhoneIsValid: this.userData && this.userData.phone ? true : false,
      claimNameIsValid: this.userData && this.userData.displayName ? true : false,
      verified:
        this.recaptchaSiteKey === null || this.recaptchaSiteKey === undefined ? true : false,
      isSubmitting: false,
      showModal: false,
    };
  }

  handleOnClickClaim() {
    this.setState({ claimFormVisible: !this.state.claimFormVisible });
  }

  handleOnCloseModal() {
    this.setState({ showModal: false });
  }

  handleOnNameChange(value) {
    const isValid = value && value.length > 3 ? true : false;
    this.setState({ claimUserName: value, claimNameIsValid: isValid });
  }

  handleOnPhoneChange(value) {
    const isValid = value && value.length > 3 ? true : false;
    this.setState({ claimUserPhone: value, claimPhoneIsValid: isValid });
  }

  handleOnEmailChange(value) {
    let isValid = isEmail(value);
    this.setState({ claimUserEmail: value, claimEmailIsValid: isValid });
  }

  submitForm() {
    let formParams = {
      displayName: this.state.claimUserName,
      email: this.state.claimUserEmail,
      phone: this.state.claimUserPhone,
      accountId: this.props.accountId,
    };
    this.setState({
      isSubmitting: true,
    });
    api()
      .site.claimSite(formParams)
      .then(result => {
        this.setState({
          isSubmitting: false,
          claimFormVisible: false,
          verified: false || this.recaptchaSiteKey == null,
        });
        var errorMsg;
        if (result.responseStatus && result.responseStatus.message) {
          errorMsg = result.responseStatus.message;
        } else {
          errorMsg = 'An error has occured, please contact site adminstrator';
        }
        result.insertUpdateList
          ? this.setState({
              showModal: true,
              modalBgColor: null,
              modalBodyText:
                'Thank you, your claim request has been received. The agency will notify you via email when the request has been processed. The agency may contact you with additional questions to help verify the request.',
              modalHeadingText: 'Claim Site Submission Success',
            })
          : this.setState({
              showModal: true,
              modalBgColor: COLOR_BASE_RED,
              modalBodyText: errorMsg,
              modalHeadingText: 'An Error Has Occurred',
            });
      });
  }

  onVerify() {
    this.setState({
      verified: true,
    });
  }

  onExpire() {
    this.setState({
      verified: false,
    });
  }

  render() {
    if (this.state.showModal) {
      return (
        <ClaimSitesModal
          visible={this.state.showModal}
          onRequestClose={this.handleOnCloseModal}
          headingText={this.state.modalHeadingText}
          bodyText={this.state.modalBodyText}
          bgColor={this.state.modalBgColor}
        />
      );
    }
    if (this.state.isSubmitting) {
      return <SpinnerPanel></SpinnerPanel>;
    } else {
      return (
        <React.Fragment>
          <Box stack="large">
            {!this.state.claimFormVisible && (
              <Box onClick={this.handleOnClickClaim} className={this.props.classes.button}>
                <Text size="medium" bold color="white" className={this.props.classes.buttonText}>
                  Claim this Site
                </Text>
                <IconCheck color="white"></IconCheck>
              </Box>
            )}
            {this.state.claimFormVisible && (
              <Box>
                <Box stack="medium">
                  <HeadingText size="medium">Claim Site</HeadingText>
                </Box>
                <Labeled
                  borderColor={this.state.claimNameIsValid ? 'blue' : 'red'}
                  label="Name"
                  key="name"
                  renderView={() => (
                    <TextInput
                      onChange={event => this.handleOnNameChange(event.target.value)}
                      value={this.state.claimUserName}
                    />
                  )}
                />
                <Labeled
                  label="Phone"
                  key="phone"
                  borderColor={this.state.claimPhoneIsValid ? 'blue' : 'red'}
                  renderView={() => (
                    <TextInput
                      onChange={event => this.handleOnPhoneChange(event.target.value)}
                      value={this.state.claimUserPhone}
                    />
                  )}
                />
                <Labeled
                  label="Email"
                  key="email"
                  borderColor={this.state.claimEmailIsValid ? 'blue' : 'red'}
                  renderView={() => (
                    <TextInput
                      onChange={event => this.handleOnEmailChange(event.target.value)}
                      value={this.state.claimUserEmail}
                    />
                  )}
                />
                <Button
                  onClick={this.submitForm}
                  size="medium"
                  disabled={
                    !(
                      this.state.claimNameIsValid &&
                      this.state.claimEmailIsValid &&
                      this.state.claimPhoneIsValid &&
                      this.state.verified
                    )
                  }
                  type="default"
                  color={COLOR_BASE_PRIMARY}
                  title="Submit"
                >
                  Submit
                </Button>
                {this.recaptchaSiteKey && (
                  <Box className={this.props.classes.captchaCont}>
                    <Reaptcha
                      ref={e => (this.captcha = e)}
                      sitekey={this.recaptchaSiteKey}
                      onVerify={this.onVerify}
                      onExpire={this.onExpire}
                    />
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </React.Fragment>
      );
    }
  }
}

const styles = theme => ({
  button: {
    backgroundColor: theme.color[COLOR_BASE_PRIMARY]['dark2'],
    padding: theme.spacing.medium,
    borderRadius: 999,
    transition: 'background-color 120ms',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.color[COLOR_BASE_PRIMARY]['dark1'],
    },
  },
  buttonText: {
    marginRight: 12,
  },
  captchaCont: {
    marginBottom: `-${theme.spacing.medium}`,
    padding: theme.spacing.medium,
  },
});

export default injectSheet(styles)(ClaimSite);
