import React from 'react';
import Link from './Link';

export const createNavLink = (history, navItem, className) => {
  if (navItem.route) {
    return (
      <Link
        onClick={() => history.push({ pathname: navItem.route })}
        className={className}
        title={navItem.name}
      >
        {navItem.name}
      </Link>
    );
  } else {
    return (
      <Link target="_blank" href={navItem.url} className={className} title={navItem.name}>
        {navItem.name}
      </Link>
    );
  }
};
