import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Labeled from '../../../../../../../components/form/Labeled';
import SuggestTextInput from '../../../../../../../components/form/SuggestTextInput';

const api = () => window.nSITE.Api;

export default class AttributesSearchForm extends Component {
  static propTypes = {
    /**
     * Array of search attributes.
     */
    searchKeys: PropTypes.array.isRequired,

    /**
     * Search criteria.
     */
    advancedCritera: PropTypes.object.isRequired,

    /**
     * Called when form is submitted.
     */
    onSubmit: PropTypes.func.isRequired,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.stateUpdate) {
      return {
        stateUpdate: false,
      };
    }

    if (!isEqual(prevState.attributes, nextProps.advancedCritera)) {
      return {
        attributes: { ...nextProps.advancedCritera },
      };
    }

    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      attributes: { ...props.advancedCritera },
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnRemove = this.handleOnRemove.bind(this);
    this.handleOnKeyPress = this.handleOnKeyPress.bind(this);
  }

  handleOnChange(key, value) {
    const newAttributes = this.state.attributes;

    if (!value) {
      delete newAttributes[key];
    } else {
      newAttributes[key] = value;
    }

    this.setState({
      attributes: newAttributes,
      stateUpdate: true,
    });
  }

  handleOnSelect(key, value) {
    this.handleOnChange(key, value);
    this.props.onSubmit(this.state.attributes);
  }

  handleOnBlur(event, key) {
    if (this.props.advancedCritera[key] !== this.state.attributes[key]) {
      this.props.onSubmit(this.state.attributes);
    }
  }

  handleOnRemove(event, key) {
    const newState = { ...this.state.attributes };
    delete newState[key];

    this.setState({ attributes: newState }, () => {
      this.props.onSubmit(newState);
    });
  }

  handleOnKeyPress(event) {
    if (event.key === 'Enter') {
      this.props.onSubmit(this.state.attributes);
    }
  }

  render() {
    const { searchKeys } = this.props;

    if (!searchKeys) {
      return null;
    }

    return (
      <form>
        {searchKeys.map(sk => (
          <Labeled
            key={sk.key}
            label={sk.name}
            renderView={() => (
              <SuggestTextInput
                onRequestSuggestions={value => api().search.suggest(sk.id, value)}
                onChange={value => this.handleOnChange(sk.key, value)}
                onSelect={value => this.handleOnSelect(sk.key, value)}
                onClear={event => this.handleOnRemove(event, sk.key)}
                onBlur={(event, value) => this.handleOnBlur(event, sk.key, value)}
                onKeyPress={this.handleOnKeyPress}
                value={this.state.attributes[sk.key]}
              />
            )}
          />
        ))}
      </form>
    );
  }
}
