import TileLayer from 'ol/layer/Tile';
import Map from 'ol/Map';
import TileArcGISRestSource from 'ol/source/TileArcGISRest';
import PropTypes from 'prop-types';
import { Component } from 'react';

/**
 * Base layer component for viewing ArcGIS tiles.
 */
export default class ArcGISTileLayer extends Component {
  static propTypes = {
    /**
     * A unique key to identify this layer.
     */
    id: PropTypes.number.isRequired,

    /**
     * The instance of map this layer is attached to. The map instance
     * will be provided to the component automatically when used as a
     * child component of the <Map/>.
     */
    map: PropTypes.instanceOf(Map),

    /**
     * Metadata object.
     */
    metadata: PropTypes.object,

    /**
     * The opacity of the tiles.
     */
    opacity: PropTypes.number,

    /**
     * ArcGIS Rest parameters
     */
    params: PropTypes.object,

    /**
     * ArcGIS Rest service URL for a Map Service or Image Service. The url should include
     * /MapServer or /ImageServer.
     */
    url: PropTypes.string.isRequired,

    /**
     * Whether or not the layer is visible.
     */
    visible: PropTypes.bool,

    /**
     * The z-index for layer rendering. At rendering time, the layers will be ordered, first by
     * Z-index and then by position. The default Z-index is 0.
     */
    zIndex: PropTypes.number,

    /**
     * min zoom for layer
     */
    minZoom: PropTypes.number,

    /**
     * Max zoom for layer
     */
    maxZoom: PropTypes.number,

    /**
     * Called after all tiles loaded.
     */
    onTilesLoadComplete: PropTypes.func,

    /**
     * Extent of the map
     */
    extent: PropTypes.array,
  };

  static defaultProps = {
    id: Math.random() * 1000000 + '',
    map: undefined,
    opacity: 1,
    params: {},
    visible: true,
    zIndex: 999,
    minZoom: 1,
    maxZoom: 17,
  };

  constructor(props) {
    super(props);

    const source = this.newSource(props);

    this.tileLayer = new TileLayer({
      id: props.id,
      visible: props.visible,
      extent: props.extent,
      opacity: props.opacity,
      zIndex: props.zIndex,
      minZoom: props.minZoom,
      maxZoom: props.maxZoom,
      source: source,
    });

    this.tileLoadCounter = 0;

    /**
     * Metadata is set for layer use.
     */
    if (props.metadata) {
      this.tileLayer.set('metadata', props.metadata);
    }

    if (props.onTilesLoadComplete) {
      source.on('tileloadstart', this.handleOnTileLoadStart);
      source.on('tileloadend', this.handleOnTileLoadEnd);
    }
  }

  handleOnTileLoadStart = () => {
    this.tileLoadCounter = this.tileLoadCounter + 1;
  };

  handleOnTileLoadEnd = () => {
    this.tileLoadCounter = this.tileLoadCounter - 1;
    if (this.tileLoadCounter === 0) {
      this.props.onTilesLoadComplete();
    }
  };

  newSource(props) {
    return new TileArcGISRestSource({
      url: props.url,
      crossOrigin: 'anonymous',
      params: props.params,
      hidpi: false,
    });
  }

  componentDidMount() {
    this.props.map.getLayers().push(this.tileLayer);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible) {
      this.tileLayer.setVisible(this.props.visible);
    }

    if (prevProps.url !== this.props.url) {
      this.tileLayer.setSource(this.newSource(this.props));
    }
  }

  componentWillUnmount() {
    this.props.map.removeLayer(this.tileLayer);
    this.tileLayer = undefined;
  }

  render() {
    return null;
  }
}
