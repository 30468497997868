import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Box, Column, Columns } from '../../../../lib/base';
import ProfilePageLink from '../../../components/profile/header/ProfilePageLink';
import SiteNameAndAddress from '../../../components/profile/header/SiteNameAndAddress';
import { COLOR_BASE_PRIMARY } from '../../../Constants';
import { isSmallScreen } from '../../../util/browser';
import { getSiteId, toSiteNameAndAddress } from '../../../util/entities/siteProfile';
import SiteProfileTabs from './SiteProfileTabs';

export default class SiteProfileHeader extends PureComponent {
  static propTypes = {
    /**
     * Current tab.
     */
    tab: PropTypes.string.isRequired,

    /**
     * Site profile data.
     */
    siteProfile: PropTypes.shape({
      profileFacilityDetail: PropTypes.shape({
        siteName: PropTypes.string,
        address: PropTypes.string,
        city: PropTypes.string,
        zip: PropTypes.string,
        state: PropTypes.string,
      }),
    }).isRequired,
  };

  render() {
    const padding = isSmallScreen() ? 'medium' : 'large';

    return (
      <Box
        pLeft={padding}
        pRight={padding}
        pTop={padding}
        bgColor={COLOR_BASE_PRIMARY}
        bgColorVariant="light1"
      >
        <Columns vAlign="top" gutter="medium">
          <Column flex={1}>
            <SiteNameAndAddress site={toSiteNameAndAddress(this.props.siteProfile)} />
          </Column>
          <Column flex={0}>
            <Box pad="medium" pRight="small">
              <ProfilePageLink sourceIdentifier={getSiteId(this.props.siteProfile)} print />
            </Box>
          </Column>
        </Columns>
        <Columns column vAlign="bottom">
          <Column flex={1} style={{ overflow: 'hidden' }}>
            <Box textAlign="center" style={{ overflowX: 'auto' }}>
              <SiteProfileTabs tab={this.props.tab} />
            </Box>
          </Column>
        </Columns>
      </Box>
    );
  }
}
