/**
 * Superclass for all logical api endpoints providing common logic used in all endpoint subclasses.
 */
export default class BaseEndpoint {
  constructor(apiContext) {
    this.apiContext = apiContext;
  }

  /**
   * Returns the api context.
   * @returns {ApiContext} the api context.
   */
  get context() {
    return this.apiContext;
  }

  /**
   * Returns the full url path of the endpoint.
   *
   * @param path the relative path to the endpoint.
   * @returns {string} the complete url path.
   */
  url(path) {
    return `${this.context.baseUrl}${path}`;
  }

  /**
   * GET
   *
   * @param url the URL to fetch.
   * @param options additional options used to fetch.
   * @returns {Promise<Response>}
   */
  get(url, options) {
    return fetch(
      url,
      Object.assign(
        {
          method: 'GET',
        },
        options || {}
      )
    );
  }

  /**
   * POST
   *
   * @param url the URL to POST.
   * @param data params used to post.
   * @returns {Promise<Response>}
   */
  post(url, data) {
    return fetch(
      url,
      Object.assign({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
    );
  }

  /**
   * Transforms an object to a parameter string to use during URL construction. Each enumerable
   * property on the object is used at the parameter name and the property value is the value. An
   * example:   const obj = { foo: 'bar', foo2: 'bar2' } would translate to: "foo=bar&foo2=bar2"
   *
   * @param obj the object parameters.
   * @returns {string} parameter string.
   */
  toParameterString(obj) {
    const parameters = [];

    Object.keys(obj).forEach(key => {
      const value = obj[key];

      if (value instanceof Array) {
        value.forEach(arrayValue => parameters.push(`${key}=${arrayValue}`));
      } else if (!(value instanceof Object)) {
        if (value !== undefined && value !== null) {
          parameters.push(`${key}=${encodeURIComponent(value)}`);
        }
      } else if (value instanceof Object) {
        Object.keys(value).forEach(subkey => {
          if (value[subkey]) {
            parameters.push(`${key}.${subkey}=${encodeURIComponent(value[subkey])}`);
          }
        });
      }
    });

    return parameters.join('&');
  }
}
