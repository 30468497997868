import React, { PureComponent } from 'react';
import ReactJSON from 'react-json-view';
import injectSheet from 'react-jss';
import WindowDocumentTitle from '../../components/dom/WindowDocumentTitle';
import Config from '../../config';
import BaseStandardPage from '../base/BaseStandardPage';

class DebugPage extends PureComponent {
  renderValue(value) {
    if (typeof value === 'object') {
      return (
        <ReactJSON
          src={value}
          style={{ padding: '4px', size: '12px' }}
          name={null}
          displayObjectSize={false}
          displayDataTypes={false}
          sortKeys
          enableClipboard={false}
          collapsed={3}
        />
      );
    }

    if (typeof value === 'boolean') {
      return value === true ? 'true' : 'false';
    }

    return value;
  }

  render() {
    const { classes } = this.props;
    const settings = Config.all();
    const keys = Object.keys(settings).sort();

    const rows = keys.map(key => {
      return (
        <tr key={key}>
          <td className={classes.td}>{key}</td>
          <td className={classes.td}>{this.renderValue(settings[key])}</td>
        </tr>
      );
    });

    return (
      <BaseStandardPage>
        <WindowDocumentTitle title="Debug" />
        <table className={classes.table}>
          <thead>
            <tr>
              <th colSpan={2} className={classes.header}>
                <h5>Application Settings</h5>
              </th>
            </tr>
            <tr>
              <th className={classes.colHeader}>Key</th>
              <th className={classes.colHeader}>Value</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </BaseStandardPage>
    );
  }
}

const styles = () => ({
  table: {
    marginTop: '24px',
    marginBottom: '24px',
    maxWidth: '1200px',
    margin: '0 auto',
    fontSize: '13px',
  },

  header: {
    fontSize: '24px',
    fontWeight: 'bold',
    padding: '8px',
  },

  colHeader: {
    fontSize: '16px',
    fontWeight: 'bold',
    backgroundColor: '#efefef',
    textAlign: 'left',
    padding: '8px',
  },

  td: {
    padding: '8px',
    border: '1px solid #efefef',
  },
});

export default injectSheet(styles)(DebugPage);
