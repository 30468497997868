import {
  FETCH_EXPORTGROUPS_BEGIN,
  FETCH_EXPORTGROUPS_ERROR,
  FETCH_EXPORTGROUPS_SUCCESS,
  FETCH_EXPORTCSV_BEGIN,
  FETCH_EXPORTCSV_ERROR,
  FETCH_EXPORTCSV_SUCCESS,
} from './export.actionType';

/**
 *
 */
const api = () => window.nSITE.Api;

export function fetchExportGroupsIfNeeded() {
  return (dispatchEvent, getState) => {
    if (shouldFetchExportGroups(getState())) {
      return dispatchEvent(fetchExportGroups());
    }
    return undefined;
  };
}

function shouldFetchExportGroups(state) {
  return !state.exportData.exportTypeGroups.isLoading;
}

function fetchExportGroups() {
  return dispatch => {
    dispatch(fetchExportGroupsBegin());
    api()
      .exportGroups.getAll()
      .then(results => dispatch(fetchExportGroupsSuccess(results)))
      .catch(err => dispatch(fetchExportGroupsError(err.message)));
  };
}

function fetchExportGroupsSuccess(results) {
  return {
    type: FETCH_EXPORTGROUPS_SUCCESS,
    payload: { results },
  };
}

function fetchExportGroupsBegin() {
  return {
    type: FETCH_EXPORTGROUPS_BEGIN,
  };
}

function fetchExportCsvBegin(exportTypeId) {
  return {
    type: FETCH_EXPORTCSV_BEGIN,
    payload: exportTypeId,
  };
}

function fetchExportCsvError(message) {
  return {
    type: FETCH_EXPORTCSV_ERROR,
    payload: { error: message },
  };
}

function fetchExportCsvSuccess() {
  return {
    type: FETCH_EXPORTCSV_SUCCESS,
  };
}

function fetchExportGroupsError(message) {
  return {
    type: FETCH_EXPORTGROUPS_ERROR,
    payload: { error: message },
  };
}

export function fetchCsv(exportTypeId, fileName) {
  return (dispatch, getState) => {
    dispatch(fetchExportCsvBegin(exportTypeId));
    api()
      .exportGroups.downloadCsv(getState().map, exportTypeId, fileName)
      .then(results => dispatch(fetchExportCsvSuccess()))
      .catch(err => dispatch(fetchExportCsvError(err.message)));
  };
}
